import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {Col, Row} from "reactstrap";

import {setPageTitle, setBreadCrumbs, getJobTypes, getFitters, getClaimJobs} from "../../../store/actions";
import FilterContainer from "../../../components/Filter";
import ClaimJobsTable from "../../../components/Tables/ComplianceClaimJobsTable";
import {__} from "../../../utils/helpers";

class Claims extends Component {
    constructor(props) {
        super(props);

        this.cacheKeys = {
            filters: 'compliances.claims.jobs.filter',
        };

        this.state = {
            countries: [
                {
                    'code': 'de',
                    'name': 'Deutschland',
                    'icon': 'flag-icon flag-icon-de',
                },
                {
                    'code': 'at',
                    'name': 'Österreich',
                    'icon': 'flag-icon flag-icon-at',
                },
                {
                    'code': 'ch',
                    'name': 'Schweiz',
                    'icon': 'flag-icon flag-icon-ch',
                },
                {
                    'code': 'nl',
                    'name': 'Niederlande',
                    'icon': 'flag-icon flag-icon-nl',
                }
            ],
            filters: JSON.parse(localStorage.getItem(this.cacheKeys.filters)) || {
                jobTypes: [],
                countries: [],
                textSearch: "",
                fitters: []
            },
        };
    }

    componentDidMount() {
        this.setBreadCrumbs();
        this.setPageTitle();

        if(this.props.jobTypes.length === 0)
            this.props.getJobTypes();

        if(this.props.fitters.length === 0)
            this.props.getFitters();
    }

    setBreadCrumbs(){
        this.props.setBreadCrumbs([
            {
                name: 'Home',
                href: '/dashboard'
            },
            {
                name: __("claims"),
                route: '/compliances/claims',
            },
        ]);
    }

    setPageTitle(){
        this.props.setPageTitle(__("claims"));
    }

    onClearCountries = (e) => {
        const filters = {
            ...this.state.filters,
            countries: [],
        };
        this.setState({
            filters
        }, () => {
            localStorage.setItem(this.cacheKeys.filters, JSON.stringify(filters))
        });
    }

    onCountryClick = (e) => {
        const value = e.target.dataset.value;
        const {countries} = this.state.filters;
        const index = countries.findIndex((element) => element === value);

        if(index === -1){
            countries.push(value);
        } else {
            countries.splice(index, 1);
        }

        const filters = {
            ...this.state.filters,
            countries: countries,
        };

        this.setState({
            filters,
        }, () => {
            localStorage.setItem(this.cacheKeys.filters, JSON.stringify(filters))
        });

        //this.props.onFiltersUpdated(this.state.filters);
    }

    onClearJobTypes = (e) => {
        const filters = {
            ...this.state.filters,
            jobTypes: [],
        };

        this.setState({
            filters
        }, () => {
            localStorage.setItem(this.cacheKeys.filters, JSON.stringify(filters))
        });
    }

    onJobTypeClick = (e) => {
        const value = e.target.dataset.value;
        const {jobTypes} = this.state.filters;
        const index = jobTypes.findIndex((element) => element === value);

        if(index === -1){
            jobTypes.push(value);
        } else {
            jobTypes.splice(index, 1);
        }

        const filters = {
            ...this.state.filters,
            jobTypes: jobTypes,
        };

        this.setState({
            filters
        }, () => {
            localStorage.setItem(this.cacheKeys.filters, JSON.stringify(filters))
        });
    }

    onClearTextSearch = (e) => {
        const filters = {
            ...this.state.filters,
            textSearch: '',
        };

        this.setState({
            filters: filters
        }, () => {
            localStorage.setItem(this.cacheKeys.filters, JSON.stringify(filters))
        });
    }

    onSelectFitters = (values) => {
        const data = values === null ? [] : values.map(function(value){
            return value.value;
        });

        const filters = {
            ...this.state.filters,
            fitters: data,
        };

        this.setState({
            filters
        }, () => {
            localStorage.setItem(this.cacheKeys.filters, JSON.stringify(filters))
        });
    }

    onTextChange = (e) => {
        const filters = {
            ...this.state.filters,
            textSearch: e.target.value,
        };
        this.setState({
            filters
        }, () => {
            localStorage.setItem(this.cacheKeys.filters, JSON.stringify(filters))
        });
    }

    onFiltersUpdated = (filters) => {
        this.setState({
            filters
        }, () => {
            localStorage.setItem(this.cacheKeys.filters, JSON.stringify(filters))
        })
    };

    getJobs() {
        const {claimJobs} = this.props;

        if(!claimJobs || !typeof(claimJobs.data) === 'array')
            return [];

        return claimJobs.data;
    }

    setClaimJob = (job) => {
        this.props.dispatch({ type: 'GET_CLAIM_JOB_SUCCESS', payload: job });
    }

    getClaimJobs = (meta) => {
        this.props.getClaimJobs(
            meta
        );
    }

    render() {
        const {jobTypesLoading, jobTypes, fitters, claimJobs, claimJobsLoading} = this.props;
        const {countries, filters} = this.state;

        return (
            <React.Fragment>
                <div className="page-content">
                    <Row>
                        <Col xl="8">
                            <ClaimJobsTable
                                loading={claimJobsLoading}
                                getData={this.getClaimJobs}
                                filters={filters}
                                setClaimJob={this.setClaimJob}
                                meta={claimJobs.meta}
                                jobs={this.getJobs('open')}
                            />
                        </Col>
                        <Col xl="4">
                            <FilterContainer
                                horizontal={false}
                                loading={jobTypesLoading}
                                jobTypes={jobTypes}
                                countries={countries}
                                fitters={fitters.filter(fitter => fitter.status.status === 'active')}
                                filters={filters}
                                onFiltersUpdated={this.onFiltersUpdated}
                                onClearCountries={this.onClearCountries}
                                onCountryClick={this.onCountryClick}
                                onClearJobTypes={this.onClearJobTypes}
                                onJobTypeClick={this.onJobTypeClick}
                                onClearTextSearch={this.onClearTextSearch}
                                onTextChange={this.onTextChange}
                                onSelectFitters={this.onSelectFitters}
                            />
                        </Col>
                    </Row>
                </div>
            </React.Fragment>
        )
    }
}


const mapStatetoProps = (state) => {
    const { jobTypes, jobTypesLoading } = state.JobTypes;
    const { fitters, fittersLoading } = state.Fitters;
    const { claimJobs, claimJobsLoading } = state.Compliances;

    return {
        jobTypes,
        jobTypesLoading,
        fitters,
        fittersLoading,
        claimJobs,
        claimJobsLoading,
    };
};

function mapDispatchToProps(dispatch) {
    let actions = bindActionCreators({ setPageTitle, setBreadCrumbs, getJobTypes, getFitters, getClaimJobs }, dispatch);
    return { ...actions, dispatch };
}


export default withRouter(
    connect(mapStatetoProps, mapDispatchToProps)(Claims)
);
