export const GET_COMPLIANCE_RANDOM_JOB = "GET_COMPLIANCE_RANDOM_JOB";
export const GET_COMPLIANCE_RANDOM_JOB_SUCCESS = "GET_COMPLIANCE_RANDOM_JOB_SUCCESS";
export const RESET_COMPLIANCE_RANDOM_JOB = "RESET_COMPLIANCE_RANDOM_JOB";
export const RESET_COMPLIANCE_RANDOM_JOB_SUCCESS = "RESET_COMPLIANCE_RANDOM_JOB_SUCCESS";

export const ADD_COMPLIANCE_JOB = "ADD_COMPLIANCE_JOB";
export const ADD_COMPLIANCE_JOB_SUCCESS = "ADD_COMPLIANCE_JOB_SUCCESS";
export const ADD_COMPLIANCE_JOB_ERROR = "ADD_COMPLIANCE_JOB_ERROR";


export const GET_COMPLIANCE_JOB = "GET_COMPLIANCE_JOB";
export const GET_COMPLIANCE_JOB_SUCCESS = "GET_COMPLIANCE_JOB_SUCCESS";

export const GET_COMPLIANCE_JOBS = "GET_COMPLIANCE_JOBS";
export const GET_COMPLIANCE_JOBS_SUCCESS = "GET_COMPLIANCE_JOBS_SUCCESS";

export const GET_COMPLIANCE = "GET_COMPLIANCE";
export const GET_COMPLIANCE_SUCCESS = "GET_COMPLIANCE_SUCCESS";

export const GET_COMPLIANCES = "GET_COMPLIANCES";
export const GET_COMPLIANCES_SUCCESS = "GET_COMPLIANCES_SUCCESS";

export const SAVE_COMPLIANCE_JOB_QUESTIONS = "SAVE_COMPLIANCE_JOB_QUESTIONS";
export const SAVE_COMPLIANCE_JOB_QUESTIONS_SUCCESS = "SAVE_COMPLIANCE_JOB_QUESTIONS_SUCCESS";

export const GET_CLAIM_JOBS = "GET_CLAIM_JOBS";
export const GET_CLAIM_JOBS_SUCCESS = "GET_CLAIM_JOBS_SUCCESS";

export const GET_CLAIM_JOB = "GET_CLAIM_JOB";
export const GET_CLAIM_JOB_SUCCESS = "GET_CLAIM_JOB_SUCCESS";

export const GET_CLAIM_TEMPLATES = "GET_CLAIM_TEMPLATES";
export const GET_CLAIM_TEMPLATES_SUCCESS = "GET_CLAIM_TEMPLATES_SUCCESS";

export const GET_CLAIM_PRIORITIES = "GET_CLAIM_PRIORITIES";
export const GET_CLAIM_PRIORITIES_SUCCESS = "GET_CLAIM_PRIORITIES_SUCCESS";


