import {call, takeEvery, put, takeLatest} from "redux-saga/effects";

import {
    getPbxCallSuccess,
    getPbxCallsSuccess,
    getPbxCallReasonsSuccess,
} from "./actions";

import {getApiBackend} from "../../api";
import queryString from "query-string";
import {flatten} from "q-flat";

const api    = getApiBackend();

/**
 * Changes the layout type
 * @param {*} param0
 */
function* getPbxCall({ payload: { id } }) {
    try {
        const response = yield call(api.get, '/v2/pbx/calls/'+ id)
        yield put(getPbxCallSuccess(response));

    } catch (error) { }
}

/**
 * Changes the layout type
 * @param {*} param0
 */
function* getPbxCalls({ payload: { meta } }) {
    try {
        const response = yield call(api.get, '/v2/pbx/calls?with=logs,job.type,job.status,record,number.jobType,number.jobSource&'+ queryString.stringify(flatten(meta), {encode:false}))

        yield put(getPbxCallsSuccess(response));

    } catch (error) { }
}

/**
 * Changes the layout type
 */
function* getPbxCallReasons() {
    try {
        const response = yield call(api.get, '/v2/pbx/calls/reasons')
        yield put(getPbxCallReasonsSuccess(response));

    } catch (error) { }
}

/**
 * Watchers
 */

function* PbxSaga() {
    yield takeEvery('GET_PBX_CALL', getPbxCall);
    yield takeLatest('GET_PBX_CALLS', getPbxCalls);
    yield takeLatest('GET_PBX_CALL_REASONS', getPbxCallReasons);
}

export default PbxSaga;
