import React, { Component } from "react";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {getJob, addJobStatus, addJobReset, patchJob, deleteJobPartialPayment} from "../../../store/actions";
import { Code } from 'react-content-loader';
import OverviewCard from "../../../components/Jobs/OverviewCard";
import StatusHistory from "../../../components/Jobs/StatusHistory";
import Claims from "../../../components/Jobs/Claims";
import MessageHistory from "../../../components/Jobs/MessageHistory";
import CallHistory from "../../../components/Jobs/CallHistory";
import {Button, ButtonGroup} from "reactstrap";
import AddStatusModal from "../../../components/Jobs/AddStatusModal";
import AddClaimModal from "../../../components/Jobs/AddClaimModal";
import SendMessageModal from "../../../components/Jobs/SendMessageModal";
import {toast} from "react-toastify";
import {__} from "../../../utils/helpers";
const MyCodeLoader = () => <Code />;

class Job extends Component {
    constructor(props) {
        super(props);

        this.toasts = {
            created: null,
        };
        this.cacheKeys = {
            activeTab: 'jobs.show.activeTab',
        }

        const job_id = parseInt(props.match.params.id);
        this.state = {
            job_id,
            activeTab: localStorage.getItem(this.cacheKeys.activeTab) || 'statusHistory',
        };
    }

    componentDidMount() {
        const {job_id} = this.state;
        this.props.getJob(job_id);

        document.title = __("job");
    }

    getJob(){
        return this.props.job;
    }

    hasOpenClaim(){
        const job = this.getJob();
        return job && job.claims && job.claims.findIndex((item) => item.state === 'open' ) !== -1;
    }

    requestWaitInfo = () => {
        const {job} = this.props;
        this.props.addJobStatus(job.id, {
            type: 'status',
            status: 'request-wait-info',
        });
    }

    requestCancellation = () => {
        const {job} = this.props;
        this.props.addJobStatus(job.id, {
            type: 'status',
            status: 'request-cancellation',
        });
    }

    render() {
        const {user, job, jobHistoryLoading, jobClaimsLoading, jobMessagesLoading, createdJob} = this.props;

        if(createdJob && this.toasts.created === null){
            console.log("createdJob", createdJob);
            this.toasts.created = toast.success(__("jobCreatedSuccess"), {
                onClose: props => {
                    this.props.addJobReset();
                    this.toasts.created = null;
                    console.log("onClose");
                },
                autoClose: 5000,
                closeButton: true,
                pauseOnHover: true,
                closeOnClick: true,
            });

            console.log(this.toasts.created);
        }

        if(!job)
            return "";

        const tableStyle = {
            maxHeight: '300px',
            overflowY: 'auto',
        };

        document.title = __("job") +" "+ job.code;

        return (
            <>
                <div className={"row"}>
                    <div className={'col-md-4'}>
                        <OverviewCard job={job}
                                      user={user}
                                      patchJob={this.props.patchJob}
                                      patchJobLoading={this.props.patchJobLoading}
                                      deleteJobPartialPayment={this.props.deleteJobPartialPayment}
                                      jobPartialPaymentsLoading={this.props.jobPartialPaymentsLoading} />
                    </div>
                    <div className={'col-md-8'}>
                        <div className={'card'}>
                            <div className="card-body d-flex justify-content-between align-items-center">
                                <h4 className="card-title m-b-0">{__("statusHistory")}</h4>
                                {!jobHistoryLoading && (
                                    <ButtonGroup>
                                        <Button size="xs" onClick={this.requestCancellation}><i className="fa fa-ban" /> {__("cancelButton")}</Button>
                                        <Button size="xs" onClick={this.requestWaitInfo}><i className="fa fa-stopwatch" /> {__("waitingTime")}</Button>
                                        <AddStatusModal job={job} size="xs" type="comment"><i className="fa fa-comment-dots" /> {__("comment")}</AddStatusModal>
                                        <AddStatusModal job={job} size="xs" color="inverse" type="status"><i className="fa fa-plus-square" /> {__("status")}</AddStatusModal>
                                    </ButtonGroup>
                                )}

                            </div>

                            {jobHistoryLoading ? (
                                <div className="card-body">
                                    <MyCodeLoader />
                                </div>
                            ) : (
                                <StatusHistory
                                    style={tableStyle}
                                    job={job}
                                />
                            )}

                        </div>

                        <div className={'card'}>
                            <div className="card-body d-flex justify-content-between align-items-center">
                                <h4 className="card-title m-b-0">{__("claims")}</h4>

                                {!jobClaimsLoading && (
                                    <ButtonGroup>
                                        <AddClaimModal job={job} size="xs" color="inverse" type="status"><i className="fa fa-plus-square" /> {__("claim")}</AddClaimModal>
                                    </ButtonGroup>
                                )}
                            </div>

                            {jobClaimsLoading ? (
                                <div className="card-body">
                                    <MyCodeLoader />
                                </div>
                            ) : (
                                <Claims
                                    style={tableStyle}
                                    job={job}
                                />
                            )}

                        </div>

                        <div className={'card'}>
                            <div className="card-body d-flex justify-content-between align-items-center">
                                <h4 className="card-title m-b-0">{__("messageHistory")}</h4>

                                {!jobMessagesLoading && (
                                    <ButtonGroup>
                                        <SendMessageModal job={job} size="xs" color="inverse" type="status"><i className="fa fa-plus-square" /> {__("message")}</SendMessageModal>
                                    </ButtonGroup>
                                )}
                            </div>
                            {jobMessagesLoading ? (
                                <div className="card-body">
                                    <MyCodeLoader />
                                </div>
                            ) : (
                                <MessageHistory
                                    style={tableStyle}
                                    job={job}
                                />
                            )}
                        </div>

                        <div className={'card'}>
                            <div className="card-body">
                                <h4 className="card-title m-b-0">{__("calls")}</h4>
                            </div>

                            <CallHistory
                                style={tableStyle}
                                job={job}
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapStatetoProps = state => {
    const { user } = state.Auth;
    const { job, jobHistoryLoading, jobClaimsLoading, jobMessagesLoading, createdJob, patchJobLoading, jobPartialPaymentsLoading } = state.Jobs;
    return { user, job, jobHistoryLoading, jobClaimsLoading, jobMessagesLoading, createdJob, patchJobLoading, jobPartialPaymentsLoading };
}

export default withRouter(connect(mapStatetoProps, { getJob, addJobStatus, addJobReset, patchJob, deleteJobPartialPayment })(Job));
