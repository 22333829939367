// @flow
import {
    SET_PAGE_TITLE_SUCCESS,
    SET_BREAD_CRUMBS_SUCCESS,
} from "./actionTypes";

const INIT_STATE = {
    title: "",
    breadcrumbs:  [],
};

const Layout = (state = INIT_STATE, action) => {

    switch (action.type) {

        case SET_PAGE_TITLE_SUCCESS:
            //console.log('SET_PAGE_TITLE_SUCCESS', action);
            return {
                ...state,
                title: action.payload.title,
            };
        case SET_BREAD_CRUMBS_SUCCESS:
            return {
                ...state,
                breadcrumbs: action.payload.breadcrumbs,
            };
        default:
            return state;
    }
};


export default Layout;
