import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import React, {Component} from "react";
import Moment from "react-moment";
import {countriesMap, formatDisplayPhoneNumber, __} from "../../utils/helpers";
import {getPbxCallReasons, patchApiCall} from "../../store/actions";
import ExternalLink from "../Buttons/ExternalLink";
import Label from "../Form/Label";
import {Button} from "reactstrap";

const phoneStateLabelMap = {
    ringing: __("incomingCall"),
    connecting: __("dialing"),
    connected: __("connected"),
    dialed: __("ended"),
    received: __("ended"),
    missed: __("missed")
};

const phoneStateColorMap = {
    ringing: 'warning',
    connecting: 'warning',
    connected: 'success',
    dialed: 'info',
    received: 'info',
    missed: 'danger'
}

class Calls extends Component {

    constructor(props) {
        super(props);

        this.state = {
            call_edit: null,
            input: {
                reason_id: 0,
                call_comment: "",
            }
        }
    }

    componentDidMount() {
        const {callReasonsLoading, getPbxCallReasons} = this.props;

        if(!callReasonsLoading) {
            getPbxCallReasons();
        }
    }

    hasDuplicateCall(key, value){
        const {calls} = this.props;

        return calls.filter((item) => (item[key] === value)).length > 1;
    }

    commentIsRequired(reason_id) {
        const {callReasons} = this.props;

        const callReason = callReasons.find((item) => {
            return item.id === parseInt(reason_id);
        })

        if(!callReason)
            return false;

        return callReason.comment_required;
    }

    renderCallReasons() {
        const {callReasons} = this.props;

        return callReasons.map((item, index) => {
            if(item.id === 0)
                return "";

            return (
                <option key={"call-reason-"+ index} value={item.id}>{item.reason}</option>
            )
        });
    }

    renderCalls () {
        const {calls, patchApiCall} = this.props;
        const {call_edit, input} = this.state;

        const api_call_ids = [];

        return calls.map((item) => {

            if(item.api_call_id){
                if(api_call_ids.includes(item.api_call_id))
                    return '';

                api_call_ids.push(item.api_call_id);
            }

            if(item?.csi?.closed || item?.state === 'failed'){
                return '';
            }

            //if(item.api_call_id !== undefined && this.hasDuplicateCall('api_call_id', item.api_call_id))
            //    return '';

            let callStateColor = 'dark';
            let callStateIcon;

            if(item.state === 'missed')
                callStateColor = 'danger';

            if(item.state === 'dialed')
                callStateIcon = 'mdi mdi-phone-outgoing';
            else if(item.state === 'connecting') {
                callStateIcon = 'mdi mdi-phone-dial';
                callStateColor = 'warning';
            } else if(item.state === 'connected') {
                callStateIcon = 'mdi mdi-phone-in-talk';
                callStateColor = 'success';
            } else if(item.state === 'ringing') {
                callStateIcon = 'mdi mdi-phone-ring';
                callStateColor = 'warning';
            } else
                callStateIcon = 'mdi mdi-phone-incoming';

            /**
            const jobSource = item.csi !== null && item.csi.number !== null && item.csi.number.job_source !== null ? item.csi.number.job_source : null;
            const jobType = item.csi !== null && item.csi.number !== null && item.csi.number.job_type !== null ? item.csi.number.job_type : null;
            const country = item.csi !== null && item.csi.number !== null ? countriesMap[item.csi.number.job_source.country] : null;
            */
            const jobSource = item?.csi?.number?.job_source;
            const jobType = item?.csi?.number?.job_type;
            const country = countriesMap[item?.csi?.number?.job_source?.country];

            const patchCall = () => {

                if(this.commentIsRequired(input.reason_id) && input.call_comment.trim() === "")
                    return;

                patchApiCall(item.api_call_id, {
                    reason_id: input.reason_id,
                    call_comment: input.call_comment,
                });

                this.commentIsRequired(input.reason_id);

                this.setState({
                    call_edit: null,
                    input: {
                        ...input,
                        call_comment: "",
                    }
                })
            }

            const commentRequired = this.commentIsRequired(input?.reason_id);

            return (
                <div key={item.id} >
                    <div className={'comment-row p-3 border-bottom'}>
                        <div className="d-flex flex-row ">
                            <div className="comment-text w-100 p-1">
                                {/* eslint-disable-next-line no-mixed-operators */}
                                <h5 className="font-weight-medium mb-1">{item?.csi?.number?.name || item.state === 'dialed' && __("outgoing")}</h5>
                                <div className="comment-footer">
                                    <span className={"badge rounded-pill font-weight-medium mb-1 fs-1 py-1 bg-light-"+ (phoneStateColorMap[item.state] || 'info') +" text-"+ (phoneStateColorMap[item.state] || 'info')}>{phoneStateLabelMap[item.state] || item.state}</span>
                                    <span className="badge rounded-pill bg-light text-muted fw-normal font-weight-medium fs-2 mt-1 ml-0 pl-0 m-r-5">
                                    <i className="fa fa-clock m-r-5" />
                                    <Moment unix format={'HH:mm'}>{item.startTime}</Moment>
                                </span>
                                        {item.duration !== '00:00' && (
                                            <span className="badge rounded-pill bg-light text-muted fw-normal font-weight-medium fs-2 mt-1">
                                                <i className="fa fa-hourglass m-r-5 " />
                                                {item.duration}
                                            </span>
                                        )}
                                    { /** }
                                     <span className="action-icons text-end">
                                     <a className="ps-3" href="#">
                                     <i className="fa fa-home" />
                                     </a>
                                     <a className="ps-3" href="#">
                                     <i className="fa fa-check" />
                                     </a>
                                     <Link to={{
                                        pathname: "/jobs/new",
                                        state: {
                                            call: item,
                                            jobSource,
                                            jobType,
                                            country
                                        }
                                    }}
                                     className="ps-3"
                                     >
                                     <i className="fa fa-plus" />
                                     </Link>
                                     </span>*/ }
                                </div>
                                <p className="mb-1 fs-3 text-muted">
                                    <a href={'tel:'+ item.phoneNumber} className="m-r-5">
                                        <i className={callStateIcon +' text-'+ callStateColor} />
                                    </a>
                                    <span>{formatDisplayPhoneNumber(item.phoneNumber)}</span>
                                </p>
                                {item?.csi?.contact_name && item?.csi?.contact_name !== "" && (
                                    <p className="mb-1 fs-3 text-muted">
                                        <span className="m-r-5">
                                            <i className="fa fa-user" />
                                        </span>
                                        <span>{item?.csi?.contact_name}</span>
                                    </p>
                                )}
                                { item?.csi?.job && (
                                    <p className="mb-1 fs-3 text-muted">
                                        <span className="m-r-5">
                                            <i className="fa fa-building" />
                                        </span>
                                        <span>{item?.csi?.job?.address.zipcode} {item?.csi?.job?.address.city}</span>
                                    </p>
                                )}

                            </div>
                            <div className="p-1 d-flex align-content-center flex-column">
                            { item.csi && (
                                <>
                                    <div className="mb-2">
                                        <ExternalLink href="/jobs/new"
                                                      queryData={{
                                                          call: {
                                                              api_call_id: item.api_call_id,
                                                              phoneNumber: item.phoneNumber,
                                                              csi: {
                                                                  id: item.api_call_id,
                                                                  contact: item.csi?.contact,
                                                                  to: item.csi?.to,
                                                                  from: item.csi?.from,
                                                              }
                                                          },
                                                          jobSource,
                                                          jobType,
                                                          country
                                                      }}
                                                      className="d-block text-dark"
                                        >
                                            <i className="fa fa-plus-square" style={{ fontSize: '25px'}} />
                                        </ExternalLink>
                                    </div>
                                    { item.csi.job && (
                                        <div className="mb-2">
                                            <ExternalLink href={"/jobs/"+ item.csi.job.id}
                                                          className="d-block text-dark"
                                            >
                                                <i className={item.csi.job.type.icon} style={{ fontSize: '25px'}} />
                                            </ExternalLink>
                                        </div>
                                    )}

                                    <div>
                                        <span className="d-block text-dark" onClick={() => {
                                            this.setState({
                                                call_edit: call_edit !== item.id ? item.id : null,
                                            })
                                        }}>
                                            <i className="fa fa-edit" style={{cursor: 'pointer', fontSize: '25px'}} />
                                        </span>
                                    </div>
                                </>
                            )}
                            </div>
                        </div>
                        { call_edit === item.id && (
                            <div className="mt-2">
                                <div className="form-group m-b-15">
                                    <Label>{__("category")}</Label>
                                    <select className="form-control"
                                            onChange={(e) => {
                                                this.setState({
                                                    input: {
                                                        ...input,
                                                        reason_id: e.target.value
                                                    }
                                                })
                                            }}
                                            value={input.reason_id}>
                                        {this.renderCallReasons()}
                                    </select>
                                </div>
                                <div className="form-group m-b-15">
                                    <Label>{__("comment")} {commentRequired && (<span>({__("required")})</span>)}</Label>
                                    <input type="text"
                                           className="form-control"
                                           onChange={(e) => {
                                               this.setState({
                                                   input: {
                                                       ...input,
                                                       call_comment: e.target.value
                                                   }
                                               })
                                           }}
                                           value={input.call_comment} />
                                </div>
                                <Button color="inverse" onClick={patchCall}><i className="fa fa-save" /> {__("save")}</Button>
                            </div>
                        )}
                    </div>
                </div>
            );
        });
    }

    render() {
        const {websocketConnection} = this.props;

        return (
            <>

                {websocketConnection === "connected" && (
                    <div className="comment-widgets w-100">
                        {this.renderCalls()}
                        <div className="h-25" />
                    </div>
                )}

                {websocketConnection === "connecting" && (
                    <>
                        <div className="align-self-center">
                            <p className="text-center">
                                    <span className="fa-stack fa-2x">
                                        <i className="fas fa-phone fa-stack-1x" />
                                        <i className="fas fa-spinner fa-spin fa-stack-2x" />
                                    </span>
                            </p>
                        </div>
                    </>
                )}

                {websocketConnection === "disconnected" && (
                    <>
                        <div className="align-self-center">
                            <p className="text-center">
                                <span className="fa-stack fa-2x">
                                    <i className="fas fa-phone fa-stack-1x" />
                                    <i className="fas fa-ban fa-stack-2x" style={{color: "Tomato"}} />
                                </span>
                            </p>
                        </div>
                    </>
                )}
            </>
        );
    }
}

const mapStateToProps = state => {
    const { calls, websocketConnection } = state.Phone;
    const { callReasonsLoading, callReasons } = state.Pbx;

    return {
        calls,
        websocketConnection,
        callReasonsLoading,
        callReasons,
    };
};

export default withRouter(
    connect(mapStateToProps, { getPbxCallReasons, patchApiCall })(Calls)
);
