import Legacy from "../pages/Legacy";
import Dashboard from "../pages/Dashboard";
import Login from "../pages/Login";
import Job from "../pages/Jobs/Show";
import JobMonitor from "../pages/Dashboard/JobMonitor";
import Jobs from "../pages/Jobs";
import Statistics from "../pages/Statistics";
import Calls from "../pages/PBX/Calls";
import Claims from "../pages/Compliance/Claims";
import RandomJob from "../pages/Compliance/Show/RandomJob";
import ComplianceJob from "../pages/Compliance/Show/Job";
import Compliance from "../pages/Compliance/Show";
import Fitters from "../pages/Fitters";
import Phone from "../pages/Phone";
import JobForm from "../pages/Jobs/New/JobForm";
import Fitter from "../pages/Fitters/Show";

const authProtectedRoutes = [
    { path: "/dashboard/job_monitor", component: JobMonitor, exact: true },
    { path: "/dashboard", component: Dashboard, exact: true },
    { path: "/statistics", component: Statistics, exact: true },

    { path: "/compliances/claims", component: Claims, exact: true },
    { path: "/compliances/:id/jobs/random", component: RandomJob, exact: true },
    { path: "/compliances/:id/jobs/:job_id", component: ComplianceJob, exact: true },
    { path: "/compliances/:id", component: Compliance, exact: true },

    { path: "/pbx/calls", component: Calls, exact: true },
    { path: "/jobs/new", component: JobForm, exact: true },
    { path: "/jobs/:id", component: Job, exact: true },
    { path: "/jobs", component: Jobs, exact: true },

    { path: "/fitters/:id", component: Fitter, exact: true },
    { path: "/fitters", component: Fitters, exact: true },

    { path: "/", component: Legacy, exact: true },
];

const publicRoutes = [
    { path: "/login", component: Login, exact: true },
    { path: "/frame/job_monitor", component: JobMonitor, exact: true },
    { path: "/frame/phone", component: Phone, exact: true },
];

export {authProtectedRoutes, publicRoutes};