import {
    GET_API_CALL_SUCCESS,
    GET_API_CALL_WS_SUCCESS,
    CREATE_API_TEST_CALL_SUCCESS,
    SET_PHONE_STATE_SUCCESS,
    PATCH_API_CALL_SUCCESS,
} from "./actionTypes";

const INIT_STATE = {
    error: null,
    loading: false,
    websocketConnection: 'disconnected',

    calls: [],
    apiCalls: {},
};

const Phone = (state = INIT_STATE, action) => {
    switch (action.type) {

        case SET_PHONE_STATE_SUCCESS:
            let newState = {
                ...state,
                ...action.payload,
            };

            if(typeof action.payload.calls !== "undefined"){
                newState.calls = [...action.payload.calls];
            }

            return newState;
        case CREATE_API_TEST_CALL_SUCCESS:
        case GET_API_CALL_SUCCESS:
            return {
                ...state,
                calls: state.calls.map((item) => {
                    if(item.api_call_id !== action.payload.id)
                        return item;

                    return {
                        ...item,
                        csi: action.payload,
                    }
                }),
                apiCalls: {
                    ...state.apiCalls,
                    [action.payload.id]: action.payload,
                }
            };

        case GET_API_CALL_WS_SUCCESS:
            return {
                ...state,
                calls: state.calls.map((item) => {
                    if(item.api_call_id !== action.payload.data.call.id)
                        return item;

                    return {
                        ...item,
                        csi: action.payload.data.call,
                    }
                }),
                apiCalls: {
                    ...state.apiCalls,
                    [action.payload.id]: action.payload.data.call,
                }
            };

        case PATCH_API_CALL_SUCCESS:
            return {
                ...state,
                calls: state.calls.map((item) => {
                    if(item.api_call_id !== action.payload.id)
                        return item;

                    return {
                        ...item,
                        csi: action.payload,
                    }
                }),
            }

        default:
            return state;
    }
};


export default Phone;
