import React, {Component} from 'react';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter, ButtonGroup} from 'reactstrap';
import PropTypes from "prop-types";
import {__} from "../../utils/helpers";
import Label from "../Form/Label";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {addJobClaim, getClaimTemplates} from "../../store/actions";


class AddClaimModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            input: {
                claim_template_id: 0,
                comment: '',
            },
        };
    }

    componentDidMount() {
        const {claimTemplates, claimTemplatesLoading} = this.props;

        if(claimTemplates.length === 0 && claimTemplatesLoading === false)
            this.props.getClaimTemplates();
    }

    submit = () => {
        const {job} = this.props;
        const {input} = this.state;

        if(input.claim_template_id > 0){
            this.props.addJobClaim(job.id, input);
            this.toggle();
        }

    }

    toggle = () => {
        const {modal} = this.state;
        this.setState({
            modal: !modal,
        })
    };

    renderTemplatesDropdown = () => {
        const {claimTemplates} = this.props;

        return claimTemplates.map((template) => {

            return (
                <option key={'claim-template-'+ template.id} value={template.id}>{template.name}</option>
            );
        });
    }

    render() {
        const {
            color,
            size,
            className,
            modalClassName,
            children,
        } = this.props;

        const { modal, input } = this.state;

        return (
            <>
                <Button color={color} size={size} className={className} onClick={this.toggle}>{children}</Button>
                <Modal isOpen={modal} toggle={this.toggle} className={modalClassName}>
                    <ModalHeader toggle={this.toggle}>{__("addClaim")}</ModalHeader>
                    <ModalBody>

                        <div className="form-group m-b-15">
                            <Label>{__("category")}</Label>
                            <select required
                                    className="form-control"
                                    value={input.status}
                                    onChange={(e) => {
                                        this.setState({
                                            input: {
                                                ...input,
                                                claim_template_id: parseInt(e.target.value)
                                            }
                                        })
                                    }}>
                                <option key="claim-template-0" value={0}>Bitte auswählen!</option>
                                {this.renderTemplatesDropdown()}
                            </select>
                        </div>

                        <div className="form-group">
                            <Label>{__("comment")}</Label>
                            <textarea className="form-control"
                                      value={input.comment}
                                      onChange={(e) => {
                                          this.setState({
                                              input: {
                                                  ...input,
                                                  comment: e.target.value
                                              }
                                          })
                                      }}
                            />
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <ButtonGroup>
                            <Button color="secondary" onClick={this.toggle}><i className="fa fa-times-circle" /> {__("close")}</Button>
                            <Button color="inverse" onClick={this.submit}><i className="fa fa-save" /> {__("save")}</Button>
                        </ButtonGroup>
                    </ModalFooter>
                </Modal>
            </>
        );
    }
}

const mapStatetoProps = (state) => {
    const {claimTemplates, claimTemplatesLoading} = state.Compliances;
    return {
        claimTemplates,
        claimTemplatesLoading,
    };
};

export default withRouter(connect(mapStatetoProps, { addJobClaim, getClaimTemplates })(AddClaimModal));


AddClaimModal.propTypes = {
    job: PropTypes.object.isRequired,
    color: PropTypes.string,
    size: PropTypes.string,
    className: PropTypes.string,
    modalClassName: PropTypes.string,
};
