import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import { withRouter } from "react-router-dom";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

import {setBreadCrumbs, getPbxCalls, getJobTypes, getJobSources, setJob} from "../../../store/actions";
import CallsTable from "../../../components/Tables/CallsTable";
import FilterContainer from "../../../components/Filter";
import {__} from "../../../utils/helpers";

class Calls extends Component {
    constructor(props) {
        super(props);
        const addJobData = {
            code: "",
        }

        this.cacheKeys = {
            filters: 'pbx.calls.filter',
        }

        this.ref = {
            CallsTable: null,
        }

        const filters = JSON.parse(localStorage.getItem(this.cacheKeys.filters)) || {
            jobTypes: [],
            jobSources: [],
            countries: [],
            textSearch: "",
            fitters: [],
            claimPriorities: [],
            startDate: null,
            endDate: null,
            calls: [],
        };

        if(typeof(filters.claimPriorities) === "undefined"){
            filters.claimPriorities = [];
        }

        this.state = {
            countries: [
                {
                    'code': 'de',
                    'name': 'Deutschland',
                    'icon': 'flag-icon flag-icon-de',
                },
                {
                    'code': 'at',
                    'name': 'Österreich',
                    'icon': 'flag-icon flag-icon-at',
                },
                {
                    'code': 'ch',
                    'name': 'Schweiz',
                    'icon': 'flag-icon flag-icon-ch',
                },
                {
                    'code': 'nl',
                    'name': 'Niederlande',
                    'icon': 'flag-icon flag-icon-nl',
                }
            ],
            filters: filters,
            addJobData,
            audioPlayerSrc: null,
        };

        this.togglemodal.bind(this);
    }

    componentDidMount() {
        document.title = "PBX - "+ __("calls");

        //console.log("componentDidMount", this.props.jobTypes.length, this.props.jobTypesLoading);
        if(this.props.jobTypes.length === 0 && this.props.jobTypesLoading === false)
            this.props.getJobTypes();

        if(this.props.jobSources.length === 0 && this.props.jobSourcesLoading === false)
            this.props.getJobSources();
    }

    togglemodal = () => {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    onClearCountries = (e) => {
        const filters = {
            ...this.state.filters,
            countries: [],
        };
        this.setState({
            filters
        }, () => {
            localStorage.setItem(this.cacheKeys.filters, JSON.stringify(filters))
        });
    }

    onClearCallsFilter = (e) => {
        const filters = {
            ...this.state.filters,
            calls: [],
        };
        this.setState({
            filters
        }, () => {
            localStorage.setItem(this.cacheKeys.filters, JSON.stringify(filters))
        });
    }

    onCallFilterClick = (e) => {
        const value = e.target.dataset.value;
        let {calls} = this.state.filters;

        if(typeof(calls) === "undefined")
            calls   = [];

        const index = calls.findIndex((element) => element === value);

        if(index === -1){
            calls.push(value);
        } else {
            calls.splice(index, 1);
        }

        const filters = {
            ...this.state.filters,
            calls: calls,
        };

        this.setState({
            filters,
        }, () => {
            localStorage.setItem(this.cacheKeys.filters, JSON.stringify(filters))
        });
    }

    onClaimPriorityClick = (e) => {
        const value = e.target.dataset.value;
        let {claimPriorities} = this.state.filters;
        const index = claimPriorities ? claimPriorities.findIndex((element) => element === value) : -1;

        if(typeof(claimPriorities) === 'undefined'){
            claimPriorities = [];
        }

        if(index === -1){
            claimPriorities.push(value);
        } else {
            claimPriorities.splice(index, 1);
        }

        const filters = {
            ...this.state.filters,
            claimPriorities: claimPriorities,
        };

        this.setState({
            filters,
        }, () => {
            localStorage.setItem(this.cacheKeys.filters, JSON.stringify(filters))
        });
    }

    onClearClaimPriorities = (e) => {
        const filters = {
            ...this.state.filters,
            claimPriorities: [],
        };
        this.setState({
            filters
        }, () => {
            localStorage.setItem(this.cacheKeys.filters, JSON.stringify(filters))
        });
    }

    onCountryClick = (e) => {
        const value = e.target.dataset.value;
        const {countries} = this.state.filters;
        const index = countries.findIndex((element) => element === value);

        if(index === -1){
            countries.push(value);
        } else {
            countries.splice(index, 1);
        }

        const filters = {
            ...this.state.filters,
            countries: countries,
        };

        this.setState({
            filters,
        }, () => {
            localStorage.setItem(this.cacheKeys.filters, JSON.stringify(filters))
        });
    }

    onClearJobTypes = (e) => {
        const filters = {
            ...this.state.filters,
            jobTypes: [],
        };

        this.setState({
            filters
        }, () => {
            localStorage.setItem(this.cacheKeys.filters, JSON.stringify(filters))
        });
    }

    onJobTypeClick = (e) => {
        const value = e.target.dataset.value;
        const {jobTypes} = this.state.filters;
        const index = jobTypes.findIndex((element) => element === value);

        if(index === -1){
            jobTypes.push(value);
        } else {
            jobTypes.splice(index, 1);
        }

        const filters = {
            ...this.state.filters,
            jobTypes: jobTypes,
        };

        this.setState({
            filters
        }, () => {
            localStorage.setItem(this.cacheKeys.filters, JSON.stringify(filters))
        });
    }

    onJobSourceClick = (e) => {
        const value = e.target.dataset.value;

        e.target.blur();

        let {jobSources} = this.state.filters;

        if(typeof(jobSources) === "undefined"){
            jobSources  = [];
        }

        const index = jobSources.findIndex((element) => element === value);

        if(index === -1){
            jobSources.push(value);
        } else {
            jobSources.splice(index, 1);
        }

        const filters = {
            ...this.state.filters,
            jobSources: jobSources,
        };

        this.setState({
            filters
        }, () => {
            localStorage.setItem(this.cacheKeys.filters, JSON.stringify(filters))
        });
    }

    onClearTextSearch = (e) => {
        const filters = {
            ...this.state.filters,
            textSearch: '',
        };

        this.setState({
            filters: filters
        }, () => {
            localStorage.setItem(this.cacheKeys.filters, JSON.stringify(filters))
        });
    }

    onSelectFitters = (values) => {
        const data = values === null ? [] : values.map(function(value){
            return value.value;
        });

        const filters = {
            ...this.state.filters,
            fitters: data,
        };

        this.setState({
            filters
        }, () => {
            localStorage.setItem(this.cacheKeys.filters, JSON.stringify(filters))
        });
    }

    onTextChange = (e) => {
        const filters = {
            ...this.state.filters,
            textSearch: e.target.value,
        };
        this.setState({
            filters
        }, () => {
            localStorage.setItem(this.cacheKeys.filters, JSON.stringify(filters))
        });
    };

    onClearDateRange = (e) => {
        this.onDateRangeChange(null, null);
    }

    onDateRangeChange = (start, end) => {
        const filters = {
            ...this.state.filters,
            startDate: start !== null ? start.format('DD.MM.YYYY') : null,
            endDate: end !== null ? end.format('DD.MM.YYYY') : null
        };
        this.setState({
            filters
        }, () => {
            localStorage.setItem(this.cacheKeys.filters, JSON.stringify(filters))
        });
    }

    onFiltersUpdated = (filters) => {
        this.setState({
            filters
        }, () => {
            localStorage.setItem(this.cacheKeys.filters, JSON.stringify(filters))
        })
    };

    onPlayRecord = (uri) => {
        this.setState({
            audioPlayerSrc: uri,
        });
    };

    onAddJobClick = () => {

    }

    getCalls = (meta) => {
        this.props.getPbxCalls(
            meta
        );
    }

    setBreadCrumbs(){
        this.props.setBreadCrumbs([
            {
                name: 'Home',
                href: '/dashboard'
            },
            {
                name: 'PBX',
                href: '#'
            },
            {
                name: __("calls"),
                route: '/pbx/calls',
            },
        ]);
    }

    reloadData(){
        if(this.ref.CallsTable !== null){
            this.ref.CallsTable.reloadData();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        //this.setBreadCrumbs();
    }

    render() {
        const {jobTypesLoading, jobTypes, jobSources, jobSourcesLoading, claimPriorities, fitters, calls, callsLoading, setJob} = this.props;
        const {countries, filters, audioPlayerSrc} = this.state;
        return (
            <React.Fragment>
                {audioPlayerSrc !== null ? (
                    <div id={"footer-bar"} style={{position: 'fixed', left: 0, bottom: 0, width: '100%', height: '85px', zIndex: 999}}>
                        <AudioPlayer
                            autoPlay
                            src={audioPlayerSrc}
                            onPlay={e => console.log("onPlay")}
                            // other props here
                        />
                    </div>
                ) : ''}

                <div className="page-content">
                    <Container fluid>
                        <Row>
                            <Col xl="8">
                                <CallsTable
                                    ref={ n => this.ref['CallsTable'] = n }
                                    loading={callsLoading}
                                    getData={this.getCalls}
                                    filters={filters}
                                    meta={calls.meta}
                                    calls={calls.data}
                                    setJob={setJob}
                                    onPlayRecord={this.onPlayRecord}
                                />
                            </Col>
                            <Col xl="4">

                                <FilterContainer
                                    horizontal={false}
                                    loading={jobTypesLoading}
                                    jobTypes={jobTypes}
                                    jobSources={jobSources}
                                    jobSourcesLoading={jobSourcesLoading}
                                    claimPriorities={claimPriorities}
                                    countries={countries}
                                    fitters={fitters.filter(fitter => fitter.status.status === 'active')}
                                    filters={filters}
                                    display={{
                                        jobSourcesTagCloud: true,
                                        jobTypesSelect: true,
                                        countriesSelect: true,
                                        fittersSelect: false,
                                        textSearchInput: true,
                                        dateRangePicker: true,
                                        appointmentDateRangePicker: false,
                                        callsSelect: false,
                                        jobStatesSelect: false
                                    }}
                                    onFiltersUpdated={this.onFiltersUpdated}
                                    onClaimPriorityClick={this.onClaimPriorityClick}
                                    onClearClaimPriorities={this.onClearClaimPriorities}
                                    onClearCountries={this.onClearCountries}
                                    onCountryClick={this.onCountryClick}
                                    onClearJobTypes={this.onClearJobTypes}
                                    onJobTypeClick={this.onJobTypeClick}
                                    onJobSourceClick={this.onJobSourceClick}
                                    onClearTextSearch={this.onClearTextSearch}
                                    onTextChange={this.onTextChange}
                                    onDateRangeChange={this.onDateRangeChange}
                                    onClearDateRange={this.onClearDateRange}
                                    onSelectFitters={this.onSelectFitters}
                                    onClearCallsFilter={this.onClearCallsFilter}
                                    onCallFilterClick={this.onCallFilterClick}
                                />
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = (state) => {
    const { calls, callsLoading } = state.Pbx;
    const { jobTypes, jobTypesLoading } = state.JobTypes;
    const { jobSources, jobSourcesLoading } = state.JobSources;

    return {
        calls,
        callsLoading,
        jobTypes,
        jobTypesLoading,
        fitters: [],
        jobSources,
        jobSourcesLoading,
        jobs: []
    };
};

function mapDispatchToProps(dispatch) {
    let actions = bindActionCreators({ setBreadCrumbs, getPbxCalls, getJobTypes, getJobSources, setJob }, dispatch);
    return { ...actions, dispatch };
}


export default withRouter(
    connect(mapStatetoProps, mapDispatchToProps)(Calls)
);
