import React, {Component} from "react";
import { Code } from 'react-content-loader';
import {connect} from "react-redux";
import moment from "moment";
import EchoRedux from "laravel-echo-redux";
import {Tabs, Tab} from "react-bootstrap";
import {Redirect, withRouter} from "react-router-dom";
import {bindActionCreators} from "redux";
import {__} from "../../../utils/helpers";
import {
    getComplianceRandomJob,
    getCompliances,
    getComplianceJob,
    saveComplianceJobQuestions,
    resetComplianceRandomJob,
    getClaimTemplates,
    getClaimPriorities,
    setPageTitle,
    setBreadCrumbs,
} from "../../../store/actions";
import {getApiAuthBackend} from "../../../api/Auth";

import OverviewCard from "../../../components/Jobs/OverviewCard";
import StatusHistory from "../../../components/Jobs/StatusHistory";
import Dialog from "../../../components/Compliance/Dialog";
import Claims from "../../../components/Jobs/Claims";
import CallHistory from "../../../components/Jobs/CallHistory";
import MessageHistory from "../../../components/Jobs/MessageHistory";
const MyCodeLoader = () => <Code />;


class ComplianceJob extends Component {
    constructor(props) {
        super(props);

        const compliance_id = parseInt(this.props.match.params.id);
        const job_id = parseInt(this.props.match.params.job_id);

        this.cacheKeys = {
            filters: 'compliances.jobs.activeTab',
        }

        this.echoChannel = 'compliance.jobs.'+ job_id

        this.state = {
            compliance_id,
            job_id,
            redirectRandom: false,
            showCreateDialog: true,
            channel: {
                members: []
            },
            activeTab: localStorage.getItem(this.cacheKeys.activeTab) || 'statusHistory',
        };

        if(this.props.compliances.length === 0)
            this.props.getCompliances();

        if(this.props.claimTemplates.length === 0)
            this.props.getClaimTemplates();

        if(this.props.claimPriorities.length === 0)
            this.props.getClaimPriorities();

        if(this.getJob() === null){
            this.props.getComplianceJob(compliance_id, job_id);
        }
    }

    componentDidMount() {
        this.joinChannel();
    }

    componentWillUnmount() {
        this.leaveChannel();
    }

    joinChannel(){
        console.log("joinChannel");

        const {job_id} = this.state;
        const self = this;
        EchoRedux.echo.join(this.echoChannel)
            .here((users) => {
                console.log("here", users);
                self.setState({
                    channel: {
                        ...self.state.channel,
                        members: users,
                    }
                });
            })

            .joining((user) => {
                console.log('joining', user);
                /**
                 const {channel} = self.state;

                 self.setState({
                    channel: {
                        ...channel,
                        members: [
                            ...channel.members,
                            user
                        ],
                    }
                });*/
            })
            .leaving((user) => {
                const {channel} = self.state;
                console.log('leaving', user);
                self.setState({
                    channel: {
                        ...channel,
                        members: channel.members.filter(item => item.id !== user.id)
                    }
                });
            });

        EchoRedux.subscribePrivate(
            'job.'+ job_id,
            '.App\\Events\\JobUpdatedEvent',
            'GET_JOB_SUCCESS_WS'
        );
    }

    leaveChannel(){
        const {job_id} = this.state;
        console.log("leaveChannel");
        EchoRedux.echo.leave(this.echoChannel);
        EchoRedux.echo.leave('job.'+ job_id);
    }

    getOtherViewUsers(){
        const {channel} = this.state;
        const current_user = getApiAuthBackend().getAuthenticatedUser();
        return channel.members.filter(user => user.id !== current_user.id);
    }

    isViewedByAnotherUser(){
        const {channel} = this.state;
        const current_user = getApiAuthBackend().getAuthenticatedUser();
        return channel.members.filter(user => user.id !== current_user.id).length > 0;
    }

    getCompliance(){
        const {compliance_id} = this.state;

        const compliance = this.props.compliances.find(function(item){
            return item.id === compliance_id;
        });

        return typeof compliance !== 'undefined' ? compliance : null;
    }

    getJob() {
        return this.props.complianceJob;
    }

    getComplianceJob(){
        const {compliance_id} = this.state;
        const job = this.getJob();
        const compliance = job.compliances.find((item) => item.compliance_id === compliance_id);

        return compliance ? compliance : null;
    }

    submitDialogData = (data) => {
        const {compliance_id, job_id} = this.state;
        this.props.saveComplianceJobQuestions(compliance_id, job_id, data);

        this.setState({
            showCreateDialog: false,
        });
    }

    submitDialogDataAndContinue = (data) => {
        this.submitDialogData(data);
        setTimeout(function(self){
            self.setState({
                redirectRandom: true,
            })
        }, 500, this)
    }

    renderDialogList() {
        const job = this.getJob();
        const compliance = this.getCompliance();
        const self = this;

        return job.compliance_dialogues.map(function(item){
            return (
                <Dialog
                    key={'dialog-'+ item.id}
                    readonly={true}
                    compliance={compliance}
                    state={item.compliance_state}
                    comment={item.compliance_comment}
                    dialogData={item}
                    job={job}
                    answers={item.answers}
                    claimTemplates={self.props.claimTemplates}
                />
            )
        });
    }

    setBreadCrumbs(){
        const job = this.getJob();
        const compliance = this.getCompliance();

        if(!job || !compliance)
            return;

        this.props.setBreadCrumbs([
            {
                name: 'Home',
                href: '/dashboard'
            },
            {
                name: compliance.compliance_title,
                route: '/compliances/'+ compliance.compliance_id,
            },
            {
                name: __("job") +' '+ job.code,
                route: '/compliances/'+ compliance.compliance_id +'/jobs/'+ job.id,
            },
        ]);
    }

    setPageTitle(){
        const job = this.getJob();
        const compliance = this.getCompliance();

        if(job && compliance)
            this.props.setPageTitle(compliance.compliance_title +' - '+ __("job") +' '+ job.code);
    }

    hasOpenClaim(){
        const job = this.getJob();
        return job.claims && job.claims.findIndex((item) => item.state === 'open' ) !== -1;
    }

    getLastDialogState = () => {
        const job = this.getJob();

        if(job.compliance_dialogues.length === 0)
            return null;

        return job.compliance_dialogues[0].compliance_state;
    }

    renderCreateDialog(){
        const {showCreateDialog} = this.state;
        const {claimTemplates} = this.props;
        const compliance = this.getCompliance();
        const job = this.getJob();
        const isViewedByAnotherUser = this.isViewedByAnotherUser();
        const complianceJob = this.getComplianceJob();

        if(complianceJob.state === 'not_reached' && complianceJob.retry_locked === 1){

            let message;

            switch(complianceJob.retry_locked_reason){
                case 'retry limit reached':
                    message = __("complianceRetryLimitReached");
                    break;
                case 'retry contact cooldown':
                    const then = moment(complianceJob.retry_after, 'YYYY-MM-DD HH:mm:ss').unix();
                    const now = moment().unix();
                    const timestamp = moment(then - now);
                    const hours = timestamp / 3600;
                    message = __("complianceRetryContactCooldown", {
                        hours: Math.ceil(hours),
                    });
                    break;
                default:
                    message = '['+ complianceJob.retry_locked_reason +']';
                    break;
            }

            return (
                <div className={'card card-inverse card-danger'}>
                    <div className={'card-body'}>
                        <h3 className={'card-title'}>{__("editingLocked")}</h3>
                        <p className={'text-white'}>{message}</p>

                        <button className={'btn btn-inverse'}
                                onClick={() => {
                                    this.setState({
                                        redirectRandom: true,
                                    })
                                }}>
                            {__("openNextJob")}
                        </button>
                    </div>


                </div>

            )
        }

        if(isViewedByAnotherUser === true){
            return (
                <div className={'card card-inverse card-danger'}>
                    <div className={'card-body'}>
                        <h3 className={'card-title'}>{__("editingLocked")}</h3>
                        <p className={'text-white'}>
                            {__("complianceLockedByMessage", {
                                users: this.getOtherViewUsers().map(function(user){
                                    return user.name
                                }).join(', ')
                            })}
                        </p>
                        <ul className={'text-white'}>
                            { this.getOtherViewUsers().map(function(user){
                                return (
                                    <li key={'user-'+ user.id}>{user.name}</li>
                                )
                            })}
                        </ul>

                        <button className={'btn btn-inverse'}
                                onClick={() => {
                                    this.setState({
                                        redirectRandom: true,
                                    })
                                }}>
                            {__("openNextJob")}
                        </button>
                    </div>


                </div>
            );
        }

        if((complianceJob.state === 'canceled' || complianceJob.state === 'no_information' || complianceJob.state === 'success')){
            return (
                <div className={'card'}>
                    <div className={'card-body'}>
                        <div className={'btn-group'}>
                            <button className={'btn btn-inverse'}
                                    onClick={() => {
                                        this.setState({
                                            redirectRandom: true,
                                        })
                                    }}>
                                <i className="fa fa-arrow-circle-right m-r-5" />
                                {__("openNextJob")}
                            </button>
                        </div>
                    </div>
                </div>
            )
        }

        if(showCreateDialog === false){
            return (
                <div className={'card'}>
                    <div className={'card-body'}>
                        <div className={'btn-group'}>
                            <button className={'btn btn-outline-inverse'}
                                    onClick={() => {
                                        this.setState({
                                            redirectRandom: true,
                                        })
                                    }}>
                                {__("openNextJob")}
                            </button>
                            <button className={'btn btn-inverse'}
                                    onClick={() => {
                                        this.setState({
                                            showCreateDialog: true,
                                        })
                                    }}>
                                {__("createDialog")}
                            </button>
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <div>
                <Dialog
                    readonly={false}
                    compliance={compliance}
                    job={job}
                    submitData={
                        (data) => this.submitDialogData(data)} submitDataAndContinue={(data) => this.submitDialogDataAndContinue(data)
                }
                    claimTemplates={claimTemplates}
                />
            </div>
        );
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.setPageTitle();
        this.setBreadCrumbs();
    }

    render() {
        const {compliance_id, redirectRandom, activeTab} = this.state;
        const {complianceJobLoading} = this.props;
        const job = this.getJob();
        const compliance = this.getCompliance();

        if(job === null || compliance === null){
            return (
                <MyCodeLoader />
            );
        }

        if(redirectRandom === true){
            //this.props.resetComplianceRandomJob();
            this.leaveChannel();
            this.props.resetComplianceRandomJob();
            return (
                <Redirect to={"/compliances/"+ compliance_id +"/jobs/random" }  />
            )
        }

        const toggleTab = tab => {
            if(activeTab !== tab){
                this.setState({
                    activeTab: tab,
                }, () => {
                    localStorage.setItem(this.cacheKeys.activeTab, tab)
                })
            }
        }

        const claimTitle = this.hasOpenClaim() === false ? "Beschwerden" : (
            <span>{__("claims")} <i className={'fa fa-exclamation-triangle text-danger'} /></span>
        );

        return (
            <div className={"row"}>

                <div className={'col-md-3'}>
                    <OverviewCard job={job} readonly={false} />
                </div>
                <div className={'col-md-5'}>
                    {complianceJobLoading === true ? (
                        <MyCodeLoader />
                    ) : (
                        <div className={'row'}>
                            <div className={"col-md-12 col-xs-12"}>
                                <div>
                                    { this.renderCreateDialog() }
                                </div>
                                <div>
                                    { this.renderDialogList()}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className={'col-md-4'}>
                    <div className={'card'}>
                        <Tabs
                            id="controlled-tab-example"
                            className={'customtab'}
                            activeKey={activeTab}
                            onSelect={(k) => toggleTab(k)}
                        >
                            <Tab eventKey="statusHistory" title="Status">
                                <StatusHistory
                                    displayTitle={false}
                                    displayCard={false}
                                    job={job}
                                    readonly={true}
                                />
                            </Tab>
                            <Tab eventKey="claims" title={claimTitle}>
                                <Claims
                                    displayTitle={false}
                                    displayCard={false}
                                    job={job}
                                    readonly={true}
                                />
                            </Tab>
                            <Tab eventKey="messages" title="Nachrichten">
                                <MessageHistory
                                    displayTitle={false}
                                    displayCard={false}
                                    job={job}
                                    readonly={true}
                                />
                            </Tab>
                            <Tab eventKey="calls" title="Anrufe">
                                <CallHistory
                                    displayTitle={false}
                                    displayCard={false}
                                    job={job}
                                    readonly={true}
                                />
                            </Tab>
                        </Tabs>
                    </div>
                </div>

            </div>
        );
    }
}

const mapStatetoProps = state => {
    const { jobs } = state.Jobs;
    const { complianceJobLoading, compliances, complianceJobs, complianceJob, randomJob, randomJobLoading, claimTemplates, claimPriorities } = state.Compliances;

    return {
        complianceJobLoading,
        jobs,
        compliances,
        complianceJobs,
        complianceJob,
        claimTemplates,
        claimPriorities,
        randomJob,
        randomJobLoading,
    };
};

function mapDispatchToProps(dispatch) {
    let actions = bindActionCreators({ getComplianceRandomJob, getCompliances, getComplianceJob, saveComplianceJobQuestions, resetComplianceRandomJob, getClaimTemplates, getClaimPriorities, setPageTitle, setBreadCrumbs }, dispatch);
    return { ...actions, dispatch };
}

export default withRouter(
    connect(mapStatetoProps, mapDispatchToProps)(ComplianceJob)
);

