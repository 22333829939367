import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import { withRouter } from "react-router-dom";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

import {getCompliances, getComplianceJobs, addComplianceJob, getClaimPriorities, getClaimTemplates, getJobTypes, getFitters, setPageTitle, setBreadCrumbs} from "../../../store/actions";
import OpenJobsTable from "../../../components/Tables/ComplianceOpenJobsTable";
import FilterContainer from "../../../components/Filter";
import {__} from "../../../utils/helpers";

class Compliance extends Component {
    constructor(props) {
        super(props);
        const compliance_id = parseInt(this.props.match.params.id);
        const addJobData = {
            code: "",
        }

        this.cacheKeys = {
            filters: 'compliances.jobs.filter',
        }

        this.ref = {
            OpenJobsTable: null,
        }


        const filters = JSON.parse(localStorage.getItem(this.cacheKeys.filters)) || {
            jobTypes: [],
            countries: [],
            textSearch: "",
            fitters: [],
            claimPriorities: []
        };

        if(typeof(filters.claimPriorities) === "undefined"){
            filters.claimPriorities = [];
        }

        this.state = {
            compliance_id,
            countries: [
                {
                    'code': 'de',
                    'name': 'Deutschland',
                    'icon': 'flag-icon flag-icon-de',
                },
                {
                    'code': 'at',
                    'name': 'Österreich',
                    'icon': 'flag-icon flag-icon-at',
                },
                {
                    'code': 'ch',
                    'name': 'Schweiz',
                    'icon': 'flag-icon flag-icon-ch',
                },
                {
                    'code': 'nl',
                    'name': 'Niederlande',
                    'icon': 'flag-icon flag-icon-nl',
                }
            ],
            filters: filters,
            addJobData,
        };

        this.togglemodal.bind(this);
    }

    componentDidMount() {
        if(this.props.compliances.length === 0)
            this.props.getCompliances();

        if(this.props.jobTypes.length === 0)
            this.props.getJobTypes();

        if(this.props.claimPriorities.length === 0)
            this.props.getClaimPriorities();

        if(this.props.fitters.length === 0)
            this.props.getFitters();
    }

    togglemodal = () => {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    onClearCountries = (e) => {
        const filters = {
            ...this.state.filters,
            countries: [],
        };
        this.setState({
            filters
        }, () => {
            localStorage.setItem(this.cacheKeys.filters, JSON.stringify(filters))
        });
    }

    onClaimPriorityClick = (e) => {
        const value = e.target.dataset.value;
        let {claimPriorities} = this.state.filters;
        const index = claimPriorities ? claimPriorities.findIndex((element) => element === value) : -1;

        if(typeof(claimPriorities) === 'undefined'){
            claimPriorities = [];
        }

        if(index === -1){
            claimPriorities.push(value);
        } else {
            claimPriorities.splice(index, 1);
        }

        const filters = {
            ...this.state.filters,
            claimPriorities: claimPriorities,
        };

        this.setState({
            filters,
        }, () => {
            localStorage.setItem(this.cacheKeys.filters, JSON.stringify(filters))
        });
    }

    onClearClaimPriorities = (e) => {
        const filters = {
            ...this.state.filters,
            claimPriorities: [],
        };
        this.setState({
            filters
        }, () => {
            localStorage.setItem(this.cacheKeys.filters, JSON.stringify(filters))
        });
    }

    onCountryClick = (e) => {
        const value = e.target.dataset.value;
        const {countries} = this.state.filters;
        const index = countries.findIndex((element) => element === value);

        if(index === -1){
            countries.push(value);
        } else {
            countries.splice(index, 1);
        }

        const filters = {
            ...this.state.filters,
            countries: countries,
        };

        this.setState({
            filters,
        }, () => {
            localStorage.setItem(this.cacheKeys.filters, JSON.stringify(filters))
        });
    }

    onClearJobTypes = (e) => {
        const filters = {
            ...this.state.filters,
            jobTypes: [],
        };

        this.setState({
            filters
        }, () => {
            localStorage.setItem(this.cacheKeys.filters, JSON.stringify(filters))
        });
    }

    onJobTypeClick = (e) => {
        const value = e.target.dataset.value;
        const {jobTypes} = this.state.filters;
        const index = jobTypes.findIndex((element) => element === value);

        if(index === -1){
            jobTypes.push(value);
        } else {
            jobTypes.splice(index, 1);
        }

        const filters = {
            ...this.state.filters,
            jobTypes: jobTypes,
        };

        this.setState({
            filters
        }, () => {
            localStorage.setItem(this.cacheKeys.filters, JSON.stringify(filters))
        });
    }

    onClearTextSearch = (e) => {
        const filters = {
            ...this.state.filters,
            textSearch: '',
        };

        this.setState({
            filters: filters
        }, () => {
            localStorage.setItem(this.cacheKeys.filters, JSON.stringify(filters))
        });
    }

    onSelectFitters = (values) => {
        const data = values === null ? [] : values.map(function(value){
            return value.value;
        });

        const filters = {
            ...this.state.filters,
            fitters: data,
        };

        this.setState({
            filters
        }, () => {
            localStorage.setItem(this.cacheKeys.filters, JSON.stringify(filters))
        });
    }

    onTextChange = (e) => {
        const filters = {
            ...this.state.filters,
            textSearch: e.target.value,
        };
        this.setState({
            filters
        }, () => {
            localStorage.setItem(this.cacheKeys.filters, JSON.stringify(filters))
        });
    }

    onFiltersUpdated = (filters) => {
        this.setState({
            filters
        }, () => {
            localStorage.setItem(this.cacheKeys.filters, JSON.stringify(filters))
        })
    };

    onAddJobClick = () => {
        const {addJobData, compliance_id} = this.state;
        const {addComplianceJobLoading} = this.props;

        if(addComplianceJobLoading === false)
            this.props.addComplianceJob(compliance_id, addJobData.code);
    }

    getCompliance(){
        const {compliance_id} = this.state;

        const compliance = this.props.compliances.find(function(item){
            return item.id === compliance_id;
        });

        return typeof compliance !== 'undefined' ? compliance : null;
    }

    getJobs(type, jobMonitorJobs, applyFilters) {
        const {complianceJobs} = this.props;
        return complianceJobs.data;
        /**
        return complianceJobs.data.filter(job => job.compliance_cache !== null
            && typeof(job.compliance_cache[compliance_id]) !== "undefined"
            && job.compliance_cache[compliance_id] === type);*/
    }

    setComplianceJob = (job) => {
        const {compliance_id} = this.state;
        this.props.dispatch({ type: 'GET_COMPLIANCE_JOB_SUCCESS', compliance_id, payload: job });
    }

    getComplianceJobs = (meta) => {
        const {match} = this.props;

        this.props.getComplianceJobs(
            parseInt(match.params.id),
            'open',
            meta
        );
    }

    setBreadCrumbs(){
        const compliance = this.getCompliance();

        if(!compliance)
            return;

        this.props.setBreadCrumbs([
            {
                name: 'Home',
                href: '/dashboard'
            },
            {
                name: compliance.compliance_title,
                route: '/compliances/'+ compliance.compliance_id,
            },
        ]);
    }

    setPageTitle(){
        const compliance = this.getCompliance();

        if(compliance)
            this.props.setPageTitle(compliance.compliance_title );
    }

    reloadData(){
        if(this.ref.OpenJobsTable !== null){
            this.ref.OpenJobsTable.reloadData();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.setPageTitle();
        this.setBreadCrumbs();
    }

    render() {
        const {jobTypesLoading, jobTypes, claimPriorities, fitters, complianceJobs, addComplianceJobLoading} = this.props;
        const {compliance_id, countries, filters, addJobData} = this.state;
        const compliance = this.getCompliance();

        if(!compliance)
            return (<div />);
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Row>
                            <Col xl="8">
                                <OpenJobsTable
                                    ref={ n => this.ref['OpenJobsTable'] = n }
                                    loading={this.props.complianceJobsLoading}
                                    compliance_id={compliance_id}
                                    getData={this.getComplianceJobs}
                                    filters={filters}
                                    type={compliance.compliance_type}
                                    setComplianceJob={this.setComplianceJob}
                                    meta={complianceJobs.meta}
                                    jobs={this.getJobs('open')}
                                />
                            </Col>
                            <Col xl="4">
                                <FilterContainer
                                    horizontal={false}
                                    loading={jobTypesLoading}
                                    jobTypes={jobTypes}
                                    showClaimPriorities={compliance.compliance_type === 'claims'}
                                    claimPriorities={claimPriorities}
                                    countries={countries}
                                    fitters={fitters.filter(fitter => fitter.status.status === 'active')}
                                    filters={filters}
                                    display={{
                                        jobSourcesTagCloud: false,
                                        jobTypesSelect: true,
                                        countriesSelect: true,
                                        fittersSelect: false,
                                        textSearchInput: true,
                                        dateRangePicker: true,
                                        appointmentDateRangePicker: false,
                                        callsSelect: false,
                                        jobStatesSelect: false
                                    }}
                                    onFiltersUpdated={this.onFiltersUpdated}
                                    onClaimPriorityClick={this.onClaimPriorityClick}
                                    onClearClaimPriorities={this.onClearClaimPriorities}
                                    onClearCountries={this.onClearCountries}
                                    onCountryClick={this.onCountryClick}
                                    onClearJobTypes={this.onClearJobTypes}
                                    onJobTypeClick={this.onJobTypeClick}
                                    onClearTextSearch={this.onClearTextSearch}
                                    onTextChange={this.onTextChange}
                                    onSelectFitters={this.onSelectFitters}
                                />

                                <div className={'card'}>
                                    <div className={'card-body'}>
                                        <div className={'row'}>
                                            <div className={'col-md-6'}>
                                                <h4 className="card-title" style={{marginTop: '0.5rem', marginBottom: 0}}>
                                                    <label htmlFor={'add_job_code'}>{__("addJob")}</label>
                                                </h4>
                                            </div>
                                            <div className={'col-md-6'}>
                                                <div className={'form-group d-inline-block w-100 m-r-15'} style={{marginBottom: 0}}>
                                                    <div className={"input-group"}>
                                                        <input
                                                            id={'add_job_code'}
                                                            type="text"
                                                            className={'form-control'}
                                                            style={{height: '40px'}}
                                                            placeholder={__("jobCode")}
                                                            minLength={4}
                                                            maxLength={4}
                                                            value={addJobData.code}
                                                            onKeyDown={(e) => {
                                                                if (e.key === 'Enter') {
                                                                    this.onAddJobClick(e);
                                                                }
                                                            }}
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    addJobData:{
                                                                        ...addJobData,
                                                                        code: e.target.value,
                                                                    }
                                                                });
                                                            }}
                                                        />
                                                        <div className="input-group-append">
                                                            <button
                                                                className={'btn btn-outline-primary'}
                                                                type="button"
                                                                onClick={this.onAddJobClick}
                                                                disabled={addComplianceJobLoading}
                                                            >
                                                                { addComplianceJobLoading === true ? (
                                                                    <i className={'fa fa-spinner fa-spin'} />
                                                                ) : (
                                                                    <i className={'fa fa-plus'} />
                                                                )}

                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = (state) => {
    const { jobTypes, jobTypesLoading } = state.JobTypes;
    const { fitters, fittersLoading } = state.Fitters;
    const { compliances, complianceJobs, claimPriorities, claimPrioritiesLoading, complianceJobsLoading, addComplianceJobLoading } = state.Compliances;

    return {
        compliances,
        complianceJobs,
        complianceJobsLoading,
        addComplianceJobLoading,
        claimPriorities,
        claimPrioritiesLoading,
        jobTypes,
        jobTypesLoading,
        fitters,
        fittersLoading,
    };
};

function mapDispatchToProps(dispatch) {
    let actions = bindActionCreators({ getCompliances, getComplianceJobs, getClaimPriorities, addComplianceJob, getClaimTemplates, getJobTypes, getFitters, setPageTitle, setBreadCrumbs }, dispatch);
    return { ...actions, dispatch };
}

export default withRouter(
    connect(mapStatetoProps, mapDispatchToProps)(Compliance)
);
