import {
    GET_JOB_SOURCE,
    GET_JOB_SOURCE_SUCCESS,
    GET_JOB_SOURCES,
    GET_JOB_SOURCES_SUCCESS,
} from "./actionTypes";

export const getJobSource = () => ({
    type: GET_JOB_SOURCE,
    payload: {}
});

export const getJobSourceSuccess = response => ({
    type: GET_JOB_SOURCE_SUCCESS,
    payload: response.data.data
});

export const getJobSources = () => ({
    type: GET_JOB_SOURCES,
    payload: {}
});

export const getJobSourcesSuccess = response => ({
    type: GET_JOB_SOURCES_SUCCESS,
    payload: response.data.data
});

