import React, { Component } from "react";
import {
    JobTypeColumn, ViewColumn, DateTimeColumn, CountryColumn, CustomerColumn, FitterColumn, JobStateColumn, AppointmentColumn,
} from "./Columns/JobColumns";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from 'react-bootstrap-table2-paginator';
import {BootstrapTableOptions, BootstrapTablePaginationOptions} from "./Options";
import {__} from "../../utils/helpers";

class JobsTable extends Component {
    constructor(props) {
        super(props);
        const {meta} = props;

        this.cacheKeys = {
            oldTableState: 'jobs.oldTableState',
        }

        this.state = {
            meta,
            defaultMeta: {
                page: 1,
                per_page: 10,
                sort_column: 'job_id',
                sort_order: 'desc',
            },
            oldTableState: JSON.parse(localStorage.getItem(this.cacheKeys.oldTableState)) || null,
        };
    }

    componentDidMount() {
        const {filters, data, loading} = this.props;
        const {meta} = this.state;

        if(data.length === 0 && loading === false){
            this.props.getData({
                ...this.state.defaultMeta,
                filters,
            });
        }

        if(data.length > 0 && loading === false && meta.current_page === 1){
            this.props.getData({
                ...meta,
                filters,
            });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {filters} = this.props;

        if(filters !== prevProps.filters){
            this.props.getData({
                ...this.state.defaultMeta,
                filters,
            });
        }
    }

    columns(){
        const {setJob} = this.props;

        const columns = [];

        columns.push({
            dataField: 'job_status',
            text: __("status"),
            formatter: JobStateColumn,
            sort: true,
            style: {
                width: '10%',
                //display: 'block',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
            }
        });

        columns.push({
            dataField: 'job_type',
            text: '',
            formatter: JobTypeColumn,
            sort: true,
            style: {
                width: '5%',
            }
        });

        columns.push({
            dataField: 'job_country',
            text: '',
            formatter: CountryColumn,
            sort: true,
            style: {
                width: '5%',
            }
        });

        columns.push({
            dataField: 'job_created',
            text: __("created"),
            formatter: DateTimeColumn,
            sort: true,
            style: {
                width: '15%',
            }
        });

        columns.push({
            dataField: 'job_appointment',
            text: __("appointment"),
            formatter: AppointmentColumn,
            sort: true,
            style: {
                width: '15%',
            }
        });

        columns.push({
            dataField: 'job_contact_name',
            text: __("customer"),
            formatter: CustomerColumn,
            sort: false,
            style: {
                //width: '200px',
            }
        });

        columns.push({
            dataField: 'fitter_id',
            text: __("partner"),
            formatter: FitterColumn,
            sort: false,
            style: {
                width: '30%',
            }
        });

        columns.push({
            dataField: 'id',
            text: '',
            formatter: ViewColumn,
            sort: false,
            formatExtraData: {
                setJob: setJob,
            }
        });

        return columns;
    }

    reloadData = () => {
        const {oldTableState} = this.state;
        const {filters} = this.props;

        this.props.getData({
            filters,
            page: oldTableState.page,
            per_page: oldTableState.sizePerPage,
            sort_column: oldTableState.sortField,
            sort_order: oldTableState.sortOrder,
        });
    }

    onTableChange = (type, newState) => {
        const {oldTableState} = this.state;
        const {filters} = this.props;

        if (oldTableState !== null
            && newState.sortOrder === oldTableState.sortOrder
            && newState.sortField === oldTableState.sortField
            //&& newState.filters === oldTableState.filters
            && newState.page === oldTableState.page
            && newState.sizePerPage === oldTableState.sizePerPage) {

            //&& newState.searchText === this.oldTableState.searchText)
            return;
        }


        this.setState({
            oldTableState: newState
        },() => {
            localStorage.setItem(this.cacheKeys.oldTableState, JSON.stringify(newState))
        });

        switch(type){
            case 'pagination':
            case 'sort':
                this.props.getData({
                    filters,
                    page: newState.page,
                    per_page: newState.sizePerPage,
                    sort_column: newState.sortField,
                    sort_order: newState.sortOrder,
                });

                break;
            default:
                // nothing to-do
                break;
        }
    }

    sort = () => {
        const {meta} = this.props;

        return {
            dataField: meta.sort.column ? meta.sort.column : '',
            order: meta.sort.order ? meta.sort.order.toLowerCase() : 'desc',
        };
    }

    paginationFactory = () => {
        const {meta} = this.props;
        return paginationFactory({
            ...BootstrapTablePaginationOptions,
            page: meta.current_page,
            sizePerPage: parseInt(meta.per_page),
            totalSize: meta.total,
            showTotal: true,
        });
    }

    render() {
        const {loading, data} = this.props;
        const self = this;

        return (
            <ToolkitProvider
                keyField='id'
                data={ data }
                columns={ this.columns() }
                search
            >
                {
                    props => (
                        <div className="">
                            <BootstrapTable
                                { ...props.baseProps }
                                { ...BootstrapTableOptions }
                                ref={ n => self.node = n }
                                loading={loading}
                                pagination={ self.paginationFactory() }
                                sort={ self.sort() }
                                onTableChange={self.onTableChange}
                                remote={ {
                                    filter: true,
                                    pagination: true,
                                    sort: true,
                                    cellEdit: false
                                } }
                            />

                        </div>
                    )
                }
            </ToolkitProvider>
        );
    }
}

export default JobsTable;
