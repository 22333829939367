import React from "react";
import overlayFactory from "react-bootstrap-table2-overlay";
import filterFactory from "react-bootstrap-table2-filter";
import {ButtonGroup, Button} from "reactstrap";
import {__} from "../../utils/helpers";

export const BootstrapTableSizePerPageRenderer = ({
                                                      options,
                                                      currSizePerPage,
                                                      onSizePerPageChange
                                                  }) => (
    <div className="m-l-15 m-r-15 btn-group d-inline-block" role="group">
        {
            options.map(option => (
                <button
                    key={ option.text }
                    type="button"
                    onClick={ () => onSizePerPageChange(option.page) }
                    className={ `btn ${currSizePerPage === `${option.page}` ? 'btn-secondary' : 'btn-light'}` }
                >
                    { option.text }
                </button>
            ))
        }
    </div>
);

export const BootstrapTableTotalRenderer = (from, to, size) => (
    <div className="d-inline-block">
        <span>
            Zeige { from } bis { to } of { size } Results
        </span>
    </div>
);

export const BootstrapTablePageListRenderer = ({
                                     pages,
                                     onPageChange
                                 }) => {
    return (
        <div className={'d-inline-block m-l-15 m-r-15 m-t-15'}>
            <ButtonGroup>
                {
                    pages.map(p => {
                        return (
                            <Button key={'pagination-'+ p.page} color={p.active ? 'secondary' : 'light'} onClick={ () => onPageChange(p.page) }>{ p.page }</Button>
                        );
                    })
                }
            </ButtonGroup>
        </div>
    );
};

export const BootstrapTableOptions = {
    bootstrap4: true,
    striped: false,
    hover: true,
    bordered: false,
    headerWrapperClasses: 'table-dark',
    classes: 'customize-table v-top',
    overlay: overlayFactory({ spinner: true, styles: { overlay: (base) => ({
                ...base,
                //background: 'rgba(192,192,192,0.3)',
                top: '-109px  !important',
                height: '62px !important',
                color: '#000',
    }) } }),
    //overlay: overlayFactory({ spinner: true, background: 'rgba(192,192,192,0.3)' }),
    filter: filterFactory(),
    noDataIndication: (
        <div className="text-muted mt-3 no-data" id="no-data">
            <p className="text-danger mb-1">
                <i className="fa fa-ban" style={{ 'fontSize': '24px' }} />
            </p>
            <p>No Data Found!</p>
        </div>
    ),
};

export const BootstrapTablePaginationOptions = {
    paginationSize: 3,  // the pagination bar size, default is 5
    showTotal: true, // display pagination information
    sizePerPageList: [
        {
            text: '10',
            value: 10
        },
        {
            text: '25',
            value: 25
        },
        {
            text: '50',
            value: 50
        },
        {
            text: '100',
            value: 100
        }
    ], // A numeric array is also available: [5, 10]. the purpose of above example is custom the text
    withFirstAndLast: true, // hide the going to first and last page button
    alwaysShowAllBtns: true, // always show the next and previous page button
    firstPageText: 'firstPageText', // the text of first page button
    prePageText: __('prePageText'), // the text of previous page button
    nextPageText: __('nextPageText'), // the text of next page button
    lastPageText: __('lastPageText'), // the text of last page button
    nextPageTitle: __('nextPageTitle'), // the title of next page button
    prePageTitle: __('prePageTitle'), // the title of previous page button
    firstPageTitle: __('firstPageTitle'), // the title of first page button
    lastPageTitle: __('lastPageTitle'), // the title of last page button
    sizePerPageRenderer: BootstrapTableSizePerPageRenderer,
    paginationTotalRenderer: BootstrapTableTotalRenderer,
    pageListRenderer: BootstrapTablePageListRenderer,
}

