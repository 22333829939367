import { toast } from 'react-toastify';
import i18n from "../i18n";
export const defaultLanguage = {
    'code': 'en',
    'name': 'English',
    'icon': 'flag-icon flag-icon-gb',
};

export const languages = [
    {
        'code': 'de',
        'name': 'German',
        'icon': 'flag-icon flag-icon-de',
    },
    {
        'code': 'en',
        'name': 'English',
        'icon': 'flag-icon flag-icon-gb',
    },/**
     {
                    'code': 'nl',
                    'name': 'Dutch',
                    'icon': 'flag-icon flag-icon-nl',
                }*/
];
export function __(key, opts) {
    return i18n.t(key, opts);
}

export function getSelectedLanguage() {
    let selectedLanguage = languages.find((item) => {
        return item.code === i18n.language;
    });

    if(!selectedLanguage)
        return defaultLanguage;

    return selectedLanguage;
}


/**
 *
 * @returns {boolean}
 */
export function runsInElectron() {
    return isElectron();
}

/**
 *
 * @returns {boolean}
 */
export function isElectron() {
    // Renderer process
    if (typeof window !== 'undefined' && typeof window.process === 'object' && window.process.type === 'renderer') {
        return true;
    }

    // Main process
    if (typeof process !== 'undefined' && typeof process.versions === 'object' && !!process.versions.electron) {
        return true;
    }

    // Detect the user agent when the `nodeIntegration` option is set to true
    if (typeof navigator === 'object' && typeof navigator.userAgent === 'string' && navigator.userAgent.indexOf('Electron') >= 0) {
        return true;
    }

    return false;
}

export function formatCurrencyInput(value){
    let regex   = /(.*)(([.,])(\d{0,2}))$/gm,
        str     = value,
        number  = 0,
        result  = regex.exec(str);

    if(typeof(value) === 'undefined' || value === "" || value === null )
        value   = 0;

    if(result === null){
        number      = parseFloat(value);
    } else {
        let base    = result[1],
            dez     = result[4];

        base        = base.replace(/\./g, '');
        base        = base.replace(/,/g, '');

        number      = parseFloat(base +"."+ dez);
    }

    return number.toLocaleString(
        'de-DE', // leave undefined to use the browser's locale,
        // or use a string like 'en-US' to override it.
        { minimumFractionDigits: 2 }
    );
}

export const notify = (level, message) => {
    switch (level) {
        case 'info':
            toast.info(message);

            break;
        case 'success':
            toast.success(message);

            break;
        case 'warning':
            toast.warn(message);

            break;
        case 'error':
            toast.error(message);

            break;
        default:
            toast(message);
    }
};

export const stateColorsMap = {
    'active': 'success',
    'accepted': 'success',
    'closed': 'success',
    'paid': 'success',
    'standard': 'success',
    'success': 'success',
    'in': 'success',

    'inactive': 'danger',
    'new': 'danger',
    'open': 'danger',
    'canceled': 'danger',
    'unpaid': 'danger',

    'working': 'warning',
    'in-progress': 'warning',
    'in-progress-delayed-1': 'warning',
    'in-progress-delayed-2': 'warning',
    'in-progress-delayed-4': 'warning',
    'partialpayment': 'warning',
    'bar': 'warning',

    'info': 'info',
    'offer': 'info',
    'ec': 'info',
    'out': 'info',
    'not_reached': 'info',

    'claim': 'inverse',
    'declined': 'inverse',
    'transfer': 'inverse',
    'no_information': 'inverse',
    'request-wait-info': 'info',
    'request-cancellation': 'info',
};

export const stateTranslationsMap = {
    de: {
        'active': 'Aktiv',
        'accepted': 'Akzeptiert',
        'closed': 'Geschlossen',
        'paid': 'Bezahlt',
        'standard': 'Standard',
        'success': 'Erfolgreich',
        'in': 'IN',

        'inactive': 'Inaktiv',
        'new': 'Neu',
        'open': 'Offen',
        'canceled': 'Storniert',
        'unpaid': 'Unbezahlt',

        'working': 'In Arbeit',
        'in-progress': 'In Arbeit',
        'in-progress-delayed-1': 'In Arbeit',
        'in-progress-delayed-2': 'In Arbeit',
        'in-progress-delayed-4': 'In Arbeit',
        'partialpayment': 'Teilzahlung',
        'bar': 'BAR',

        'info': 'Info',
        'offer': 'Angebot',
        'ec': 'EC',
        'out': 'OUT',
        'not_reached': 'Nicht erreicht',

        'claim': 'Beschwerde',
        'declined': 'Abgelehnt',
        'transfer': 'Überweisung',
        'no_information': 'Keine Angaben',
        'request-wait-info': 'Wartezeit-Anfrage',
        'request-cancellation': 'Storno-Anfrage',
    },
    en: {
        'active': 'Active',
        'accepted': 'Accepted',
        'closed': 'Closed',
        'paid': 'Paid',
        'standard': 'Standard',
        'success': 'Success',
        'in': 'IN',

        'inactive': 'Inactive',
        'new': 'New',
        'open': 'Open',
        'canceled': 'Canceled',
        'unpaid': 'Unpaid',

        'working': 'Working',
        'in-progress': 'In Progress',
        'in-progress-delayed-1': 'In Progress',
        'in-progress-delayed-2': 'In Progress',
        'in-progress-delayed-4': 'In Progress',
        'partialpayment': 'Partial Payment',
        'bar': 'BAR',

        'info': 'Info',
        'offer': 'Offer',
        'ec': 'EC',
        'out': 'OUT',
        'not_reached': 'Not Reached',

        'claim': 'Claim',
        'declined': 'Declined',
        'transfer': 'Transfer',
        'no_information': 'No Information',
        'request-wait-info': 'Request Wait Info',
        'request-cancellation': 'Request Cancellation',
}
}

export const jobStates = [
    'new', 'in-progress', 'request-wait-info', 'request-cancellation', 'canceled', 'closed'
];

export const stateColor = (state) => {

    let color = stateColorsMap[state];

    if(color === undefined)
        color = 'info';

    return color;
}

export const stateText = (state) => {
    const selectedLanguage = getSelectedLanguage();
    let text = stateTranslationsMap[selectedLanguage.code][state];

    if(text === undefined)
        text = state;

    return text;
}

export const stateToBadge = (state, props) => {
    let color = stateColor(state);
    let text = stateText(state);

    return (
        <span {...props} className={"badge bg-light-"+ color +" text-"+ color}>{text}</span>
    )

}
export const formatDisplayPhoneNumber = (number) => {
    return '0'+ number.substr(2).replace(/(\d{3})/g, '$1 ').replace(/(^\s+|\s+$)/,'');
}

export const countriesMap = {
    de: {
        'code': 'de',
        'name': 'Deutschland',
        'icon': 'flag-icon flag-icon-de',
        'mapCenter': {lat: 50.9863612, lng: 9.4526253}
    },
    at: {
        'code': 'at',
        'name': 'Österreich',
        'icon': 'flag-icon flag-icon-at',
        'mapCenter': {lat: 47.6910132, lng: 12.2248135}
    },
    ch: {
        'code': 'ch',
        'name': 'Schweiz',
        'icon': 'flag-icon flag-icon-ch',
        'mapCenter': {lat: 46.8077152, lng: 5.9814056}
    },
    nl: {
        'code': 'nl',
        'name': 'Niederlande',
        'icon': 'flag-icon flag-icon-nl',
        'mapCenter': {lat: 52.2076821, lng: 4.1584767}
    }
};

export const countriesArray = [
    countriesMap['de'],
    countriesMap['at'],
    countriesMap['ch'],
    countriesMap['nl'],
];
