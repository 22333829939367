import React, { Component } from "react";
import {connect} from "react-redux";

import {
    getFittersByDistance,
    getFitters,
} from "../../store/actions";
import Phone from "../Buttons/Phone";
import Telegram from "../Buttons/Telegram";
import {withRouter} from "react-router-dom";
import {Code} from "react-content-loader";
import {Input, InputGroup, InputGroupAddon, InputGroupText} from "reactstrap";
import {__} from "../../utils/helpers";
const MyCodeLoader = () => <Code />;

class FitterSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: {
                text: "",
            }
        };
    }

    componentDidMount() {
        const {fitters, fittersLoading} = this.props;

        if(fitters.length === 0 && fittersLoading === false)
            this.props.getFitters();

        this.fetchFittersByDistance();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const watch = [
            'job_type_id', 'job_source_id', 'country_code', 'mapLocation'
        ];

        for(let i = 0; i < watch.length; i++){
            let key = watch[i];

            if(typeof this.props[key] !== "object" && prevProps[key] !== this.props[key]){
                this.fetchFittersByDistance();
                break;
            }

            if(typeof this.props[key] === "object" && JSON.stringify(prevProps[key]) !== JSON.stringify(this.props[key])){
                this.fetchFittersByDistance();
                break;
            }
        }
    }

    onFitterSelected(fitter, location){
        this.setState({
            fitter: fitter,
            location: location,
        });

        this.props.onFitterSelected(fitter, location);
    }

    fetchFittersByDistance = () => {
        const {job_type_id, job_source_id, country_code, mapLocation, fittersDistanceLoading} = this.props;

        if(fittersDistanceLoading || !job_type_id || !job_source_id || !country_code || !mapLocation?.lat  || !mapLocation?.lng)
            return;

        this.props.getFittersByDistance(job_type_id, job_source_id, mapLocation, country_code);
    }

    renderFitterRow = (item) => {
        const {fitter_id} = this.props;
        const {fitter} = this.state;
        const active = (fitter && fitter.id === item.fitter.id) || (fitter_id && parseInt(fitter_id) === item.fitter.id);

        const selectRow = () => {
            this.onFitterSelected(item.fitter, item?.location);
        };

        let duration = item.route?.duration.text.replace('Stunden', 'h').replace('Minuten', 'min');
        let location = item.location?.name.replace("Gebiet", '');

        const system = item.fitter.relationships?.system || item.fitter.system;

        return (
            <tr key={'fitter-'+ item.fitter.id}
                className={active ? 'table-success' : ''}
                onClick={selectRow}
                style={{cursor: 'pointer'}}
            >
                <td style={{width: '30px'}}>
                    <Phone size="xs" style={{display: 'block'}} showNumber={false} number={item.fitter.contact.phone} />
                    {item.fitter.contact.phone_alternate && (
                        <Phone style={{display: 'block', marginTop: '5px'}} size="xs" showNumber={false} number={item.fitter.contact.phone_alternate} />
                    )}
                    {item.fitter.contact.telegram_name && (
                        <Telegram style={{display: 'block', marginTop: '5px'}} size="xs" showUser={false} user={item.fitter.contact.telegram_name} />
                    )}
                </td>
                <td>
                    <span>{item.fitter.display_name}</span>
                    <small className="d-block">{system && system.name}</small>
                </td>
                <td>
                    <span>{location}</span>
                    <small className="d-block">{item.route?.distance.text}</small>
                    <small className="d-block">{duration}</small>
                </td>
            </tr>
        )
    }

    renderFittersByDistance = () => {
        const {fittersDistance} = this.props;

        return fittersDistance.map((item) => {
            return this.renderFitterRow(item);
        });
    }

    renderFittersTable = () => {
        const fitters = this.getFitters();

        return fitters.map((fitter) => {
            return this.renderFitterRow({
                fitter,
            })
        });
    }

    getFitters() {
        const {fitters, fittersLoading} = this.props;
        const {filter} = this.state;

        if(fittersLoading || !fitters)
            return [];

        return fitters.filter((fitter) => {
            if(fitter.status.status !== 'active')
                return false;

            if(filter.text !== "" && fitter.display_name.toLowerCase().search(filter.text.toLowerCase()) === -1)
                return false;

            return true;
        });
    }

    render() {
        const {view, fittersDistanceLoading} = this.props;
        const {filter} = this.state;

        return (
            <>
                { view === 'distance' ? (
                    <>
                        {fittersDistanceLoading ? (
                            <div className="card-body">
                                <MyCodeLoader />
                            </div>
                        ) : (
                            <table className="table table-hover customize-table v-top m-b-0">
                                <tbody>
                                {this.renderFittersByDistance()}
                                </tbody>
                            </table>
                        )}
                    </>
                ) : (
                    <>
                        <table className="table table-hover customize-table v-top m-b-0">
                            <thead>
                            <tr>
                                <th colSpan="3">
                                    <InputGroup size="sm">
                                        <InputGroupAddon addonType={"prepend"}>
                                            <InputGroupText style={{fontSize: '1rem'}}>
                                                <i className="fa fa-search" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input type="text"
                                               placeholder={__("search")}
                                               value={filter.text}
                                               onChange={(e) => {
                                                   this.setState({
                                                       filter: {
                                                           ...filter,
                                                           text: e.target.value
                                                       }
                                                   })
                                               }}
                                        />
                                    </InputGroup>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.renderFittersTable()}
                            </tbody>
                        </table>
                    </>
                )}
            </>
        );
    }


}

const mapStatetoProps = (state) => {
    const { fitters, fittersLoading, fittersDistance, fittersDistanceLoading } = state.Fitters;
    return {
        fitters,
        fittersLoading,
        fittersDistance,
        fittersDistanceLoading,
    };
};

export default withRouter(connect(mapStatetoProps, {
    getFittersByDistance,
    getFitters,
})(FitterSelect));
