import React, { Component } from "react";
import PropTypes from "prop-types";
import {ButtonDropdown, ButtonGroup, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";
import {__} from "../../utils/helpers";

class JobTypesSelect extends Component {
    constructor (props) {
        super(props)

        this.state = {
            dropdownOpen: false,
        }

        this.fitters = React.createRef()
    }

    toggleOpen = () => {
        const {dropdownOpen} = this.state;

        this.setState({
            dropdownOpen: !dropdownOpen,
        })
    }

    renderSelect(){
        const {data, filters, onJobTypeClick} = this.props;
        const {jobTypes} = filters;

        if(data === undefined)
            return "";

        return data.map(function(jobType, key) {
            const index = jobTypes.findIndex((element) => element === jobType.id);

            return (
                <DropdownItem key={'jobType-'+ jobType.id} data-value={jobType.id} onClick={onJobTypeClick} className={'dropdown-item '+ (index !== -1 ? 'active' : '')}>
                    <span className={jobType.icon +' text-'+ jobType.color +' m-r-10'} /> {jobType.name}
                </DropdownItem>
            )
        });
    }

    render(){
        const {filters, onClearJobTypes} = this.props;
        const {dropdownOpen} = this.state;

        return (
            <ButtonGroup>
                <ButtonDropdown isOpen={dropdownOpen} toggle={this.toggleOpen}>
                    <DropdownToggle caret className={'btn-'+ (filters.jobTypes.length === 0 ? 'secondary' : 'dark')}>
                        {__("branch")}
                    </DropdownToggle>
                    <DropdownMenu>
                        { this.renderSelect() }
                    </DropdownMenu>

                </ButtonDropdown>
                <button type="button" onClick={onClearJobTypes} className={'btn btn-'+ (filters.jobTypes.length === 0 ? 'secondary' : 'dark') +''} disabled={filters.jobTypes.length === 0}>
                    <i className={'fa fa-times'} />
                </button>
            </ButtonGroup>
        )
    }
}

export default JobTypesSelect;

JobTypesSelect.propTypes = {
    onJobTypeClick: PropTypes.func.isRequired,
    onClearJobTypes: PropTypes.func.isRequired,
    data: PropTypes.array.isRequired,
    filters: PropTypes.object.isRequired,
};