import React from "react";
import Moment from 'moment';
import 'moment/locale/de';
import {Link} from "react-router-dom";

export const JobCodeColumn = (cell, row, rowIndex, formatExtraData) => {
    return (
        <span>
            {row.code}
        </span>
    );
}

export const IconsColumn = (cell, row, rowIndex, formatExtraData) => {
    return (
        <span>
            <Link to={'/compliances/'+ formatExtraData.compliance_id +'/jobs/'+ row.id} className={'btn btn-xs btn-primary'} onClick={() => formatExtraData.setComplianceJob(row)}>
                <i className={'fa fa-play'} />
            </Link>
        </span>
    );
}

export const ClaimPriorityColumn = (cell, row, rowIndex, formatExtraData) => {
    let claim;

    if(row.claims.length !== 0){
        claim = row.claims[0];
    }

    if(!claim || !claim.template || !claim.template.priority){
        return (<div />);
    }

    const priority = claim.template.priority;

    return (
        <span>
            <span className={'label label-'+ priority.color}>
                <i className={priority.icon} />
                <span className={'ml-2'}>{priority.name}</span>
            </span>

        </span>
    );
}


export const JobTypeColumn = (cell, row, rowIndex, formatExtraData) => {
    return (
        <span>
            <i className={row.type.icon} title={row.type.name} />
        </span>
    );
}

export const CountryColumn = (cell, row, rowIndex, formatExtraData) => {
    return (
        <span>
            <i className={'flag-icon flag-icon-'+ row.address.country} />
        </span>
    );
}

export const CreatedColumn = (cell, row, rowIndex, formatExtraData) => {
    return (
        <span>
            {Moment(row.created).fromNow()}
        </span>
    );
}

export const FitterColumn = (cell, row, rowIndex, formatExtraData) => {
    return (
        <span>
            <a href={'/fitters/'+ row.fitter.id} target={'_blank'} className={'d-block'} rel="noreferrer">
                <small>
                    {row.fitter.display_name}
                </small>
            </a>
        </span>
    );
}

export const CustomerColumn = (cell, row, rowIndex, formatExtraData) => {
    return (
        <span>
            <span>{row.contact.name}</span>
            <span className={'d-block text-muted'}>{row.address.zipcode} {row.address.city}</span>
        </span>
    );
}
