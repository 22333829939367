import axios from 'axios';
import {ipcRenderer} from "../lib/Electron/ipcRenderer";
import {runsInElectron} from "../utils/helpers";

axios.defaults.baseURL = `${process.env.REACT_APP_API_URL}/api`;
axios.defaults.headers.common = { Accept: 'application/json', 'Content-Type': 'application/json' };

class ApiAuthBackend {
    getAuthenticatedUser () {
        if(runsInElectron() === false) {
            if (!sessionStorage.getItem("authUser")) return null;
            return JSON.parse(sessionStorage.getItem("authUser"));
        }

        return ipcRenderer.sendSync('get-auth-user');
    }

    getAuthToken() {
        if(runsInElectron() === false){
            if (!sessionStorage.getItem("authToken"))
                return null;

            const data = JSON.parse(sessionStorage.getItem("authToken"));
            return data !== null && data.token ? data.token : null
        }

        const data = ipcRenderer.sendSync('get-auth-token');
        return data !== null && data.token ? data.token : null
    }

    setLoggedInUser(token, user, skipIpc) {
        if(!skipIpc && token && user)
            ipcRenderer.send('login-accepted', user, token);

        if(user === null)
            sessionStorage.removeItem("authUser");
        else
            sessionStorage.setItem("authUser", JSON.stringify(user));

        if(token === null)
            sessionStorage.removeItem("authToken");
        else
            sessionStorage.setItem("authToken", JSON.stringify(token));
    }

    signIn = (username, password) => {
        return new Promise(async (resolve, reject) => {
            const machine = await ipcRenderer.sendSync('get-machine-info');
            axios.post('v1/user/login', { username, password, machine: machine || ''})
                .then((response) => {
                    this.setLoggedInUser({
                        token: response.data.success.token,
                        expires_at: response.data.success.expires_at,
                    },
                        {
                            ...response.data.success.profile,
                        });

                    resolve(response.data.success);
                })
                .catch(error => {
                    reject(this._handleError(error.response || error));
                });
        });
    };

    signOut = () => {

        return new Promise((resolve, reject) => {
            axios.defaults.headers.common = { Authorization: `Bearer ${this.getAuthToken()}` };
            axios.get('v1/user/logout')
                .then(() => {
                    this.setLoggedInUser(null, null);
                    resolve(true);
                })
                .catch(error => {
                    reject(this._handleError(error.response || error));
                });
        });

    };

    _handleError(error) {
        console.log(error);
        if(error.statusText)
            return error.statusText;
        // var errorCode = error.code;
        return error.message;
    }
}

let _apiAuthBackend = null;

/**
 * Initilize the backend
 * @param {*} config
 */
const initApiAuthBackend = () => {
    if (!_apiAuthBackend) {
        _apiAuthBackend = new ApiAuthBackend();
    }
    return _apiAuthBackend;
};

/**
 * Returns the firebase backend
 */
const getApiAuthBackend = () => {
    if (!_apiAuthBackend) {
        initApiAuthBackend();
    }

    return _apiAuthBackend;
};

export { initApiAuthBackend, getApiAuthBackend };
