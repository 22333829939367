import React, { Component } from "react";
import PropTypes from "prop-types";
import {ButtonDropdown, ButtonGroup, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";
import {__} from "../../utils/helpers";

class CallsSelect extends Component {
    constructor (props) {
        super(props)

        this.state = {
            dropdownOpen: false,
        }
    }

    toggleOpen = () => {
        const {dropdownOpen} = this.state;

        this.setState({
            dropdownOpen: !dropdownOpen,
        })
    }

    renderSelect(){
        const {filters, onCallFilterClick} = this.props;
        let {calls} = filters;

        if(typeof(calls) === "undefined")
            calls = [];

        const options = [
            {
                name: __("withoutJob"),
                value: "without_job",
            },
            {
                name: __("withJob"),
                value: "with_job",
            },
            {
                name: __("missedCalls"),
                value: "missed_calls",
            },
            {
                name: __("incomingCalls"),
                value: "in_calls",
            },
            {
                name: __("outgoingCalls"),
                value: "out_calls",
            }
        ];

        return options.map(function (item) {
            const index = calls.findIndex((element) => element === item.value);
            return (
                <DropdownItem key={'call-' + item.value}
                     data-value={item.value}
                     className={'dropdown-item '+ (index !== -1 ? 'active' : '')}
                     onClick={onCallFilterClick}>
                    {item.name}
                </DropdownItem>
            );
        });

    }

    render(){
        const {filters, onClearCallsFilter} = this.props;
        const {dropdownOpen} = this.state;

        let {calls} = filters;

        if(typeof(calls) === "undefined")
            calls = [];

        return (
            <ButtonGroup>
                <ButtonDropdown isOpen={dropdownOpen} toggle={this.toggleOpen}>
                    <DropdownToggle caret className={'btn-'+ (calls.length === 0 ? 'secondary' : 'dark')}>
                        {__("calls")}
                    </DropdownToggle>
                    <DropdownMenu>
                        { this.renderSelect() }
                    </DropdownMenu>
                </ButtonDropdown>
                <button type="button"
                        onClick={onClearCallsFilter}
                        className={'btn btn-'+ (calls.length === 0 ? 'secondary' : 'dark') +''}
                        disabled={calls.length === 0}
                >
                    <i className={'fa fa-times'} />
                </button>
            </ButtonGroup>
        )
    }
}

export default CallsSelect;

CallsSelect.propTypes = {
    onCallFilterClick: PropTypes.func.isRequired,
    onClearCallsFilter: PropTypes.func.isRequired,
    filters: PropTypes.object.isRequired,
};
