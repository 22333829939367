import React, { Component } from "react";
import PropTypes from "prop-types";
import {Button, Input, InputGroup, InputGroupAddon} from "reactstrap";
import {__} from "../../utils/helpers";


class TextSearchInput extends Component {
    render(){
        const {filters, onTextChange, onClearTextSearch} = this.props;
        return (
            <InputGroup>
                    <Input placeholder={__("textSearch")} onChange={onTextChange} value={filters.textSearch}  />
                    <InputGroupAddon addonType="append">
                        <Button onClick={onClearTextSearch}
                                color={(filters.textSearch.length === 0 ? 'secondary' : 'dark')}
                                disabled={filters.textSearch.length === 0}
                        >
                            <i className={'fa fa-times'} />
                        </Button>
                    </InputGroupAddon>
                </InputGroup>
        );
    }
}

export default TextSearchInput;

TextSearchInput.propTypes = {
    onTextChange: PropTypes.func.isRequired,
    onClearTextSearch: PropTypes.func.isRequired,
    filters: PropTypes.object.isRequired,
};
