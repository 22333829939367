import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import { withRouter } from "react-router-dom";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {__} from "../../utils/helpers";
import {
    setPageTitle,
    setBreadCrumbs,
    getFitters,
    getJobTypes,
    setFitter
} from "../../store/actions";
import FittersTable from "../../components/Tables/FittersTable";
import FilterContainer from "../../components/Filter";

class Fitters extends Component {
    constructor(props) {
        super(props);

        this.cacheKeys = {
            filters: 'fitters.filter',
        }

        this.ref = {
            FittersTable: null,
        }

        const filters = JSON.parse(localStorage.getItem(this.cacheKeys.filters)) || {
            jobTypes: [],
            countries: [],
            textSearch: "",
        };

        this.state = {
            countries: [
                {
                    'code': 'de',
                    'name': 'Deutschland',
                    'icon': 'flag-icon flag-icon-de',
                },
                {
                    'code': 'at',
                    'name': 'Österreich',
                    'icon': 'flag-icon flag-icon-at',
                },
                {
                    'code': 'ch',
                    'name': 'Schweiz',
                    'icon': 'flag-icon flag-icon-ch',
                },
                {
                    'code': 'nl',
                    'name': 'Niederlande',
                    'icon': 'flag-icon flag-icon-nl',
                }
            ],
            filters: filters,
        };
    }

    componentDidMount() {
        document.title = "Partner";

        if(this.props.jobTypes.length === 0 && this.props.jobTypesLoading === false)
            this.props.getJobTypes();
    }

    onClearCountries = (e) => {
        const filters = {
            ...this.state.filters,
            countries: [],
        };
        this.setState({
            filters
        }, () => {
            localStorage.setItem(this.cacheKeys.filters, JSON.stringify(filters))
        });
    }

    onCountryClick = (e) => {
        const value = e.target.dataset.value;
        const {countries} = this.state.filters;
        const index = countries.findIndex((element) => element === value);

        if(index === -1){
            countries.push(value);
        } else {
            countries.splice(index, 1);
        }

        const filters = {
            ...this.state.filters,
            countries: countries,
        };

        this.setState({
            filters,
        }, () => {
            localStorage.setItem(this.cacheKeys.filters, JSON.stringify(filters))
        });
    }

    onClearJobStates = (e) => {
        const filters = {
            ...this.state.filters,
            jobStates: [],
        };
        this.setState({
            filters
        }, () => {
            localStorage.setItem(this.cacheKeys.filters, JSON.stringify(filters))
        });
    }

    onJobStateClick = (e) => {
        const value = e.target.dataset.value;
        let {jobStates} = this.state.filters;

        if(jobStates === undefined)
            jobStates = [];

        const index = jobStates.findIndex((element) => element === value);

        if(index === -1){
            jobStates.push(value);
        } else {
            jobStates.splice(index, 1);
        }

        const filters = {
            ...this.state.filters,
            jobStates: jobStates,
        };

        this.setState({
            filters,
        }, () => {
            localStorage.setItem(this.cacheKeys.filters, JSON.stringify(filters))
        });
    }

    onClearJobTypes = (e) => {
        const filters = {
            ...this.state.filters,
            jobTypes: [],
        };

        this.setState({
            filters
        }, () => {
            localStorage.setItem(this.cacheKeys.filters, JSON.stringify(filters))
        });
    }

    onJobTypeClick = (e) => {
        const value = e.target.dataset.value;
        const {jobTypes} = this.state.filters;
        const index = jobTypes.findIndex((element) => element === value);

        if(index === -1){
            jobTypes.push(value);
        } else {
            jobTypes.splice(index, 1);
        }

        const filters = {
            ...this.state.filters,
            jobTypes: jobTypes,
        };

        this.setState({
            filters
        }, () => {
            localStorage.setItem(this.cacheKeys.filters, JSON.stringify(filters))
        });
    }

    onClearTextSearch = (e) => {
        const filters = {
            ...this.state.filters,
            textSearch: '',
        };

        this.setState({
            filters: filters
        }, () => {
            localStorage.setItem(this.cacheKeys.filters, JSON.stringify(filters))
        });
    }

    onTextChange = (e) => {
        const filters = {
            ...this.state.filters,
            textSearch: e.target.value,
        };
        this.setState({
            filters
        }, () => {
            localStorage.setItem(this.cacheKeys.filters, JSON.stringify(filters))
        });
    };

    onClearDateRange = (e) => {
        this.onDateRangeChange(null, null);
    }

    onDateRangeChange = (start, end) => {
        const filters = {
            ...this.state.filters,
            startDate: start !== null ? start.format('DD.MM.YYYY') : null,
            endDate: end !== null ? end.format('DD.MM.YYYY') : null
        };
        this.setState({
            filters
        }, () => {
            localStorage.setItem(this.cacheKeys.filters, JSON.stringify(filters))
        });
    }

    onClearAppointmentDateRange = (e) => {
        this.onAppointmentDateRangeChange(null, null);
    }

    onAppointmentDateRangeChange = (start, end) => {
        const filters = {
            ...this.state.filters,
            appointmentStartDate: start !== null ? start.format('DD.MM.YYYY') : null,
            appointmentEndDate: end !== null ? end.format('DD.MM.YYYY') : null
        };
        this.setState({
            filters
        }, () => {
            localStorage.setItem(this.cacheKeys.filters, JSON.stringify(filters))
        });
    }

    onFiltersUpdated = (filters) => {
        this.setState({
            filters
        }, () => {
            localStorage.setItem(this.cacheKeys.filters, JSON.stringify(filters))
        })
    };

    getFitters = (meta) => {
        this.props.getFitters();
    }

    setBreadCrumbs(){
        this.props.setBreadCrumbs([
            {
                name: 'Home',
                href: '/dashboard'
            },
            {
                name: __('partners'),
                href: '#'
            },
        ]);
    }

    setPageTitle(){
        this.props.setPageTitle(__('partners'));
    }

    reloadData(){
        if(this.ref.FittersTable !== null){
            this.ref.FittersTable.reloadData();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        //this.setPageTitle();
        //this.setBreadCrumbs();
    }

    render() {
        const {jobTypesLoading, jobTypes, fitters, fittersLoading, setFitter} = this.props;
        const {countries, filters} = this.state;

        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Row>
                            <Col xl="9">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title m-b-0">
                                            {__('partners')}
                                        </h4>
                                    </div>
                                    <div className="">
                                        <div>
                                            <FittersTable
                                                ref={ n => this.ref['FittersTable'] = n }
                                                loading={fittersLoading}
                                                getData={this.getFitters}
                                                setFitter={setFitter}
                                                filters={filters}
                                                meta={{}}
                                                data={fitters}
                                            />
                                        </div>
                                    </div>
                                </div>

                            </Col>
                            <Col xl="3">
                                <FilterContainer
                                    loading={jobTypesLoading}
                                    jobTypes={jobTypes}
                                    countries={countries}
                                    filters={filters}

                                    display={{
                                        jobSourcesTagCloud: false,
                                        jobTypesSelect: true,
                                        countriesSelect: true,
                                        fittersSelect: false,
                                        textSearchInput: true,
                                        dateRangePicker: false,
                                        appointmentDateRangePicker: false,
                                        callsSelect: false,
                                        jobStatesSelect: false
                                    }}

                                    onFiltersUpdated={this.onFiltersUpdated}
                                    onClearCountries={this.onClearCountries}
                                    onCountryClick={this.onCountryClick}
                                    onClearJobTypes={this.onClearJobTypes}
                                    onJobTypeClick={this.onJobTypeClick}
                                    onClearTextSearch={this.onClearTextSearch}
                                    onTextChange={this.onTextChange}
                                />
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = (state) => {
    const { fitters, fittersLoading } = state.Fitters;
    const { jobTypes, jobTypesLoading } = state.JobTypes;

    return {
        fitters,
        fittersLoading,
        jobTypes,
        jobTypesLoading,
    };
};

function mapDispatchToProps(dispatch) {
    let actions = bindActionCreators({ setPageTitle, setBreadCrumbs, getFitters, setFitter, getJobTypes }, dispatch);
    return { ...actions, dispatch };
}


export default withRouter(
    connect(mapStatetoProps, mapDispatchToProps)(Fitters)
);
