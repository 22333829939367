import React from "react";
import {Link} from "react-router-dom";
import {DisplayCountryColumn} from "./Shared";

export const IconsColumn = (cell, row, rowIndex, formatExtraData) => {

    if(row.spam !== 0){
        return (
            <span className={'text-warning'} title={'Spam'}><i className={'fa fa-trash'} /></span>
        )
    }

    if(row.missed === true || row.answered === false){
        return (
            <span className={'text-danger'} title={'Entgangener Anruf'}><i className={'fa fa-exclamation-triangle'} /></span>
        )
    }

    return '';
}

export const CountryColumn = (cell, row, rowIndex, formatExtraData) => {
    return DisplayCountryColumn(row.address.country);
}

export const NicknameColumn = (cell, row, rowIndex, formatExtraData) => {
    return (
        <span className="d-block text-truncate"
              style={{maxWidth: '200px'}}>
            {row.nickname}
        </span>
    );
}

export const CompanyColumn = (cell, row, rowIndex, formatExtraData) => {
    return (
        <span className="d-block text-truncate"
              style={{maxWidth: '200px'}}>
            {row.company}<br />
            <small>{row.salutation} {row.person}</small>
        </span>
    );
}

export const LocationsColumn = (cell, row, rowIndex, formatExtraData) => {
    return (
        <span>
            {row.locations.map((location) => {
                return (
                    <small className="d-block" key={'fitter-location-'+ location.pivot.fitter_id +'-'+ location.pivot.location_id}>
                        <i className={location.job_type.icon} /> {DisplayCountryColumn(location.address.country)} {location.name.replace('Gebiet', '').trim()}
                    </small>
                )
            })}
        </span>
    )
}

export const ViewColumn = (cell, row, rowIndex, formatExtraData) => {
    return (
        <span>
            <div className={'btn-group'}>
                <Link className={"btn btn-xs btn-secondary"}
                      to={'/fitters/'+ row.id}
                      onClick={() => formatExtraData.setFitter(row)}
                >
                    <i className={'fa fa-eye'}/>
                </Link>
            </div>
        </span>
    );
};