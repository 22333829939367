import {call, put, takeLatest} from "redux-saga/effects";

import {
    getJobSuccess,
    getJobsSuccess,
    getJobMonitorSuccess,
    getJobQuestionsSuccess,
    getJobPriceTemplatesSuccess,
    addJobStatusSuccess,
    addJobClaimSuccess,
    sendJobMessageSuccess,
    addJobPartialPaymentSuccess,
    addJobPartialPaymentError,
    deleteJobPartialPaymentSuccess,
    addJobSuccess,
    addJobError,
    patchJobSuccess,
    patchJobFitterSuccess,
    patchJobFitterError,
    patchJobAppointmentSuccess,
    patchJobAppointmentError,
} from "./actions";

import {getApiBackend} from "../../api";
import queryString from "query-string";
import {flatten} from "q-flat";

const api           = getApiBackend();
const job_relations = 'status,statusHistory,type,source,fitter,fitter.system,calls,messagesSent,partialPayments,compliances,compliance_dialogues,compliance_dialogues.answers,claims';

/**
 * Changes the layout type
 * @param {*} param0
 */
function* getJob({ payload: { id } }) {
    try {
        const response = yield call(api.get, '/v2/jobs/'+ id +'?with='+ job_relations)
        yield put(getJobSuccess(response));

    } catch (error) { }
}

function* patchJob({ payload: { id, data } }) {
    try {
        const response = yield call(api.patch, '/v2/jobs/'+ id +'?with='+ job_relations, data)
        yield put(patchJobSuccess(response));

    } catch (error) { }
}

function* addJob({ payload: { data, history } }) {
    try {
        const response = yield call(api.post, '/v2/jobs?with='+ job_relations, data)
        yield put(addJobSuccess(response, history));

    } catch (error) {
        yield put(addJobError(error, history));
    }
}

function* addJobStatus({ payload: { id, data } }) {
    try {
        const response = yield call(api.post, '/v2/jobs/'+ id +'/status?with='+ job_relations, data)
        yield put(addJobStatusSuccess(response));

    } catch (error) { }
}

function* addJobClaim({ payload: { id, data } }) {
    try {
        const response = yield call(api.post, '/v2/jobs/'+ id +'/claims?with='+ job_relations, data)
        yield put(addJobClaimSuccess(response));

    } catch (error) { }
}

function* sendJobMessage({ payload: { id, data } }) {
    try {
        const response = yield call(api.post, '/v2/jobs/'+ id +'/messages?with='+ job_relations, data)
        yield put(sendJobMessageSuccess(response));

    } catch (error) { }
}

function* patchJobFitter({ payload: { id, data } }) {
    try {
        const response = yield call(api.patch, '/v2/jobs/'+ id +'/fitter?with='+ job_relations, data)
        yield put(patchJobFitterSuccess(response));

    } catch (error) {
        yield put(patchJobFitterError(error));
    }
}

function* patchJobAppointment({ payload: { id, data } }) {
    try {
        const response = yield call(api.patch, '/v2/jobs/'+ id +'/appointment?with='+ job_relations, data)
        yield put(patchJobAppointmentSuccess(response));

    } catch (error) {
        yield put(patchJobAppointmentError(error));
    }
}

function* addJobPartialPayment({ payload: { id, data } }) {
    try {
        const response = yield call(api.post, '/v2/jobs/'+ id +'/partialPayments?with='+ job_relations, data)
        yield put(addJobPartialPaymentSuccess(response));

    } catch (error) {
        yield put(addJobPartialPaymentError(error));
    }
}

function* deleteJobPartialPayment({ payload: { id, partial_payment_id } }) {
    try {
        const response = yield call(api.delete, '/v2/jobs/'+ id +'/partialPayments/'+ partial_payment_id +'?with='+ job_relations)
        yield put(deleteJobPartialPaymentSuccess(response));

    } catch (error) { }
}

/**
 * Changes the layout type
 * @param {*} param0
 */
function* getJobs({payload: {meta}}) {
    try {
        const response = yield call(api.get, '/v2/jobs?with='+ job_relations +'&'+ queryString.stringify(flatten(meta)))

        yield put(getJobsSuccess(response));

    } catch (error) { }
}

/**
 * Changes the layout type
 * @param {*} param0
 */
function* getJobMonitor() {
    try {
        const response = yield call(api.get, '/v2/dashboard/jobMonitor?per_page=9999')
        yield put(getJobMonitorSuccess(response));

    } catch (error) { console.log(error);}
}

/**
 * Changes the layout type
 * @param {*} param0
 */
function* getJobQuestions({payload: {filter}}) {
    try {
        const response = yield call(api.get, '/v2/jobs/questions?'+ queryString.stringify(flatten(filter)))

        yield put(getJobQuestionsSuccess(response));

    } catch (error) { }
}

/**
 * Changes the layout type
 * @param {*} param0
 */
function* getJobPriceTemplates({payload: {filter}}) {
    try {
        const response = yield call(api.get, '/v2/jobs/priceTemplates?'+ queryString.stringify(flatten(filter)))

        yield put(getJobPriceTemplatesSuccess(response));

    } catch (error) { }
}

/**
 * Watchers
 */
function* JobsSaga() {
    yield takeLatest('GET_JOB', getJob);
    yield takeLatest('PATCH_JOB', patchJob);
    yield takeLatest('PATCH_JOB_FITTER', patchJobFitter);
    yield takeLatest('PATCH_JOB_APPOINTMENT', patchJobAppointment);
    yield takeLatest('ADD_JOB', addJob);
    yield takeLatest('ADD_JOB_STATUS', addJobStatus);
    yield takeLatest('ADD_JOB_CLAIM', addJobClaim);
    yield takeLatest('SEND_JOB_MESSAGE', sendJobMessage);
    yield takeLatest('ADD_JOB_PARTIAL_PAYMENT', addJobPartialPayment);
    yield takeLatest('DELETE_JOB_PARTIAL_PAYMENT', deleteJobPartialPayment);
    yield takeLatest('GET_JOBS', getJobs);
    yield takeLatest('GET_JOB_MONITOR', getJobMonitor);
    yield takeLatest('GET_JOB_QUESTIONS', getJobQuestions);
    yield takeLatest('GET_JOB_PRICE_TEMPLATES', getJobPriceTemplates);
}

export default JobsSaga;
