import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import overlayFactory from 'react-bootstrap-table2-overlay';

import {formatCurrencyInput} from "../../utils/helpers";
import {
    FitterColumn,
    JobsTotalCountColumn,
    JobsCanceledCountColumn,
    JobsCanceledQuoteColumn,
    JobsClaimedCountColumn,
    JobsAvgPaymentColumn,
    JobsOpenPaymentColumn,
    JobsTotalPaymentColumn,
} from "./FittersTableColumns";
import {BootstrapTableOptions} from "../Tables/Options";

class FittersTable extends Component {
    constructor(props) {
        super(props);

        this.cacheKeys = {
            oldTableState: 'statistics.fitters.oldTableState',
        }

        this.state = {
            //meta,
            oldTableState: JSON.parse(localStorage.getItem(this.cacheKeys.oldTableState)) || null,
        };
    }

    componentDidMount() {
        const {filters, fitterStatistics} = this.props;

        if(fitterStatistics.length === 0){
            this.props.getData({
                filters,
            });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {filters} = this.props;

        if(filters !== prevProps.filters){
            this.props.getData({
                ...this.state.defaultMeta,
                filters,
            });
        }
    }

    columns(){
        const columns = [];

        columns.push({
            dataField: 'fitter_id',
            text: 'Name',
            formatter: FitterColumn,
            sort: false
        });

        columns.push({
            dataField: 'jobs_count',
            text: 'Aufträge',
            formatter: JobsTotalCountColumn,
            sort: true,
            sortValue: (cell, row) => parseInt(row.jobs_count)
        });

        columns.push({
            dataField: 'canceled_jobs_count',
            text: 'Storno',
            formatter: JobsCanceledCountColumn,
            sort: true,
            sortValue: (cell, row) => parseInt(row.canceled_jobs_count)
        });

        columns.push({
            dataField: 'canceled_jobs_quote',
            text: '',
            formatter: JobsCanceledQuoteColumn,
            sort: true,
            sortValue: (cell, row) => parseFloat(row.canceled_jobs_quote)
        });

        columns.push({
            dataField: 'claimed_jobs_count',
            text: 'Beschwerden',
            formatter: JobsClaimedCountColumn,
            sort: true,
            sortValue: (cell, row) => parseInt(row.claimed_jobs_count)
        });

        columns.push({
            dataField: 'jobs_avg_payment',
            text: 'Ø Umsatz',
            formatter: JobsAvgPaymentColumn,
            sort: true,
            sortValue: (cell, row) => parseFloat(row.jobs_avg_payment)
        });

        columns.push({
            dataField: 'jobs_open_payment',
            text: 'Offen',
            formatter: JobsOpenPaymentColumn,
            sort: true,
            sortValue: (cell, row) => parseFloat(row.jobs_open_payment)
        });

        columns.push({
            dataField: 'jobs_payment',
            text: 'Gesamt',
            formatter: JobsTotalPaymentColumn,
            sort: true,
            sortValue: (cell, row) => parseFloat(row.jobs_payment)
        });

        return columns;
    }

    expandRow() {

        const renderCancellationTemplates = (row) => {
            if(!row.cancellations)
                return '';

            return row.cancellations.map((template) => {
                return (
                    <tr key={'summary-cancellation-template-'+ template.id}>
                        <td><small>{template.name}</small></td>
                        <td className="text-right"><small>{formatCurrencyInput(parseFloat(template.percent).toFixed(2))} %</small></td>
                        <td className="text-right"><small>{template.count} x</small></td>
                    </tr>
                )
            });
        };

        return {
            renderer: (row, rowIndex) => (
                <Row>
                    <Col xl="6">
                        <table className="table table-hover customize-table v-top">
                            <thead className="">
                            <tr>
                                <th>Gesamt</th>
                                <th style={{width: '95px'}} />
                                <th style={{width: '95px'}} className="text-right">{row.jobs_count} x</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>Storno</td>
                                <td className="text-right">{formatCurrencyInput(parseFloat(row.canceled_jobs_quote).toFixed(2))} %</td>
                                <td className="text-right">{row.canceled_jobs_count} x</td>
                            </tr>
                            {renderCancellationTemplates(row)}
                            </tbody>
                        </table>
                    </Col>
                </Row>
            )
        };
    }

    sort = () => {
        const {meta} = this.props;

        return {
            dataField: meta.sort.column ? meta.sort.column : '',
            order: meta.sort.order ? meta.sort.order.toLowerCase() : '',
        };
    }

    paginationFactory = () => {
        const {meta} = this.props;
        return paginationFactory({
            page: meta.current_page,
            sizePerPage: parseInt(meta.per_page),
            totalSize: meta.total,
            showTotal: true,
        });
    }

    indication() {
        return (
            <div className="text-muted mt-3 no-data" id="no-data">
                <p className="text-danger mb-1">
                    <i className="fa fa-ban" style={{ 'fontSize': '24px' }} />
                </p>
                <p>No Data Found!</p>
            </div>
        );
    }

    render() {
        const {loading, fitterStatistics} = this.props;
        const self = this;
        const boxStyle = {
        };

        return (
                <div className="comment-widgets m-b-0" style={boxStyle}>
                    <div>
                        <ToolkitProvider
                            keyField='fitter_id'
                            data={ fitterStatistics }
                            columns={ this.columns() }
                            search
                        >
                            {
                                props => (
                                    <div className="">
                                        <BootstrapTable
                                            { ...props.baseProps }
                                            { ...BootstrapTableOptions }
                                            ref={ n => self.node = n }
                                            loading={loading}
                                            overlay={overlayFactory()}
                                            striped={true}
                                            hover={true}
                                            bootstrap4
                                            expandRow={self.expandRow()}
                                            classes={'table table-hover customize-table v-top m-b-0'}
                                            //pagination={ self.paginationFactory() }
                                            noDataIndication={self.indication()}
                                            //sort={ self.sort() }
                                            //filter={ filterFactory() }
                                            //onTableChange={self.onTableChange}
                                            bordered={false}
                                            //loading={loading}
                                            /*selectRow={this.selectRow()}*/
                                        />
                                    </div>
                                )
                            }
                        </ToolkitProvider>
                    </div>

                </div>
        );
    }
}

export default FittersTable;
