import {call, put, takeLatest} from "redux-saga/effects";

import {
    getStatisticsFittersSuccess,
    getStatisticsJobMapSuccess,
} from "./actions";

import {getApiBackend} from "../../api";
import queryString from "query-string";
import {flatten} from "q-flat";

const api    = getApiBackend();

/**
 * Changes the layout type
 * @param {*} param0
 */
function* getStatisticsFitters({ payload: { meta } }) {
    try {
        const response = yield call(api.get, '/v2/statistics/fitters?'+ queryString.stringify(flatten(meta), {encode:false}))

        yield put(getStatisticsFittersSuccess(response));

    } catch (error) { }
}

/**
 * Changes the layout type
 * @param {*} param0
 */
function* getStatisticsJobMap({ payload: { meta } }) {
    try {
        const response = yield call(api.get, '/v2/statistics/jobMap?'+ queryString.stringify(flatten(meta), {encode:false}))

        yield put(getStatisticsJobMapSuccess(response));

    } catch (error) { }
}

/**
 * Watchers
 */

function* StatisticsSaga() {
    yield takeLatest('GET_STATISTICS_FITTERS', getStatisticsFitters);
    yield takeLatest('GET_STATISTICS_JOB_MAP', getStatisticsJobMap);
}

export default StatisticsSaga;
