import {runsInElectron} from "../../utils/helpers";
import {ipcRenderer} from "../../lib/Electron/ipcRenderer";
import {Base64} from "js-base64";

function ExternalLink(props) {
    let {children, href, className, style, queryData} = props;

    if(runsInElectron()) {

        return (
            <span className={className} onClick={() => {
                const preferences = ipcRenderer.sendSync('get-preferences');
                if(href.substr(0, 1) === "/") {
                    href = preferences.baseUrls.proxy +''+ href;
                }

                if(typeof queryData === 'object') {
                    href = href +'?queryData='+ Base64.encode(JSON.stringify(queryData));
                }

                window.require('electron').shell.openExternal(href);
            }} style={{...style, cursor: 'pointer'}}>
                {children}
            </span>
        );
    }

    return (
        <a className={className} href={href} style={props.style} target="_blank" rel="noreferrer">
            {children}
        </a>
    );
}

export default ExternalLink;

