
export default class User {
    constructor(data) {
        this.id = data.id;
        this.type = data.type;
        this.name = data.name;
        this.email  = data.email;
        this.language = data.language;
        this.locale = data.locale;
        this.locked = data.locked;
        this.created = data.created;
        this.profile_id = data.profile_id;
        this.profile_type = data.profile_type;
        this.profile = data.profile;

        this.permissions = data.permissions;
        this.roles = data.roles;
    }

    test() {
        console.log("Test called!!!");
    }

    hasPermission(permission) {

        if(permission instanceof Array){
            for(let i = 0; i < permission.length; i++) {
                if(this.hasPermission(permission[i]) === true)
                    return true;
            }

            return false;
        }

        if(this.permissions instanceof Array && this.permissions instanceof Array) {
            let index = this.permissions.findIndex((item) => {
                return item.name === permission;
            });

            if(index !== -1)
                return true;

            index = this.roles.findIndex((role) => {
                return role.permissions.findIndex((item) => {
                    return item.name === permission;
                }) !== -1;
            });

            return index !== -1;
        }

       return false;
    }
}