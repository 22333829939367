// @flow
import {
    GET_JOB_SOURCE,
    GET_JOB_SOURCE_SUCCESS,
    GET_JOB_SOURCES,
    GET_JOB_SOURCES_SUCCESS,
} from "./actionTypes";

const INIT_STATE = {
    error: null,
    loading: false,
    jobSourcesLoading: false,
    jobSources:  [],
};

const JobSources = (state = INIT_STATE, action) => {

    let job;

    switch (action.type) {

        case GET_JOB_SOURCE:
            job = state.jobs.find((element) => {
                return element.id === action.payload.id;
            });

            if(job === undefined)
                job = {};

            return {
                ...state,
                loading: true,
                job: job,
                //tasks: []
            };
        case GET_JOB_SOURCE_SUCCESS:
            return {
                ...state,
                loading: false,
                //task: state.tasks[0]
                //task: task,
                //tasks: {},
                jobs: state.jobs.map(function(item){
                    if(item.id !== action.payload.id){
                        return item;
                    }

                    return action.payload;
                })
            };

        case GET_JOB_SOURCES:
            return {
                ...state,
                jobSourcesLoading: true,
            }
        case GET_JOB_SOURCES_SUCCESS:
            return {
                ...state,
                jobSourcesLoading: false,
                jobSources: action.payload,
            }
        default:
            return state;
    }
};


export default JobSources;
