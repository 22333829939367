import React from 'react'
import { Row, Col } from "reactstrap";
import { GoogleMap, Marker } from '@react-google-maps/api';
import {BulletList } from "react-content-loader";
import {formatCurrencyInput, __} from "../../utils/helpers";

const MyCodeLoader = () => <BulletList  />;

const containerStyle = {
    width: '100%',
    height: '800px'
};

const center = {
    lat: 50.9863612, lng: 9.4526253
};

function JobMap(props) {
    const {jobMap, loading} = props;
    const isLoaded = true;

    // eslint-disable-next-line
    const [map, setMap] = React.useState(null)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onLoad = React.useCallback(function callback(map) {
        new window.google.maps.LatLngBounds();
        //map.fitBounds(bounds);
        setMap(map)
    }, [])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, []);

    const renderJobMarkers = (jobs) => {
        if(!jobs)
            return '';

        return jobs.map((job) => {
            const iconState = job.status.status === "canceled" ? 'off' : 'on';
            return (
                <Marker key={'marker-job-'+ job.id}
                        position={job.location}
                        icon={iconState === "off" ? "https://maps.google.com/mapfiles/ms/icons/red-dot.png" : "https://maps.google.com/mapfiles/ms/icons/green-dot.png"}
                        clickable={true}
                        title={__("job")+': '+ job.code+'\n'+ __("branch") +': '+ job.type.name +'\n'+__("branch")+': '+ job.status.status}
                />
            );
        })
    };

    const renderCancellationSummary = (templates) => {
        if(!templates)
            return '';

        return templates.map((template) => {
            return (
                <tr key={'summary-cancellation-template-'+ template.id}>
                    <td><small>{template.name}</small></td>
                    <td className="text-right"><small>{formatCurrencyInput(template.percent.toFixed(2))} %</small></td>
                    <td className="text-right"><small>{template.count} x</small></td>
                </tr>
            )
        });
    }

    return isLoaded ? (
        <Row>
            <Col xl="8" style={{paddingRight: 0}}>
                <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={center}
                    zoom={6}
                    onLoad={onLoad}
                    onUnmount={onUnmount}
                >
                    { renderJobMarkers(jobMap && jobMap.jobs) }
                    <></>
                </GoogleMap>
            </Col>
            <Col xl="4" style={{paddingLeft: 0}}>
                {loading === false ? (
                    <>
                        { jobMap && jobMap.jobSummary && (
                            <table className="table table-static table-hover">
                                <thead className="table-dark">
                                <tr>
                                    <th>{__("total")}</th>
                                    <th style={{width: '95px'}} />
                                    <th style={{width: '95px'}} className="text-right">{jobMap.jobSummary.total} x</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>{__("done")}</td>
                                    <td className="text-right">{formatCurrencyInput(jobMap.jobSummary.states.done_percent.toFixed(2))} %</td>
                                    <td className="text-right">{jobMap.jobSummary.states.done} x</td>
                                </tr>
                                <tr>
                                    <td>{__("canceled")}</td>
                                    <td className="text-right">{formatCurrencyInput(jobMap.jobSummary.states.canceled_percent.toFixed(2))} %</td>
                                    <td className="text-right">{jobMap.jobSummary.states.canceled} x</td>
                                </tr>
                                {renderCancellationSummary(jobMap.jobSummary.cancellations)}
                                </tbody>
                            </table>
                        )}
                    </>
                ) : (
                    <MyCodeLoader />
                )}
            </Col>
        </Row>
    ) : <></>
}

export default JobMap;
