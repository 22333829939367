import React, { Component } from "react";
import Moment from "react-moment";
import moment from "moment";
import StatusBadge from "./StatusBadge";
import { __ } from "../../utils/helpers";

class Claims extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    renderTableRows(){
        const { job } = this.props;

        return job.claims.map(function(item){

            let infoRowClass = '';

            if(item.state === 'open'){
                infoRowClass = 'table-warning';
            } else if(item.state === 'closed'){
                infoRowClass = 'table-success';
            }

            return (
                <tbody key={'claim-'+ item.id +'-body'}>
                <tr key={'claim-'+ item.id}>
                    <td>
                        <StatusBadge state={item.state} />
                    </td>
                    <td>
                        <Moment date={item.created} format="DD.MM." />, <Moment date={item.created} format="HH:mm" /> Uhr
                    </td>
                    <td>
                        {item.user.name}
                    </td>
                </tr>

                <tr key={'claim-'+ item.id +'-info'} className={infoRowClass}>
                    <td colSpan={3}>
                        <p>
                            <small>{__("claimReason")}:</small> <br />
                            {item.template.name}
                        </p>
                        <p>
                            <small>{__("comment")}:</small> <br />
                            <span dangerouslySetInnerHTML={{ __html: item.text }} />
                        </p>
                        {item.state === 'closed' ? (
                            <p>
                                <small>{__("closedAtBy", {
                                    date: moment(item.closed_at).format("DD.MM."),
                                    time: moment(item.closed_at).format("HH:mm"),
                                    user: item.closed_by.name
                                })}:</small> <br />
                                <span dangerouslySetInnerHTML={{ __html: item.closed_comment }} />
                            </p>
                        ) : ''}


                    </td>
                </tr>

                </tbody>
            );
        });
    }

    renderTable(){
        const { job, style } = this.props;

        if(!job.claims || job.claims.length === 0)
            return (
                <p className="p-30 p-b-3">{__("noDataAvailable")}</p>
            );

        return (
            <div className="table-responsive" style={style}>
                <table className="table table-hover table-striped customize-table v-top m-b-0">
                    <thead className="table-dark">
                    <tr>
                        <th>{__("status")}</th>
                        <th>{__("date")}</th>
                        <th>{__("user")}</th>
                    </tr>
                    </thead>
                    { this.renderTableRows()}
                </table>

            </div>
        )
    }

    render() {
        return this.renderTable();
    }
}

export default Claims;
