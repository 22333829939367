import React, { Component } from "react";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

import {
    IconsColumn,
    DirectionColumn,
    ToColumn,
    FromColumn,
    AgentColumn,
    DateColumn,
    DurationColumn,
    JobColumn,
    PlayColumn,
} from "./Columns/CallsColumns";
import {BootstrapTableOptions} from "./Options";
import {__} from "../../utils/helpers";

class CallsTable extends Component {
    constructor(props) {
        super(props);
        const {meta} = props;

        this.cacheKeys = {
            oldTableState: 'pbx.calls.oldTableState',
        }

        this.state = {
            meta,
            defaultMeta: {
                page: 1,
                per_page: 10,
                sort_column: 'call_created',
                sort_order: 'desc',
            },
            oldTableState: JSON.parse(localStorage.getItem(this.cacheKeys.oldTableState)) || null,
        };
    }

    componentDidMount() {
        const {filters, calls} = this.props;

        if(calls.length === 0){
            this.props.getData({
                ...this.state.defaultMeta,
                filters,
            });
        }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {filters} = this.props;

        if(filters !== prevProps.filters){
            this.props.getData({
                ...this.state.defaultMeta,
                filters,
            });
        }
    }

    columns(){
        const {setJob} = this.props;

        const columns = [];

        columns.push({
            dataField: 'call_direction',
            text: '',
            formatter: DirectionColumn,
            sort: true
        });

        columns.push({
            dataField: 'state',
            text: '',
            formatter: IconsColumn,
            sort: false
        });

        columns.push({
            dataField: 'job',
            text: '',
            formatter: JobColumn,
            sort: false,
            formatExtraData: {
                setJob: setJob,
            }
        });

        columns.push({
            dataField: 'call_created',
            text: __("date"),
            formatter: DateColumn,
            sort: true,
        });

        columns.push({
            dataField: 'agent',
            text: __("agent"),
            formatter: AgentColumn,
            sort: false
        });

        columns.push({
            dataField: 'call_from',
            text: __("caller"),
            formatter: FromColumn,
            sort: true
        });

        columns.push({
            dataField: 'call_to',
            text: __("receiver"),
            formatter: ToColumn,
            sort: true
        });

        columns.push({
            dataField: 'call_duration',
            text: __("duration"),
            formatter: DurationColumn,
            sort: true
        });

        columns.push({
            dataField: 'call_player',
            text: __("record"),
            formatter: PlayColumn,
            sort: false,
            formatExtraData: {
                onPlayRecord: this.props.onPlayRecord,
            }
        });

        return columns;
    }

    reloadData = () => {
        const {oldTableState} = this.state;
        const {filters} = this.props;

        this.props.getData({
            filters,
            page: oldTableState.page,
            per_page: oldTableState.sizePerPage,
            sort_column: oldTableState.sortField,
            sort_order: oldTableState.sortOrder,
        });
    }

    onTableChange = (type, newState) => {
        const {oldTableState} = this.state;
        const {filters} = this.props;

        if (oldTableState !== null
            && newState.sortOrder === oldTableState.sortOrder
            && newState.sortField === oldTableState.sortField
            //&& newState.filters === oldTableState.filters
            && newState.page === oldTableState.page
            && newState.sizePerPage === oldTableState.sizePerPage) {

            //&& newState.searchText === this.oldTableState.searchText)
            return;
        }


        this.setState({
            oldTableState: newState
        },() => {
            localStorage.setItem(this.cacheKeys.oldTableState, JSON.stringify(newState))
        });

        switch(type){
            default:
                // nothing to-do
                break;
            case 'pagination':
            case 'sort':
                this.props.getData({
                    filters,
                    page: newState.page,
                    per_page: newState.sizePerPage,
                    sort_column: newState.sortField,
                    sort_order: newState.sortOrder,
                });

                break;
        }
    }

    sort = () => {
        const {meta} = this.props;

        return {
            dataField: meta.sort.column ? meta.sort.column : '',
            order: meta.sort.order ? meta.sort.order.toLowerCase() : '',
        };
    }

    paginationFactory = () => {
        const {meta} = this.props;
        return paginationFactory({
            page: meta.current_page,
            sizePerPage: parseInt(meta.per_page),
            totalSize: meta.total,
            showTotal: true,
        });
    }

    indication() {
        return (
            <div className="text-muted mt-3 no-data" id="no-data">
                <p className="text-danger mb-1">
                    <i className="fa fa-ban" style={{ 'fontSize': '24px' }} />
                </p>
                <p>No Data Found!</p>
            </div>
        );
    }

    render() {
        const {loading, calls, meta} = this.props;
        const self = this;
        const boxStyle = {
            /**
             height: '500px',
             overflow: 'auto'*/
        };

        return (
            <div className="card">
                <div className="card-body">
                    <h4 className="card-title">
                        {__("calls")}
                        { loading === false && meta.total > 0 ? (
                            <span className="label label-inverse float-right">{meta.total}</span>
                        ) : '' }
                    </h4>
                </div>

                <div className="comment-widgets m-b-20" style={boxStyle}>
                    <div>
                        <ToolkitProvider
                            keyField='id'
                            data={ calls }
                            columns={ this.columns() }
                            search

                        >
                            {
                                props => (
                                    <div className="">
                                        <BootstrapTable
                                            { ...props.baseProps }
                                            { ...BootstrapTableOptions }
                                            ref={ n => self.node = n }
                                            loading={loading}
                                            pagination={ self.paginationFactory() }
                                            sort={ self.sort() }
                                            onTableChange={self.onTableChange}
                                            remote={ {
                                                filter: true,
                                                pagination: true,
                                                sort: true,
                                                cellEdit: false
                                            } }
                                        />
                                    </div>
                                )
                            }
                        </ToolkitProvider>
                    </div>

                </div>
            </div>
        );
    }
}

export default CallsTable;
