import React, { Component } from "react";
import Moment from "react-moment";
import {__} from "../../utils/helpers";

class MessageHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    renderTableRows(){
        const { job } = this.props;

        return job.messages_sent.map(function(item){

            let rowClass = 'table-success';

            if(item.state === 'error'){
                rowClass = 'table-error';
            }

            return (
                <tr key={'message-'+ item.id} className={rowClass}>
                    <td>
                        <p className="small">{__("sentByTo", {
                            sender: item.user.name,
                            receiver:  item.receiver_name !== "" ? ' '+ item.receiver_name +" ["+ item.receiver +"]" : item.receiver
                        })}
                            <br />
                            <Moment date={item.created} format="DD.MM." />, <Moment date={item.created} format="HH:mm" /> Uhr
                        </p>

                        {item.state === 'error' ? (
                            <p className="bold">{item.state_info}</p>
                        ) : ''}

                        <p className="small">
                            <span dangerouslySetInnerHTML={{ __html: item.message.split("\n").join("<br />") }} />
                        </p>
                    </td>
                </tr>
            );
        });
    }

    renderTable(){
        const { job, style } = this.props;

        if(!job.messages_sent || job.messages_sent.length === 0)
            return (
                <p className="p-30 pb-3">{__("noDataAvailable")}</p>
            );


        return (
            <div className="table-responsive" style={style}>
                <table className="table table-hover table-striped customize-table v-top m-b-0">
                    <thead className="table-dark">
                    <tr>
                        <th>{__("message")}</th>
                    </tr>
                    </thead>
                    <tbody>
                    { this.renderTableRows()}
                    </tbody>
                </table>

            </div>
        )
    }

    render() {
        return this.renderTable();
    }
}

export default MessageHistory;
