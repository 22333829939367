import {
    SET_FITTER,
    GET_FITTER,
    GET_FITTER_SUCCESS,
    GET_FITTERS,
    GET_FITTERS_SUCCESS,
    GET_FITTERS_BY_DISTANCE,
    GET_FITTERS_BY_DISTANCE_SUCCESS,
    ADD_FITTER_STATUS,
    ADD_FITTER_STATUS_SUCCESS,
} from "./actionTypes";

export const setFitter = (data) => ({
    type: SET_FITTER,
    payload: {...data}
});


export const getFitter = (id) => ({
    type: GET_FITTER,
    payload: {id}
});

export const getFitterSuccess = response => ({
    type: GET_FITTER_SUCCESS,
    payload: response.data.data
});

export const addFitterStatus = (id, data) => ({
    type: ADD_FITTER_STATUS,
    payload: {id, data}
});

export const addFitterStatusSuccess = response => ({
    type: ADD_FITTER_STATUS_SUCCESS,
    payload: response.data.data
});

export const getFitters = () => ({
    type: GET_FITTERS,
    payload: {}
});

export const getFittersSuccess = response => ({
    type: GET_FITTERS_SUCCESS,
    payload: response.data.data
});

export const getFittersByDistance = (job_type_id, job_source_id, location, country) => ({
    type: GET_FITTERS_BY_DISTANCE,
    payload: {
        job_type_id,
        job_source_id,
        location,
        country,
    }
});

export const getFittersByDistanceSuccess = response => ({
    type: GET_FITTERS_BY_DISTANCE_SUCCESS,
    payload: response.data.data
});

