import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {connect} from "react-redux";
import { Code } from 'react-content-loader';
import {getCommissionsOverview} from "../../../store/actions";
import {formatCurrencyInput,__} from "../../../utils/helpers";
const MyCodeLoader = () => <Code />;

class CommissionsOverview extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };

        this.positionColors = {
            1: 'success',
            2: 'warning',
            3: 'danger',
        };
    }

    componentDidMount() {
        const {getCommissionsOverview, commissionsOverviewLoading} = this.props;

        if(commissionsOverviewLoading === false)
            getCommissionsOverview();
    }

    renderTableRows() {
        let {commissionsOverview} = this.props;

        if(commissionsOverview === null)
            return <></>;

        if(typeof commissionsOverview === "object" && Array.isArray(commissionsOverview) === false) {
            commissionsOverview = Object.values(commissionsOverview);
        }

        if( Array.isArray(commissionsOverview) === false)
            return <></>;

        commissionsOverview.sort((a,b) =>  a.amount + b.amount );

        return commissionsOverview.map((item, index) => {
            let positionColor = this.positionColors[index + 1] ?? 'light-danger';
            return (
                <tr key={'commissions-overview-'+ index}>
                    <td>
                        <span
                            className={'round rounded-circle text-white d-inline-block text-center bg-'+ positionColor}>{ index + 1 }</span>
                    </td>
                    <td>{item.user_name}</td>
                    <td>{item.jobs_done_count + item.jobs_canceled_count}</td>
                    <td>{item.jobs_done_count}</td>
                    <td>{item.jobs_canceled_count}</td>
                    <td className="text-right">{formatCurrencyInput(item.amount)} <i className="fas fa-lira-sign "></i></td>
                </tr>
            );
        })
    }

    render() {
        const {commissionsOverviewLoading} = this.props;
        return (
            <React.Fragment>
                <div className="card">
                    <div className="card-body">
                        <div className="d-md-flex">
                            <div>
                                <h4 className="card-title"><span className="lstick d-inline-block align-middle" />
                                    {__('employeeOfTheMonth')}
                                </h4>
                            </div>
                            <div className="ms-auto">
                                { /**
                                <select className="form-select">
                                    <option selected="">January</option>
                                    <option value="1">February</option>
                                    <option value="2">March</option>
                                    <option value="3">April</option>
                                </select> */ }
                            </div>
                        </div>

                        {commissionsOverviewLoading ? (
                            <MyCodeLoader />
                        ) : (
                            <div className="table-responsive mt-3 no-wrap" style={{height: '400px', 'overflowY': 'auto'}}>
                                <table className="table v-middle mb-0">
                                    <thead>
                                    <tr>
                                        <th className="border-0">{__('position')}</th>
                                        <th className="border-0">{__('name')}</th>
                                        <th className="border-0">{__('jobs')}</th>
                                        <th className="border-0">{__('done')}</th>
                                        <th className="border-0">{__('canceled')}</th>
                                        <th className="border-0 text-right">{__('commissionApprox')}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.renderTableRows()}
                                    </tbody>
                                </table>
                            </div>
                        )}

                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { user } = state.Auth;
    const { commissionsOverview, commissionsOverviewLoading } = state.Commissions;

    return {
        user,
        commissionsOverview,
        commissionsOverviewLoading,
    };
};

export default withRouter(
    connect(mapStatetoProps, { getCommissionsOverview })(CommissionsOverview)
);
