import {
    GET_JOB_TYPE,
    GET_JOB_TYPE_SUCCESS,
    GET_JOB_TYPES,
    GET_JOB_TYPES_SUCCESS,
} from "./actionTypes";

export const getJobType = () => ({
    type: GET_JOB_TYPE,
    payload: {}
});

export const getJobTypeSuccess = response => ({
    type: GET_JOB_TYPE_SUCCESS,
    payload: response.data.data
});

export const getJobTypes = () => ({
    type: GET_JOB_TYPES,
    payload: {}
});

export const getJobTypesSuccess = response => ({
    type: GET_JOB_TYPES_SUCCESS,
    payload: response.data.data
});

