import {
    GET_STATISTICS_FITTERS,
    GET_STATISTICS_FITTERS_SUCCESS,
    GET_STATISTICS_JOB_MAP,
    GET_STATISTICS_JOB_MAP_SUCCESS,
} from "./actionTypes";

export const getStatisticsFitters = (meta) => ({
    type: GET_STATISTICS_FITTERS,
    payload: {meta}
});

export const getStatisticsFittersSuccess = response => ({
    type: GET_STATISTICS_FITTERS_SUCCESS,
    payload: {
        ...response.data
    }
});

export const getStatisticsJobMap = (meta) => ({
    type: GET_STATISTICS_JOB_MAP,
    payload: {meta}
});

export const getStatisticsJobMapSuccess = response => ({
    type: GET_STATISTICS_JOB_MAP_SUCCESS,
    payload: {
        ...response.data
    }
});
