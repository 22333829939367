import { all } from 'redux-saga/effects';
import AuthSaga from './auth/saga';
import JobsSaga from './jobs/saga';
import JobTypesSaga from './jobTypes/saga';
import JobSourcesSaga from './jobSources/saga';
import FittersSaga from './fitters/saga';
import ComplianceSaga from './compliances/saga';
import PbxSaga from './pbx/saga';
import LayoutSaga from './layout/saga';
import PhoneSaga from './phone/saga';
import CancellationTemplatesSaga from './cancellationTemplates/saga';
import StatisticsSaga from './statistics/saga';
import CommissionsSaga from './commissions/saga';

export default function* rootSaga() {
    yield all([
        AuthSaga(),
        JobsSaga(),
        JobTypesSaga(),
        JobSourcesSaga(),
        FittersSaga(),
        ComplianceSaga(),
        PbxSaga(),
        LayoutSaga(),
        PhoneSaga(),
        CancellationTemplatesSaga(),
        StatisticsSaga(),
        CommissionsSaga(),
    ])
}