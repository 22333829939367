// @flow
import {
    GET_JOB,
    GET_JOB_SUCCESS,
    GET_JOB_SUCCESS_WS,
    SET_JOB,
    GET_JOBS,
    GET_JOBS_SUCCESS,
    GET_JOB_MONITOR,
    GET_JOB_MONITOR_SUCCESS,
    GET_JOB_QUESTIONS,
    GET_JOB_QUESTIONS_SUCCESS,
    GET_JOB_PRICE_TEMPLATES,
    GET_JOB_PRICE_TEMPLATES_SUCCESS,
    ADD_JOB_STATUS,
    ADD_JOB_STATUS_SUCCESS,
    ADD_JOB_CLAIM,
    ADD_JOB_CLAIM_SUCCESS,
    SEND_JOB_MESSAGE,
    SEND_JOB_MESSAGE_SUCCESS,
    ADD_JOB_PARTIAL_PAYMENT,
    ADD_JOB_PARTIAL_PAYMENT_SUCCESS,
    ADD_JOB_PARTIAL_PAYMENT_ERROR,
    PATCH_JOB_FITTER,
    PATCH_JOB_FITTER_SUCCESS,
    PATCH_JOB_FITTER_ERROR,
    PATCH_JOB_APPOINTMENT,
    PATCH_JOB_APPOINTMENT_SUCCESS,
    PATCH_JOB_APPOINTMENT_ERROR,
    DELETE_JOB_PARTIAL_PAYMENT,
    DELETE_JOB_PARTIAL_PAYMENT_SUCCESS,
    ADD_JOB,
    ADD_JOB_SUCCESS,
    ADD_JOB_ERROR,
    ADD_JOB_RESET,
    PATCH_JOB,
    PATCH_JOB_SUCCESS,
} from "./actionTypes";
import {toast} from "react-toastify";


const INIT_STATE = {
    error: null,
    loading: false,
    jobsLoading: false,
    jobs: {
        data: [],
        meta: {
            current_page: 0,
            from: 0,
            last_page: 0,
            per_page: 0,
            to: 0,
            total: 0,
            path: '',
            sort: {
                order: 'DESC',
                column: 'job_id',
            }
        }
    },
    job: null,

    patchJobLoading: false,

    jobHistoryLoading: false,
    jobMessagesLoading: false,
    jobClaimsLoading: false,
    jobPartialPaymentsLoading: false,

    jobQuestions: [],
    jobQuestionsLoading: false,

    jobPriceTemplates: [],
    jobPriceTemplatesLoading: false,

    jobMonitorLoading: false,
    jobMonitorJobs: [],

    addJobLoading: false,
    createdJob: null,
};


const Jobs = (state = INIT_STATE, action) => {
    let job, index, list;

    switch (action.type) {
        case GET_JOB:
            return {
                ...state,
                loading: true,
                //job: null,
                //tasks: []
            };
        case SET_JOB:
        case GET_JOB_SUCCESS:
            return {
                ...state,
                loading: false,
                job: action.payload,
            };
        case GET_JOB_SUCCESS_WS:
            job = action.payload.data.job;
            list = state.jobMonitorJobs;
            index = get_monitor_job_index(job, list);

            if(is_monitor_job(job)){
                if(index === -1){
                    list.push(job);
                } else {
                    //list[index] = job;
                    list	= list.map(function(row){

                        if(row.id !== job.id){
                            return row;
                        }

                        return job;
                    });
                }
            } else if(index !== -1){
                list = list.filter(row => job.id !== row.id);
            }

          //  console.log(list);

            if(state.job.id === job.id) {
                return {
                    ...state,
                    jobMonitorJobs: list,
                    job,
                }
            }

            return {
                ...state,
                jobMonitorJobs: list,
            };
        case PATCH_JOB:
            return {
                ...state,
                patchJobLoading: true,
            };
        case PATCH_JOB_SUCCESS:
            return {
                ...state,
                patchJobLoading: false,
                job: action.payload
            };
        case ADD_JOB:
            return {
                ...state,
                addJobLoading: true,
            };
        case ADD_JOB_RESET:
            return {
                ...state,
                addJobLoading: false,
                createdJob: null,
            };
        case ADD_JOB_SUCCESS:
            return {
                ...state,
                addJobLoading: false,
                job: action.payload,
                createdJob: action.payload,
            };
        case ADD_JOB_ERROR:
            return {
                ...state,
                addJobLoading: false,
            };

        case ADD_JOB_STATUS:
            return {
                ...state,
                jobHistoryLoading: true,
            };
        case ADD_JOB_STATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                jobHistoryLoading: false,
                job: action.payload,
            };

        case ADD_JOB_CLAIM:
            return {
                ...state,
                jobClaimsLoading: true,
            };
        case ADD_JOB_CLAIM_SUCCESS:
            return {
                ...state,
                loading: false,
                jobClaimsLoading: false,
                job: action.payload,
            };

        case PATCH_JOB_FITTER:
        case PATCH_JOB_APPOINTMENT:
            return {
                ...state,
                patchJobLoading: true
            };
        case PATCH_JOB_FITTER_SUCCESS:
        case PATCH_JOB_APPOINTMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                patchJobLoading: false,
                job: action.payload,
            };


        case ADD_JOB_PARTIAL_PAYMENT:
        case DELETE_JOB_PARTIAL_PAYMENT:
            return {
                ...state,
                jobPartialPaymentsLoading: true,
            };
        case ADD_JOB_PARTIAL_PAYMENT_SUCCESS:
        case DELETE_JOB_PARTIAL_PAYMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                jobPartialPaymentsLoading: false,
                job: action.payload,
            };

        case ADD_JOB_PARTIAL_PAYMENT_ERROR:
        case PATCH_JOB_FITTER_ERROR:
        case PATCH_JOB_APPOINTMENT_ERROR:
            if(action.payload.response.status === 400){
                if(action.payload.response.data.errors){
                    action.payload.response.data.errors.map((error) => {
                        toast.error(error.userMessage);
                        return error;
                    });
                }
            }

            return {
                ...state,
                loading: false,
                jobPartialPaymentsLoading: false,
                patchJobLoading: false,
            };

        case SEND_JOB_MESSAGE:
            return {
                ...state,
                jobMessagesLoading: true,
            };
        case SEND_JOB_MESSAGE_SUCCESS:
            return {
                ...state,
                loading: false,
                jobMessagesLoading: false,
                job: action.payload,
            };

        case GET_JOBS:
            return {
                ...state,
                jobsLoading: true,
            }
        case GET_JOBS_SUCCESS:
            return {
                ...state,
                jobsLoading: false,
                jobs: action.payload,
            }
        case GET_JOB_MONITOR:
            return {
                ...state,
                jobMonitorLoading: true,
            };

        case GET_JOB_MONITOR_SUCCESS:
            return {
                ...state,
                jobMonitorLoading: false,
                jobMonitorJobs: action.payload,
            };

        case GET_JOB_QUESTIONS:
            return {
                ...state,
                jobQuestionsLoading: true,
                jobQuestions: [],
            };
        case GET_JOB_QUESTIONS_SUCCESS:
            return {
                ...state,
                jobQuestionsLoading: false,
                jobQuestions: action.payload,
            };

        case GET_JOB_PRICE_TEMPLATES:
            return {
                ...state,
                jobPriceTemplatesLoading: true,
                jobPriceTemplates: [],
            };
        case GET_JOB_PRICE_TEMPLATES_SUCCESS:
            return {
                ...state,
                jobPriceTemplatesLoading: false,
                jobPriceTemplates: action.payload,
            };

        default:
            return state;
    }
};

function get_monitor_job_index(job, list){
    return list.findIndex((element) => {
        return element.id === job.id;
    });
}

function is_monitor_job(job){
    return (job.job_monitor.column !== null);
}

export default Jobs;
