import { LOGIN_USER, LOGIN_SUCCESS, LOGOUT_USER, LOGOUT_USER_SUCCESS, API_ERROR, GET_PROFILE, GET_PROFILE_SUCCESS, GET_PROFILE_ERROR, SET_LOGGED_IN, SET_LOGGED_IN_SUCCESS } from './actionTypes';

export const loginUser = (user, history) => {
    return {
        type: LOGIN_USER,
        payload: { user, history }
    }
}

export const loginSuccess = (user) => {
    return {
        type: LOGIN_SUCCESS,
        payload: user
    }
}

export const logoutUser = (history) => {
    return {
        type: LOGOUT_USER,
        payload: { history }
    }
}

export const logoutUserSuccess = () => {
    return {
        type: LOGOUT_USER_SUCCESS,
        payload: {}
    }
}

export const getProfile = () => {
    return {
        type: GET_PROFILE,
        payload: { }
    }
}

export const getProfileSuccess = (response) => {
    return {
        type: GET_PROFILE_SUCCESS,
        payload: response.data.data
    }
}

export const getProfileError = (response) => {
    return {
        type: GET_PROFILE_ERROR,
        payload: {}
    }
}

export const apiError = (error) => {
    return {
        type: API_ERROR,
        payload: error
    }
}

export const setLoggedIn = (token, user) => {
    return {
        type: SET_LOGGED_IN,
        payload: {token, user}
    }
}

export const setLoggedInSuccess = (token, user) => {
    return {
        type: SET_LOGGED_IN_SUCCESS,
        payload: {token, user}
    }
}
