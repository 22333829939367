import React, {Component} from 'react';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter, ButtonGroup, InputGroup, InputGroupAddon} from 'reactstrap';
import PropTypes from "prop-types";

import Label from "../Form/Label";
import {formatCurrencyInput, stateTranslationsMap, __} from "../../utils/helpers";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {addJobPartialPayment} from "../../store/actions";


class AddPartialPaymentModal extends Component {
    constructor(props) {
        super(props);
        this.amountInput = null;
        this.state = {
            input: {
                amount: '0,00',
                type: 'bar',
            },
        };
    }

    componentDidMount() {
        const {job} = this.props;
        const {input} = this.state;

        const amount = formatCurrencyInput(job.sales.paid.total - job.sales.partial_payment);

        this.setState({
            input: {
                ...input,
                amount,
            }
        })
    }

    submit = () => {
        const {job} = this.props;
        const {input} = this.state;

        this.props.addJobPartialPayment(job.id, input);
        this.toggle();
    }

    toggle = () => {
        const {modal} = this.state;

        if(!modal === true){
            setTimeout(() => {
                if(this.amountInput !== null){
                    this.amountInput.focus()
                    this.amountInput.select()
                }
            }, 50);
        }

        this.setState({
            modal: !modal,
        })
    };

    renderTypesDropdown = () => {
        const types = [
            'bar', 'ec'
        ];

        return types.map((type) => {
            const label = stateTranslationsMap.de[type];
            return (
                <option key={'state-'+ type} value={type}>{label}</option>
            );
        });
    }

    render() {
        const {
            color,
            size,
            className,
            modalClassName,
            children,
        } = this.props;

        const { modal, input } = this.state;

        return (
            <>
                <Button color={color} size={size} className={className} onClick={this.toggle}>{children}</Button>
                <Modal isOpen={modal} toggle={this.toggle} className={modalClassName}>
                    <ModalHeader toggle={this.toggle}>Neue Teilzahlung</ModalHeader>
                    <ModalBody>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group m-b-15">
                                    <Label>{__("amount")}</Label>
                                    <InputGroup>
                                        <input className="form-control"
                                               ref={(input) => { this.amountInput = input; }}
                                               value={input.amount}
                                               onChange={(e) => {
                                                   this.setState({
                                                       input: {
                                                           ...input,
                                                           amount: e.target.value
                                                       }
                                                   })
                                               }}
                                               onBlur={(e) => {
                                                   this.setState({
                                                       input: {
                                                           ...input,
                                                           amount: formatCurrencyInput(e.target.value),
                                                       }
                                                   })
                                               }}
                                               onClick={() => {
                                                   if(this.amountInput !== null)
                                                       this.amountInput?.select();
                                               }}
                                        />
                                        <InputGroupAddon addonType="append">
                                            €
                                        </InputGroupAddon>
                                    </InputGroup>
                                </div>

                            </div>

                            <div className="col-md-6">
                                <div className="form-group">
                                    <Label>{__("paymentMethod")}</Label>
                                    <select className="form-control"
                                            value={input.type}
                                            onChange={(e) => {
                                                this.setState({
                                                    input: {
                                                        ...input,
                                                        type: e.target.value
                                                    }
                                                })
                                            }}>
                                        {this.renderTypesDropdown()}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <ButtonGroup>
                            <Button color="secondary" onClick={this.toggle}><i className="fa fa-times-circle" /> {__("close")}</Button>
                            <Button color="inverse" onClick={this.submit}><i className="fa fa-save" /> {__("save")}</Button>
                        </ButtonGroup>
                    </ModalFooter>
                </Modal>
            </>
        );
    }
}

const mapStatetoProps = (state) => {
    return {
    };
};

export default withRouter(connect(mapStatetoProps, { addJobPartialPayment })(AddPartialPaymentModal));


AddPartialPaymentModal.propTypes = {
    job: PropTypes.object.isRequired,
    color: PropTypes.string,
    size: PropTypes.string,
    className: PropTypes.string,
    modalClassName: PropTypes.string,
};
