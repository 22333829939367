import {
    GET_PBX_CALL,
    GET_PBX_CALL_SUCCESS,
    GET_PBX_CALLS,
    GET_PBX_CALLS_SUCCESS,
    GET_PBX_CALL_REASONS,
    GET_PBX_CALL_REASONS_SUCCESS,
} from "./actionTypes";

export const getPbxCall = () => ({
    type: GET_PBX_CALL,
    payload: {}
});

export const getPbxCallSuccess = response => ({
    type: GET_PBX_CALL_SUCCESS,
    payload: response.data.data
});

export const getPbxCalls = (meta) => ({
    type: GET_PBX_CALLS,
    payload: {meta}
});

export const getPbxCallsSuccess = response => ({
    type: GET_PBX_CALLS_SUCCESS,
    payload: {
        ...response.data
    }
});

export const getPbxCallReasons = () => ({
    type: GET_PBX_CALL_REASONS,
    payload: {}
});

export const getPbxCallReasonsSuccess = response => ({
    type: GET_PBX_CALL_REASONS_SUCCESS,
    payload: response.data.data
});

