import {
    GET_COMPLIANCES,
    GET_COMPLIANCES_SUCCESS,
    GET_COMPLIANCE,
    GET_COMPLIANCE_SUCCESS,
    GET_COMPLIANCE_RANDOM_JOB,
    GET_COMPLIANCE_RANDOM_JOB_SUCCESS,
    RESET_COMPLIANCE_RANDOM_JOB,
    RESET_COMPLIANCE_RANDOM_JOB_SUCCESS,
    GET_COMPLIANCE_JOB,
    GET_COMPLIANCE_JOB_SUCCESS,
    GET_COMPLIANCE_JOBS,
    GET_COMPLIANCE_JOBS_SUCCESS,
    SAVE_COMPLIANCE_JOB_QUESTIONS,
    SAVE_COMPLIANCE_JOB_QUESTIONS_SUCCESS,
    GET_CLAIM_JOBS_SUCCESS,
    GET_CLAIM_JOBS,
    GET_CLAIM_JOB_SUCCESS,
    GET_CLAIM_JOB,
    GET_CLAIM_TEMPLATES,
    GET_CLAIM_TEMPLATES_SUCCESS,
    ADD_COMPLIANCE_JOB,
    ADD_COMPLIANCE_JOB_SUCCESS,
    ADD_COMPLIANCE_JOB_ERROR, GET_CLAIM_PRIORITIES, GET_CLAIM_PRIORITIES_SUCCESS,
} from "./actionTypes";

export const getCompliances = () => ({
    type: GET_COMPLIANCES,
    payload: {}
});

export const getCompliancesSuccess = response => ({
    type: GET_COMPLIANCES_SUCCESS,
    payload: response.data.data
});

export const getCompliance = (id) => ({
    type: GET_COMPLIANCE,
    payload: {id}
});

export const getComplianceSuccess = response => ({
    type: GET_COMPLIANCE_SUCCESS,
    payload: response.data.data
});

export const getComplianceRandomJob = (id) => ({
    type: GET_COMPLIANCE_RANDOM_JOB,
    payload: {id}
});

export const getComplianceRandomJobSuccess = response => ({
    type: GET_COMPLIANCE_RANDOM_JOB_SUCCESS,
    payload: response.data.data
});

export const resetComplianceRandomJob = () => ({
    type: RESET_COMPLIANCE_RANDOM_JOB,
    payload: {}
});

export const resetComplianceRandomJobSuccess = () => ({
    type: RESET_COMPLIANCE_RANDOM_JOB_SUCCESS,
    payload: {}
});


export const addComplianceJob = (id, code) => ({
    type: ADD_COMPLIANCE_JOB,
    payload: {id, code}
});

export const addComplianceJobSuccess = (id, response) => ({
    type: ADD_COMPLIANCE_JOB_SUCCESS,
    compliance_id: id,
    payload: {
        ...response.data.data
    }
});

export const addComplianceJobError = (id, error) => ({
    type: ADD_COMPLIANCE_JOB_ERROR,
    compliance_id: id,
    payload: {
        ...error.response.data
    }
});

export const getComplianceJob = (id, job_id) => ({
    type: GET_COMPLIANCE_JOB,
    payload: {id, job_id}
});

export const getComplianceJobSuccess = (id, response) => ({
    type: GET_COMPLIANCE_JOB_SUCCESS,
    compliance_id: id,
    payload: {
        ...response.data.data
    }
});

export const getComplianceJobs = (id, type, meta) => ({
    type: GET_COMPLIANCE_JOBS,
    payload: {id, type, meta}
});

export const getComplianceJobsSuccess = (id, type, response) => ({
    type: GET_COMPLIANCE_JOBS_SUCCESS,
    payload: {
        compliance_id: id,
        type: type,
        ...response.data,
    }
});

export const saveComplianceJobQuestions = (id, job_id, data) => ({
    type: SAVE_COMPLIANCE_JOB_QUESTIONS,
    payload: {id, job_id, data}
});

export const saveComplianceJobQuestionsSuccess = response => ({
    type: SAVE_COMPLIANCE_JOB_QUESTIONS_SUCCESS,
    payload: response.data.data
});


export const getClaimJob = (id) => ({
    type: GET_CLAIM_JOB,
    payload: {id}
});

export const getClaimJobSuccess = (response) => ({
    type: GET_CLAIM_JOB_SUCCESS,
    payload: {
        ...response.data.data
    }
});

export const getClaimJobs = (meta) => ({
    type: GET_CLAIM_JOBS,
    payload: {meta}
});

export const getClaimJobsSuccess = (response) => ({
    type: GET_CLAIM_JOBS_SUCCESS,
    payload: {
        ...response.data,
    }
});

export const getClaimTemplates = () => ({
    type: GET_CLAIM_TEMPLATES,
    payload: {}
});

export const getClaimTemplatesSuccess = (response) => ({
    type: GET_CLAIM_TEMPLATES_SUCCESS,
    payload: {
        ...response.data,
    }
});

export const getClaimPriorities = () => ({
    type: GET_CLAIM_PRIORITIES,
    payload: {}
});

export const getClaimPrioritiesSuccess = (response) => ({
    type: GET_CLAIM_PRIORITIES_SUCCESS,
    payload: {
        ...response.data,
    }
});
