import React, { Component } from "react";

class Legacy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            legacyUrl: this.props.location.pathname.replace('/', '/legacy/') +'?_layout=layouts.plain',
            content: '',
        };
    }

    async componentDidMount() {
        const {legacyUrl} = this.state;
        let response = await fetch(legacyUrl, {
            headers: new Headers({
                //'Authorization': 'Bearer '+getApiAuthBackend().getAuthenticatedUser().token
            }),
        });

        this.setState({
            content: await response.text(),
        })
    }

    render() {
        const {content} = this.state;
        return (
            <>
                <div dangerouslySetInnerHTML={{__html: content}} />
            </>
        );
    }
}

export default Legacy;