import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { setPhoneState } from '../../store/actions';
import { getApiBackend } from "../../api";
import BriaCall from "../../models/Phone/BriaCall";

const api = getApiBackend();

// eslint-disable-next-line import/no-mutable-exports
export let GlobalBriaConnector;

class BriaConnector extends React.Component {
    static __parseCall = (call) => {

        let participantInfo = {
            phoneNumber: null,
            displayPhoneNumber: null,
            displayName: null,
            state: null,
            startTime: null,
        };

        /* Each call has a list of one or more remote participants */
        let participants = call.getElementsByTagName('participants');

        if (participants.length > 0) {
            participants = call.getElementsByTagName('participants')[0].getElementsByTagName('participant');

            /* Iterate through the list of remote participants and pick out the information */
            for (let p = 0; p < participants.length; p++) {
                const participant = participants[p];
                const number = BriaConnector.__getStringValueFromXMLTag(participant, 'number');
                participantInfo = {
                    phoneNumber: number,
                    displayPhoneNumber: number,
                    displayName: BriaConnector.__getStringValueFromXMLTag(participant, 'displayName'),
                    state: BriaConnector.__getStringValueFromXMLTag(participant, 'state'),
                    startTime: BriaConnector.__getStringValueFromXMLTag(participant, 'timeInitiated'),
                };
            }
        } else {
            participantInfo = {
                phoneNumber: BriaConnector.__getStringValueFromXMLTag(call, 'number'),
                displayPhoneNumber: BriaConnector.__getStringValueFromXMLTag(call, 'number'),
                displayName: BriaConnector.__getStringValueFromXMLTag(call, 'displayName'),
                state: BriaConnector.__getStringValueFromXMLTag(call, 'type'),
                startTime: BriaConnector.__getStringValueFromXMLTag(call, 'timeInitiated'),
            };
        }

        const dpn = BriaConnector.parseDisplayPhoneNumber(participantInfo.phoneNumber);
        const callData = {
            key: BriaConnector.__getStringValueFromXMLTag(call, 'id'),
            id: BriaConnector.__getStringValueFromXMLTag(call, 'id'),
            api_call_id: BriaConnector.__parseApiCallId(participantInfo.displayName),
            hold: BriaConnector.__getStringValueFromXMLTag(call, 'holdStatus'),
            duration: BriaConnector.__formatDuration(BriaConnector.__getStringValueFromXMLTag(call, 'duration')),
            state: participantInfo.state,
            phoneNumber: BriaConnector.parsePhoneNumber(participantInfo.phoneNumber),
            displayPhoneNumber: dpn,
            displayName: BriaConnector.__parseDisplayName(participantInfo.displayName),
            startTime: participantInfo.startTime,
            options: [],
        };

        if (callData.api_call_id === null && typeof (this.outgoingCalls[callData.id]) !== 'undefined') {
            callData.api_call_id = this.outgoingCalls[callData.id];
        }

        switch (callData.state) {
            case 'ringing':
                callData.type = 'incoming';
                callData.callAction = 'answer';
                break;
            case 'connecting':
                callData.type = 'outgoing';
                callData.callAction = 'cancel';
                break;
            case 'failed':
                callData.type = 'lost';
                callData.callAction = 'recall';
                callData.options = [
                    {
                        action: 'categorize',
                    },
                ];
                break;
            case 'connected':
                callData.type = 'pending';
                callData.callAction = 'cancel';
                callData.options = [
                    {
                        action: 'hold',
                    },
                ];
                break;
            default:
                callData.type = 'lost';
                callData.callAction = 'recall';
                callData.options = [
                    {
                        action: 'categorize',
                    },
                ];
                break;
        }

        if (callData.hold === 'localHold') {
            callData.type = 'hold';
            callData.callAction = 'resume';
            callData.options = [];
        }

        return callData;
    };

    static __parseMessage = (msg) => {
        let messageType = BriaConnector.ApiMessageTypes.UNKNOWN;
        let eventType = BriaConnector.ApiEventTypes.UNKNOWN;
        let errorCode = 0;
        let errorText = '';
        let transactionId = '';
        let userAgentString = '';
        let contentType = '';
        let contentLength = 0;
        let content = '';

        /* Replace any Windows-Style line-endings with \n */
        const lines = msg.replace(/\r\n/g, '\n').split('\n');
        let line = lines[0];

        /* Parse the first line to determine the type of message */
        if (line.substr(0, 4) === 'POST') {
            messageType = BriaConnector.ApiMessageTypes.EVENT;
            line = line.substr(5).trim();
            if (line.substr(0, 13) === '/statusChange') {
                eventType = BriaConnector.ApiEventTypes.STATUSCHANGE;
            }
        } else if (line.substr(0, 8) === 'HTTP/1.1') {
            line = line.substr(8).trim();
            if (line.substr(0, 6) === '200 OK') {
                messageType = BriaConnector.ApiMessageTypes.RESPONSE;
            } else if ((line[0] === '4') || (line[0] === '5')) {
                messageType = BriaConnector.ApiMessageTypes.ERROR;
                errorCode = line.substr(0, 3);
                errorText = line.substr(4);
            }
        }

        /* Parse the remaining lines in the header and extract values */
        let i = 1;
        for (i; i < lines.length; i++) {
            line = lines[i];
            if (line[0] === '<') {
                /* Start of the content section */
                break;
            } else if (line.substr(0, 15) === 'Transaction-ID:') {
                transactionId = line.substr(15).trim();
            } else if (line.substr(0, 11) === 'User-Agent:') {
                userAgentString = line.substr(11).trim();
            } else if (line.substr(0, 13) === 'Content-Type:') {
                contentType = line.substr(13).trim();
            } else if (line.substr(0, 15) === 'Content-Length:') {
                contentLength = Number(line.substr(15).trim());
            } else {
                /* Ignore any unknown headers */
            }
        }

        /* Re-assemble the content portion from the remaining lines */
        for (; i < lines.length; i++) {
            content += lines[i];
            if (i < lines.length - 1) {
                content += '\n';
            }
        }

        /* Return object with all the details from the message */
        return {
            messageType,
            eventType,
            errorCode,
            errorText,
            transactionId,
            userAgentString,
            contentType,
            contentLength,
            content,
        };
    };

    static __parseApiCallId = (displayName) => {
        if (typeof (displayName) !== 'string') return null;

        return displayName.split('#')[1];
    };

    static parsePhoneNumber = (rawNumber) => {
        if (typeof (rawNumber) === 'undefined') return '';

        if (rawNumber === '') return '';

        if (rawNumber === null) return '';

        let number = rawNumber;

        if (number.substr(0, 1) === '0') {
            number = `${this.defaultCountryPrefix}${number.substr(1)}`;
        }

        if (number.substr(0, 1) === '+') {
            number = number.substr(1);
        }

        return number.split('@')[0];
    };

    static parseDisplayPhoneNumber = (rawNumber) => {
        const parsedNumber = this.parsePhoneNumber(rawNumber);

        if (parsedNumber.substr(0, 3) === this.defaultCountryPrefix) {
            return `0${parsedNumber.substr(3)}`;
        }

        return parsedNumber;
    };

    static __parseDisplayName = (displayName) => {
        if (typeof (displayName) !== 'string') return null;

        return displayName.split('#')[0];
    };

    static __getStringValueFromXMLTag = (xmlItem, tagName) => {
        /* Get the first XML element with the given tag name */
        if (typeof (xmlItem) === 'undefined') return '';

        const element = xmlItem.getElementsByTagName(tagName)[0];
        if (element != null) {
            /* Get the first child node from the element */
            const childNode = element.childNodes[0];
            /* If the child node exist, return the value for it */
            if (childNode != null) return childNode.nodeValue;
        }
        /* If tag isn't found or has no value, return a blank string */
        return '';
    };

    static __formatDuration = (duration) => `${BriaConnector.__get2D(Math.floor(duration / 60))}:${BriaConnector.__get2D(Math.floor(duration % 60))}`;

    static __get2D = (num) => (num.toString().length < 2 ? `0${num}` : num).toString();

    constructor(props) {
        super(props);
        console.log("BriaConnector::constructor");

        this.state = {
            calls: [],
            websocketConnection: null,
        };
        this.outgoingCalls = {};
        this.csiResults = {};
        this.websocket = null;
        this.lastTransactionID = 0;
    }

    componentDidMount() {
        //const { websocketConnection } = this.state;
        GlobalBriaConnector = this;
        console.log("BriaConnector::componentDidMount");

        this.__connect();

        /**
        if (websocketConnection !== null) {
            notify('info', `Websocket-Verbindung: ${websocketConnection}`);
        }*/
    }

    __onCategorizeCall = (call, data) => async (event) => {
        event.preventDefault();
        /**
        const csiData = await CsiWrapper.__apiCategorizeCall(call, data);
        if (csiData !== null) {
            await this.__updateCsiCallInformations(call);
        }*/
    };

    __onAnswerCall = (call) => (event) => {
        event.preventDefault();
        this.__apiAnswerCall(call.id);
    };

    __onCancelCall = (call) => (event) => {
        event.preventDefault();
        this.__apiCancelCall(call.id);
    };

    __onHoldCall = (call) => (event) => {
        event.preventDefault();
        this.__apiHoldCall(call.id);
    };

    __onResumeCall = (call) => (event) => {
        event.preventDefault();
        this.__apiResumeCall(call.id);
    };

    makeCall = (number, displayName) => {
        this.__apiPlaceCall(number, displayName);
    };

    updateState = (data) => {
        const {setPhoneState} = this.props;
        this.setState(data);

        setPhoneState(data)
    }

    __connect = () => {
        console.log("BriaConnector::__connect", BriaConnector.websocketUrl);
        this.websocket = new WebSocket(BriaConnector.websocketUrl);

        this.updateState({
            websocketConnection: 'connecting',
        });

        this.websocket.onopen = () => {
            console.log("BriaConnector::websocket::onopen");
            this.__onConnectedToWebSocket();

            this.updateState({
                websocketConnection: 'connected',
            });
        };

        this.websocket.onerror = (error) => {
            console.log("BriaConnector::websocket::onerror", error);
            this.updateState({
                websocketConnection: 'disconnected',
            });
        };

        this.websocket.onclose = () => {
            console.log("BriaConnector::websocket::onclose");
            this.updateState({
                websocketConnection: 'disconnected',
            });

            /* Set timer to attempt re-connection in 5 seconds */
            setTimeout(() => {
                this.__connect();
            }, 5000);
        };

        /* Event handler for received messages via web-socket connection */
        this.websocket.onmessage = (event) => {
            //console.log("BriaConnector::websocket::onmessage", event);
            this.updateState({
                websocketConnection: 'connected',
            });

            this.__processApiMessageReceived(event.data);
        };

    };

    __sendMessage = (msg) => {
        this.websocket.send(msg);
    };

    __apiAnswerCall = (call_id) => {
        const content = `${BriaConnector.xmlDeclarationString}<answerCall>\r\n <callId>${call_id}</callId>\r\n</answerCall>`;

        const msg = this.__constructApiMessage('answer', content);
        this.__sendMessage(msg);
    };

    __apiCancelCall = (call_id) => {
        const content = `${BriaConnector.xmlDeclarationString}<endCall>\r\n <callId>${call_id}</callId>\r\n</endCall>`;
        const msg = this.__constructApiMessage('endCall', content);
        this.__sendMessage(msg);
    };

    __apiHoldCall = (call_id) => {
        const content = `${BriaConnector.xmlDeclarationString}<holdCall>\r\n <callId>${call_id}</callId>\r\n</holdCall>`;
        const msg = this.__constructApiMessage('hold', content);
        this.__sendMessage(msg);
    };

    __apiResumeCall = (call_id) => {
        const content = `${BriaConnector.xmlDeclarationString}<resumeCall>\r\n <callId>${call_id}</callId>\r\n</resumeCall>`;
        const msg = this.__constructApiMessage('resume', content);
        this.__sendMessage(msg);
    };

    __apiPlaceCall = (number, displayName, suppressGUI = true) => {
        const content = `${BriaConnector.xmlDeclarationString}<dial type="audio">\r\n <number>${number}</number>\r\n <displayName>${displayName}</displayName>\r\n <suppressMainWindow>${suppressGUI}</suppressMainWindow>\r\n</dial>`;
        const msg = this.__constructApiMessage('call', content);
        this.__sendMessage(msg);
    };

    __onConnectedToWebSocket = () => {
        /* Do some stuff here when the connection is first established ... */
        this.__apiGetStatusHistory();
        this.__apiGetStatus('call');

        this.__apiGetStatusWithParameters('account', ' <accountType>sip</accountType>\r\n');
    };

    __processApiMessageReceived = (msg) => {
        const incomingMessage = BriaConnector.__parseMessage(msg);

        switch (incomingMessage.messageType) {
            case BriaConnector.ApiMessageTypes.RESPONSE:
                /* This is a response to a status query */
                this.__processResponse(incomingMessage.content);
                break;
            case BriaConnector.ApiMessageTypes.EVENT:
                /* Check the event type */
                if (incomingMessage.eventType === BriaConnector.ApiEventTypes.STATUSCHANGE) {
                    /* Handle the Status Change Event */
                    this.__processStatusChangeEvent(incomingMessage.content);
                }
                break;
            case BriaConnector.ApiMessageTypes.ERROR:
                break;
            default:
                break;
        }
    };

    __processResponse = (responseXML) => {
        /* Basic responses carry no content, so we don't need to process them */
        /* For responses with XML data, figure out the type and distribute to sub-processing
            functions */
        if (responseXML.length > 0) {
            const parser = new DOMParser();
            const xmlDoc = parser.parseFromString(responseXML, 'text/xml');
            const statusElement = xmlDoc.getElementsByTagName('status')[0];
            console.log("__processResponse");
            if (statusElement != null) {
                const statusType = statusElement.getAttributeNode('type');

                switch (statusType.nodeValue) {
                    case 'call':
                        this.__handleCallStatusResponse(xmlDoc);
                        break;
                    case 'callHistory':
                        this.__handleCallHistoryStatusResponse(xmlDoc);
                        break;
                    default:
                        break;
                }
            }
        }
    };

    __processStatusChangeEvent = (eventXML) => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(eventXML, 'text/xml');
        const eventElement = xmlDoc.getElementsByTagName('event')[0];
        const eventType = eventElement.getAttributeNode('type');
        this.__handleStatusChangeEvent(eventType.nodeValue);
    };

    __handleStatusChangeEvent = (eventType) => {
        //console.log("__handleStatusChangeEvent", eventType);
        switch (eventType) {
            case 'call':
                this.__apiGetStatus(eventType);
                break;
            //case 'missedCall':
            //    this.__apiGetStatusWithParameters('callHistory', ' <count>1</count>\r\n <entryType>missed</entryType>\r\n');
            //    break;
            case 'presence':
                break;
            //case 'missedCall':
            case 'callHistory':
                this.__apiGetStatusHistory();
                break;
            default:
                break;
        }
    };

    __handleCallStatusResponse = async (callStatusDoc) => {
        /* Each Call Status Response contain zero or more 'call'
        elements with information about each ongoing call */
        const calls = callStatusDoc.getElementsByTagName('call');
        console.log("BriaConnector::__handleCallStatusResponse", calls);

        const { calls: currentCalls } = this.state;
        let index = null;
        let call = null;

        /* Iterate through the list of calls and pick out the information */
        if (calls.length > 0) {
            const findCallIndex = (item) => item.id === call.id;

            for (let i = 0; i < calls.length; i++) {
                call = BriaConnector.__parseCall(calls[i]);
                index = currentCalls.findIndex(findCallIndex);

                if (typeof (call.api_call_id) === 'undefined' && typeof (this.outgoingCalls[call.id]) !== 'undefined') {
                    call.api_call_id = this.outgoingCalls[call.id];
                }

                if (typeof (call.api_call_id) === 'undefined' && call.type === 'outgoing') {
                    // eslint-disable-next-line no-await-in-loop
                    //call.csi = await CsiWrapper.__fetchOutgoingCallInformations(call);
                    call.csi = null;

                    if (call.csi !== null) {
                        call.api_call_id = call.csi.data.id;
                        this.outgoingCalls[call.id] = call.csi.data.id;
                    }
                } else {
                    if (typeof (this.csiResults[call.api_call_id]) === 'undefined') {
                        // eslint-disable-next-line no-await-in-loop
                        let csiResponse = await this.__getCsiCallInformation(call);
                        if(csiResponse)
                            call.csi = csiResponse.data;
                    }

                    //if(typeof(this.props.apiCalls[call.api_call_id]) !== "undefined"){
                    //    call.csi = this.props.apiCalls[call.api_call_id];
                    //}
                }

                if (index === -1) {
                    currentCalls.unshift(new BriaCall(call));
                } else {
                    currentCalls[index] = new BriaCall(call);
                }
            }

            console.log("BriaConnector::__handleCallStatusResponse::updateState", currentCalls);

            this.updateState({
                calls: currentCalls,
            });
        }
    };

    __handleCallHistoryStatusResponse = async (callStatusDoc) => {
        const calls = callStatusDoc.getElementsByTagName('callHistory');
        let call = null;
        let index = null;

        console.log("BriaConnector::__handleCallHistoryStatusResponse", calls);

        if (calls.length > 0) {
            const { calls: currentCalls } = this.state;

            const findCallIndex = (item) => item.id === call.id;
            const csiApiIds = [];

            for (let i = 0; i < calls.length; i++) {
                call = BriaConnector.__parseCall(calls[i]);
                csiApiIds[i] = call.api_call_id;

                if (typeof (this.csiResults[call.api_call_id]) === 'undefined') {
                    console.log("setNull");
                    call.csi = null;
                } else {
                    call.csi = this.csiResults[call.api_call_id];
                }

                index = currentCalls.findIndex(findCallIndex);

                if (index === -1)
                    currentCalls.push(new BriaCall(call));
                else
                    currentCalls[index] = new BriaCall(call);
            }

            const apiInformation = await this.__getCsiBulkCallInformations(csiApiIds);
            for (let i = 0; i < currentCalls.length; i++){
                currentCalls[i].csi = apiInformation.data[i];
            }
            console.log("BriaConnector::__handleCallHistoryStatusResponse::updateState", currentCalls)
            this.updateState({
                calls: currentCalls,
            });
        } else {
            console.log("BriaConnector::__handleCallHistoryStatusResponse::updateState", [])
            this.updateState({
                calls: [],
            });
        }
    };

    __constructApiMessage = (requestType, body) => {
        const contentLength = body.length;

        let msg = `GET /${requestType}\r\nUser-Agent: ${BriaConnector.userAgentString}\r\nTransaction-ID: ${this.__getNextTransactionID()}\r\nContent-Type: application/xml\r\nContent-Length: ${contentLength}`;
        if (contentLength > 0) {
            msg += `\r\n\r\n${body}`;
        }
        return msg;
    };

    __getNextTransactionID = () => {
        this.lastTransactionID++;
        return this.lastTransactionID;
    };

    __apiGetStatus = (statusType) => {
        const content = `${BriaConnector.xmlDeclarationString}<status>\r\n <type>${statusType}</type>\r\n</status>`;
        const msg = this.__constructApiMessage('status', content);
        this.__sendMessage(msg);
    };

    __apiGetStatusWithParameters = (statusType, parameterXML) => {
        const content = `${BriaConnector.xmlDeclarationString}<status>\r\n <type>${statusType}</type>\r\n${parameterXML}</status>`;
        const msg = this.__constructApiMessage('status', content);
        this.__sendMessage(msg);
    };

    __apiGetStatusHistory = () => {
        const content = `${BriaConnector.xmlDeclarationString}<status>\r\n`
            + ' <type>callHistory</type> \r\n'
            + ' <count>10</count>\r\n'
            + ' <entryType>all</entryType>\r\n'
            + '</status>';

        const msg = this.__constructApiMessage('status ', content);

        this.__sendMessage(msg);
    };

    async __getCsiCallInformation(call) {

        if(!call){
            return null;
        }

        const api_call_id = (typeof (call) === 'object' ? call.api_call_id : call);
        let result;

        if(api_call_id !== 0){
            try {
                /**
                result = await api.post('/v2/pbx/calls/test_call', {
                    call_id: api_call_id,
                });*/
                result = await api.get('/v2/pbx/calls/'+ api_call_id +'?with=logs,number,number.jobType,number.jobSource')
            } catch (e){
                result = null;
                console.log(e);
            }
        } else
            result = null;

       // console.log("__getCsiCallInformation", api_call_id, result);

        this.csiResults[api_call_id] = result !== null ? result.data : null;
        return result !== null ? result.data : null;
    }

    async __getCsiBulkCallInformations(calls){

        const result = await api.post('/v2/pbx/calls/bulk', {
            ids: calls,
        });
        /**
        const result = await api.post('/v2/pbx/calls/test_call', {
            ids: calls,
        });*/

        return result.data;
    }

    async __updateCsiCallInformations(call) {
        const { calls } = this.state;

        const api_call_id = typeof (call) === 'object' ? call.api_call_id : call;

        const index = calls.findIndex((item) => item.api_call_id === api_call_id);

        if (index !== -1) {
            calls[index].csi = await this.__getCsiCallInformation(api_call_id);

            this.updateState({
                calls,
            });
        }
    }

    static xmlDeclarationString = '<?xml version="1.0" encoding="utf-8" ?>\r\n';

    static userAgentString = 'CSI telephone module';

    static defaultCountryPrefix = '+49';

    static websocketUrl = 'wss://cpclientapi.softphone.com:9002/counterpath/socketapi/v1';

    static ApiMessageTypes = {
        UNKNOWN: 0,
        RESPONSE: 1,
        EVENT: 2,
        ERROR: 3,
    };

    static ApiEventTypes = {
        UNKNOWN: 0,
        STATUSCHANGE: 1,
    };

    render() {
        console.log("BriaConnector::render");
        return "";
    }
}

const mapStateToProps = state => {
    const { error, apiCalls } = state.Phone;
    return { error, apiCalls };
}

export default withRouter(connect(mapStateToProps, { setPhoneState })(BriaConnector));
