import React, { Component } from "react";
import ReactStars from "react-rating-stars-component";
import Label from "../Form/Label";
import {__} from "../../utils/helpers";

class Question extends Component {
    constructor(props) {
        super(props);
        this.state = {
            claimTemplateSelected: 0,
        };
    }

    componentDidMount() {
        const {claimTemplateSelected} = this.state;
        const {claimTemplates} = this.props;

        if(claimTemplateSelected === 0){
            this.setState({
                claimTemplateSelected: parseInt(typeof(claimTemplates[0]) !== "undefined" ? claimTemplates[0].id : 0),
            });
        }
    }

    ratingChanged = (newRating) => {
        this.props.questionValueChanged(newRating);
    };

    inputChanged = (event) => {
        this.props.questionValueChanged(event.target.value);
    }

    claimInputChanged = (event) => {
        const {claimTemplateSelected} = this.state;

        this.props.questionValueChanged({
            value: parseInt(event.target.value),
            template_id: claimTemplateSelected,
        });
    }

    renderClaimTemplates(){
        const {claimTemplateSelected} = this.state;
        const {claimTemplates, readonly} = this.props;

        const options = claimTemplates.map(function(item){
            return (
                <option key={'claimTemplate-'+ item.id} value={item.id}>{item.name}</option>
            )
        });

        const inputChanged = (event) => {
            this.setState({
                claimTemplateSelected: parseInt(event.target.value),
            });

            const {value} = this.props;

            this.props.questionValueChanged({
                ...value,
                template_id: parseInt(event.target.value),
            });
        }

        return (
            <div className={'form-group'}>
                <Label>{__("claimReason")}</Label>
                <select className={'form-control'}
                        onChange={inputChanged}
                        value={claimTemplateSelected}
                        readOnly={readonly === false}
                >
                    { options }>
                </select>
            </div>
        )
    }

    renderType(){
        const { question, readonly } = this.props;
        let { value } = this.props;

        if(question.question_type === 'grades') {
            return (
                <div className={'form-group'}>
                    <Label><span dangerouslySetInnerHTML={{__html: question.question_title}}/></Label>
                    <ReactStars
                        count={5}
                        edit={readonly === false}
                        onChange={this.ratingChanged}
                        value={value !== null ? parseInt(value) : 0}
                        size={34}
                        activeColor="#ffd700"
                    />
                </div>
            );
        } else if(question.question_type === 'textarea'){
            return (
                <div className={'form-group'}>
                    <Label><span dangerouslySetInnerHTML={{ __html: question.question_title }} /></Label>
                    <textarea
                        className={'form-control'}
                        readOnly={readonly}
                        value={value !== null ? value : ""}
                        onChange={this.inputChanged}
                    />
                </div>
            );
        } else if(question.question_type === 'claim_selection'){

            try {
                value = JSON.parse(value);
            } catch (e) {

            }

            value = value !== null && typeof value.value !== 'undefined' ? value.value : '';

            return (
                <div>
                    <div className={'form-group'}>
                        <Label>{question.question_title}</Label>
                        <select className={'form-control'}
                                onChange={this.claimInputChanged}
                                value={value}
                                readOnly={readonly === false}
                        >
                            <option value={''}>{__("pleaseChoose")}</option>
                            <option value={0}>{__("no")}</option>
                            <option value={1}>{__("yes")}</option>
                        </select>
                    </div>

                    {value === 1 ? this.renderClaimTemplates() : ''}
                </div>
            );
        }
    }

    render() {
        return (
            <div className="d-flex no-block m-b-15">
                <div className="align-self-center">
                    { this.renderType() }
                </div>
            </div>
        );
    }
}

export default Question;
