import React, { Component } from "react";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {
    ButtonDropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
} from "reactstrap";
import { Code } from 'react-content-loader';

import { countriesArray, __ } from "../../../utils/helpers";
import {getJobSources, getJobTypes, getJobQuestions, getJobPriceTemplates, addJob, addJobReset} from "../../../store/actions";
import Map from "../../../components/Google/Map";
import Label from "../../../components/Form/Label";
import StaticText from "../../../components/Form/StaticText";
import Question from "../../../components/Jobs/Question";
import JobAppointmentSelect from "../../../components/Form/JobAppointmentSelect";
import FitterSelect from "../../../components/Jobs/FitterSelect";
import {Base64} from "js-base64";
const MyCodeLoader = () => <Code />;

class JobForm extends Component {
    constructor(props) {
        super(props);

        const job_id = parseInt(props.match.params.id);
        this.state = {
            job_id,
            call: null,
            fitter: null,
            location: null,
            jobType: null,
            country: null,
            jobSource: null,
            views: {
                fitterSelect: 'distance',
            },
            dropdowns: {
                jobType: false,
                jobSource: false,
                country: false,
            },
            mapPlace: null,
            address: {
                street: '',
                house: '',
                city: '',
                zipcode: '',
                country: '',
            },
            contact: {
                name: '',
                phone: '',
                phone_alternate: '',
                email: ''
            },
            fitters: [],
            questionValues: {},
            jobData: {
                remark: '',
                appointment: false,
                handle_datetime: '',
                meeting_point: '',
                price_template: '',
            },
        };
    }

    initValues() {
        let {jobSource, jobType, country, contact} = this.state;

        let updateState = { };

        if(this.props.jobTypes.length > 0 && this.state.jobType === null){
            jobType = this.props.jobTypes[0];
            updateState = {
                ...updateState,
                jobType,
            };
        }

        if(this.state.country === null ){
            country = countriesArray[0];
            updateState = {
                ...updateState,
                country,
            };
        }


        if(this.props.jobSources.length > 0 && this.state.jobSource === null){
            const itemsFiltered = this.props.jobSources.filter((item) => {
                return item.type !== null && item.type.id === jobType?.id && item.country === country?.code;
            });

            if(itemsFiltered.length > 0){
                jobSource = itemsFiltered[0];
                updateState = {
                    ...updateState,
                    jobSource,
                };
                /**
                this.setState({
                    jobSource,
                });*/
            }
        }

        const queryParams = new URLSearchParams(window.location.search);

        if(queryParams.has('queryData')) {
            const queryData = JSON.parse(Base64.decode(queryParams.get('queryData')));

            updateState = {
                ...updateState,
                contact: {
                  ...contact,
                  phone: queryData.call.phoneNumber,
                },
                call: queryData.call,
                jobSource: queryData.jobSource,
                jobType: queryData.jobType,
                country: queryData.country,
            }

        }

        if(Object.keys(updateState).length > 0){
            this.setState(updateState);
        }
    }

    componentDidMount() {
        if(this.props.jobTypes.length === 0 && this.props.jobTypesLoading === false)
            this.props.getJobTypes();

        if(this.props.jobSources.length === 0 && this.props.jobSourcesLoading === false)
            this.props.getJobSources();

        this.initValues();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let {jobSource, jobType, country, call, contact, mapPlace} = this.state;

        let fittersReloaded = false;
        const update = {};

        if(this.props && this.props.location && this.props.location.state){
            if(this.props.location.state.call && JSON.stringify(this.props.location.state.call) !== JSON.stringify(call)){
                update['call'] = this.props.location.state.call;
                update['contact'] = {
                    ...contact,
                    phone: this.props.location.state.call.phoneNumber,
                };
            }

            if(this.props.location.state.jobSource && JSON.stringify(this.props.location.state.jobSource) !== JSON.stringify(jobSource)){
                update['jobSource'] = this.props.location.state.jobSource;
            }

            if(this.props.location.state.jobType && JSON.stringify(this.props.location.state.jobType) !== JSON.stringify(jobType)){
                update['jobType'] = this.props.location.state.jobType;
            }

            if(this.props.location.state.country && JSON.stringify(this.props.location.state.country) !== JSON.stringify(country)){
                update['country'] = this.props.location.state.country;
            }

            if(Object.keys(update).length > 0){
                this.fetchJobPriceTemplates();
                this.fetchJobQuestions();

                fittersReloaded = true;
                this.setState(update, () => {
                    //this.fetchFittersByDistance();
                });
            }
        }

        if(fittersReloaded === false && JSON.stringify(prevState.mapPlace) !== JSON.stringify(mapPlace)){
            fittersReloaded = true;
            //this.fetchFittersByDistance();
        }

        //this.initValues();
    }

    isCreateAble = () => {
        const {fitter, address, contact} = this.state;

        if(fitter === null)
            return false;

        if(address.street === '')
            return false;

        if(address.house === '')
            return false;

        if(contact.name === '')
            return false;

        if(contact.phone === '')
            return false;

        return true;
    }

    renderJobType(){
        const {jobType} = this.state;

        return jobType === null ? __('branch') : (
            <><i className={jobType.icon} />  {jobType.name}</>
        )
    }

    renderJobTypes(){
        const {jobTypes} = this.props;
        return jobTypes.map((item) => {
            return (
                <DropdownItem key={'jobType-'+ item.id} onClick={() => this.setJobType(item)}><i className={item.icon} />  {item.name}</DropdownItem>
            );
        })
    }

    setJobType = (jobType) => {
        this.setState({
            jobType,
            jobSource: null,
            fitter: null,
        }, () => {
            this.fetchJobPriceTemplates();
            this.fetchJobQuestions();
        })
    }

    toggleJobTypes = () => {
        const {dropdowns} = this.state;

        this.setState({
            dropdowns: {
                ...dropdowns,
                jobType: !dropdowns.jobType
            }
        })
    }

    renderJobSource(){
        const {jobSource} = this.state;

        return jobSource === null ? __('source') : (
            <>{jobSource.name}</>
        )
    }

    renderJobSources(){
        const {jobSources} = this.props;
        const {country, jobType} = this.state;

        return jobSources.filter((item) => { return item.type !== null && item.type.id === jobType.id && country.code === item.country}).map((item) => {
            return (
                <DropdownItem key={'jobSource-'+ item.id} onClick={() => this.setJobSource(item)}>{item.name}</DropdownItem>
            );
        })
    }

    setJobSource = (jobSource) => {
        this.setState({
            jobSource,
        }, () => {
            this.fetchJobPriceTemplates();
            this.fetchJobQuestions();
        });
    }

    fetchJobPriceTemplates = () => {
        const {country, jobType, jobSource} = this.state;

        if(!country || !jobType || !jobSource)
            return;

        const filter = {
            countries: [country.code],
            job_types: [jobType.id],
            job_sources: [jobSource.id]
        };

        this.props.getJobPriceTemplates(filter);
    }

    fetchJobQuestions = () => {
        const {country, jobType, jobSource} = this.state;

        if(!country || !jobType || !jobSource)
            return;

        const filter = {
            countries: [country.code],
            job_types: [jobType.id],
            job_sources: [jobSource.id]
        };

        this.props.getJobQuestions(filter);
    }

    toggleJobSources = () => {
        const {dropdowns} = this.state;

        this.setState({
            dropdowns: {
                ...dropdowns,
                jobSource: !dropdowns.jobSource
            }
        })
    }

    renderCountry(){
        const {country} = this.state;

        return country === null ? __("country") : (
            <><i className={country.icon} />  {country.name}</>
        )
    }

    renderCountries(){
        return countriesArray.map((item) => {
            return (
                <DropdownItem key={'country-'+ item.code} onClick={() => this.setCountry(item)}><i className={item.icon} />  {item.name}</DropdownItem>
            );
        })
    }

    setCountry = (country) => {
        this.setState({
            country,
            jobSource: null,
            mapPlace: null,
            fitter: null,
        }, () => {
            this.fetchJobPriceTemplates();
            this.fetchJobQuestions();
        })
    }

    toggleCountry = () => {
        const {dropdowns} = this.state;

        this.setState({
            dropdowns: {
                ...dropdowns,
                country: !dropdowns.country
            }
        })
    }

    onFitterSelectViewChange = (view) => {
        const {views} = this.state;

        this.setState({
            views: {
                ...views,
                fitterSelect: view
            }
        })
    }

    renderQuestions = () => {
        const {questionValues} = this.state;
        const {jobQuestions} = this.props;

        return jobQuestions.map((question) => {
            const value = questionValues[question.question_id] !== undefined ? questionValues[question.question_id] : '';
            return <Question title={question.question_title}
                             type={question.question_type}
                             value={value}
                             onChange={(value) => {
                                 this.setState({
                                     questionValues: {
                                         ...questionValues,
                                         [question.question_id]: value,
                                     }
                                 })
                             }}
            />
        });
    }

    renderPriceTemplates = () => {
        const {jobPriceTemplates} = this.props;

        return jobPriceTemplates.map((priceTemplate) => {
            return (
                <option key={'price-template-'+ priceTemplate.id}>{priceTemplate.name}</option>
            )
        })
    }

    handleSubmit = () => {
        const {jobType, jobSource, call, fitter, mapPlace, address, contact, jobData} = this.state;

        if(!this.isCreateAble())
            return;

        this.props.addJob({
            type_id: jobType.id,
            source_id: jobSource.id,
            fitter_id: fitter.id,
            call_id: call !== null ? call.api_call_id : null,
            address,
            location: {
                lat: mapPlace.geometry.location.lat(),
                lng: mapPlace.geometry.location.lng(),
            },
            contact,
            info: jobData,
        }, this.props.history);
    }

    onFitterSelected = (fitter, location) => {
        this.setState({
            fitter: fitter,
            location: location,
        });
    }

    render() {

        const {dropdowns, views, jobType, jobSource, country, mapPlace, address, contact, jobData} = this.state;
        const {jobQuestionsLoading, jobQuestions, addJobLoading, createdJob} = this.props;

        if(createdJob){
            this.props.history.push('/jobs/'+ createdJob.id);
            return <div />;
        }

        let mapCenter = null;

        if(country){
            mapCenter = country.mapCenter;
        }

        if(mapPlace){
            mapCenter = mapPlace.geometry.location;
        }

        const dropdownToggleStyle = {
            //maxWidth: '140px',
            width: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        }

        const mapContainerStyle = {
            height: "250px",
            width: "100%"
        }

        const displayFitterSelect = address !== null && address.street && address.house;

        return (
            <>
                <div className={"row"}>

                    <div className={'col-md-12 m-b-15'}>
                        <div className="d-flex justify-content-between">
                            <div>
                                <ButtonDropdown className="m-r-15" size="lg" style={{flex: 1}} isOpen={dropdowns.jobType} toggle={this.toggleJobTypes}>
                                    <DropdownToggle caret style={dropdownToggleStyle}>
                                        {this.renderJobType()}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        {this.renderJobTypes()}
                                    </DropdownMenu>
                                </ButtonDropdown>


                                <ButtonDropdown className="m-r-15" size="lg" style={{flex: 1}} isOpen={dropdowns.country} toggle={this.toggleCountry}>
                                    <DropdownToggle caret style={dropdownToggleStyle}>
                                        {this.renderCountry()}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        {this.renderCountries()}
                                    </DropdownMenu>
                                </ButtonDropdown>


                                <ButtonDropdown className="m-r-15" size="lg" style={{flex: 1}}
                                                isOpen={dropdowns.jobSource}
                                                toggle={this.toggleJobSources}
                                >
                                    <DropdownToggle caret={!!(jobType && country)}
                                                    style={dropdownToggleStyle}
                                    >
                                        {this.renderJobSource()}
                                    </DropdownToggle>
                                    {jobType && country && (
                                        <DropdownMenu>
                                            {this.renderJobSources()}
                                        </DropdownMenu>
                                    )}
                                </ButtonDropdown>
                            </div>
                            <div>
                                <button className={"btn btn-lg btn-"+ (this.isCreateAble() ? 'success' : 'outline-secondary')}
                                        onClick={this.handleSubmit}
                                        disabled={!this.isCreateAble()}>
                                    {addJobLoading ? (
                                        <i className="fa fa-spin fa-spinner m-r-10" />
                                    ) : (
                                        <i className="fa fa-plus-square m-r-10" />
                                    )}
                                    {__("createJob")}
                                </button>
                            </div>
                        </div>

                    </div>

                    <div className={'col-md-6'}>
                        <div className={'row'}>
                            <div className={'col-md-12'}>
                                { jobType && country && (
                                    <>
                                        <Map center={mapCenter}
                                             show={{
                                                 autocomplete: true,
                                             }}
                                             mapContainerStyle={mapContainerStyle}
                                             autocompleteInputStyle={{
                                                 border: 0,
                                             }}
                                             autocompleteAutoFocus
                                             onAddressChanged={(address, place) => {
                                                 console.log("onAddressChanged");
                                                 this.setState({
                                                     address,
                                                     mapPlace: place
                                                 })
                                                 document.title = __("createJob") +" - "+ address.city;
                                             }}/**
                                             onPlaceChanged={(place) => {
                                                 console.log("onPlaceChanged");
                                                 this.setState({mapPlace: place})
                                             }}*/
                                             restrictions={{
                                                 country: country.code
                                             }}
                                        />
                                        <hr className="m-t-0" />
                                    </>
                                )}
                            </div>
                            <div className={'col-md-6'}>
                                <div className={'card'}>
                                    <div className="card-header bg-secondary">
                                        <h4 className="mb-0 text-white">{__("address")}</h4>
                                    </div>

                                    { address && (
                                        <>

                                            <div className={'card-body'}>
                                                <div className={'row'}>
                                                    <div className={'col-md-6'}>
                                                        <div className={'form-group'}>
                                                            <Label>{__("zipcode")}</Label>
                                                            <StaticText>
                                                                {address.zipcode !== '' ? address.zipcode : '-'}
                                                            </StaticText>
                                                        </div>
                                                    </div>
                                                    <div className={'col-md-6'}>
                                                        <div className={'form-group'}>
                                                            <Label>{__("city")}</Label>
                                                            <StaticText>
                                                                {address.city !== '' ? address.city : '-'}
                                                            </StaticText>
                                                        </div>
                                                    </div>

                                                    <div className={'col-md-6'}>
                                                        <div className={'form-group'}>
                                                            <Label>{__("street")}</Label>
                                                            <StaticText>{address.street !== '' ? address.street : '-'}</StaticText>
                                                        </div>
                                                    </div>
                                                    <div className={'col-md-6'}>
                                                        <div className={'form-group'}>
                                                            <Label>{__("house")}</Label>
                                                            <input type="text"
                                                                   className="form-control"
                                                                   value={address.house}
                                                                   onChange={(e) => {
                                                                       this.setState({
                                                                           contact: {
                                                                               ...address,
                                                                               house: e.target.value
                                                                           }
                                                                       })
                                                                   }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </>
                                    )}
                                </div>
                            </div>

                            <div className={'col-md-6'}>
                                <div className={'card'}>
                                    <div className="card-header bg-secondary">
                                        <h4 className="mb-0 text-white">{__("customer")}</h4>
                                    </div>
                                    <div className={'card-body'}>
                                        <div className="form-group">
                                            <Label>{__("contactPerson")}</Label>
                                            <input type="text"
                                                   className="form-control"
                                                   value={contact.name}
                                                   onChange={(e) => {
                                                       this.setState({
                                                           contact: {
                                                               ...contact,
                                                               name: e.target.value
                                                           }
                                                       })
                                                   }}
                                            />
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group m-t-15">
                                                    <Label>{__("phoneNumber")}</Label>
                                                    <input type="text"
                                                           className="form-control"
                                                           value={contact.phone}
                                                           onChange={(e) => {
                                                               this.setState({
                                                                   contact: {
                                                                       ...contact,
                                                                       phone: e.target.value
                                                                   }
                                                               })
                                                           }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group m-t-15">
                                                    <Label>{__("alternatePhoneNumber")}</Label>
                                                    <input type="text"
                                                           className="form-control"
                                                           value={contact.phone_alternate}
                                                           onChange={(e) => {
                                                               this.setState({
                                                                   contact: {
                                                                       ...contact,
                                                                       phone_alternate: e.target.value
                                                                   }
                                                               })
                                                           }}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group m-t-15">
                                            <Label>{__("emailAddress")}</Label>
                                            <input type="email"
                                                   className="form-control"
                                                   value={contact.email}
                                                   onChange={(e) => {
                                                       this.setState({
                                                           contact: {
                                                               ...contact,
                                                               email: e.target.value
                                                           }
                                                       })
                                                   }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    { (jobQuestions.length > 0 || (jobType && Array.isArray(jobType.fields))) && (
                        <div className={'col-md-3'}>
                            <div className={'card'}>
                                <div className="card-header bg-secondary">
                                    <h4 className="mb-0 text-white">{__("informations")}</h4>
                                </div>

                                {(jobQuestionsLoading || jobQuestions.length > 0) ? (
                                    <>
                                        <div className={'card-body'}>
                                            {jobQuestionsLoading ? (
                                                <MyCodeLoader />
                                            ) : (
                                                <>
                                                    {this.renderQuestions()}
                                                </>
                                            )}
                                        </div>
                                        <hr />
                                    </>
                                ) : ''}


                                { jobType && (
                                    <div className={'card-body'}>
                                        {/* eslint-disable-next-line valid-typeof */}
                                        { jobType && typeof Array.isArray(jobType.fields) && jobType.fields.includes('job_appointment') && (
                                            <JobAppointmentSelect appointment={jobData.appointment}
                                                                  onAppointmentChange={(value) => {
                                                                      this.setState({
                                                                          jobData: {
                                                                              ...jobData,
                                                                              appointment: value,
                                                                          }
                                                                      })
                                                                  }}
                                                                  datetime={jobData.handle_datetime}
                                                                  onDatetimeChange={(value) => {
                                                                      this.setState({
                                                                          jobData: {
                                                                              ...jobData,
                                                                              handle_datetime: value,
                                                                          }
                                                                      })
                                                                  }}
                                            />
                                        ) }

                                        {/* eslint-disable-next-line valid-typeof */}
                                        { jobType && Array.isArray(jobType.fields) && jobType.fields.includes('job_meeting_point') && (
                                            <div className={'form-group m-t-15'}>
                                                <Label>{__("meetingPoint")}</Label>
                                                <input type="text"
                                                       className="form-control"
                                                       value={jobData.meeting_point}
                                                       onChange={(e) => {
                                                           this.setState({
                                                               jobData: {
                                                                   ...jobData,
                                                                   meeting_point: e.target.value,
                                                               }
                                                           })
                                                       }}
                                                />
                                            </div>
                                        )}

                                        {/* eslint-disable-next-line valid-typeof */}
                                        { jobType && Array.isArray(jobType.fields) && jobType.fields.includes('job_price_template') && (
                                            <div className={'form-group m-t-15'}>
                                                <Label>{__("price")}</Label>
                                                <select className="form-control"
                                                        value={jobData.price_template}
                                                        onChange={(e) => {
                                                            this.setState({
                                                                jobData: {
                                                                    ...jobData,
                                                                    price_template: e.target.value,
                                                                }
                                                            })
                                                        }}>
                                                    <option value={''}>{__("withoutSelection")}</option>
                                                    {this.renderPriceTemplates()}
                                                </select>
                                            </div>
                                        )}

                                        {/* eslint-disable-next-line valid-typeof */}
                                        { jobType && Array.isArray(jobType.fields) && jobType.fields.includes('job_remark') && (
                                            <div className={'form-group m-t-15'}>
                                                <Label>{__("remark")}</Label>
                                                <textarea className="form-control"
                                                          value={jobData.remark}
                                                          onChange={(e) => {
                                                              this.setState({
                                                                  jobData: {
                                                                      ...jobData,
                                                                      remark: e.target.value,
                                                                  }
                                                              })
                                                          }}
                                                />
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    )}

                    <div className={'col-md-3'}>
                        <div className={'card'}>
                            <div className="card-header bg-secondary">
                                <h4 className="mb-0 text-white float-start">{__("partnerSelection")}</h4>
                                <div className="btn-group d-inline-block float-end">
                                    <button className={'btn btn-xs '+ (views.fitterSelect === 'distance' ? 'btn-dark' : 'btn-outline-dark')}
                                            onClick={() => {
                                                this.onFitterSelectViewChange('distance');
                                            }}><i className="fa fa-map-marker" /></button>
                                    <button className={"btn btn-xs " + (views.fitterSelect === 'table' ? 'btn-dark' : 'btn-outline-dark')}
                                            onClick={() => {
                                                this.onFitterSelectViewChange('table');
                                            }}><i className="fa fa-search" /></button>
                                </div>
                            </div>

                            {displayFitterSelect ? (
                                <>
                                    <FitterSelect view={views.fitterSelect}
                                                  job_type_id={jobType?.id}
                                                  job_source_id={jobSource?.id}
                                                  country_code={country?.code}
                                                  mapLocation={{
                                                      lat: mapPlace?.geometry?.location.lat(),
                                                      lng: mapPlace?.geometry?.location.lng(),
                                                  }}
                                                  onFitterSelected={this.onFitterSelected}
                                    />
                                </>
                            ) : (
                                <div className="card-body">
                                    <p className="m-b-0"><i className="fa fa-exclamation-circle m-r-5"/> {__("pleaseChooseAddress")}</p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapStatetoProps = (state) => {
    const { jobTypes, jobTypesLoading } = state.JobTypes;
    const { jobSources, jobSourcesLoading } = state.JobSources;
    const { jobQuestions, jobQuestionsLoading, jobPriceTemplates, jobPriceTemplatesLoading, addJobLoading, createdJob } = state.Jobs;
    const { fittersDistance, fittersDistanceLoading } = state.Fitters;

    return {
        jobTypes,
        jobTypesLoading,
        jobSources,
        jobSourcesLoading,
        jobQuestions,
        jobQuestionsLoading,
        jobPriceTemplates,
        jobPriceTemplatesLoading,
        fittersDistance,
        fittersDistanceLoading,
        addJobLoading,
        createdJob,
    };
};

export default withRouter(connect(mapStatetoProps, {
    addJob,
    addJobReset,
    getJobTypes,
    getJobSources,
    getJobQuestions,
    getJobPriceTemplates,
})(JobForm));
