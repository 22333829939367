import React, { Component } from "react";
import {__} from "../../utils/helpers";

class Locations extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    renderTableRows(){
        const { fitter } = this.props;

        return fitter.locations.map(function(item){
            return (

                <tr key={'location-'+ item.id}>
                    <td>
                        <i className={item.job_type.icon} />
                        &nbsp;
                        {item.job_type.name}
                    </td>
                    <td>
                        {item.name}
                    </td>
                    <td>
                        {item.address.zipcode} {item.address.city}
                    </td>
                </tr>
            );
        });
    }

    renderTable(){
        const { fitter, style } = this.props;

        if(!fitter.status_history || fitter.status_history.length === 0)
            return (
                <p className="p-30 pb-3">{__("noDataAvailable")}</p>
            );


        return (
            <div className={'table-responsive'} style={style}>
                <table className={'table table-hover customize-table v-top m-b-0'}>
                    <thead className="table-dark">
                    <tr>
                        <th>{__("branch")}</th>
                        <th>{__("location")}</th>
                        <th>{__("zipcode")} {__("city")}</th>
                    </tr>
                    </thead>
                    <tbody>
                    { this.renderTableRows()}
                    </tbody>
                </table>

            </div>
        )
    }

    render() {
        return this.renderTable();
    }
}

export default Locations;
