import {
    SET_JOB,
    GET_JOB,
    GET_JOB_SUCCESS,
    GET_JOBS,
    GET_JOBS_SUCCESS,
    GET_JOB_MONITOR,
    GET_JOB_MONITOR_SUCCESS,
    GET_JOB_QUESTIONS_SUCCESS,
    GET_JOB_QUESTIONS,
    GET_JOB_PRICE_TEMPLATES,
    GET_JOB_PRICE_TEMPLATES_SUCCESS,
    ADD_JOB_STATUS,
    ADD_JOB_STATUS_SUCCESS,
    ADD_JOB_CLAIM,
    ADD_JOB_CLAIM_SUCCESS,
    SEND_JOB_MESSAGE,
    SEND_JOB_MESSAGE_SUCCESS,
    ADD_JOB_PARTIAL_PAYMENT,
    ADD_JOB_PARTIAL_PAYMENT_SUCCESS,
    DELETE_JOB_PARTIAL_PAYMENT,
    DELETE_JOB_PARTIAL_PAYMENT_SUCCESS,
    ADD_JOB,
    ADD_JOB_SUCCESS,
    ADD_JOB_ERROR,
    ADD_JOB_RESET,
    PATCH_JOB,
    PATCH_JOB_SUCCESS,
    ADD_JOB_PARTIAL_PAYMENT_ERROR,
    PATCH_JOB_FITTER,
    PATCH_JOB_FITTER_SUCCESS,
    PATCH_JOB_FITTER_ERROR,
    PATCH_JOB_APPOINTMENT,
    PATCH_JOB_APPOINTMENT_SUCCESS,
    PATCH_JOB_APPOINTMENT_ERROR,
} from "./actionTypes";

export const setJob = (data) => ({
    type: SET_JOB,
    payload: {...data}
});

export const getJob = (id) => ({
    type: GET_JOB,
    payload: {id}
});

export const getJobSuccess = response => ({
    type: GET_JOB_SUCCESS,
    payload: response.data.data
});

export const patchJob = (id, data) => ({
    type: PATCH_JOB,
    payload: {id, data}
});

export const patchJobSuccess = response => ({
    type: PATCH_JOB_SUCCESS,
    payload: response.data.data
});

export const addJob = (data, history) => ({
    type: ADD_JOB,
    payload: {data, history}
});

export const addJobReset = () => ({
    type: ADD_JOB_RESET,
    payload: {}
});

export const addJobSuccess = (response, history) => ({
    type: ADD_JOB_SUCCESS,
    payload: response.data.data,
    history,
});

export const addJobError = (response, history) => ({
    type: ADD_JOB_ERROR,
    payload: response.data.data,
    history,
});

export const patchJobFitter = (id, data) => ({
    type: PATCH_JOB_FITTER,
    payload: {id, data}
});

export const patchJobFitterSuccess = response => ({
    type: PATCH_JOB_FITTER_SUCCESS,
    payload: response.data.data
});

export const patchJobFitterError = error => ({
    type: PATCH_JOB_FITTER_ERROR,
    payload: error,
});

export const patchJobAppointment = (id, data) => ({
    type: PATCH_JOB_APPOINTMENT,
    payload: {id, data}
});

export const patchJobAppointmentSuccess = response => ({
    type: PATCH_JOB_APPOINTMENT_SUCCESS,
    payload: response.data.data
});

export const patchJobAppointmentError = error => ({
    type: PATCH_JOB_APPOINTMENT_ERROR,
    payload: error,
});

export const addJobPartialPayment = (id, data) => ({
    type: ADD_JOB_PARTIAL_PAYMENT,
    payload: {id, data}
});

export const addJobPartialPaymentSuccess = response => ({
    type: ADD_JOB_PARTIAL_PAYMENT_SUCCESS,
    payload: response.data.data
});

export const addJobPartialPaymentError = error => ({
    type: ADD_JOB_PARTIAL_PAYMENT_ERROR,
    payload: error,
});

export const deleteJobPartialPayment = (id, partial_payment_id) => ({
    type: DELETE_JOB_PARTIAL_PAYMENT,
    payload: {id, partial_payment_id}
});

export const deleteJobPartialPaymentSuccess = response => ({
    type: DELETE_JOB_PARTIAL_PAYMENT_SUCCESS,
    payload: response.data.data
});



export const addJobStatus = (id, data) => ({
    type: ADD_JOB_STATUS,
    payload: {id, data}
});

export const addJobStatusSuccess = response => ({
    type: ADD_JOB_STATUS_SUCCESS,
    payload: response.data.data
});

export const addJobClaim = (id, data) => ({
    type: ADD_JOB_CLAIM,
    payload: {id, data}
});

export const addJobClaimSuccess = response => ({
    type: ADD_JOB_CLAIM_SUCCESS,
    payload: response.data.data
});

export const sendJobMessage = (id, data) => ({
    type: SEND_JOB_MESSAGE,
    payload: {id, data}
});

export const sendJobMessageSuccess = response => ({
    type: SEND_JOB_MESSAGE_SUCCESS,
    payload: response.data.data
});



export const getJobs = (meta) => ({
    type: GET_JOBS,
    payload: {meta}
});

export const getJobsSuccess = response => ({
    type: GET_JOBS_SUCCESS,
    payload: response.data
});

export const getJobMonitor = () => ({
    type: GET_JOB_MONITOR,
    payload: {}
});

export const getJobMonitorSuccess = response => ({
    type: GET_JOB_MONITOR_SUCCESS,
    payload: response.data.data
});

export const getJobQuestions = (filter) => ({
    type: GET_JOB_QUESTIONS,
    payload: {
        filter
    }
});

export const getJobQuestionsSuccess = response => ({
    type: GET_JOB_QUESTIONS_SUCCESS,
    payload: response.data.data
});

export const getJobPriceTemplates = (filter) => ({
    type: GET_JOB_PRICE_TEMPLATES,
    payload: {
        filter
    }
});

export const getJobPriceTemplatesSuccess = response => ({
    type: GET_JOB_PRICE_TEMPLATES_SUCCESS,
    payload: response.data.data
});

