import React from "react";
import { Route, Redirect } from "react-router-dom";
import {initApiAuthBackend} from "../api/Auth";
import {runsInElectron} from "../utils/helpers";

const apiAuthBackend = initApiAuthBackend();

const AppRoute = ({
                      component: Component,
                      layout: Layout,
                      isAuthProtected,
                      ...rest
                  }) => (
    <Route
        {...rest}
        render={props => {
            if (isAuthProtected && !apiAuthBackend.getAuthenticatedUser() && runsInElectron()) {
                return (
                    <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
                );
            }
            return (
                <Layout>
                    <Component {...props} />
                </Layout>
            );
        }}
    />
);

export default AppRoute;

