import React, {Component} from "react";
import { Row, Col } from "reactstrap";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

import PropTypes from 'prop-types';
import CallsSelect from "./CallsSelect";
import CountriesSelect from "./CountriesSelect";
import JobTypesSelect from "./JobTypesSelect";
import JobSourcesTagCloud from "./JobSourcesTagCloud";
import TextSearchInput from "./TextSearchInput";
import FittersSelect from "./FittersSelect";
import DateRangeSelect from "./DateRangeSelect";
import {
    getJobTypes,
    getJobSources,
    getClaimPriorities,
    getFitters,
} from "../../store/actions";
import JobStatesSelect from "./JobStatesSelect";
import {jobStates} from "../../utils/helpers";
import AppointmentDateRangeSelect from "./AppointmentDateRangeSelect";

class FilterContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            countries: [
                {
                    'code': 'de',
                    'name': 'Deutschland',
                    'icon': 'flag-icon flag-icon-de',
                },
                {
                    'code': 'at',
                    'name': 'Österreich',
                    'icon': 'flag-icon flag-icon-at',
                },
                {
                    'code': 'ch',
                    'name': 'Schweiz',
                    'icon': 'flag-icon flag-icon-ch',
                },
                {
                    'code': 'nl',
                    'name': 'Niederlande',
                    'icon': 'flag-icon flag-icon-nl',
                }
            ],
        }
    }

    componentDidMount() {
        const {
            display,
            getFitters,
            fitters,
            fittersLoading,
            getJobTypes,
            jobTypes,
            jobTypesLoading,
            getJobSources,
            jobSources,
            jobSourcesLoading,
            getClaimPriorities,
            claimPriorities,
            claimPrioritiesLoading,
        } = this.props;

        if(display && display.fittersSelect && fitters.length === 0 && fittersLoading === false)
            getFitters();

        if(display && display.jobSourcesTagCloud && jobSources.length === 0 && jobSourcesLoading === false)
            getJobSources();

        if(display && display.jobTypesSelect && jobTypes.length === 0 && jobTypesLoading === false)
            getJobTypes();

        if(display && display.claimPrioritiesSelect && claimPriorities.length === 0 && claimPrioritiesLoading === false)
            getClaimPriorities();
    }

    render() {
        const {countries} = this.state;
        const {
            display,
            filters,
            fitters,
            jobTypes,
            jobSources,
        } = this.props;

        return (
            <Row>
                <Col xl="12">
                    <div className={'m-b-15 d-flex flex-row flex-wrap justify-content-between'}>
                        { display && display.jobTypesSelect && (
                            <div className={'p-1'}>
                                <JobTypesSelect onClearJobTypes={this.props.onClearJobTypes}
                                                onJobTypeClick={this.props.onJobTypeClick}
                                                filters={filters}
                                                data={jobTypes}
                                />
                            </div>
                        )}

                        { display && display.countriesSelect && (
                            <div className={'p-1'}>
                                <CountriesSelect onClearCountries={this.props.onClearCountries}
                                                 onCountryClick={this.props.onCountryClick}
                                                 filters={filters}
                                                 data={countries}
                                />
                            </div>
                        )}

                        { display && display.jobStatesSelect && (
                            <div className={'p-1'}>
                                <JobStatesSelect onClearJobStates={this.props.onClearJobStates}
                                                 onJobStateClick={this.props.onJobStateClick}
                                                 filters={filters}
                                                 data={jobStates}
                                />
                            </div>
                        )}

                        { display && display.callsSelect && (
                            <div className={'p-1'}>
                                <CallsSelect onClearCallsFilter={this.props.onClearCallsFilter}
                                             onCallFilterClick={this.props.onCallFilterClick}
                                             filters={filters}
                                />
                            </div>
                        )}
                    </div>
                </Col>

                { display && display.dateRangePicker && (
                    <Col xl="12">
                        <div className="m-b-15">
                            <DateRangeSelect onDateRangeChange={this.props.onDateRangeChange}
                                             onClearDateRange={this.props.onClearDateRange}
                                             filters={filters}
                            />
                        </div>
                    </Col>
                )}

                { display && display.appointmentDateRangePicker && (
                    <Col xl="12">
                        <div className="m-b-15">
                            <AppointmentDateRangeSelect onDateRangeChange={this.props.onAppointmentDateRangeChange}
                                                        onClearDateRange={this.props.onClearAppointmentDateRange}
                                                        filters={filters}
                            />
                        </div>
                    </Col>
                )}

                { display && display.textSearchInput && (
                    <Col xl="12">
                        <div className="m-b-15">
                            <TextSearchInput onClearTextSearch={this.props.onClearTextSearch}
                                             onTextChange={this.props.onTextChange}
                                             filters={filters}
                            />
                        </div>
                    </Col>
                )}
                { display && display.fittersSelect && (
                    <Col xl="12">
                        <div className="m-b-15">
                            <FittersSelect onSelectFitters={this.props.onSelectFitters}
                                           filters={filters}
                                           data={fitters}
                            />
                        </div>
                    </Col>
                )}
                { display && display.jobSourcesTagCloud && (
                    <Col xl="12">
                        <JobSourcesTagCloud onJobSourceClick={this.props.onJobSourceClick}
                                            filters={filters}
                                            data={jobSources}
                                            jobTypes={jobTypes}
                        />
                    </Col>
                )}
            </Row>
        );
    }
}

const mapStatetoProps = (state) => {
    const { fitters, fittersLoading } = state.Fitters;
    const { jobTypes, jobTypesLoading } = state.JobTypes;
    const { jobSources, jobSourcesLoading } = state.JobSources;
    const { claimPriorities, claimPrioritiesLoading } = state.Compliances;

    return {
        fitters,
        fittersLoading,
        jobTypes,
        jobTypesLoading,
        jobSources,
        jobSourcesLoading,
        claimPriorities,
        claimPrioritiesLoading,
    };
};

function mapDispatchToProps(dispatch) {
    let actions = bindActionCreators({ getFitters, getJobTypes, getJobSources, getClaimPriorities }, dispatch);
    return { ...actions, dispatch };
}


export default connect(mapStatetoProps, mapDispatchToProps)(FilterContainer);


FilterContainer.propTypes = {
    display: PropTypes.shape({
        jobSourcesTagCloud: PropTypes.bool,
        fittersSelect: PropTypes.bool,
        textSearchInput: PropTypes.bool,
        dateRangePicker: PropTypes.bool,
        appointmentDateRangePicker: PropTypes.bool,
        callsSelect: PropTypes.bool,
        countriesSelect: PropTypes.bool,
        jobTypesSelect: PropTypes.bool,
        jobStatesSelect: PropTypes.bool,
        claimPrioritiesSelect: PropTypes.bool,
    }),

    onJobSourceClick: PropTypes.func,
    onSelectFitters: PropTypes.func,

    onClearJobStates: PropTypes.func,
    onJobStateClick: PropTypes.func,

    onClearTextSearch: PropTypes.func,
    onTextChange: PropTypes.func,

    onClearDateRange: PropTypes.func,
    onDateRangeChange: PropTypes.func,

    onClearAppointmentDateRange: PropTypes.func,
    onAppointmentDateRangeChange: PropTypes.func,

    onClearCallsFilter: PropTypes.func,
    onCallFilterClick: PropTypes.func,

    onClearCountries: PropTypes.func,
    onCountryClick: PropTypes.func,

    onClearJobTypes: PropTypes.func,
    onJobTypeClick: PropTypes.func,

    onClearClaimPriorities: PropTypes.func,
    onClaimPriorityClick: PropTypes.func,
};
