import React from "react";
import PropTypes from "prop-types";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import Label from "./Label";
import {__} from "../../utils/helpers";

const JobAppointmentSelect = (props) => {
    const {appointment, onAppointmentChange, datetime, onDatetimeChange} = props;


    const initialSettings = {
        singleDatePicker: true,
        timePicker: true,
        timePicker24Hour: true,
        autoUpdateInput: false,
        showDropdowns: true,
        alwaysShowCalendars: true,
        showWeekNumbers: true,
        locale: {
            cancelLabel: 'clear',
            format: 'DD.MM.YYYY'
        }
    };

    const handleApply = (event, picker) => {
        picker.element.val(
            picker.startDate.format('DD.MM.YYYY HH:mm')
        );
    };

    const handleCallback = (start, end, picker) => {
        onDatetimeChange(start.format('DD.MM.YYYY HH:mm'));
    };

    const handleCancel = (event, picker) => {
        picker.element.val('');
        onDatetimeChange(null);
    };

    return (
        <div className={'form-group'}>
            <div className="d-flex justify-content-between align-items-center">
                <Label>
                    {__("appointment")}
                </Label>
                <div className="m-t-5">
                    <div className="btn-group btn-group-xs">
                        <button className={'btn btn-xs '+ (appointment === false ? 'btn-dark' : 'btn-outline-dark')}
                                onClick={() => {
                                    onAppointmentChange(false);
                                }}>{__("no")}</button>
                        <button className={'btn btn-xs '+ (appointment === true ? 'btn-dark' : 'btn-outline-dark')}
                                onClick={() => {
                                    onAppointmentChange(true);
                                }}>{__("yes")}</button>
                    </div>
                </div>
            </div>
            {appointment === true ? (
                <div className="m-t-10">
                    <DateRangePicker initialSettings={initialSettings}
                                     onApply={handleApply}
                                     onCancel={handleCancel}
                                     onCallback={handleCallback}>
                        <input className={'form-control'}
                               readOnly={true}
                               style={{height: '40px'}}
                               placeholder={__("chooseAppointment")}
                               value={datetime}
                        />
                    </DateRangePicker>
                </div>
            ) : ''}
        </div>
    );
}

export default JobAppointmentSelect;

JobAppointmentSelect.propTypes = {
    appointment: PropTypes.bool.isRequired,
    onAppointmentChange: PropTypes.func.isRequired,
    datetime: PropTypes.string.isRequired,
    onDatetimeChange: PropTypes.func.isRequired,
};
