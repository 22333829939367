import {BrowserRouter, Switch} from "react-router-dom";
import {Provider} from "react-redux";
import EchoRedux from 'laravel-echo-redux';
import './assets/scss/style.scss';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { initApiBackend } from './api';
import { initApiAuthBackend, getApiAuthBackend } from './api/Auth';
import Layout from "./components/Layout";
import AppRoute from "./routes/route";
import history from './routes/history';
import {authProtectedRoutes, publicRoutes} from "./routes";
import Plain from "./components/Layout/Plain";
import store from './store';
import React, {Suspense} from "react";
import './i18n'

initApiAuthBackend();
initApiBackend();

const config = {
    store, //Redux store (required)
    debug: true, //Debug mode on/off (optional)
    host: "/",
    encrypted: true,
    auth: {
        headers: {
            'Authorization': 'Bearer '+ getApiAuthBackend().getAuthToken()
        },
    },
    //transports: ['websocket'],
    transports: ['polling']
}

EchoRedux.init(config)

const authUser = getApiAuthBackend().getAuthenticatedUser();

if(authUser !== null){
    if(authUser.type === 'admin'){
        EchoRedux.subscribePrivate(
            'admins',
            '.App\\Events\\JobUpdatedEvent',
            'GET_JOB_SUCCESS_WS'
        );

        EchoRedux.subscribePrivate(
            'admins',
            '.App\\Events\\JobCreatedEvent',
            'GET_JOB_SUCCESS_WS'
        );
    } else if(authUser.type === 'contractor'){
        EchoRedux.subscribePrivate(
            'contractor_group.'+ authUser.profile_id,
            '.App\\Events\\JobUpdatedEvent',
            'GET_JOB_SUCCESS_WS'
        );

        EchoRedux.subscribePrivate(
            'contractor_group.'+ authUser.profile_id,
            '.App\\Events\\JobCreatedEvent',
            'GET_JOB_SUCCESS_WS'
        );
    } else if(authUser.type === 'fitter_system') {
        EchoRedux.subscribePrivate(
            'fitter_system.'+ authUser.profile_id,
            '.App\\Events\\JobUpdatedEvent',
            'GET_JOB_SUCCESS_WS'
        );

        EchoRedux.subscribePrivate(
            'fitter_system.'+ authUser.profile_id,
            '.App\\Events\\JobCreatedEvent',
            'GET_JOB_SUCCESS_WS'
        );
    }

    EchoRedux.subscribePrivate(
        'user.'+ authUser.id,
        '.App\\Events\\UserCommissionUpdatedEvent',
        'GET_COMMISSIONS_SUMMARY_SUCCESS_WS'
    );

    EchoRedux.subscribePrivate(
        'telephone',
        '.App\\Events\\CallUpdatedEvent',
        'GET_API_CALL_WS_SUCCESS'
    );
}


function App() {
  return (
      <Provider store={store}>
          <Suspense fallback={null}>
              <BrowserRouter history={history}>
                  <Switch>
                      {publicRoutes.map((route, idx) => (
                          <AppRoute
                              path={route.path}
                              layout={Plain}
                              component={route.component}
                              key={idx}
                              isAuthProtected={false}
                          />
                      ))}

                      {authProtectedRoutes.map((route, idx) => (
                          <AppRoute
                              path={route.path}
                              layout={Layout}
                              component={route.component}
                              key={idx}
                              isAuthProtected={true}
                          />
                      ))}


                  </Switch>

              </BrowserRouter>
          </Suspense>
      </Provider>
  );
}

export default App;