import {
    SET_PHONE_STATE,
    SET_PHONE_STATE_SUCCESS,
    GET_API_CALL,
    GET_API_CALL_SUCCESS,
    PATCH_API_CALL,
    PATCH_API_CALL_SUCCESS,
    CREATE_API_TEST_CALL,
    CREATE_API_TEST_CALL_SUCCESS,
} from "./actionTypes";

export const setPhoneState = (data) => ({
    type: SET_PHONE_STATE,
    payload: {data}
});

export const setPhoneStateSuccess = (response) => ({
    type: SET_PHONE_STATE_SUCCESS,
    payload: response
});


export const getApiCall = (id) => ({
    type: GET_API_CALL,
    payload: {id}
});

export const getApiCallSuccess = response => ({
    type: GET_API_CALL_SUCCESS,
    payload: response.data.data
});

export const patchApiCall = (id, data) => ({
    type: PATCH_API_CALL,
    payload: {id, data}
});

export const patchApiCallSuccess = response => ({
    type: PATCH_API_CALL_SUCCESS,
    payload: response.data.data
});


export const createApiTestCall = (data) => ({
    type: CREATE_API_TEST_CALL,
    payload: {data}
});

export const createApiTestCallSucces = response => ({
    type: CREATE_API_TEST_CALL_SUCCESS,
    payload: response.data.data
});