import React from "react";
import {Link} from "react-router-dom";
import {DisplayCountryColumn, DisplayStateColumn, DisplayDateTimeColumn, DisplayJobCodeColumn, DisplayJobTypeColumn} from "./Shared";

export const IconsColumn = (cell, row, rowIndex, formatExtraData) => {

    if(row.spam !== 0){
        return (
            <span className={'text-warning'} title={'Spam'}><i className={'fa fa-trash'} /></span>
        )
    }

    if(row.missed === true || row.answered === false){
        return (
            <span className={'text-danger'} title={'Entgangener Anruf'}><i className={'fa fa-exclamation-triangle'} /></span>
        )
    }

    return '';
}

export const DirectionColumn = (cell, row, rowIndex, formatExtraData) => {
    const map = {
        '': '',
        'in': (
            <span className="label label-success"><i className={'fa fa-arrow-right'} /> in</span>
        ),
        'out': (
            <span className="label label-info"><i className={'fa fa-arrow-left'} /> out</span>
        )
    };

    return map[row.direction];
};

export const FromColumn = (cell, row, rowIndex, formatExtraData) => {

    if(row.direction === 'out' && row.number && row.number.job_source){
        return (
            <span>
                {row.from}<br />
                <small>{row.number.job_source.name}</small>
            </span>
        )
    }

    return row.from;
};

export const ToColumn = (cell, row, rowIndex, formatExtraData) => {

    if(row.direction === 'in' && row.number && row.number.job_source){
        return (
            <span>
                {row.to}<br />
                <small>{row.number.job_source.name}</small>
            </span>
        )
    }


    return row.to;
};

export const AgentColumn = (cell, row, rowIndex, formatExtraData) => {

    let agent = '';
    let log;

    if(typeof(row.logs) === 'undefined'){
        return "";
    }

    if(typeof(row.logs) !== "undefined"){
        for(let i = 0; i < row.logs.length; i++){
            log = row.logs[i];

            if(log.agent !== '')
                agent = log.agent;
        }
    }


    const agents = agent.split(',');
    if(agents.length === 1)
        return agents[0];

    return (
        <div style={{maxHeight: '80px', overflowY: 'scroll'}}>
            <div dangerouslySetInnerHTML={{__html: agents.join('<br />')}} />
        </div>
    );
};

export const JobTypeColumn = (cell, row, rowIndex, formatExtraData) => {
    return DisplayJobTypeColumn(row.type);
};

export const JobStateColumn = (cell, row, rowIndex, formatExtraData) => {
    if(row.status)
        return DisplayStateColumn(row.status.status, {
            style: {
                width: '100%',
                //display: 'block',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            }
        });
    return '';
};


export const JobCodeColumn = (cell, row, rowIndex, formatExtraData) => {
    return DisplayJobCodeColumn(row.code);
};


export const JobColumn = (cell, row, rowIndex, formatExtraData) => {
    if(row.job && row.job.type){
        return (
            <Link to={'/jobs/'+ row.id}
                  onClick={() => formatExtraData.setJob(row)}
            >
                <span className={"label label-"+ row.job.type.color}>
                    <i className={row.job.type.icon} title={row.job.type.name} />
                    &nbsp;&nbsp;{row.job.code}
                </span>
            </Link>
        );
    }

    return '';
}

export const PlayColumn = (cell, row, rowIndex, formatExtraData) => {

    return (
        <span>
            {row.record && (
                <div className={'btn-group'}>
                    <button className={"btn btn-xs btn-primary"} onClick={() => {formatExtraData.onPlayRecord('/pbx/calls/'+ row.id +'/modal/record/play')}}>
                        <i className={'fa fa-play'}/>
                    </button>

                    <a className={"btn btn-xs btn-secondary"} href={'/pbx/calls/'+ row.id +'/modal/record/play'}>
                        <i className={'fa fa-download'}/>
                    </a>
                </div>
            )}

        </span>
    );

};

export const ViewColumn = (cell, row, rowIndex, formatExtraData) => {

    return (
        <span>
            <div className={'btn-group'}>
                <Link className={"btn btn-xs btn-secondary"}
                      to={'/jobs/'+ row.id}
                      onClick={() => formatExtraData.setJob(row)}
                >
                    <i className={'fa fa-eye'}/>
                </Link>
            </div>
        </span>
    );

};

export const CountryColumn = (cell, row, rowIndex, formatExtraData) => {
    return DisplayCountryColumn(row.address.country);
}

export const DateTimeColumn = (cell, row, rowIndex, formatExtraData) => {
    return (
        <>
            {DisplayDateTimeColumn(row.created)}
            <small className="d-block">
                <i className="fa fa-hashtag m-r-5" />
                <span>{row.code}</span>
            </small>
        </>
    );
}

export const AppointmentColumn = (cell, row, rowIndex, formatExtraData) => {
    return (
        <>
            {row.handle.type === 'appointment' && (
                <>
                    {DisplayDateTimeColumn(row.handle.datetime)}
                </>
            )}
        </>
    );
}

export const DurationColumn = (cell, row, rowIndex, formatExtraData) => {
    if(row.missed === true || row.answered === false || row.spam !== 0 || row.duration === 0)
        return '';

    let seconds = row.duration%60;
    let minutes = (row.duration-seconds)/60;

    if(seconds < 10)
        seconds = "0"+ seconds;

    return (
        <span>
            {minutes+":"+seconds}
        </span>
    );
}

export const FitterColumn = (cell, row, rowIndex, formatExtraData) => {
    return (
        <span>
            <Link to={'/fitters/'+ row.fitter.id}
                  title={row.fitter.display_name}
                  className="d-block text-truncate"
                  style={{maxWidth: '150px'}}
            >
                {row.fitter.display_name}
            </Link>
        </span>
    );
}

export const CustomerColumn = (cell, row, rowIndex, formatExtraData) => {
    return (
        <span className="d-block text-truncate"
              style={{maxWidth: '200px'}}>
            <span>{row.contact.name}</span>
            <small><br />{row.address.street} {row.address.house}</small>
            <small><br />{row.address.zipcode} {row.address.city}</small>
        </span>
    );
}
