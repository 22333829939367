import React, { Component } from "react";
import Moment from "react-moment";
import StatusBadge from "./StatusBadge";
import { __} from "../../utils/helpers";

class CallHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    getAgentName = (call) => {

        if(typeof(call.logs) === "undefined")
            return "";

        const log = call.logs.find((item) => item.agent !== "" && item.event === "answer");

        if(typeof(log) === "undefined")
            return "";

        return log.agent;
    }

    renderTableRows(){
        const { job } = this.props;
        const self = this;

        const pad = function(num) {
            return ("0"+num).slice(-2);
        }
        const hhmmss = function(secs) {
            let minutes = Math.floor(secs / 60);
            secs = secs%60;
            let hours = Math.floor(minutes/60)
            minutes = minutes%60;

            if(pad(hours) > 0)
                return `${pad(hours)}:${pad(minutes)}:${pad(secs)}`;

            return `${(minutes)}:${pad(secs)}`;
        }

        return job.calls.map(function(item){
            return (
                <tr key={'call-'+ item.id}>
                    <td>
                        <StatusBadge state={item.direction} />
                    </td>
                    <td>
                        <Moment date={item.created} format="DD.MM." />, <Moment date={item.created} format="HH:mm" /> Uhr
                    </td>
                    <td>
                        {item.direction === 'in' ? item.from : item.to}
                    </td>
                    <td>
                        {item.user ? (
                            <span className={'d-block'}>{item.user.name}</span>
                        ) : ''}
                        <span className={'d-block text-muted'}>{ self.getAgentName(item) }</span>
                    </td>
                    <td>
                        {hhmmss(item.duration)}
                    </td>
                </tr>
            );
        });
    }

    renderTable(){
        const { job, style } = this.props;

        if(!job.calls || job.calls.length === 0)
            return (
                <p className="p-30 p-b-3">{__("noDataAvailable")}</p>
            );


        return (
            <div className="table-responsive" style={style}>
                <table className="table table-hover customize-table v-top m-b-0">
                    <thead className="table-dark">
                    <tr>
                        <th width={'1%'}>&nbsp;</th>
                        <th>{__("date")}</th>
                        <th>{__("phoneNumber")}</th>
                        <th>{__("agent")}</th>
                        <th>{__("duration")}</th>
                    </tr>
                    </thead>
                    <tbody>
                    { this.renderTableRows()}
                    </tbody>
                </table>

            </div>
        )
    }

    render() {
        return this.renderTable();
    }
}

export default CallHistory;
