import React, {Component} from "react";
import image_profile from "../../assets/images/users/1.jpg";
import Moment from "react-moment";
import {__, languages, getSelectedLanguage} from "../../utils/helpers"
import i18n from "../../i18n";

class TopBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedLanguage: '',
        };
    }

    renderCommissionItems() {
        const {commissionsSummary} = this.props;

        // eslint-disable-next-line no-unused-vars
        let lastDate;

        return commissionsSummary.commissions.map((item, key) => {
            let skipDate = lastDate === item.date;
            lastDate = item.date;
            if(item.type === 'adjustment') {
                return (
                    <tr key={'summary-'+ key}>
                        <td>
                            {!skipDate && (
                                <Moment date={item.date} format="DD.MM." />
                            )}
                        </td>
                        <td colSpan={3}>{item.description}</td>
                        <td className="text-right">
                            <span className={ (item.amount > 0 ? 'text-success' : 'text-danger')}>{item.amount} <i className="fas fa-lira-sign "></i></span>
                        </td>
                    </tr>
                )
            }
            if(item.type === 'mistake') {
                return (
                    <tr key={'summary-'+ key} className="bg-light-danger">
                        <td>
                            {!skipDate && (
                                <Moment date={item.date} format="DD.MM." />
                            )}
                        </td>
                        <td colSpan={3}>{item.description}</td>
                        <td className="text-right">
                            <span className={ (item.amount > 0 ? 'text-success' : 'text-danger')}>{item.amount} <i className="fas fa-lira-sign " /></span>
                        </td>
                    </tr>
                )
            }
            if(item.type === 'jobs') {
                return (
                    <tr key={'summary-'+ key}>
                        <td><Moment date={item.date} format="DD.MM." /></td>
                        <td>{__('general.jobs')}</td>
                        <td>{item.data?.done?.count}</td>
                        <td>{item.data?.canceled?.count}</td>
                        <td className="text-right">
                            <span className={ (item.amount > 0 ? 'text-success' : 'text-danger')}>{item.amount} <i className="fas fa-lira-sign " /></span>
                        </td>
                    </tr>
                )
            }

            return (
                <tr key={'summary-'+ key}>
                    <td><Moment date={item.date} format="DD.MM." /></td>
                    <td>{item.description}</td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
            )
        });
    }
    render() {
        const {user, commissionsSummary, settings, toggleLeftSidebar} = this.props;
        const selectedLanguage = getSelectedLanguage();

        return (
            <header className="topbar" data-navbarbg={settings.NavbarBg}>
                <nav className="navbar top-navbar navbar-expand-md navbar-dark">
                    <div className="navbar-header" data-logobg={settings.LogoBg}>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a className="nav-toggler waves-effect waves-light d-block d-md-none">
                            <i className="ti-menu ti-close"/>
                        </a>
                        <a className="navbar-brand" href="/">
                            <b className="logo-icon">
                                CSI
                            </b>
                            <span className="logo-text">
                                Management
                            </span>
                        </a>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a className="topbartoggler d-block d-md-none waves-effect waves-light"
                           data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
                           aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <i className="ti-more"/>
                        </a>
                    </div>

                    <div className="navbar-collapse collapse" id="navbarSupportedContent" data-navbarbg={settings.NavbarBg}>
                        <ul className="navbar-nav me-auto">
                            <li className="nav-item">
                                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                <a className="nav-link sidebartoggler d-none d-md-block waves-effect waves-dark" onClick={toggleLeftSidebar}>
                                    <i className="ti-menu text-dark"/>
                                </a>
                            </li>
                            { commissionsSummary && (
                                <li className="nav-item dropdown">
                                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */}
                                    <a className="nav-link dropdown-toggle waves-effect waves-dark" href="#"
                                       data-bs-toggle="dropdown"
                                       aria-haspopup="true" aria-expanded="false">
                                        <i className="ti-credit-card text-dark"/>
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-start" style={{width: '600px'}}>
                                        <div className="d-flex no-block align-items-center p-3 bg-dark text-white mb-2">
                                            <div className="ms-2">
                                                <h4 className="mb-0 text-white">{commissionsSummary.amount} <i className="fas fa-lira-sign " /></h4>
                                                <p className="mb-0">{__('estimatedProvisionFor')} {commissionsSummary.month_name} {commissionsSummary.year}</p>
                                            </div>
                                        </div>

                                        <div style={{maxHeight: '500px', overflow: 'auto'}}>
                                            <table className="table table-hover customize-table v-top">
                                                <thead>
                                                <tr>
                                                    <th>{__("date")}</th>
                                                    <th>{__("description")}</th>
                                                    <th>{__("done")}</th>
                                                    <th>{__("canceled")}</th>
                                                    <th className="text-right">{__("amount")}</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {this.renderCommissionItems()}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </li>
                            )}
                        </ul>

                        <ul className="navbar-nav justify-content-end">


                            <li className="nav-item d-none d-md-block search-box">
                                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                <a className="nav-link d-none d-md-block waves-effect waves-dark">
                                    <i className="ti-search"/>
                                </a>
                                <form className="app-search">
                                    <input type="text" className="form-control" placeholder="Search & enter"/>
                                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                    <a className="srh-btn"><i className="ti-close"/></a>
                                </form>
                            </li>

                            <li className="nav-item dropdown">
                                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */}
                                <a className="nav-link dropdown-toggle text-muted waves-effect waves-dark"
                                   href=""
                                   data-bs-toggle="dropdown"
                                   aria-haspopup="true"
                                   aria-expanded="false"
                                >
                                    <i className={selectedLanguage.icon} />
                                </a>
                                <div className="dropdown-menu dropdown-menu-end dropdown-menu-animate-up">
                                    { languages.map((language, key) => {
                                        return (
                                            // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                            <a key={'top-bar-lang-'+ key} className="dropdown-item" href="#" onClick={() => {
                                                i18n.changeLanguage(language.code).then(() => this.setState({
                                                    selectedLanguage: language.code
                                                }));
                                            }}>
                                                <i className={language.icon} /> {language.name}
                                            </a>
                                        )
                                    })}
                                </div>
                            </li>


                            <li className="nav-item dropdown">
                                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */}
                                <a className="nav-link dropdown-toggle waves-effect waves-dark" href="#"
                                   data-bs-toggle="dropdown"
                                   aria-haspopup="true" aria-expanded="false">
                                    <img src={image_profile} alt="user" width="30"
                                         className="profile-pic rounded-circle"/>
                                </a>
                                <div className="dropdown-menu dropdown-menu-end user-dd animated flipInY">
                                    <div className="d-flex no-block align-items-center p-3 bg-primary text-white mb-2">
                                        <div className="">
                                            <img src={image_profile} alt="user" className="rounded-circle" width="60"/>
                                        </div>
                                        <div className="ms-2">
                                            <h4 className="mb-0 text-white">{user.name}</h4>
                                            <p className="mb-0">{user.email}</p>
                                        </div>
                                    </div>
                                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                    {/**
                                    <a className="dropdown-item" onClick={handleLogout}>
                                        <i data-feather="log-out" className="feather-sm text-danger me-1 ms-1"/> Logout
                                    </a>*/}
                                </div>
                            </li>
                        </ul>
                    </div>
                </nav>
            </header>
        );
    }
}

export default TopBar;