import React, { Component } from "react";
import Moment from "react-moment";
import { toast } from 'react-toastify';
import {__} from "../../utils/helpers";
import Label from "../Form/Label";
import Question from "./Question";


class Dialog extends Component {
    constructor(props) {
        super(props);

        const questionValues = {};

        if(props.answers){
            for(let i = 0; i < props.answers.length; i++){
                questionValues[props.answers[i].question_id] = props.answers[i].answer_value;
            }
        }

        this.state = {
            comment: props.comment ?? '',
            state: props.state ?? 'success',
            questionValues,
        };
    }

    getQuestions(){
        const { compliance, job } = this.props;

        return compliance.questions.filter((item) => {
            if(item.question_conditions === null)
                return true;
            let condition, status, fieldValue;

            for(let i = 0; i < item.question_conditions.length; i++){
                condition = item.question_conditions[i];
                fieldValue = condition.field.split('.').reduce((o,i)=>o[i], job);


                switch(condition.operator){
                    default:
                        // nothing to-do
                        break;
                    case '==':
                        // eslint-disable-next-line
                        status = fieldValue == condition.value;
                        break;
                    case '===':
                        status = fieldValue === condition.value;
                        break;
                    case '!=':
                        // eslint-disable-next-line
                        status = fieldValue == condition.value;
                        break;
                    case '!===':
                        status = fieldValue === condition.value;
                        break;
                    case '>=':
                        status = fieldValue >= condition.value;
                        break;
                    case '<=':
                        status = fieldValue <= condition.value;
                        break;
                    case '>':
                        status = fieldValue > condition.value;
                        break;
                    case '<':
                        status = fieldValue < condition.value;
                        break;
                }

                if(status === false)
                    return false;
            }

            return true;
        });
    }

    renderQuestions(){
        const {readonly} = this.props;
        const self = this;

        return this.getQuestions().map(function(question){
            return (
                <Question
                    key={'question-'+ question.question_id}
                    readonly={readonly}
                    question={question}
                    value={self.getQuestionValue(question.question_id)}
                    questionValueChanged={(value) => self.questionValueChanged(question.question_id, value)}
                    claimTemplates={self.props.claimTemplates}
                />
            )
        })
    }

    stateChanged = (event) => {
        this.setState({
            state: event.target.value,
        });
    }

    commentChanged = (event) => {
        this.setState({
            comment: event.target.value,
        })
    }

    getQuestionValue(question_id){
        const {questionValues} = this.state;

        if(questionValues[question_id])
            return questionValues[question_id];

        return null;
    }

    questionValueChanged = (question_id, value) => {
        this.setState({
            questionValues: {
                ...this.state.questionValues,
                [question_id]: value,
            }
        });
    }

    getSubmitData() {
        const {state, comment, questionValues: answers} = this.state;

        return {
            state,
            comment,
            answers,
        };
    }

    validateCheck = (data) => {
        const {compliance, readonly} = this.props;

        if(readonly && readonly === true){
            return false;
        }

        let commentRequired = false, claimQuestion;

        if(data.state === 'success'){
            if(Object.keys(data.answers).length !== this.getQuestions().length){
                toast.error(__("complianceFillAnswersMessage"))
                return false;
            }

            claimQuestion = compliance.questions.find((item) => item.question_type === 'claim_selection');

            if(claimQuestion){
                if(typeof(data.answers[claimQuestion.question_id]) === 'undefined'){
                    commentRequired = true;
                } else if (data.answers[claimQuestion.question_id].value === 1){
                    commentRequired = true;
                }
            }

            if(commentRequired === true && data.comment.trim() === ''){
                toast.error(__("complianceCommentRequiredMessage"))
                return false;
            }
        }

        return true;
    }

    submitData = () => {
        const data = this.getSubmitData();
        if(this.validateCheck(data) === true)
            this.props.submitData(data);
    }

    submitDataAndContinue = () => {
        const data = this.getSubmitData();
        if(this.validateCheck(data) === true)
            this.props.submitDataAndContinue(data);
    }

    render() {
        const {state, comment} = this.state;
        const {readonly, dialogData} = this.props;

        let cardStyle = {};
        let cardClass = 'card';

        if(readonly === false){
            cardStyle = {
                //backgroundColor: '#333',
                //borderColor: '#333',
                border: '1px solid #333'
            }
            cardClass = 'card card-inverse';
        }

        let stateName = '';

        switch(state){
            default:
                // nothing to-do
                break;
            case 'success':
                stateName = __("success");
                break;
            case 'no_information':
                stateName = __("noInformation");
                break;
            case 'not_reached':
                stateName = __("notReached");
                break;
            case 'canceled':
                stateName = __("canceled");
                break;
        }

        return (
            <div className={cardClass} style={cardStyle}>
                <div className={'card-body'}>
                    <h4 className="card-title">{__("dialog")}</h4>

                    {dialogData &&
                    <div className={'row'}>
                        <div className={'col-md-3'}>
                            <div className={'form-group'}>
                                <Label>{__("date")}</Label>
                                <p className={'form-control-static'}>
                                    <Moment date={dialogData.compliance_created} format="DD.MM." />, <Moment date={dialogData.compliance_created} format="HH:mm" /> Uhr
                                </p>
                            </div>
                        </div>
                        <div className={'col-md-3'}>
                            <div className={'form-group'}>
                                <Label>Score</Label>
                                <p className={'form-control-static'}>
                                    {dialogData.compliance_score}
                                </p>
                            </div>
                        </div>
                        <div className={'col-md-3'}>
                            <div className={'form-group'}>
                                <Label>{__("user")}</Label>
                                <p className={'form-control-static'}>
                                    {dialogData.user.name}
                                </p>
                            </div>
                        </div>
                        <div className={'col-md-12'}>
                            <hr />
                        </div>
                    </div>
                    }

                    <div className={'form-group m-b-15'}>
                        <Label>{__("status")}</Label>
                        { readonly === false ? (
                            <select id="compliance_state" name="data[compliance_state]"
                                    className="form-control" onChange={this.stateChanged} value={state} readOnly={readonly}>
                                <option value="success">{__("success")}</option>
                                <option value="no_information">{__("noInformation")}</option>
                                <option value="not_reached">{__("notReached")}</option>
                                <option value="canceled">{__("canceled")}</option>
                            </select>
                        ) : (
                            <p className={'form-control-static'}>
                                {stateName}
                            </p>
                        )}

                    </div>

                    { state === 'success' &&
                        <div>
                            { this.renderQuestions() }
                        </div>
                    }

                    { state === 'success' &&
                        <div className={'form-group m-b-30'}>
                            <Label>{__("comment")}</Label>
                            <textarea className={'form-control'} onChange={this.commentChanged} value={comment} readOnly={readonly} />
                        </div>
                    }


                    { readonly === false &&
                        <div className={'btn-group'}>
                            <button className={'btn btn-inverse'} onClick={this.submitData}>
                                <i className="fa fa-save m-r-5" />
                                {__("save")}
                            </button>

                            <button className={'btn btn-secondary'} onClick={this.submitDataAndContinue}>
                                <i className="fa fa-save m-r-5" />
                                {__("saveAndNext")}
                            </button>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

export default Dialog;
