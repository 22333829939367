import React, { Component } from "react";
import BriaConnector from "../../lib/Bria/BriaConnector";
import Calls from "../../components/Phone/Calls";

class Phone extends Component {
    setPageTitle(){
        this.props.setPageTitle('Telefon Modul');
    }

    componentDidMount() {
        document.title = "Telefonmodul";
    }


    render() {
        return (
            <React.Fragment>
                <BriaConnector />
                <Calls />
            </React.Fragment>
        );
    }
}

export default Phone;
