import React, { Component } from "react";
import Moment from "react-moment";
import 'moment/locale/de';
import StatusBadge from "../Jobs/StatusBadge";
import {stateColor, __} from "../../utils/helpers"
import Label from "../Form/Label";

class OverviewCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editSales:  false,
            input: {
                sales: {
                    paid: {
                        total: null,
                        material: null,
                    },
                }
            }
        };

        this.totalPaidInput = null;
    }

    render() {
        const { fitter } = this.props;

        return (
            <div className="card">
                <div className={"card-body bg-light-"+ stateColor(fitter.status.status)}>
                    <center className="m-t-10">
                        <div>
                            <i className={'fa fa-user'} style={{fontSize: '80px'}} />
                        </div>
                        <h4 className="card-title m-t-10">
                            {fitter.display_name}
                        </h4>
                        <h6 className="card-subtitle">
                            { fitter.system?.name || ''}
                        </h6>
                        <div className="row text-center justify-content-md-center">
                            <div className="col-4">
                                <span className="link">
                                    <i className="fa fa-hashtag m-r-5" />
                                    <font className="font-medium">{fitter.name}</font>
                                </span>
                            </div>
                            <div className="col-4">
                                <span className="link">
                                    <font className="font-medium">
                                        <StatusBadge border state={fitter.status.status || 'unknown'} />
                                    </font>
                                </span>
                            </div>
                        </div>
                    </center>
                </div>
                <div>
                    <hr style={{marginTop: 0}} />
                </div>

                <div className="card-body">
                    <div className="row">
                        <div className="col-md-6">
                            <Label>{__("created")}</Label>
                            <h6>
                                <Moment date={fitter.created} format="DD.MM.YYYY" />, <Moment date={fitter.created} format="HH:mm" /> Uhr
                            </h6>
                        </div>

                    </div>

                    <Label className="p-t-30 d-block">{__("company")}</Label>
                    <h6>{fitter.company}</h6>

                    <Label className="p-t-30 d-block">{__("Person")}</Label>
                    <h6>{fitter.person}</h6>

                    <Label className="p-t-30 d-block">{__("nickname")}</Label>
                    <h6>{fitter.nickname}</h6>

                    <div className="row">
                        <div className="col-md-6">
                            <Label className="p-t-30 d-block">{__("phoneNumber")}</Label>
                            <h6>
                                <a href={'tel:+'+ fitter.contact.phone}>
                                    {fitter.contact.phone}
                                </a>
                            </h6>
                        </div>
                        <div className="col-md-6">
                            {fitter.contact.phone_alternate !== '' ? (
                                <>
                                    <Label className="p-t-30 d-block">{__("alternatePhoneNumber")}</Label>
                                    <h6>
                                        <a href={'tel:+'+ fitter.contact.phone_alternate}>
                                            {fitter.contact.phone_alternate}
                                        </a>
                                    </h6>
                                </>
                            ) : ''}
                        </div>
                    </div>
                    {fitter.contact.sms !== '' ? (
                        <>
                            <Label className="p-t-30 d-block">{__("smsPhoneNumber")}</Label>
                            <h6>
                                {fitter.contact.sms}
                            </h6>
                        </>
                    ) : ''}
                    {fitter.contact.email !== '' ? (
                        <>
                            <Label className="p-t-30 d-block">{__("emailAddress")}</Label>
                            <h6>
                                {fitter.contact.email}
                            </h6>
                        </>
                    ) : ''}
                    {fitter.contact.email !== '' ? (
                        <>
                            <Label className="p-t-30 d-block">Telegram</Label>
                            <h6>
                                {fitter.contact.telegram_name} <small>{fitter.contact.telegram}</small>
                            </h6>
                        </>
                    ) : ''}

                    <Label className="p-t-30 d-block">{__("address")}</Label>
                    <h6>
                        <span className={'d-block'}>
                            {fitter.address.street} {fitter.address.house}
                        </span>
                        <span className={'m-t-5 d-block'}>
                            {fitter.address.country.toUpperCase()}-{fitter.address.zipcode} {fitter.address.city}
                        </span>
                    </h6>
                </div>
                {/*
                <div>
                    <hr />
                </div>
                <div className={'card-body'}>
                    <div className=" d-flex justify-content-between align-items-center m-b-30">
                        <h4 className="card-title m-b-0">Partner</h4>
                        <ButtonGroup>
                            <SelectFitterModal color="secondary" size="xs" job={job}>
                                <i className={'fa '+ (!patchJobLoading ? 'fa-exchange-alt' : 'fa-spin fa-spinner')} />
                            </SelectFitterModal>
                        </ButtonGroup>
                    </div>

                    {job.fitter && job.fitter.system && (
                        <>
                            <Label>Zentrale</Label>
                            <h6>{job.fitter && job.fitter.system && job.fitter.system.name}</h6>
                        </>
                    )}
                    {job.fitter && (
                        <>
                            <Label className="p-t-30 d-block">Partner</Label>
                            <h6>{job.fitter && job.fitter.display_name}</h6>
                        </>
                    )}
                </div>*/}
            </div>
        );
    }
}

export default OverviewCard;
