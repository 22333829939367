import {
    GET_STATISTICS_FITTERS,
    GET_STATISTICS_FITTERS_SUCCESS, GET_STATISTICS_JOB_MAP, GET_STATISTICS_JOB_MAP_SUCCESS,
} from "./actionTypes";

const INIT_STATE = {
    error: null,
    loading: false,
    fitterStatisticsLoading: false,
    fitterStatistics: [],
    jobMapLoading: false,
    jobMap: null
};

const Statistics = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_STATISTICS_FITTERS:
            return {
                ...state,
                fitterStatisticsLoading: true,
            }
        case GET_STATISTICS_FITTERS_SUCCESS:
            return {
                ...state,
                fitterStatisticsLoading: false,
                fitterStatistics: action.payload.data,
            }

        case GET_STATISTICS_JOB_MAP:
            return {
                ...state,
                jobMapLoading: true,
            }
        case GET_STATISTICS_JOB_MAP_SUCCESS:
            return {
                ...state,
                jobMapLoading: false,
                jobMap: action.payload.data,
            }
        default:
            return state;
    }
};


export default Statistics;
