import React, { Component } from "react";
import Select from 'react-select'


class Fitters extends Component {
    constructor (props) {
        super(props)
        /**
        this.options = [
            {value: 1, label: 'John'},
            {value: 2, label: 'Miles'},
            {value: 3, label: 'Charles'},
            {value: 4, label: 'Herbie'},
        ];*/

        this.state = {
            selected: [],
            tags: [
                { id: 1, name: "Apples" },
                { id: 2, name: "Pears" }
            ],
            suggestions: [
                { id: 3, name: "Bananas" },
                { id: 4, name: "Mangos" },
                { id: 5, name: "Lemons" },
                { id: 6, name: "Apricots" }
            ]
        }

        this.fitters = React.createRef()
    }

    onDelete (i) {
        const tags = this.state.tags.slice(0)
        tags.splice(i, 1)
        this.setState({ tags })
    }

    onAddition (tag) {
        const tags = [].concat(this.state.tags, tag)
        this.setState({ tags })
    }

    options() {
        return this.props.fitters.map(function(fitter, key){
            return {
                value: fitter.id,
                label: fitter.display_name,
            };
        });

    }

    onSelectRef = ref => {
        this.selectRef = ref;
    };

    render () {
        return (
            <Select options={this.options()}
                    isMulti
                    name="filter-fitters"
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={this.props.onSelectFitters}
                    ref={ref => {
                        this.selectRef = ref;
                    }}
            />
        )
    }
}

export default Fitters;
