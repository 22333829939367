import React from "react";
import Moment from 'moment';
import 'moment/locale/de';
import {Link} from "react-router-dom";

export const JobCodeColumn = (cell, row, rowIndex, formatExtraData) => {
    return (
        <span>
            {row.code}
        </span>
    );
}

export const IconsColumn = (cell, row, rowIndex, formatExtraData) => {

    if(row.spam !== 0){
        return (
            <span className={'text-warning'} title={'Spam'}><i className={'fa fa-trash'} /></span>
        )
    }

    if(row.missed === true || row.answered === false){
        return (
            <span className={'text-danger'} title={'Entgangener Anruf'}><i className={'fa fa-exclamation-triangle'} /></span>
        )
    }

    return '';
}

export const DirectionColumn = (cell, row, rowIndex, formatExtraData) => {
    const map = {
        '': '',
        'in': (
            <span className="btn btn-xs btn-inverse"><i className={'fa fa-arrow-right'} /> in</span>
        ),
        'out': (
            <span className="btn btn-xs btn-outline-inverse"><i className={'fa fa-arrow-left'} /> out</span>
        )
    };

    return map[row.direction];
};

export const FromColumn = (cell, row, rowIndex, formatExtraData) => {

    if(row.direction === 'out' && row.number && row.number.job_source){
        return (
            <span>
                {row.from}<br />
                <small>{row.number.job_source.name}</small>
            </span>
        )
    }

    return row.from;
};

export const ToColumn = (cell, row, rowIndex, formatExtraData) => {

    if(row.direction === 'in' && row.number && row.number.job_source){
        return (
            <span>
                {row.to}<br />
                <small>{row.number.job_source.name}</small>
            </span>
        )
    }


    return row.to;
};

export const AgentColumn = (cell, row, rowIndex, formatExtraData) => {

    let agent = '';
    let log;

    if(typeof(row.logs) === 'undefined'){
        return "";
    }

    if(typeof(row.logs) !== "undefined"){
        for(let i = 0; i < row.logs.length; i++){
            log = row.logs[i];

            if(log.agent !== '')
                agent = log.agent;
        }
    }


    const agents = agent.split(',');
    if(agents.length === 1)
        return agents[0];

    return (
        <div style={{maxHeight: '80px', overflowY: 'scroll'}}>
            <div dangerouslySetInnerHTML={{__html: agents.join('<br />')}} />
        </div>
    );
};

export const JobTypeColumn = (cell, row, rowIndex, formatExtraData) => {
    if(row.number && row.number.job_type){
        return (
            <span>
                <i className={row.number.job_type.icon} title={row.number.job_type.name} />
            </span>
        );
    }

    return '';
}

export const JobColumn = (cell, row, rowIndex, formatExtraData) => {
    if(row.job && row.job.type){
        return (
            <Link
                to={'/jobs/'+ row.job.id}
                onClick={() => formatExtraData.setJob(row.job)}
                className={'btn btn-xs btn-'+ row.job.type.color}>
                <span>
                    <i className={row.job.type.icon} title={row.job.type.name} />
                    &nbsp;&nbsp;{row.job.code}
                </span>
            </Link>
        );
    }

    return '';
}

export const PlayColumn = (cell, row, rowIndex, formatExtraData) => {

    return (
        <span>
            {row.record && (
                <div className={'btn-group'}>
                    <button className={"btn btn-xs btn-primary"} onClick={() => {formatExtraData.onPlayRecord('/pbx/calls/'+ row.id +'/modal/record/play')}}>
                        <i className={'fa fa-play'}/>
                    </button>

                    <a className={"btn btn-xs btn-secondary"} href={'/pbx/calls/'+ row.id +'/modal/record/play'} target="_blank" rel="noreferrer">
                        <i className={'fa fa-download'}/>
                    </a>
                </div>
            )}

        </span>
    );

};

export const CountryColumn = (cell, row, rowIndex, formatExtraData) => {
    return (
        <span>
            <i className={'flag-icon flag-icon-'+ row.address.country} />
        </span>
    );
}

export const DateColumn = (cell, row, rowIndex, formatExtraData) => {
    return (
        <>
            <span style={{display:'block'}}>
                {Moment(row.created).format('DD.MM.YYYY')}
            </span>
            <small>
                {Moment(row.created).format('HH:mm')} Uhr
            </small>
        </>
    );
}

export const TimeColumn = (cell, row, rowIndex, formatExtraData) => {
    return (
        <span>
            {Moment(row.created).format('hh:mm')} Uhr
        </span>
    );
}

export const DurationColumn = (cell, row, rowIndex, formatExtraData) => {
    if(row.missed === true || row.answered === false || row.spam !== 0 || row.duration === 0)
        return '';

    let seconds = row.duration%60;
    let minutes = (row.duration-seconds)/60;

    if(seconds < 10)
        seconds = "0"+ seconds;

    return (
        <span>
            {minutes+":"+seconds}
        </span>
    );
}

export const FitterColumn = (cell, row, rowIndex, formatExtraData) => {
    return (
        <span>
            <a href={'/fitters/'+ row.fitter.id} target={'_blank'} className={'d-block'} rel="noreferrer">
                <small>
                    {row.fitter.display_name}
                </small>
            </a>
        </span>
    );
}

export const CustomerColumn = (cell, row, rowIndex, formatExtraData) => {
    return (
        <span>
            <span>{row.contact.name}</span>
            <span className={'d-block text-muted'}>{row.address.zipcode} {row.address.city}</span>
        </span>
    );
}
