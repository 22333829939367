import React, { Component } from "react";
import {Row, Col, ButtonGroup, Button, DropdownMenu, ButtonDropdown, DropdownToggle} from "reactstrap";

import Fitters from "./Fitters";

class FilterContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            toggle: {
                jobTypes: false,
                countries: false,
            }
        }
    }

    renderClaimPriorities(){
        const {showClaimPriorities} = this.props;
        const {claimPriorities} = this.props.filters;
        const self = this;

        if(!showClaimPriorities)
            return (<div />);

        return this.props.claimPriorities.map(function(claimPriority, key) {
            const index = typeof(claimPriorities) !== 'undefined' ? claimPriorities.findIndex((element) => element === claimPriority.id) : -1;
            return (
                <div key={'priority-' + claimPriority.id} data-value={claimPriority.id} className={'dropdown-item '+ (index !== -1 ? 'active' : '')}
                     onClick={self.props.onClaimPriorityClick}>
                    <span className={claimPriority.icon + ' m-r-10 text-'+ claimPriority.color}></span> {claimPriority.name}
                </div>
            );

        });
    }

    renderCountries(){
        const {countries} = this.props.filters;
        const self = this;
        return this.props.countries.map(function(country, key) {
            const index = countries.findIndex((element) => element === country.code);
            return (
                    <div key={'country-' + country.code} data-value={country.code} className={'dropdown-item '+ (index !== -1 ? 'active' : '')}
                         onClick={self.props.onCountryClick}>
                        <span className={country.icon + ' m-r-10'}></span> {country.name}
                    </div>
            );

        });
    }

    renderJobTypes(){
        const {jobTypes} = this.props.filters;
        const self = this;

        return this.props.jobTypes.map(function(jobType, key) {
            const index = jobTypes.findIndex((element) => element === jobType.id);

            return (
                <div key={'jobType-'+ jobType.id} data-value={jobType.id} onClick={self.props.onJobTypeClick} className={'dropdown-item '+ (index !== -1 ? 'active' : '')}>
                    <span className={jobType.icon +' text-'+ jobType.color +' m-r-10'}></span> {jobType.name}
                </div>
            );
        });
        /**
         * <a key={'jobType-'+ jobType.id} href="#" className="list-group-item">
         <span className={jobType.icon +' text-'+ jobType.color +' m-r-10'}></span> {jobType.name}
         </a>
         */
    }

    toggle = (type) => {
        const {toggle} = this.state;

        this.setState({
            toggle: {
                ...toggle,
                [type]: !toggle[type]
            }
        })
    }

    renderJobTypesBtn() {
        const {filters} = this.props;
        const {toggle} = this.state;

        console.log(filters.jobTypes.length);

        return (
            <div className="d-inline-block m-r-15">
                <ButtonGroup>
                    <ButtonDropdown isOpen={toggle.jobTypes} toggle={() => this.toggle('jobTypes')}>
                        <DropdownToggle caret className={'btn-'+ (filters.jobTypes.length === 0 ? 'light' : 'dark')}>
                            Branche
                        </DropdownToggle>
                        <DropdownMenu>
                            { this.renderJobTypes() }
                        </DropdownMenu>
                    </ButtonDropdown>
                    <Button onClick={this.props.onClearJobTypes} className={'btn-'+ (filters.jobTypes.length === 0 ? 'light' : 'dark')} disabled={filters.jobTypes.length === 0}>
                        <i className={'fa fa-times'} />
                    </Button>
                </ButtonGroup>
            </div>
        );
    }

    renderClaimPrioritiesBtn(){
        const {filters, showClaimPriorities} = this.props;

        if(!showClaimPriorities){
            return (<span />);
        }

        return (
            <DropdownMenu className="d-inline-block mr-1">
                <ButtonGroup>
                    <ButtonDropdown>
                        <DropdownToggle caret
                                        className={'btn-'+ (typeof(filters.claimPriorities) !== "undefined" && filters.claimPriorities.length === 0 ? 'secondary' : 'dark')}
                        >
                            Priorität
                        </DropdownToggle>
                        <DropdownMenu>
                            { this.renderClaimPriorities() }
                        </DropdownMenu>
                    </ButtonDropdown>


                    <Button type="button"
                            onClick={this.props.onClearClaimPriorities}
                            className={'btn-'+ (typeof(filters.claimPriorities) !== "undefined" && filters.claimPriorities.length === 0 ? 'secondary' : 'dark') +''}
                            disabled={typeof(filters.claimPriorities) !== "undefined" && filters.claimPriorities.length === 0}>
                        <i className={'fa fa-times'} />
                    </Button>
                </ButtonGroup>
            </DropdownMenu>
        )
    }

    renderCountriesBtn(){
        const {filters} = this.props;
        const {toggle} = this.state;

        return (
            <div className="d-inline-block m-r-15">
                <ButtonGroup>
                    <ButtonDropdown isOpen={toggle.countries} toggle={() => this.toggle('countries')}>
                        <DropdownToggle caret className={'btn-'+ (filters.countries.length === 0 ? 'light' : 'dark')}>
                            Land
                        </DropdownToggle>
                        <DropdownMenu>
                            { this.renderCountries() }
                        </DropdownMenu>
                    </ButtonDropdown>
                    <Button onClick={this.props.onClearCountries}
                            className={'btn btn-'+ (filters.countries.length === 0 ? 'light' : 'dark') +''}
                            disabled={filters.countries.length === 0}
                    >
                        <i className={'fa fa-times'} />
                    </Button>
                </ButtonGroup>
            </div>
        )
    }

    renderTextSearchInput(){
        const {filters, horizontal} = this.props;

        let formGroupClass = 'form-group d-inline-block w-25 m-r-15';

        if(horizontal === false){
            formGroupClass = 'form-group d-inline-block w-100 m-r-15';
        }

        return (
            <div className={formGroupClass}>
                <div className={"input-group"}>
                    <input type="text" className={'form-control'} style={{height: '40px'}} placeholder="Textsuche" onChange={this.props.onTextChange} value={filters.textSearch}  />
                    <div className="input-group-append">
                        <button onClick={this.props.onClearTextSearch} className={'btn btn-'+ (filters.textSearch.length === 0 ? 'secondary' : 'dark') +''} type="button" disabled={filters.textSearch.length === 0}>
                            <i className={'fa fa-times'} />
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    renderFittersSelect(){
        const {fitters, horizontal} = this.props;

        let classNames = "form-group d-inline-block w-25"
        if(horizontal === false){
            classNames = "form-group d-inline-block w-100";
        }

        return (
            <div className={classNames}>
                <Fitters fitters={fitters} onSelectFitters={this.props.onSelectFitters} />
            </div>
        )
    }


    render() {
        const {horizontal} = this.props;

        return (
            <div className="">
                <div className="">
                    {typeof(horizontal) === 'undefined' || horizontal === true ? (
                        <Row>
                            <Col xl="12">
                                <div className={'m-b-15'}>
                                    {this.renderJobTypesBtn()}
                                    {this.renderCountriesBtn()}
                                    {this.renderClaimPrioritiesBtn()}
                                    {this.renderTextSearchInput()}
                                    {this.renderFittersSelect()}
                                </div>
                            </Col>
                        </Row>
                    ) : (
                        <Row>
                            <Col xl="12">
                                <div className={'m-b-15'}>
                                    {this.renderJobTypesBtn()}
                                    {this.renderCountriesBtn()}
                                    {this.renderClaimPrioritiesBtn()}
                                </div>
                            </Col>
                            <Col xl="12">
                                {this.renderTextSearchInput()}
                            </Col>
                            <Col xl="12">
                                {this.renderFittersSelect()}
                            </Col>
                        </Row>
                    )}
                </div>
            </div>
        );
    }
}

export default FilterContainer;
