import PropTypes from "prop-types";
import Label from "../Form/Label";
import {__} from "../../utils/helpers";

function Question(props) {
    const {title} = props;

    const renderType = (props) =>{
        const {type, value, onChange} = props;

        if(type === 'input'){
            return (
                <input type="text"
                       className="form-control"
                       required
                       value={value}
                       onChange={(e) => {
                           onChange(e.target.value);
                       }}
                />
            );
        }

        if(type === 'bool'){
            return (
                <select className="form-control"
                        required
                        onChange={(e) => {
                            onChange(e.target.value);
                        }}
                >
                    <option value={''} selected={value === ''}>{__("pleaseChoose")}</option>
                    <option value={false} selected={value === false}>{__("no")}</option>
                    <option value={true} selected={value === true}>{__("yes")}</option>
                </select>
            );
        }
    };


    return (
        <div className="form-group m-t-15">
            <Label>{title}</Label>
            {renderType(props)}
        </div>
    );
}

export default Question;

Question.propTypes = {
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};
