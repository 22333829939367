import React, {Component} from 'react';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter, ButtonGroup} from 'reactstrap';
import PropTypes from "prop-types";

import Label from "../Form/Label";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {sendJobMessage} from "../../store/actions";
import {__} from "../../utils/helpers";


class SendMessageModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            input: {
                message: '',
            },
        };
    }

    submit = () => {
        const {job,} = this.props;
        const {input} = this.state;

        this.props.sendJobMessage(job.id, input);
        this.toggle();
    }

    toggle = () => {
        const {modal} = this.state;
        this.setState({
            modal: !modal,
        })
    };

    render() {
        const {
            color,
            size,
            className,
            modalClassName,
            children,
        } = this.props;

        const { modal, input } = this.state;

        return (
            <>
                <Button color={color} size={size} className={className} onClick={this.toggle}>{children}</Button>
                <Modal isOpen={modal} toggle={this.toggle} className={modalClassName}>
                    <ModalHeader toggle={this.toggle}>{__("sendMessage")}</ModalHeader>
                    <ModalBody>
                        <div className="form-group">
                            <Label>{__("message")}</Label>
                            <textarea className="form-control"
                                      value={input.message}
                                      onChange={(e) => {
                                          this.setState({
                                              input: {
                                                  ...input,
                                                  message: e.target.value
                                              }
                                          })
                                      }}
                            />
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <ButtonGroup>
                            <Button color="secondary" onClick={this.toggle}><i className="fa fa-times-circle" /> {__("close")}</Button>
                            <Button color="inverse" onClick={this.submit}><i className="fa fa-paper-plane" /> {__("send")}</Button>
                        </ButtonGroup>
                    </ModalFooter>
                </Modal>
            </>
        );
    }
}

const mapStatetoProps = (state) => {
    return {
    };
};

export default withRouter(connect(mapStatetoProps, { sendJobMessage })(SendMessageModal));


SendMessageModal.propTypes = {
    job: PropTypes.object.isRequired,
    type: PropTypes.string,
    color: PropTypes.string,
    size: PropTypes.string,
    className: PropTypes.string,
    modalClassName: PropTypes.string,
};
