import {call, takeEvery, put} from "redux-saga/effects";

import {
    createApiTestCallSucces, patchApiCallSuccess,
    setPhoneStateSuccess,
} from "./actions";

import {getApiBackend} from "../../api";

const api    = getApiBackend();

/**
 * Changes the layout type
 * @param {*} param0
 */
function* createApiTestCall({ payload: { data } }) {
    try {
        const response = yield call(api.post, '/v2/pbx/calls/test_call', data)
        yield put(createApiTestCallSucces(response));
    } catch (error) { }
}

/**
 * Changes the layout type
 * @param {*} param0
 */
function* patchApiCall({ payload: { id, data } }) {
    try {
        const response = yield call(api.patch, '/v2/pbx/calls/'+ id +'?with=logs,number,number.jobType,number.jobSource,job', data)
        yield put(patchApiCallSuccess(response));
    } catch (error) { }
}

/**
 * Changes the layout type
 * @param {*} param0
 */
function* setPhoneState({ payload: { data } }) {
    try {
        //console.log("setPhoneState", data);
        yield put(setPhoneStateSuccess(data));
    } catch (error) {
        console.log(error);
    }
}


/**
 * Watchers
 */
function* PhoneSaga() {
    yield takeEvery('SET_PHONE_STATE', setPhoneState);
    yield takeEvery('CREATE_API_TEST_CALL', createApiTestCall);
    yield takeEvery('PATCH_API_CALL', patchApiCall);
}

export default PhoneSaga;
