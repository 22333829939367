import PropTypes from "prop-types";
import {formatDisplayPhoneNumber} from "../../utils/helpers";

function Phone(props) {
    let number = props.number;


    if(number.substr(0, 1) !== '+')
        number = '+'+ number;

    const showNumber = props.showNumber === undefined || props.showNumber === true;

    return (
        <a className={"btn btn-"+ props.size +" btn-dark"} href={'tel:'+ number} style={props.style} target="_blank" rel="noreferrer">
            <i className={'fa fa-phone '+ (showNumber ? 'm-r-5' : '')} />
            {showNumber && (
                <>{formatDisplayPhoneNumber(number)}</>
            )}
        </a>
    );
}

export default Phone;

Phone.propTypes = {
    size: PropTypes.string.isRequired,
    number: PropTypes.string.isRequired,
    showNumber: PropTypes.bool,
    style: PropTypes.object,
};
