import React, { Component } from "react";
import PropTypes from 'prop-types';

class JobSourcesTagCloud extends Component {
    constructor (props) {
        super(props)

        this.state = {
            selected: [],
        }
    }

    render(){
        const {jobTypes, data, onJobSourceClick} = this.props;
        const {jobSources} = this.props.filters;

        return jobTypes.map(function(jobType){
            return (
                <div key={'jobSourceType-'+ jobType.id} style={{paddingTop: '15px', marginBottom: '15px', borderTop: '1px dotted rgb(103, 117, 124)'}}>
                    <p><small>{jobType.name}</small></p>
                    {
                        data.filter(function(jobSource){
                            if(!jobSource.type)
                                return false;

                            return jobSource.hidden === 0 && jobSource.type.id === jobType.id;
                        }).map(function(jobSource){
                            const index = jobSources.findIndex((element) => element === jobSource.id);

                            return (
                                <button key={'jobSource-'+ jobSource.id}
                                        data-value={jobSource.id}
                                        onClick={onJobSourceClick}
                                        className={'btn btn-xs btn-inline '+ (index !== -1 ? 'btn-secondary' : '')}
                                        style={{marginRight: '5px', marginBottom: '5px'}}
                                >
                                    {jobSource.name}
                                </button>
                            );
                        })
                    }
                </div>
            )
        });
    }
}

export default JobSourcesTagCloud;

JobSourcesTagCloud.propTypes = {
    onJobSourceClick: PropTypes.func.isRequired,
    data: PropTypes.array.isRequired,
    jobTypes: PropTypes.array.isRequired,
    filters: PropTypes.object.isRequired,
};