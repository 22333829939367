import React, { Component } from "react";
import { GoogleMap, Autocomplete, Marker } from '@react-google-maps/api';
import {__} from "../../utils/helpers";

class Map extends Component {
    constructor (props) {
        super(props)

        this.state = {
            mainMarker: null,
        };

        this.map = null;
        this.autocomplete = null
    }

    onMapLoaded = (map) => {
        this.map = map;
    }

    onAutocompleteLoaded = (autocomplete) => {
        const {autocompleteAutoFocus} = this.props;
        this.autocomplete = autocomplete;

        if(autocompleteAutoFocus !== undefined)
            this.autocompleteInput.focus();
    }

    getAddress(place) {
        const address = {
            country: '',
            zipcode: '',
            city: '',
            street: '',
            house: '',
        }
        const componentForm = {
            street_number: 'short_name',
            route: 'long_name',
            locality: 'long_name',
            administrative_area_level_3: 'short_name',
            country: 'short_name',
            postal_code: 'short_name'
        };

        for (let i = 0; i < place.address_components.length; i++) {
            let addressType = place.address_components[i].types[0];

            if (componentForm[addressType]) {
                let val = place.address_components[i][componentForm[addressType]];

                // eslint-disable-next-line default-case
                switch(addressType){
                    case 'route':
                        address.street = val;
                        break;
                    case 'street_number':
                        address.house = val;
                        break;
                    case 'locality':
                        address.city = val;
                        break;
                    case 'postal_code':
                        address.zipcode = val;
                        break;
                    case 'country':
                        address.country = val.toLowerCase();
                        break;
                }
            }
        }

        return address;
    }

    onPlaceChanged = () => {
        if (this.autocomplete !== null) {
            const place = this.autocomplete.getPlace();

            if(this.props.onPlaceChanged !== undefined)
                this.props.onPlaceChanged(place);

            if(this.props.onAddressChanged !== undefined)
                this.props.onAddressChanged(this.getAddress(place), place);

            this.setState({
                mainMarker: place,
            })

            if (place.geometry.viewport) {
                this.map.fitBounds(place.geometry.viewport);
            } else {
                this.map.setCenter(place.geometry.location);
                this.map.setZoom(17);  // Why 17? Because it looks good.
            }
        } else {
            console.log('Autocomplete is not loaded yet!')
        }
    }

    render () {
        const {show, center, restrictions, autocompleteInputStyle, mapContainerStyle} = this.props;
        const {mainMarker} = this.state;

        return (
           <>
                <GoogleMap
                    id="searchbox-example"
                    mapContainerStyle={mapContainerStyle}
                    zoom={5}
                    center={center}
                    onLoad={this.onMapLoaded}
                >
                    {mainMarker && (
                        <Marker position={mainMarker.geometry.location}/>
                    )}

                </GoogleMap>
                { show.autocomplete && (
                    <Autocomplete
                        types={['geocode','establishment']}
                        restrictions={restrictions}
                        onLoad={this.onAutocompleteLoaded}
                        onPlaceChanged={this.onPlaceChanged}
                    >
                        <input
                            className="form-control form-control-lg"
                            type="text"
                            placeholder={__("pleaseFillAddress")}
                            style={autocompleteInputStyle}
                            ref={(autocompleteInput) => { this.autocompleteInput = autocompleteInput; }}
                        />
                    </Autocomplete>
                )}
            </>
        )
    }
}

export default Map;