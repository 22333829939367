import {call, put, takeLatest} from "redux-saga/effects";

import {
    getFitterSuccess, getFittersSuccess, getFittersByDistanceSuccess
} from "./actions";

import {getApiBackend} from "../../api";

const api    = getApiBackend();
const fitter_relations = 'status,system,locations,statusHistory';

/**
 * Changes the layout type
 * @param {*} param0
 */
function* getFitter({ payload: { id } }) {
    try {
        const response = yield call(api.get, '/v2/fitters/'+ id +'?with='+ fitter_relations)
        yield put(getFitterSuccess(response));

    } catch (error) { }
}

/**
 * Changes the layout type
 * @param {*} param0
 */
function* getFitters() {
    try {
        const response = yield call(api.get, '/v2/fitters?per_page=1000&with='+ fitter_relations)

        yield put(getFittersSuccess(response));

    } catch (error) { }
}

/**
 * Changes the layout type
 * @param {*} param0
 */
function* getFittersByDistance({ payload: { job_type_id, job_source_id, location, country } }) {
    try {
        const response = yield call(api.get, '/v1/fitters/distance?with=system&job_type_id='+ job_type_id
            +'&source_id='+ job_source_id
            +'&lat='+ location.lat
            +'&lng='+ location.lng
            +'&country='+ country)

        yield put(getFittersByDistanceSuccess(response));

    } catch (error) { }
}

/**
 * Watchers
 */

function* FittersSaga() {
    yield takeLatest('GET_FITTER', getFitter);
    yield takeLatest('GET_FITTERS', getFitters);
    yield takeLatest('GET_FITTERS_BY_DISTANCE', getFittersByDistance);
}

export default FittersSaga;
