import { call, takeEvery, put } from "redux-saga/effects";

import {
    getJobSourceSuccess,
    getJobSourcesSuccess
} from "./actions";

import {getApiBackend} from "../../api";

const api    = getApiBackend();

/**
 * Changes the layout type
 * @param {*} param0
 */
function* getJobSource({ payload: { id } }) {
    try {
        const response = yield call(api.get, '/v2/jobs/sources/'+ id)
        yield put(getJobSourceSuccess(response));

    } catch (error) { }
}

/**
 * Changes the layout type
 * @param {*} param0
 */
function* getJobSources() {
    try {
        const response = yield call(api.get, '/v2/jobs/sources?per_page=999&with=type,contractor')

        yield put(getJobSourcesSuccess(response));

    } catch (error) { }
}

/**
 * Watchers
 */

function* JobSourcesSaga() {
    yield takeEvery('GET_JOB_SOURCE', getJobSource);
    yield takeEvery('GET_JOB_SOURCES', getJobSources);


}

export default JobSourcesSaga;
