// @flow
import {
    GET_COMMISSIONS_OVERVIEW, GET_COMMISSIONS_OVERVIEW_SUCCESS,
    GET_COMMISSIONS_SUMMARY,
    GET_COMMISSIONS_SUMMARY_SUCCESS,
    GET_COMMISSIONS_SUMMARY_SUCCESS_WS,
} from "./actionTypes";

const INIT_STATE = {
    error: null,
    commissionsSummary: null,
    commissionsSummaryLoading: false,
    commissionsOverview: null,
    commissionsOverviewLoading: false,
};

const Commissions = (state = INIT_STATE, action) => {

    switch (action.type) {
        case GET_COMMISSIONS_SUMMARY:
            return {
                ...state,
                commissionsSummaryLoading: true,
            }
        case GET_COMMISSIONS_SUMMARY_SUCCESS:
            return {
                ...state,
                commissionsSummaryLoading: false,
                commissionsSummary: action.payload,
            }
        case GET_COMMISSIONS_SUMMARY_SUCCESS_WS:
            return {
                ...state,
                commissionsSummary: action.payload.data.data,
            }
        case GET_COMMISSIONS_OVERVIEW:
            return {
                ...state,
                commissionsOverviewLoading: true,
            }
        case GET_COMMISSIONS_OVERVIEW_SUCCESS:
            return {
                ...state,
                commissionsOverviewLoading: false,
                commissionsOverview: action.payload,
            }
        default:
            return state;
    }
};


export default Commissions;
