import React, { Component } from "react";
import PropTypes from "prop-types";
import {ButtonDropdown, ButtonGroup, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";
import {__} from "../../utils/helpers";

class CountriesSelect extends Component {
    constructor (props) {
        super(props)

        this.state = {
            dropdownOpen: false,
        }
    }

    toggleOpen = () => {
        const {dropdownOpen} = this.state;

        this.setState({
            dropdownOpen: !dropdownOpen,
        })
    }

    renderSelect(){
        const {data, filters, onCountryClick} = this.props;
        const {countries} = filters;

        if(data === undefined)
            return "";

        return data.map(function(country, key) {
            const index = countries.findIndex((element) => element === country.code);
            return (
                <DropdownItem key={'country-' + country.code} data-value={country.code} className={'dropdown-item '+ (index !== -1 ? 'active' : '')}
                     onClick={onCountryClick}>
                    <span className={country.icon + ' m-r-10'} /> {country.name}
                </DropdownItem>
            );

        });

    }

    render(){
        const {filters, onClearCountries} = this.props;
        const {dropdownOpen} = this.state;

        return (
            <ButtonGroup>
                <ButtonDropdown isOpen={dropdownOpen} toggle={this.toggleOpen}>
                    <DropdownToggle caret className={'btn-'+ (filters.countries.length === 0 ? 'secondary' : 'dark')}>
                        {__("country")}
                    </DropdownToggle>
                    <DropdownMenu>
                        { this.renderSelect() }
                    </DropdownMenu>
                </ButtonDropdown>
                <button type="button" onClick={onClearCountries} className={'btn btn-'+ (filters.countries.length === 0 ? 'secondary' : 'dark') +''} disabled={filters.countries.length === 0}>
                    <i className={'fa fa-times'} />
                </button>
            </ButtonGroup>
        )
    }
}

export default CountriesSelect;

CountriesSelect.propTypes = {
    onClearCountries: PropTypes.func.isRequired,
    onCountryClick: PropTypes.func.isRequired,
    filters: PropTypes.object.isRequired,
};