// @flow
import {
    GET_COMPLIANCE,
    GET_COMPLIANCE_SUCCESS,
    GET_COMPLIANCES,
    GET_COMPLIANCES_SUCCESS,
    GET_COMPLIANCE_RANDOM_JOB,
    GET_COMPLIANCE_JOB_SUCCESS,
    GET_COMPLIANCE_RANDOM_JOB_SUCCESS,
    RESET_COMPLIANCE_RANDOM_JOB_SUCCESS,
    GET_COMPLIANCE_JOBS_SUCCESS,
    GET_COMPLIANCE_JOBS,
    GET_COMPLIANCE_JOB,
    GET_CLAIM_JOB,
    GET_CLAIM_JOB_SUCCESS,
    GET_CLAIM_JOBS,
    GET_CLAIM_JOBS_SUCCESS,
    GET_CLAIM_TEMPLATES,
    GET_CLAIM_TEMPLATES_SUCCESS,
    ADD_COMPLIANCE_JOB_SUCCESS,
    ADD_COMPLIANCE_JOB_ERROR,
    ADD_COMPLIANCE_JOB,
    SAVE_COMPLIANCE_JOB_QUESTIONS,
    SAVE_COMPLIANCE_JOB_QUESTIONS_SUCCESS, GET_CLAIM_PRIORITIES, GET_CLAIM_PRIORITIES_SUCCESS,
} from "./actionTypes";
import {GET_JOB_SUCCESS_WS} from "../jobs/actionTypes";
import { toast } from 'react-toastify';
import history from "../../routes/history";

const INIT_STATE = {
    error: null,
    loading: false,

    compliances: [],
    compliancesLoading: false,
    compliance: null,

    addComplianceJobLoading: false,

    complianceJobsLoading: false,
    complianceJobs: {
        data: [],
        meta: {
            current_page: 0,
            from: 0,
            last_page: 0,
            per_page: 0,
            to: 0,
            total: 0,
            path: '',
        }
    },

    complianceJob: null,
    complianceJobLoading: false,

    claimTemplates: [],
    claimTemplatesLoading: false,

    claimPriorities: [],
    claimPrioritiesLoading: false,

    randomJobLoading: false,
    randomJob: null,

    claimJobsLoading: false,
    claimJobs: {
        data: [],
        meta: {
            current_page: 0,
            from: 0,
            last_page: 0,
            per_page: 0,
            to: 0,
            total: 0,
            path: '',
        }
    },

    claimJob: null,
    claimJobLoading: false,

};

const Compliances = (state = INIT_STATE, action) => {

    let compliance, index, complianceJob, complianceJobs, claimJobs;

    switch (action.type) {

        case GET_COMPLIANCE:
            compliance = state.compliances.find((element) => {
                return element.id === action.payload.id;
            });

            if(compliance === undefined)
                compliance = null;

            return {
                ...state,
                loading: true,
                compliance: compliance,
                //tasks: []
            };
        case GET_COMPLIANCE_SUCCESS:
            return {
                ...state,
                loading: false,
                compliance: state.compliances.map(function(item){
                    if(item.id !== action.payload.id){
                        return item;
                    }

                    return action.payload;
                })
            };

        case GET_COMPLIANCES:
            return {
                ...state,
                loading: true,
                compliancesLoading: true,
            };
        case GET_COMPLIANCES_SUCCESS:
            return {
                ...state,
                loading: false,
                compliancesLoading: false,
                compliances: action.payload
            };
        case SAVE_COMPLIANCE_JOB_QUESTIONS:
            return {
                ...state,
                complianceJobLoading: true,
            };
        case SAVE_COMPLIANCE_JOB_QUESTIONS_SUCCESS:
            complianceJob   = state.complianceJob;
            complianceJobs  = state.complianceJobs;

            if(complianceJob !== null && complianceJob.id === action.payload.id){
                //console.log("SAVE_COMPLIANCE_JOB_QUESTIONS_SUCCESS", action.payload);

                complianceJob = action.payload;
            }

            if(action.payload !== null){
                index = complianceJobs.data.findIndex(function(item){
                    return item.id === action.payload.id;
                });

                if(index !== -1){
                    complianceJobs.data[index] = action.payload;
                }
            }

            return {
                ...state,
                complianceJobLoading: false,
                complianceJob,
                complianceJobs,
            };
        case RESET_COMPLIANCE_RANDOM_JOB_SUCCESS:
            //console.log('RESET_COMPLIANCE_RANDOM_JOB_SUCCESS');
            return {
                ...state,
                randomJob: null,
            };
        case GET_COMPLIANCE_RANDOM_JOB:
            return {
                ...state,
                randomJobLoading: true,
                randomJob: null,
            };
        case GET_COMPLIANCE_RANDOM_JOB_SUCCESS:
            //console.log("GET_COMPLIANCE_RANDOM_JOB_SUCCESS", action.payload);

            return {
                ...state,
                randomJobLoading: false,
                randomJob: action.payload,
            };
        case ADD_COMPLIANCE_JOB:
            return {
                ...state,
                addComplianceJobLoading: true,
            };
        case ADD_COMPLIANCE_JOB_SUCCESS:
            //("ADD_COMPLIANCE_JOB_SUCCESS");
            history.push('/compliances/'+ action.compliance_id +'/jobs/'+ action.payload.id);
            window.location = '/compliances/'+ action.compliance_id +'/jobs/'+ action.payload.id;
            return {
                ...state,
                addComplianceJobLoading: false,
            };
        case ADD_COMPLIANCE_JOB_ERROR:
            //console.log("ADD_COMPLIANCE_JOB_ERROR", action.payload);
            toast.error(action.payload.errors[0].userMessage);
            return {
                ...state,
                addComplianceJobLoading: false,
            };
        case GET_COMPLIANCE_JOB:
            return {
                ...state,
                complianceJobLoading: true,
                complianceJob: null,
            };
        case GET_COMPLIANCE_JOB_SUCCESS:
            //console.log("GET_COMPLIANCE_JOB_SUCCESS", action.payload);
            complianceJobs = state.complianceJobs;

            if(action.payload !== null){
                index = complianceJobs.data.findIndex(function(item){
                    return item.id === action.payload.id;
                });

                if(index !== -1){
                    complianceJobs.data[index] = action.payload;
                }
            }

            return {
                ...state,
                complianceJobLoading: false,
                complianceJob: action.payload,
                complianceJobs,
            };

        case GET_JOB_SUCCESS_WS:
            complianceJob = state.complianceJob;
            complianceJobs = state.complianceJobs;

            if(complianceJob && complianceJob.id === action.payload.data.job.id){
                console.log("GET_JOB_SUCCESS_WS", complianceJob.id, action.payload.data.job.id);
                complianceJob = action.payload.data.job;
            }

            index = complianceJobs.data.findIndex(function(item){
                return item.id === action.payload.data.job.id;
            });

            if(index !== -1){
                complianceJobs.data[index] = action.payload.data.job;
            }

            return {
                ...state,
                complianceJob,
                complianceJobs,
            };
        case GET_COMPLIANCE_JOBS:
            return {
                ...state,
                complianceJobsLoading: true,
            };
        case GET_COMPLIANCE_JOBS_SUCCESS:
            return {
                ...state,
                complianceJobs: action.payload,
                complianceJobsLoading: false,
            };

        case GET_CLAIM_JOB:
            return {
                ...state,
                claimJobLoading: true,
                claimJob: null,
            };
        case GET_CLAIM_JOB_SUCCESS:

            claimJobs = state.complianceJobs;

            index = claimJobs.data.findIndex(function(item){
                return item.id === action.payload.id;
            });

            if(index !== -1){
                claimJobs.data[index] = action.payload;
            }

            return {
                ...state,
                claimJobLoading: false,
                claimJob: action.payload,
                claimJobs,
            };
        case GET_CLAIM_JOBS:
            return {
                ...state,
                claimJobsLoading: true,
            };
        case GET_CLAIM_JOBS_SUCCESS:
            //console.log(action.payload);
            return {
                ...state,
                claimJobs: action.payload,
                claimJobsLoading: false,
            };

        case GET_CLAIM_TEMPLATES:
            return {
                ...state,
                claimTemplatesLoading: true,
            };
        case GET_CLAIM_TEMPLATES_SUCCESS:
            return {
                ...state,
                claimTemplates: action.payload.data,
                claimTemplatesLoading: false,
            };

        case GET_CLAIM_PRIORITIES:
            return {
                ...state,
                claimPrioritiesLoading: true,
            };
        case GET_CLAIM_PRIORITIES_SUCCESS:
            return {
                ...state,
                claimPriorities: action.payload.data,
                claimPrioritiesLoading: false,
            };


        default:
            return state;
    }
};

export default Compliances;
