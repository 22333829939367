import {runsInElectron} from "../../utils/helpers";

export class ipcRenderer {
//window.require('electron').ipcRenderer.send(channel, ...args);
    static send(channel, ...args){
        if(runsInElectron())
            window.require('electron').ipcRenderer.send(channel, ...args);
    }
//return window.require('electron').ipcRenderer.sendSync(channel, ...args);
    static sendSync(channel, ...args){
        if(runsInElectron())
            return window.require('electron').ipcRenderer.sendSync(channel, ...args);

        return null;
    }

}