import { takeEvery, put } from "redux-saga/effects";

import {
    setPageTitleSuccess,
    setBreadCrumbsSuccess
} from "./actions";

/**
 * Changes the layout type
 * @param {*} param0
 */
function* setPageTitle({ payload: { title } }) {
    yield put(setPageTitleSuccess(title));
}

/**
 * Changes the layout type
 * @param {*} param0
 */
function* setBreadCrumbs({ payload: { breadcrumbs } }) {
    try {
        yield put(setBreadCrumbsSuccess(breadcrumbs));

    } catch (error) { }
}

/**
 * Watchers
 */

function* LayoutSaga() {
    yield takeEvery('SET_PAGE_TITLE', setPageTitle);
    yield takeEvery('SET_BREAD_CRUMBS', setBreadCrumbs);


}

export default LayoutSaga;
