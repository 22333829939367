import { call, takeEvery, takeLatest, takeLeading, put } from "redux-saga/effects";
import queryString from 'query-string'
import { flatten } from 'q-flat'
import {
    getCompliancesSuccess,
    getComplianceSuccess,
    getComplianceJobSuccess,
    getComplianceJobsSuccess,
    getComplianceRandomJobSuccess,
    saveComplianceJobQuestionsSuccess,
    resetComplianceRandomJobSuccess,
    getClaimJobSuccess,
    getClaimJobsSuccess,
    getClaimTemplatesSuccess,
    addComplianceJobSuccess,
    addComplianceJobError,
    getClaimPrioritiesSuccess,
} from "./actions";

import {getApiBackend} from "../../api";

const api    = getApiBackend();
const compliance_relations = 'questions';
const job_relations = 'status,statusHistory,type,source,fitter,fitter.system,calls,messagesSent,partialPayments,compliances,compliance_dialogues,compliance_dialogues.answers,claims';
/**
 * Changes the layout type
 * @param {*} param0
 */
function* getCompliances() {
    try {
        const response = yield call(api.get, '/v2/compliances?with='+ compliance_relations)
        yield put(getCompliancesSuccess(response));

    } catch (error) { }
}

/**
 * Changes the layout type
 * @param {*} param0
 */
function* getCompliance({ payload: { id } }) {
    try {
        const response = yield call(api.get, '/v2/compliances/'+ id +'?with='+ compliance_relations)
        yield put(getComplianceSuccess(response));

    } catch (error) { }
}

/**
 * Changes the layout type
 * @param {*} param0
 */
function* getComplianceRandomJob({ payload: { id } }) {
    try {
        const response = yield call(api.get, '/v2/compliances/'+ id +'/jobs/random?with='+ job_relations)

        //yield put(getJobSuccess(response));
        yield put(getComplianceRandomJobSuccess(response));
        yield put(getComplianceJobSuccess(id, response));

    } catch (error) { }
}

/**
 * Changes the layout type
 * @param {*} param0
 */
function* resetComplianceRandomJob({ payload: { id } }) {
    yield put(resetComplianceRandomJobSuccess());
}

/**
 * Changes the layout type
 * @param {*} param0
 */
function* addComplianceJob({ payload: { id, code } }) {
    try {
        const response = yield call(api.post, '/v2/compliances/' + id + '/jobs', {code})
        yield put(addComplianceJobSuccess(id, response));
    } catch (error) {
        yield put(addComplianceJobError(id, error));
    }
}

/**
 * Changes the layout type
 * @param {*} param0
 */
function* getComplianceJob({ payload: { id, job_id } }) {
    try {
        const response = yield call(api.get, '/v2/compliances/'+ id +'/jobs/'+ job_id +'?with='+ job_relations)

        yield put(getComplianceJobSuccess(id, response));

    } catch (error) { }
}

/**
 * Changes the layout type
 * @param {*} param0
 */
function* getComplianceJobs({ payload: { id, type, meta } }) {
    try {
        const response = yield call(api.get, '/v2/compliances/'+ id +'/jobs?states='+ type +'&with='+ job_relations +'&'+ queryString.stringify(flatten(meta), {encode:false}))

        yield put(getComplianceJobsSuccess(id, type, response));

    } catch (error) { }
}

/**
 * Changes the layout type
 * @param {*} param0
 */
function* saveComplianceJobQuestions({ payload: { id, job_id, data } }) {
    try {
        const response = yield call(api.post, '/v2/compliances/'+ id +'/jobs/'+ job_id +'/questions?with='+ job_relations, {
            data
        })

        //yield put(getJobSuccess(response));
        //yield put(getComplianceJobSuccess(id, response));
        yield put(saveComplianceJobQuestionsSuccess(response));

    } catch (error) { }
}


/**
 * Changes the layout type
 * @param {*} param0
 */
function* getClaimJob({ payload: { id } }) {
    try {
        const response = yield call(api.get, '/v2/compliances/claims/jobs/'+ id +'?with='+ job_relations)
        yield put(getClaimJobSuccess(id, response));

    } catch (error) { }
}

/**
 * Changes the layout type
 * @param {*} param0
 */
function* getClaimJobs({ payload: { meta } }) {
    try {
        const response = yield call(api.get, '/v2/compliances/claims/jobs?with='+ job_relations +'&'+ queryString.stringify(flatten(meta), {encode:false}))

        yield put(getClaimJobsSuccess(response));

    } catch (error) { }
}

/**
 * Changes the layout type
 */
function* getClaimTemplates() {
    try {
        const response = yield call(api.get, '/v2/compliances/claims/templates')

        yield put(getClaimTemplatesSuccess(response));

    } catch (error) { }
}

/**
 * Changes the layout type
 */
function* getClaimPriorities() {
    try {
        const response = yield call(api.get, '/v2/compliances/claims/priorities')

        yield put(getClaimPrioritiesSuccess(response));

    } catch (error) { }
}

/**
 * Watchers
 */

function* ComplianceSaga() {
    yield takeEvery('GET_COMPLIANCES', getCompliances);
    yield takeEvery('GET_COMPLIANCE', getCompliance);
    yield takeEvery('GET_COMPLIANCE_RANDOM_JOB', getComplianceRandomJob);
    yield takeEvery('RESET_COMPLIANCE_RANDOM_JOB', resetComplianceRandomJob);
    yield takeLeading('ADD_COMPLIANCE_JOB', addComplianceJob);
    yield takeEvery('GET_COMPLIANCE_JOB', getComplianceJob);
    yield takeLatest('GET_COMPLIANCE_JOBS', getComplianceJobs);
    yield takeLeading('SAVE_COMPLIANCE_JOB_QUESTIONS', saveComplianceJobQuestions);
    yield takeEvery('GET_CLAIM_JOB', getClaimJob);
    yield takeLatest('GET_CLAIM_JOBS', getClaimJobs);
    yield takeLatest('GET_CLAIM_TEMPLATES', getClaimTemplates);
    yield takeLatest('GET_CLAIM_PRIORITIES', getClaimPriorities);
}

export default ComplianceSaga;
