import React, { Component } from "react";
import {__} from "../../../utils/helpers";
import JobTableRow from "./components/JobTableRow";
import { Code } from 'react-content-loader';
const MyCodeLoader = () => <Code />;

class CancellationJobs extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    renderJobs(){
        const {setJob, jobs} = this.props;
        return jobs.map((job, key) =>
            <JobTableRow type={'cancellation'} job={job} key={'job-'+ job.id} setJob={setJob}/>
        );
    }

    render() {
        const {loading, jobs} = this.props;
        const boxStyle = {
            height: '290px',
            overflow: 'auto'
        };
        return (
            <div className="card">
                <div className="card-body">
                    <h4 className="card-title">
                        {__('canceled')}
                        { loading === false && jobs.length > 0 ? (
                            <span className="label label-danger float-right">{jobs.length}</span>
                        ) : '' }
                    </h4>
                </div>

                <div className="comment-widgets m-b-0" style={boxStyle}>
                    { loading === true ? (
                        <div className={'m-3'}>
                            <MyCodeLoader />
                        </div>
                        ) : (
                            <div>
                                { jobs.length === 0 ? (
                                    <div className={'text-center'} style={{marginTop: '80px'}}>
                                        <i className={'fa fa-check-circle text-success'} style={{fontSize: '100px'}} />
                                    </div>
                                ) : (
                                    <table className={'table table-hover'}>
                                        <tbody>
                                        { this.renderJobs() }
                                        </tbody>
                                    </table>
                                    )
                                }
                            </div>
                        )
                    }
                </div>
            </div>
        );
    }
}

export default CancellationJobs;
