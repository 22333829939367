import React, {Component} from 'react';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter, ButtonGroup} from 'reactstrap';
import PropTypes from "prop-types";
import moment from "moment";
import {__} from "../../utils/helpers";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {patchJobAppointment} from "../../store/actions";
import JobAppointmentSelect from "../Form/JobAppointmentSelect";
import Label from "../Form/Label";


class ChangeAppointmentModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            input: {
                appointment: false,
                handle_datetime: null,
                notification: true,
            },
            jobData: {

            }
        };
    }

    submit = () => {
        const {job} = this.props;
        const {input} = this.state;

        this.props.patchJobAppointment(job.id, input);
        this.toggle();
    }

    toggle = () => {
        const {modal, input} = this.state;
        const {job} = this.props;

        const newState = {
            modal: !modal,
            input,
        };

        if(!modal) {
            newState.input = {
                ...input,
                appointment: job.handle.type !== 'now',
                handle_datetime: job.handle.type !== 'now' ? moment(job.handle.datetime).format('DD.MM.YYYY HH:mm') : '',
            }
        }

        this.setState(newState)
    };

    renderNotificationDropdown = () => {
        const states = [
            {
                type: true,
                label: __('yes'),
            },
            {
                type: false,
                label: __('no'),
            },

        ];
        return states.map((state) => {
            return (
                <option key={'send-notification-'+ state.type} value={state.type}>{state.label}</option>
            );
        });
    }

    render() {
        const {
            color,
            size,
            className,
            modalClassName,
            children,
        } = this.props;

        const { modal, input } = this.state;

        let title = __("changeAppointment");

        return (
            <>
                <Button color={color} size={size} className={className} onClick={this.toggle}>{children}</Button>
                <Modal isOpen={modal} toggle={this.toggle} className={modalClassName}>
                    <ModalHeader toggle={this.toggle}>{title}</ModalHeader>
                    <ModalBody>

                        <div className="form-group">
                            <Label>{__("sendNotification")}</Label>
                            <select className="form-control"
                                    value={input.type}
                                    onChange={(e) => {
                                        this.setState({
                                            input: {
                                                ...input,
                                                notification: e.target.value === 'true'
                                            }
                                        })
                                    }}>
                                {this.renderNotificationDropdown()}
                            </select>
                        </div>

                        <JobAppointmentSelect appointment={input.appointment}
                                              onAppointmentChange={(value) => {
                                                  this.setState({
                                                      input: {
                                                          ...input,
                                                          appointment: value,
                                                      }
                                                  })
                                              }}
                                              datetime={input.handle_datetime}
                                              onDatetimeChange={(value) => {
                                                  this.setState({
                                                      input: {
                                                          ...input,
                                                          handle_datetime: value,
                                                      }
                                                  })
                                              }}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <ButtonGroup>
                            <Button color="secondary" onClick={this.toggle}><i className="fa fa-times-circle" /> {__("close")}</Button>
                            <Button color="inverse" onClick={this.submit}><i className="fa fa-save" /> {__("save")}</Button>
                        </ButtonGroup>
                    </ModalFooter>
                </Modal>
            </>
        );
    }
}

const mapStatetoProps = (state) => {
    return {
    };
};

export default withRouter(connect(mapStatetoProps, { patchJobAppointment })(ChangeAppointmentModal));


ChangeAppointmentModal.propTypes = {
    job: PropTypes.object.isRequired,
    type: PropTypes.string,
    color: PropTypes.string,
    size: PropTypes.string,
    className: PropTypes.string,
    modalClassName: PropTypes.string,
};
