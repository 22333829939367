import BriaConnector from "../../lib/Bria/BriaConnector";

class BriaCall {
    constructor(data) {
        this.id = data.id && data.id;
        this.api_call_id = data.api_call_id && data.api_call_id;
        this.hold = data.hold && data.hold;
        this.startTime = data.startTime && data.startTime;
        this.duration = data.duration && data.duration;
        this.phoneNumber = data.phoneNumber && data.phoneNumber;
        this.displayPhoneNumber = data.phoneNumber && BriaConnector.parseDisplayPhoneNumber(this.phoneNumber);
        this.displayName = data.displayName && data.displayName;
        this.state = data.state && data.state;
        this.type = data.type && data.type;
        this.callAction = data.callAction && data.callAction;
        this.options = data.options && data.options;
        this.participants = data.participants && data.participants;
        this.csi = data.csi && data.csi;
    }
}

export default BriaCall;