import React, { Component } from "react";
import Moment from "react-moment";
import 'moment/locale/de';
import StatusBadge from "./StatusBadge";
import {formatCurrencyInput, stateColor, __} from "../../utils/helpers"
import Label from "../Form/Label";
import {Button, ButtonGroup} from "reactstrap";
import AddPartialPaymentModal from "./AddPartialPaymentModal";
import SelectFitterModal from "./SelectFitterModal";
import {Link} from "react-router-dom";
import User from "../../models/User";
import ChangeAppointmentModal from "./ChangeAppointmentModal";

class OverviewCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editSales:  false,
            input: {
                sales: {
                    paid: {
                        total: null,
                        material: null,
                    },
                }
            }
        };

        this.totalPaidInput = null;
    }

    renderPartialPaymentTable(){
        const { job, deleteJobPartialPayment, jobPartialPaymentsLoading, readonly } = this.props;

        if(!job.partial_payments || job.partial_payments.length === 0)
            return "";

        const rows = job.partial_payments.map(function(item){
            return (
                <tr key={'partial-payment-'+ item.id}>
                    <td>
                        <Moment date={item.created} format="DD.MM.YYYY" />
                    </td>
                    <td>
                        {formatCurrencyInput(item.amount)} €
                    </td>
                    <td>
                        {!readonly && (
                            <button className={'btn btn-xs '+ (item.is_removeable === false || jobPartialPaymentsLoading === true ? 'btn-outline-inverse' : 'btn-inverse')}
                                    disabled={item.is_removable === false || jobPartialPaymentsLoading === true}
                                    onClick={() => {
                                        deleteJobPartialPayment(job.id, item.id);
                                    }}>
                                <i className="fa fa-times" />
                            </button>
                        )}

                    </td>
                </tr>
            )
        });

        return (
            <table className="table table-hover customize-table v-top m-b-0">
                <thead className="table-dark">
                <tr>
                    <th>{__("date")}</th>
                    <th>{__("amount")}</th>
                    <th width="1%" />
                </tr>
                </thead>
                <tbody>
                {rows}
                </tbody>
            </table>
        );
    }

    render() {
        const { user, job, readonly, patchJobLoading, jobPartialPaymentsLoading } = this.props;
        let {editSales, input} = this.state;

        const userObj = new User(user);

        if(editSales === true && userObj.hasPermission(['edit job sales', 'edit job_partial_payment']) === false)
            editSales = false;
        
        return (
            <div className="card">
                <div className={"card-body bg-light-"+ stateColor(job.status.status)}>
                    <center className="m-t-10">
                        <div>
                            <i className={job.type.icon} style={{fontSize: '80px'}} />
                        </div>
                        <h4 className="card-title m-t-10">
                            {job.type.name}
                        </h4>
                        <h6 className="card-subtitle">
                            { job.source ? job.source.name : 'Fremdauftrag'}
                        </h6>
                        <div className="row text-center justify-content-md-center">
                            <div className="col-4">
                                <span className="link">
                                    <i className="fa fa-hashtag m-r-5" />
                                    <font className="font-medium">{job.code}</font>
                                </span>
                            </div>
                            <div className="col-4">
                                <span className="link">
                                    <font className="font-medium">
                                        <StatusBadge border state={job.status.status || 'unknown'} />
                                    </font>
                                </span>
                            </div>
                        </div>
                    </center>
                </div>
                <div>
                    <hr style={{marginTop: 0}} />
                </div>

                <div className="card-body">
                    <div className="row">
                        <div className="col-md-6">
                            <Label>{__("created")}</Label>
                            <h6>
                                <Moment date={job.created} format="DD.MM.YYYY" />, <Moment date={job.created} format="HH:mm" /> Uhr
                            </h6>
                        </div>
                        <div className="col-md-6">
                            <Label>{__("appointment")}</Label>
                            <h6>
                                { job.handle.type === 'now' ? (
                                    <span>{__("no")}</span>
                                ) : (
                                    <span>
                                <Moment date={job.handle.datetime} format="DD.MM.YYYY" />, <Moment date={job.handle.datetime} format="HH:mm" /> Uhr
                            </span>
                                ) }
                            </h6>
                            <ButtonGroup>
                                <ChangeAppointmentModal job={job} size="xs" type="comment"><i className="fa fa-calendar" /> {__("changeAppointment")}</ChangeAppointmentModal>
                            </ButtonGroup>
                        </div>
                    </div>

                    <Label className="p-t-30 d-block">{__("customer")}</Label>
                    <h6>{job.contact.name}</h6>

                    <div className="row">
                        <div className="col-md-6">
                            <Label className="p-t-30 d-block">{__("phoneNumber")}</Label>
                            <h6>
                                <a href={'tel:+'+ job.contact.phone}>
                                    {job.contact.phone}
                                </a>
                            </h6>
                        </div>
                        <div className="col-md-6">
                            {job.contact.phone_alternate !== '' ? (
                                <>
                                    <Label className="p-t-30 d-block">{__("alternatePhoneNumber")}</Label>
                                    <h6>
                                        <a href={'tel:+'+ job.contact.phone}>
                                            {job.contact.phone_alternate}
                                        </a>
                                    </h6>
                                </>
                            ) : ''}
                        </div>
                    </div>

                    {job.contact.email !== '' ? (
                        <>
                            <Label className="p-t-30 d-block">{__("emailAddress")}</Label>
                            <h6>
                                {job.contact.email}
                            </h6>
                        </>
                    ) : ''}


                    <Label className="p-t-30 d-block">{__("address")}</Label>
                    <h6>
                        <span className={'d-block'}>
                            {job.address.street} {job.address.house}
                        </span>
                        <span className={'m-t-5 d-block'}>
                            {job.address.country.toUpperCase()}-{job.address.zipcode} {job.address.city}
                        </span>
                    </h6>
                </div>

                <div>
                    <hr />
                </div>
                <div className={'card-body'}>
                    <div className=" d-flex justify-content-between align-items-center m-b-30">
                        <h4 className="card-title m-b-0">{__("partner")}</h4>
                        <ButtonGroup>
                            <Link to={"/fitters/"+ job.fitter.id} className={'btn btn-secondary btn-xs'}>
                                <i className={'fa fa-eye'} />
                            </Link>
                            <SelectFitterModal color="secondary" size="xs" job={job}>
                                <i className={'fa '+ (!patchJobLoading ? 'fa-exchange-alt' : 'fa-spin fa-spinner')} />
                            </SelectFitterModal>
                        </ButtonGroup>
                    </div>

                    {job.fitter && job.fitter.system && (
                        <>
                            <Label>{__("central")}</Label>
                            <h6>{job.fitter && job.fitter.system && job.fitter.system.name}</h6>
                        </>
                    )}
                    {job.fitter && (
                        <>
                            <Label className="p-t-30 d-block">{__("partner")}</Label>
                            <h6>{job.fitter && job.fitter.display_name}</h6>
                        </>
                    )}
                </div>

                { userObj.hasPermission('view job sales') && (
                    <>
                        <div>
                            <hr />
                        </div>
                        <div className="card-body">
                            <div className="d-flex justify-content-between align-items-center m-b-30">
                                <h4 className="card-title m-b-0">{__("sales")}</h4>
                                <ButtonGroup>
                                    { !readonly && job.sales.partial_payment < job.sales.paid.total && (
                                        <AddPartialPaymentModal color="secondary" size="xs" job={job}>
                                            <i className={'fa '+ (jobPartialPaymentsLoading ? 'fa-spin fa-spinner' : 'fa-plus')} /> {__("partialPayment")}
                                        </AddPartialPaymentModal>
                                    )}
                                    {editSales && (
                                        <Button size="xs"
                                                color="dark"
                                                onClick={() => {
                                                    this.props.patchJob(job.id, {
                                                        sales: input.sales,
                                                    });

                                                    const {editSales} = this.state;
                                                    this.setState({
                                                        editSales: !editSales,
                                                    });
                                                }}>
                                            <i className="fa fa-save" /> {__("save")}
                                        </Button>
                                    )}
                                    <Button size="xs"
                                            outline={editSales}
                                            color="inverse"
                                            onClick={() => {
                                                const {editSales} = this.state;
                                                this.setState({
                                                    editSales: !editSales,
                                                }, () => {
                                                    if(this.totalPaidInput){
                                                        this.totalPaidInput.focus();
                                                        this.totalPaidInput.select();
                                                    }

                                                });


                                            }}
                                    >
                                        <i className={'fa '+ (!patchJobLoading ? 'fa-edit' : 'fa-spin fa-spinner')} />
                                    </Button>
                                </ButtonGroup>
                            </div>

                            <div className="row">
                                <div className="col-md-6">
                                    <Label>{__("totalSales")}</Label>
                                    {editSales ? (
                                        <>
                                            <input type="text"
                                                   className="form-control form-control-sm"
                                                   value={(input.sales.paid.total || formatCurrencyInput(job.sales.paid.total))}
                                                   onChange={(e) => {
                                                       this.setState({
                                                           input: {
                                                               ...input,
                                                               sales: {
                                                                   ...input.sales,
                                                                   paid: {
                                                                       ...input.sales.paid,
                                                                       total: e.target.value,
                                                                   }
                                                               }
                                                           }
                                                       })
                                                   }}
                                                   onBlur={(e) => {
                                                       this.setState({
                                                           input: {
                                                               ...input,
                                                               sales: {
                                                                   ...input.sales,
                                                                   paid: {
                                                                       ...input.sales.paid,
                                                                       total: formatCurrencyInput(e.target.value),
                                                                   }
                                                               }
                                                           }
                                                       })
                                                   }}
                                                   ref={(input) => { this.totalPaidInput = input; }}
                                            />
                                        </>
                                    ) : (
                                        <h6>{formatCurrencyInput(job.sales.paid.total)} €</h6>
                                    )}
                                </div>
                                <div className="col-md-6">
                                    <Label className="d-block">{__("proportionOfMaterial")}</Label>
                                    {editSales ? (
                                        <>
                                            <input type="text"
                                                   className="form-control form-control-sm"
                                                   value={(input.sales.paid.material || formatCurrencyInput(job.sales.paid.material))}
                                                   onChange={(e) => {
                                                       this.setState({
                                                           input: {
                                                               ...input,
                                                               sales: {
                                                                   ...input.sales,
                                                                   paid: {
                                                                       ...input.sales.paid,
                                                                       material: e.target.value,
                                                                   }
                                                               }
                                                           }
                                                       })
                                                   }}
                                                   onBlur={(e) => {
                                                       this.setState({
                                                           input: {
                                                               ...input,
                                                               sales: {
                                                                   ...input.sales,
                                                                   paid: {
                                                                       ...input.sales.paid,
                                                                       material: formatCurrencyInput(e.target.value),
                                                                   }
                                                               }
                                                           }
                                                       })
                                                   }}
                                            />
                                        </>
                                    ) : (
                                        <h6>{formatCurrencyInput(job.sales.paid.material)} €</h6>
                                    )}
                                </div>

                                <div className="col-md-6">
                                    <Label className="p-t-30 d-block">{__("paid")}</Label>
                                    <h6>{formatCurrencyInput(job.sales.partial_payment)} €</h6>
                                </div>
                                <div className="col-md-6">
                                    <Label className="p-t-30 d-block">{__("open")}</Label>
                                    <h6>{formatCurrencyInput(job.sales.open)} €</h6>
                                </div>
                            </div>


                        </div>

                        { (userObj.hasPermission('view job_partial_payment') && job.partial_payments && job.partial_payments.length > 0) && (
                            <>
                                <div className="card-body d-flex justify-content-between align-items-center">
                                    <h4 className="card-title m-b-0">{__("partialPayments")}</h4>

                                </div>

                                {this.renderPartialPaymentTable()}
                            </>
                        )}
                    </>
                )}

            </div>
        );
    }
}

export default OverviewCard;
