import React, {Component} from 'react';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter, ButtonGroup, Label, Input} from 'reactstrap';
import PropTypes from "prop-types";

import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {patchJobFitter} from "../../store/actions";
import FitterSelect from "./FitterSelect";
import {__} from "../../utils/helpers";


class SelectFitterModal extends Component {
    constructor(props) {
        super(props);
        this.amountInput = null;
        this.state = {
            input: {
                fitter_id: 0,
                send_notification: true,
            },
            views: {
                fitterSelect: 'distance',
            }
        };
    }

    componentDidMount() {
        const {input} = this.state;
        const {job} = this.props;

        this.setState({
            input: {
                ...input,
                fitter_id: job.fitter.id,
            }
        })
    }

    submit = () => {
        const {job} = this.props;
        const {input} = this.state;

        this.props.patchJobFitter(job.id, input);
        this.toggle();
    }

    toggle = () => {
        const {modal} = this.state;

        if(!modal === true){
            setTimeout(() => {
                if(this.amountInput !== null){
                    this.amountInput.focus()
                    this.amountInput.select()
                }
            }, 50);
        }

        this.setState({
            modal: !modal,
        })
    };

    onFitterSelected = (fitter, location) => {
        const {input} = this.state;
        this.setState({
            input: {
                ...input,
                fitter_id: fitter?.id,
            }
        });
    }

    onFitterSelectViewChange = (view) => {
        const {views} = this.state;

        this.setState({
            views: {
                ...views,
                fitterSelect: view
            }
        })
    }

    render() {
        const {
            color,
            size,
            className,
            modalClassName,
            children,
            job,
        } = this.props;

        const { modal, views, input } = this.state;

        return (
            <>
                <Button color={color} size={size} className={className} onClick={this.toggle}>{children}</Button>
                <Modal isOpen={modal} toggle={this.toggle} className={modalClassName}>
                    <ModalHeader toggle={this.toggle}>{__("changePartner")}</ModalHeader>
                    <ModalBody>
                        <h4 className="mb-0 text-white float-start">{__("view")}</h4>
                        <div className="btn-group d-inline-block float-end">
                            <button className={'btn btn-xs '+ (views.fitterSelect === 'distance' ? 'btn-dark' : 'btn-outline-dark')}
                                    onClick={() => {
                                        this.onFitterSelectViewChange('distance');
                                    }}><i className="fa fa-map-marker" /></button>
                            <button className={"btn btn-xs " + (views.fitterSelect === 'table' ? 'btn-dark' : 'btn-outline-dark')}
                                    onClick={() => {
                                        this.onFitterSelectViewChange('table');
                                    }}><i className="fa fa-search" /></button>
                        </div>
                        <FitterSelect view={views.fitterSelect}
                                      fitter_id={input.fitter_id}
                                      job_type_id={job.type?.id}
                                      job_source_id={job.source?.id}
                                      country_code={job.address?.country}
                                      mapLocation={job.location}
                                      onFitterSelected={this.onFitterSelected} />
                    </ModalBody>
                    <ModalFooter>
                        <Label check>
                            <Input type="checkbox" checked={input.send_notification} onChange={() => {
                                this.setState({
                                    input: {
                                        ...input,
                                        send_notification: !input.send_notification
                                    }
                                })
                            }} />
                            <span className="m-l-5">{__("sendNotification")}</span>
                        </Label>
                        <ButtonGroup>
                            <Button color="secondary" onClick={this.toggle}><i className="fa fa-times-circle" /> {__("close")}</Button>
                            { input.fitter_id > 0 && input.fitter_id !== job.fitter?.id && (
                                <Button color="inverse" onClick={this.submit}><i className="fa fa-save" /> {__("save")}</Button>
                            )}
                        </ButtonGroup>
                    </ModalFooter>
                </Modal>
            </>
        );
    }
}

const mapStatetoProps = (state) => {
    return {
    };
};

export default withRouter(connect(mapStatetoProps, { patchJobFitter })(SelectFitterModal));


SelectFitterModal.propTypes = {
    job: PropTypes.object.isRequired,
    color: PropTypes.string,
    size: PropTypes.string,
    className: PropTypes.string,
    modalClassName: PropTypes.string,
};
