import {
    LOGIN_USER,
    LOGIN_SUCCESS,
    LOGOUT_USER,
    LOGOUT_USER_SUCCESS,
    API_ERROR,
    GET_PROFILE,
    GET_PROFILE_SUCCESS,
    GET_PROFILE_ERROR, SET_LOGGED_IN, SET_LOGGED_IN_SUCCESS,
} from './actionTypes';
import User from "../../models/User";
import {getApiAuthBackend} from "../../api/Auth";

const auth = getApiAuthBackend();

const initialState = {
    error: null,
    loading: false,
    //token: sessionStorage.getItem("authToken") ? JSON.parse(sessionStorage.getItem("authToken")) : null,
    //user: sessionStorage.getItem("authUser") ? new User(JSON.parse(sessionStorage.getItem("authUser"))) : null,
    token: auth.getAuthToken(),
    user: auth.getAuthenticatedUser(),
}

const Auth = (state = initialState, action) => {
    switch (action.type) {
        case SET_LOGGED_IN:
            console.log("SET_LOGGED_IN", action.payload);

            state = {
                ...state,
                user: new User(action.payload.user),
                //token: action.payload.token,
            }
            break;
        case SET_LOGGED_IN_SUCCESS:
            console.log("SET_LOGGED_IN_SUCCESS", action.payload);

            state = {
                ...state,
                user: action.payload.user,
                //token: action.payload.token,
            }
            break;
        case LOGIN_USER:
            state = {
                ...state,
                loading: true,
                error: "",
                user: null,
            }
            break;
        case LOGIN_SUCCESS:
            state = {
                ...state,
                loading: false,
                error: "",
                user: action.payload.profile,
            }
            break;
        case LOGOUT_USER:
            state = { ...state };
            break;
        case LOGOUT_USER_SUCCESS:
            state = { ...state };
            break;
        case GET_PROFILE:
            state = { ...state };
            break;
        case GET_PROFILE_SUCCESS:
            state = {
                ...state,
                profile: action.payload.model,
            };
            break;
        case GET_PROFILE_ERROR:
            state = {
                ...state,
                user: null,
                profile: null,
            };
            break;
        case API_ERROR:
            state = { ...state, error: action.payload, loading: false };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default Auth;