import React, { Component } from "react";
import {
    JobCodeColumn,
    JobTypeColumn,
    CountryColumn,
    CreatedColumn,
    FitterColumn,
    CustomerColumn,
    IconsColumn,
} from "./Columns/ComplianceJobColumns";
import filterFactory from "react-bootstrap-table2-filter";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import {__} from "../../utils/helpers";

import { Code } from 'react-content-loader';
const MyCodeLoader = () => <Code />;

class ComplianceClaimJobsTable extends Component {
    constructor(props) {
        super(props);
        const {meta} = props;

        this.cacheKeys = {
            oldTableState: 'compliances.jobs.oldTableState',
        }

        this.state = {
            meta,
            defaultMeta: {
                page: 1,
                per_page: 10,
                sort_column: 'job_id',
                sort_order: 'asc',
            },
            oldTableState: JSON.parse(localStorage.getItem(this.cacheKeys.oldTableState)) || null,
        };
    }

    componentDidMount() {
        const {filters} = this.props;
        if(this.props.jobs.length === 0){
            this.props.getData({
                ...this.state.defaultMeta,
                filters,
            });
        }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {filters} = this.props;

        if(filters !== prevProps.filters){
            this.props.getData({
                ...this.state.defaultMeta,
                filters,
            });
        }
    }

    columns(){
        return [
            {
                dataField: 'type',
                text: '',
                formatter: JobTypeColumn,
                sort: true
            },
            {
                dataField: 'job_country',
                text: '',
                formatter: CountryColumn,
            },
            {
                dataField: 'job_code',
                text: '',
                formatter: JobCodeColumn,
                sort: true
            },
            {
                dataField: 'job_created',
                text: __("created"),
                formatter: CreatedColumn,
                sort: true,
            },
            {
                dataField: 'fitter',
                text: __("partner"),
                formatter: FitterColumn,
                sort: true
            },
            {
                dataField: 'job_contact_name',
                text: __("customer"),
                formatter: CustomerColumn,
                sort: true
            },
            {
                dataField: 'job_id',
                text: '',
                formatter: IconsColumn,
                sort: false,
                formatExtraData: {
                    setClaimJob: this.props.setClaimJob,
                }
            },
        ];
    }

    onTableChange = (type, newState) => {
        const {oldTableState} = this.state;
        const {filters} = this.props;

        if (oldTableState !== null
            && newState.sortOrder === oldTableState.sortOrder
            && newState.sortField === oldTableState.sortField
            //&& newState.filters === oldTableState.filters
            && newState.page === oldTableState.page
            && newState.sizePerPage === oldTableState.sizePerPage) {

            //&& newState.searchText === this.oldTableState.searchText)
            return;
        }

        this.setState({
            oldTableState: newState
        },() => {
            localStorage.setItem(this.cacheKeys.oldTableState, JSON.stringify(newState))
        });

        switch(type){
            default:
                // nothing to-do
                break;
            case 'pagination':
            case 'sort':
                this.props.getData({
                    filters,
                    page: newState.page,
                    per_page: newState.sizePerPage,
                    sort_column: newState.sortField,
                    sort_order: newState.sortOrder,
                });
                break;
        }
    }

    sort = () => {
        const {meta} = this.props;
        return {
            dataField: meta.sort.column,
            order: meta.sort.order.toLowerCase(),
        };
    }

    paginationFactory = () => {
        const {meta} = this.props;
        return paginationFactory({
            page: meta.current_page,
            sizePerPage: parseInt(meta.per_page),
            totalSize: meta.total,
            showTotal: true,
        });
    }

    render() {
        const {loading, jobs, meta} = this.props;
        const self = this;
        const boxStyle = {};
        return (
            <div className="card">
                <div className="card-body">
                    <h4 className="card-title">
                        {__("claims")}
                        { loading === false && meta.total > 0 ? (
                            <span className="label label-danger float-right">{meta.total}</span>
                        ) : '' }
                    </h4>
                </div>

                <div className="comment-widgets m-b-20" style={boxStyle}>
                    { loading === true ? (
                        <div className={'m-3'}>
                            <MyCodeLoader />
                        </div>
                        ) : (
                            <div>
                                { jobs.length === 0 ? (
                                    <div className={'text-center'} style={{marginTop: '80px'}}>
                                        <i className={'fa fa-check-circle text-success'} style={{fontSize: '100px'}} />
                                    </div>
                                ) : (
                                    <ToolkitProvider
                                        keyField='id'
                                        data={ this.props.jobs }
                                        columns={ this.columns() }

                                        search
                                    >
                                        {
                                            props => (
                                                <div className="">
                                                    <BootstrapTable
                                                        ref={ n => self.node = n }
                                                        striped={true}
                                                        hover={true}
                                                        bootstrap4
                                                        classes={'table-nowrap table-centered table-borderless'}
                                                        pagination={ self.paginationFactory() }
                                                        sort={ self.sort() }
                                                        filter={ filterFactory() }
                                                        onTableChange={self.onTableChange}
                                                        remote={ {
                                                            filter: true,
                                                            pagination: true,
                                                            sort: true,
                                                            cellEdit: false
                                                        } }
                                                        bordered={false}
                                                        /*selectRow={this.selectRow()}*/
                                                        { ...props.baseProps }
                                                    />
                                                </div>
                                            )
                                        }
                                    </ToolkitProvider>
                                    )
                                }
                            </div>
                        )
                    }
                </div>
            </div>
        );
    }
}

export default ComplianceClaimJobsTable;
