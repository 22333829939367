import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import CommissionsOverview from "./CommissionsOverview";

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Row>
                            <Col xl="6">
                                <CommissionsOverview />
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default Dashboard;
