export const SET_JOB = "SET_JOB";
export const GET_JOB = "GET_JOB";
export const GET_JOB_SUCCESS = "GET_JOB_SUCCESS";
export const GET_JOB_SUCCESS_WS = "GET_JOB_SUCCESS_WS";
export const PATCH_JOB = "PATCH_JOB";
export const PATCH_JOB_SUCCESS = "PATCH_JOB_SUCCESS";

export const ADD_JOB = "ADD_JOB";
export const ADD_JOB_SUCCESS = "ADD_JOB_SUCCESS";
export const ADD_JOB_ERROR = "ADD_JOB_ERROR";
export const ADD_JOB_RESET = "ADD_JOB_RESET";

export const PATCH_JOB_FITTER = "PATCH_JOB_FITTER";
export const PATCH_JOB_FITTER_SUCCESS = "PATCH_JOB_FITTER_SUCCESS";
export const PATCH_JOB_FITTER_ERROR = "PATCH_JOB_FITTER_ERROR";

export const PATCH_JOB_APPOINTMENT = "PATCH_JOB_APPOINTMENT";
export const PATCH_JOB_APPOINTMENT_SUCCESS = "PATCH_JOB_APPOINTMENT_SUCCESS";
export const PATCH_JOB_APPOINTMENT_ERROR = "PATCH_JOB_APPOINTMENT_ERROR";

export const ADD_JOB_PARTIAL_PAYMENT = "ADD_JOB_PARTIAL_PAYMENT";
export const ADD_JOB_PARTIAL_PAYMENT_SUCCESS = "ADD_JOB_PARTIAL_PAYMENT_SUCCESS";
export const ADD_JOB_PARTIAL_PAYMENT_ERROR = "ADD_JOB_PARTIAL_PAYMENT_ERROR";

export const DELETE_JOB_PARTIAL_PAYMENT = "DELETE_JOB_PARTIAL_PAYMENT";
export const DELETE_JOB_PARTIAL_PAYMENT_SUCCESS = "DELETE_JOB_PARTIAL_PAYMENT_SUCCESS";

export const ADD_JOB_STATUS = "ADD_JOB_STATUS";
export const ADD_JOB_STATUS_SUCCESS = "ADD_JOB_STATUS_SUCCESS";

export const ADD_JOB_CLAIM = "ADD_JOB_CLAIM";
export const ADD_JOB_CLAIM_SUCCESS = "ADD_JOB_CLAIM_SUCCESS";

export const SEND_JOB_MESSAGE = "SEND_JOB_MESSAGE";
export const SEND_JOB_MESSAGE_SUCCESS = "SEND_JOB_MESSAGE_SUCCESS";

export const GET_JOBS = "GET_JOBS";
export const GET_JOBS_SUCCESS = "GET_JOBS_SUCCESS";

export const GET_JOB_MONITOR = "GET_JOB_MONITOR";
export const GET_JOB_MONITOR_SUCCESS = "GET_JOB_MONITOR_SUCCESS";

export const GET_JOB_QUESTIONS = "GET_JOB_QUESTIONS";
export const GET_JOB_QUESTIONS_SUCCESS = "GET_JOB_QUESTIONS_SUCCESS";

export const GET_JOB_PRICE_TEMPLATES = "GET_JOB_PRICE_TEMPLATES";
export const GET_JOB_PRICE_TEMPLATES_SUCCESS = "GET_JOB_PRICE_TEMPLATES_SUCCESS";

