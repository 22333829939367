import React, { Component } from "react";
import Moment from "react-moment";
import StatusBadge from "../Jobs/StatusBadge";
import {__} from "../../utils/helpers";

class StatusHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    renderTableRows(){
        const { fitter } = this.props;

        return fitter.status_history.map(function(item){
            return (

                <tr key={'status-'+ item.id}>
                    <td>
                        <StatusBadge state={item.status} />
                    </td>
                    <td>
                        <Moment date={item.created} format="DD.MM.YYYY" />
                    </td>
                    <td>
                        <Moment date={item.created} format="HH:mm" />
                    </td>
                    <td>
                        {item.user.name}
                    </td>
                    <td>
                        <span dangerouslySetInnerHTML={{ __html: item.info }} />
                    </td>
                </tr>
            );
        });
    }

    renderTable(){
        const { fitter, style } = this.props;

        if(!fitter.status_history || fitter.status_history.length === 0)
            return (
                <p className="p-30 pb-3">{__("noDataAvailable")}</p>
            );


        return (
            <div className={'table-responsive'} style={style}>
                <table className={'table table-hover customize-table v-top m-b-0'}>
                    <thead className="table-dark">
                    <tr>
                        <th> </th>
                        <th>{__("date")}</th>
                        <th>{__("time")}</th>
                        <th>{__("user")}</th>
                        <th>{__("comment")}</th>
                    </tr>
                    </thead>
                    <tbody>
                    { this.renderTableRows()}
                    </tbody>
                </table>

            </div>
        )
    }

    render() {
        return this.renderTable();
    }
}

export default StatusHistory;
