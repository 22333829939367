import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga'

import rootReducer from './reducers';
import rootSaga from './sagas';
import {getApiAuthBackend} from "../api/Auth";
import {getProfile, setLoggedIn} from "./auth/actions";
import socketIOClient from "socket.io-client";
import {runsInElectron} from "../utils/helpers";

const apiAuthBackend = getApiAuthBackend();

const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(sagaMiddleware)));
sagaMiddleware.run(rootSaga);

if(runsInElectron() === false) {
    const ENDPOINT = "http://127.0.0.1:9000";

    const socket = socketIOClient(ENDPOINT, {
        reconnectionDelay: 1000,
        reconnection: true,
        reconnectionAttemps: 10,
        transports: ['websocket'],
        agent: false,
        upgrade: false,
        rejectUnauthorized: false
    });

    socket.on("connect", () => {
        if(socket.connected){
            socket.emit('get-user')
        }
    });

    socket.on("disconnect", () => {
        if(!socket.connected){
            socketUserLogout();
        }
    });

    socket.on("machine-info", data => {
        console.log("machine-info", data);
    });

    socket.on("user", data => {
        const {user, token} = data;
        console.log("socket::on::user", data);

        if(user && token) {
            socketUserLogin(token, user);
        } else {
            socketUserLogout();
        }
    });
}

const socketUserLogin = (token, user) => {
    apiAuthBackend.setLoggedInUser(token, user, true);
    store.dispatch(setLoggedIn(token, user));
    store.dispatch(getProfile());
};

const socketUserLogout = () => {
    apiAuthBackend.setLoggedInUser(null, null, true);
    store.dispatch(setLoggedIn(null, null));
}

export default store;