import React, { Component } from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import { withRouter } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import {Tabs, Tab} from "react-bootstrap";
import {__} from "../../utils/helpers";
import {
    setBreadCrumbs,
    getStatisticsFitters,
    getStatisticsJobMap,
    getJobTypes,
    getJobSources,
    getFitters,
    getCancellationTemplates,
} from "../../store/actions";
import FittersTable from "../../components/Statistics/FittersTable";
import JobMap from "../../components/Statistics/JobMap";
import FilterContainer from "../../components/Filter";

class Statistics extends Component {
    constructor(props) {
        super(props);

        this.cacheKeys = {
            filters: 'statistics.filter',
            activeTab: 'statistics.activeTab',
        }

        this.ref = {
            FittersTable: null,
        }

        const filters = JSON.parse(localStorage.getItem(this.cacheKeys.filters)) || {
            jobTypes: [],
            jobSources: [],
            countries: [],
            cancellationTemplates: [],
            textSearch: "",
            fitters: [],
            claimPriorities: [],
            startDate: null,
            endDate: null,
        };

        if(typeof(filters.claimPriorities) === "undefined"){
            filters.claimPriorities = [];
        }

        this.state = {
            activeTab: localStorage.getItem(this.cacheKeys.activeTab) || 'jobMap',
            countries: [
                {
                    'code': 'de',
                    'name': 'Deutschland',
                    'icon': 'flag-icon flag-icon-de',
                },
                {
                    'code': 'at',
                    'name': 'Österreich',
                    'icon': 'flag-icon flag-icon-at',
                },
                {
                    'code': 'ch',
                    'name': 'Schweiz',
                    'icon': 'flag-icon flag-icon-ch',
                },
                {
                    'code': 'nl',
                    'name': 'Niederlande',
                    'icon': 'flag-icon flag-icon-nl',
                }
            ],
            filters,
        };

        this.togglemodal.bind(this);
    }

    componentDidMount() {

        document.title = __("stats");

        if(this.props.jobTypes.length === 0 && this.props.jobTypesLoading === false)
            this.props.getJobTypes();

        if(this.props.jobSources.length === 0 && this.props.jobSourcesLoading === false)
            this.props.getJobSources();

        if(this.props.cancellationTemplates.length === 0 && this.props.cancellationTemplatesLoading === false)
            this.props.getCancellationTemplates();

        if(this.props.fitters.length === 0  && this.props.fittersLoading === false)
            this.props.getFitters();

        if(this.props.jobMap === null && this.props.jobMapLoading === false)
            this.getStatisticsJobMap();
    }

    togglemodal = () => {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    onClearCountries = (e) => {
        const filters = {
            ...this.state.filters,
            countries: [],
        };
        this.onFiltersUpdated(filters);
    }

    onClaimPriorityClick = (e) => {
        const value = e.target.dataset.value;
        let {claimPriorities} = this.state.filters;
        const index = claimPriorities ? claimPriorities.findIndex((element) => element === value) : -1;

        if(typeof(claimPriorities) === 'undefined'){
            claimPriorities = [];
        }

        if(index === -1){
            claimPriorities.push(value);
        } else {
            claimPriorities.splice(index, 1);
        }

        const filters = {
            ...this.state.filters,
            claimPriorities: claimPriorities,
        };

        this.onFiltersUpdated(filters);
    }

    onClearClaimPriorities = (e) => {
        const filters = {
            ...this.state.filters,
            claimPriorities: [],
        };
        this.onFiltersUpdated(filters);
    }

    onCountryClick = (e) => {
        const value = e.target.dataset.value;
        const {countries} = this.state.filters;
        const index = countries.findIndex((element) => element === value);

        if(index === -1){
            countries.push(value);
        } else {
            countries.splice(index, 1);
        }

        const filters = {
            ...this.state.filters,
            countries: countries,
        };
        this.onFiltersUpdated(filters);
    }

    onClearJobTypes = (e) => {
        const filters = {
            ...this.state.filters,
            jobTypes: [],
        };

        this.onFiltersUpdated(filters);
    }

    onJobTypeClick = (e) => {
        const value = e.target.dataset.value;
        const {jobTypes} = this.state.filters;
        const index = jobTypes.findIndex((element) => element === value);

        if(index === -1){
            jobTypes.push(value);
        } else {
            jobTypes.splice(index, 1);
        }

        const filters = {
            ...this.state.filters,
            jobTypes: jobTypes,
        };

        this.onFiltersUpdated(filters);
    }

    onJobSourceClick = (e) => {
        const value = e.target.dataset.value;

        e.target.blur();

        let {jobSources} = this.state.filters;

        if(typeof(jobSources) === "undefined"){
            jobSources  = [];
        }

        const index = jobSources.findIndex((element) => element === value);

        if(index === -1){
            jobSources.push(value);
        } else {
            jobSources.splice(index, 1);
        }

        const filters = {
            ...this.state.filters,
            jobSources: jobSources,
        };
        this.onFiltersUpdated(filters);
    }

    onClearTextSearch = (e) => {
        const filters = {
            ...this.state.filters,
            textSearch: '',
        };

        this.onFiltersUpdated(filters);
    }

    onSelectFitters = (values) => {
        const data = values === null ? [] : values.map(function(value){
            return value.value;
        });

        const filters = {
            ...this.state.filters,
            fitters: data,
        };
        this.onFiltersUpdated(filters);
    }

    onSelectCancellationTemplates = (values) => {
        const data = values === null ? [] : values.map(function(value){
            return value.value;
        });

        const filters = {
            ...this.state.filters,
            cancellationTemplates: data,
        };
        this.onFiltersUpdated(filters);
    }

    onClearDateRange = (e) => {
        this.onDateRangeChange(null, null);
    }

    onDateRangeChange = (start, end) => {
        const filters = {
            ...this.state.filters,
            startDate: start !== null ? start.format('DD.MM.YYYY') : null,
            endDate: end !== null ? end.format('DD.MM.YYYY') : null
        };

        this.onFiltersUpdated(filters);
    }

    onFiltersUpdated = (filters) => {
        this.setState({
            filters
        }, () => {
            localStorage.setItem(this.cacheKeys.filters, JSON.stringify(filters))
            this.getStatisticsJobMap();
        })
    };

    getStatisticsJobMap = () => {
        const {filters} = this.state;

        this.props.getStatisticsJobMap({
            filters,
        })
    }

    getFitterStatistics = (meta) => {
        this.props.getStatisticsFitters(
            meta
        );
    }

    setBreadCrumbs(){
        this.props.setBreadCrumbs([
            {
                name: 'Home',
                href: '/dashboard'
            },
            {
                name: 'PBX',
                href: '#'
            },
            {
                name: __("calls"),
                route: '/pbx/calls',
            },
        ]);
    }

    reloadData(){
        if(this.ref.FittersTable !== null){
            this.ref.FittersTable.reloadData();
        }
    }

    render() {
        const {
            jobTypesLoading,
            jobTypes,
            jobSources,
            jobSourcesLoading,
            claimPriorities,
            fitters,
            cancellationTemplates,
            fitterStatistics,
            fitterStatisticsLoading,
            jobMap,
            jobMapLoading,

        } = this.props;
        const {countries, filters, activeTab} = this.state;

        const toggleTab = tab => {
            if(activeTab !== tab){
                this.setState({
                    activeTab: tab,
                }, () => {
                    localStorage.setItem(this.cacheKeys.activeTab, tab)
                })
            }
        }


        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Row>
                            <Col xl="8">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title">
                                            {__("calls")}
                                        </h4>
                                    </div>
                                    <Tabs
                                        id="controlled-tab-example"
                                        className={'customtab'}
                                        activeKey={activeTab}
                                        //variant="pills"
                                        onSelect={(k) => toggleTab(k)}
                                    >
                                        <Tab eventKey="jobMap"
                                             //tabClassName="btn btn-inverse"
                                             title={
                                                 <>
                                                     <span>{__("jobMap")}</span>
                                                     {jobMapLoading && (
                                                         <i className={'ml-2 fa fa-spinner fa-spin'} />
                                                     )}
                                                 </>
                                             }>
                                            <JobMap
                                                loading={jobMapLoading}
                                                filters={filters}
                                                jobMap={jobMap}
                                            />
                                        </Tab>
                                        <Tab eventKey="fitterStatistics"
                                             //tabClassName="btn btn-inverse"
                                             title={
                                                 <>
                                                     <span>{__("calls")}</span>
                                                     {fitterStatisticsLoading && (
                                                         <i className={'ml-2 fa fa-spinner fa-spin'} />
                                                     )}
                                                 </>
                                             }>
                                            <FittersTable
                                                ref={ n => this.ref['FittersTable'] = n }
                                                loading={fitterStatisticsLoading}
                                                getData={this.getFitterStatistics}
                                                filters={filters}
                                                //meta={fitterStatistics.meta}
                                                fitterStatistics={fitterStatistics}
                                            />
                                        </Tab>
                                    </Tabs>
                                </div>
                            </Col>
                            <Col xl="4">
                                <FilterContainer
                                    horizontal={false}
                                    loading={jobTypesLoading}
                                    jobTypes={jobTypes}
                                    jobSources={jobSources}
                                    jobSourcesLoading={jobSourcesLoading}
                                    claimPriorities={claimPriorities}
                                    countries={countries}
                                    fitters={fitters.filter(fitter => fitter.status.status === 'active')}
                                    cancellationTemplates={cancellationTemplates}
                                    filters={filters}
                                    display={{
                                        jobSourcesTagCloud: true,
                                        jobTypesSelect: true,
                                        countriesSelect: true,
                                        fittersSelect: true,
                                        textSearchInput: false,
                                        dateRangePicker: true,
                                        appointmentDateRangePicker: false,
                                        callsSelect: false,
                                        jobStatesSelect: false
                                    }}
                                    onClaimPriorityClick={this.onClaimPriorityClick}
                                    onClearClaimPriorities={this.onClearClaimPriorities}
                                    onClearCountries={this.onClearCountries}
                                    onCountryClick={this.onCountryClick}
                                    onClearJobTypes={this.onClearJobTypes}
                                    onJobTypeClick={this.onJobTypeClick}
                                    onJobSourceClick={this.onJobSourceClick}
                                    onClearTextSearch={this.onClearTextSearch}
                                    onDateRangeChange={this.onDateRangeChange}
                                    onClearDateRange={this.onClearDateRange}
                                    onSelectFitters={this.onSelectFitters}
                                    onSelectCancellationTemplates={this.onSelectCancellationTemplates}
                                />
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = (state) => {
    const { fitterStatistics, fitterStatisticsLoading, jobMap, jobMapLoading } = state.Statistics;
    const { jobTypes, jobTypesLoading } = state.JobTypes;
    const { jobSources, jobSourcesLoading } = state.JobSources;
    const { cancellationTemplates, cancellationTemplatesLoading } = state.CancellationTemplates;
    const { fitters, fittersLoading } = state.Fitters;

    return {
        fitterStatistics,
        fitterStatisticsLoading,
        jobMapLoading,
        jobMap,
        jobTypes,
        jobTypesLoading,
        jobSources,
        jobSourcesLoading,
        fitters,
        fittersLoading,
        cancellationTemplates,
        cancellationTemplatesLoading,
    };
};

function mapDispatchToProps(dispatch) {
    let actions = bindActionCreators({
        setBreadCrumbs,
        getStatisticsFitters,
        getStatisticsJobMap,
        getJobTypes,
        getJobSources,
        getFitters,
        getCancellationTemplates,
    }, dispatch);
    return { ...actions, dispatch };
}


export default withRouter(
    connect(mapStatetoProps, mapDispatchToProps)(Statistics)
);
