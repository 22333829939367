import {
    GET_COMMISSIONS_OVERVIEW, GET_COMMISSIONS_OVERVIEW_SUCCESS,
    GET_COMMISSIONS_SUMMARY,
    GET_COMMISSIONS_SUMMARY_SUCCESS,
} from "./actionTypes";

export const getCommissionsSummary = () => ({
    type: GET_COMMISSIONS_SUMMARY,
    payload: {}
});

export const getCommissionsSummarySuccess = response => ({
    type: GET_COMMISSIONS_SUMMARY_SUCCESS,
    payload: response.data.data
});

export const getCommissionsOverview = () => ({
    type: GET_COMMISSIONS_OVERVIEW,
    payload: {}
});

export const getCommissionsOverviewSuccess = response => ({
    type: GET_COMMISSIONS_OVERVIEW_SUCCESS,
    payload: response.data.data
});
