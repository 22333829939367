import {
    SET_PAGE_TITLE, SET_PAGE_TITLE_SUCCESS,
    SET_BREAD_CRUMBS, SET_BREAD_CRUMBS_SUCCESS,
} from "./actionTypes";

export const setPageTitle = (title) => ({
    type: SET_PAGE_TITLE,
    payload: {
        title
    }
});

export const setPageTitleSuccess = (title) => ({
    type: SET_PAGE_TITLE_SUCCESS,
    payload: {
        title
    }
});

export const setBreadCrumbs = (breadcrumbs) => ({
    type: SET_BREAD_CRUMBS,
    payload: {
        breadcrumbs
    }
});


export const setBreadCrumbsSuccess = (breadcrumbs) => ({
    type: SET_BREAD_CRUMBS_SUCCESS,
    payload: {
        breadcrumbs
    }
});

