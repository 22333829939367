// @flow
import {
    GET_FITTER,
    GET_FITTER_SUCCESS,
    GET_FITTERS,
    GET_FITTERS_SUCCESS,
    GET_FITTERS_BY_DISTANCE,
    GET_FITTERS_BY_DISTANCE_SUCCESS,
} from "./actionTypes";

const INIT_STATE = {
    error: null,
    loading: false,
    fitterLoading: false,
    fitter: null,
    fittersLoading: false,
    fitters:  [],
    fittersDistanceLoading: false,
    fittersDistance: [],
    fitterHistoryLoading: false,
};

const Fitters = (state = INIT_STATE, action) => {

    let fitter;

    switch (action.type) {

        case GET_FITTER:
            fitter = state.fitters.find((element) => {
                return element.id === action.payload.id;
            });

            if(fitter === undefined)
                fitter = {};

            return {
                ...state,
                loading: true,
            };
        case GET_FITTER_SUCCESS:
            return {
                ...state,
                loading: false,
                fitter: action.payload,
            };

        case GET_FITTERS:
            return {
                ...state,
                fittersLoading: true,
            }
        case GET_FITTERS_SUCCESS:
            return {
                ...state,
                fittersLoading: false,
                fitters: action.payload,
            }

        case GET_FITTERS_BY_DISTANCE:
            return {
                ...state,
                fittersDistanceLoading: true,
                fittersDistance: [],
            }
        case GET_FITTERS_BY_DISTANCE_SUCCESS:
            console.log("distance", action.payload);
            return {
                ...state,
                fittersDistanceLoading: false,
                fittersDistance: action.payload,
            }
        default:
            return state;
    }
};


export default Fitters;
