import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import {GET_PROFILE, LOGIN_USER, LOGOUT_USER, SET_LOGGED_IN} from './actionTypes';
import {loginSuccess, logoutUserSuccess, apiError, getProfileSuccess, getProfileError, setLoggedInSuccess} from './actions';

//AUTH related methods
import {getApiBackend, reInitApiBackend} from "../../api/index";
import {getApiAuthBackend} from "../../api/Auth";
import {runsInElectron} from "../../utils/helpers";

const apiAuthBackend    = getApiAuthBackend();
const apiBackend        = getApiBackend();

function* loginUser({ payload: { user, history } }) {
    try {
        const response = yield call(apiAuthBackend.signIn, user.username, user.password);

        //setLoggedInUser(response.data);
        reInitApiBackend();

        yield put(loginSuccess(response));

        yield call(getProfile);

        if(runsInElectron() === true) {
        } else if(history) {
            history.push('/dashboard');
        }

    } catch (error) {
        yield put(apiError(error));
    }
}

function* logoutUser({ payload: { history } }) {
    try {

        const response = yield call(apiAuthBackend.signOut);

        yield put(logoutUserSuccess(response));
        history.push('/login');
    } catch (error) {
        yield put(apiError(error));
    }
}

function* getProfile() {
    try {
        //const response = yield call(fireBaseBackend.loginUser, user.email, user.password);
        const response = yield call(apiBackend.get, '/v1/user/me');

        yield put(getProfileSuccess(response));

    } catch (error) {
        apiAuthBackend.setLoggedInUser(null, null);
        yield put(getProfileError());
        yield put(apiError(error));
    }
}

function* setLoggedIn({ payload: { token, user } }) {
    yield put(setLoggedInSuccess(token, user));
}

export function* watchUserLogin() {
    yield takeEvery(LOGIN_USER, loginUser)
}

export function* watchUserLogout() {
    yield takeEvery(LOGOUT_USER, logoutUser)
}

export function* watchGetProfile() {
    yield takeEvery(GET_PROFILE, getProfile)
}

export function* watchSetLoggedIn() {
    yield takeEvery(SET_LOGGED_IN, setLoggedIn)
}

function* authSaga() {
    yield all([
        fork(watchUserLogin),
        fork(watchUserLogout),
        fork(watchGetProfile),
        fork(watchSetLoggedIn)
    ]);
}

export default authSaga;