import React from "react";
import 'moment/locale/de';
import {formatCurrencyInput} from "../../utils/helpers";

export const FitterColumn = (cell, row, rowIndex, formatExtraData) => {
    return (
        <span>
            <a href={'/fitters/'+ row.fitter.id} target={'_blank'} className={'d-block'} rel="noreferrer">
                <small>
                    {row.fitter.display_name}
                </small>
            </a>
        </span>
    );
}

export const JobsTotalCountColumn = (cell, row, rowIndex, formatExtraData) => {
    return (
        <div className="text-right">
            <span>{row.jobs_count}</span>
        </div>
    );
}

export const JobsCanceledCountColumn = (cell, row, rowIndex, formatExtraData) => {
    return (
        <div className="text-right">
            <span>{row.canceled_jobs_count}</span>
        </div>
    );
}

export const JobsCanceledQuoteColumn = (cell, row, rowIndex, formatExtraData) => {
    return (
        <div className="text-right">
            <span>{formatCurrencyInput(parseFloat(row.canceled_jobs_quote).toFixed(2))} %</span>
        </div>
    );
}

export const JobsClaimedCountColumn = (cell, row, rowIndex, formatExtraData) => {
    return (
        <div className="text-right">
            <span>{row.claimed_jobs_count}</span>
        </div>
    );
}

export const JobsClaimedQuoteColumn = (cell, row, rowIndex, formatExtraData) => {
    return (
        <div className="text-right">
            <span>{formatCurrencyInput(parseFloat(row.claimed_jobs_quote).toFixed(2))} %</span>
        </div>
    );
}

export const JobsAvgPaymentColumn = (cell, row, rowIndex, formatExtraData) => {
    return (
        <div className="text-right">
            <span>{formatCurrencyInput(parseFloat(row.jobs_avg_payment).toFixed(2))} €</span>
        </div>
    );
}

export const JobsOpenPaymentColumn = (cell, row, rowIndex, formatExtraData) => {

    if(parseFloat(row.jobs_open_payment) === 0)
        return '';

    return (
        <div className="text-right">
            <span>{formatCurrencyInput(parseFloat(row.jobs_open_payment).toFixed(2))} €</span>
        </div>
    );
}

export const JobsTotalPaymentColumn = (cell, row, rowIndex, formatExtraData) => {
    return (
        <div className="text-right">
            <span>{formatCurrencyInput(parseFloat(row.jobs_payment).toFixed(2))} €</span>
        </div>
    );
}
