import { combineReducers } from "redux";

import Auth from "./auth/reducer";
import Jobs from "./jobs/reducer";
import JobTypes from "./jobTypes/reducer";
import JobSources from "./jobSources/reducer";
import Fitters from "./fitters/reducer";
import Compliances from "./compliances/reducer";
import Pbx from "./pbx/reducer";
import Layout from "./layout/reducer";
import Phone from "./phone/reducer";
import CancellationTemplates from "./cancellationTemplates/reducer";
import Statistics from "./statistics/reducer";
import Commissions from "./commissions/reducer";

const rootReducer = combineReducers({
    Auth,
    Jobs,
    JobTypes,
    JobSources,
    Fitters,
    Compliances,
    Pbx,
    Layout,
    Phone,
    CancellationTemplates,
    Statistics,
    Commissions,
});

export default rootReducer;
