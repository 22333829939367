import React, { Component } from "react";
import PropTypes from "prop-types";
import {ButtonDropdown, ButtonGroup, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";
import {stateText, __} from "../../utils/helpers";

class JobStatesSelect extends Component {
    constructor (props) {
        super(props)

        this.state = {
            dropdownOpen: false,
        }
    }

    toggleOpen = () => {
        const {dropdownOpen} = this.state;

        this.setState({
            dropdownOpen: !dropdownOpen,
        })
    }

    renderSelect(){
        const {data, filters, onJobStateClick} = this.props;
        const {jobStates} = filters;

        if(data === undefined)
            return "";

        return data.map(function(jobState, key) {
            const index = jobStates ? jobStates.findIndex((element) => element === jobState) : -1;
            return (
                <DropdownItem key={'jobState-' + jobState} data-value={jobState} className={'dropdown-item '+ (index !== -1 ? 'active' : '')}
                              onClick={onJobStateClick}>
                    {stateText(jobState)}
                </DropdownItem>
            );

        });

    }

    render(){
        const {filters, onClearJobStates} = this.props;
        const {dropdownOpen} = this.state;

        return (
            <ButtonGroup>
                <ButtonDropdown isOpen={dropdownOpen} toggle={this.toggleOpen}>
                    <DropdownToggle caret className={'btn-'+ (!filters.jobStates || filters.jobStates.length === 0 ? 'secondary' : 'dark')}>
                        {__("status")}
                    </DropdownToggle>
                    <DropdownMenu>
                        { this.renderSelect() }
                    </DropdownMenu>
                </ButtonDropdown>
                <button type="button"
                        onClick={onClearJobStates}
                        className={'btn btn-'+ (!filters.jobStates || filters.jobStates.length === 0 ? 'secondary' : 'dark') +''}
                        disabled={!filters.jobStates || filters.jobStates.length === 0}
                >
                    <i className={'fa fa-times'} />
                </button>
            </ButtonGroup>
        )
    }
}

export default JobStatesSelect;

JobStatesSelect.propTypes = {
    onClearJobStates: PropTypes.func.isRequired,
    onJobStateClick: PropTypes.func.isRequired,
    filters: PropTypes.object.isRequired,
};