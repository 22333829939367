import React, { Component } from "react";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from 'react-bootstrap-table2-paginator';

import {BootstrapTableOptions, BootstrapTablePaginationOptions} from "./Options";
import {CompanyColumn, LocationsColumn, NicknameColumn, ViewColumn} from "./Columns/FitterColumns";
import {JobStateColumn} from "./Columns/JobColumns";
import {__} from "../../utils/helpers";

class FittersTable extends Component {
    constructor(props) {
        super(props);
        const {meta} = props;

        this.cacheKeys = {
            oldTableState: 'fitters.oldTableState',
        }

        this.state = {
            meta,
            defaultMeta: {
                page: 1,
                per_page: 10,
                sort_column: 'fitter_id',
                sort_order: 'desc',
            },
            oldTableState: JSON.parse(localStorage.getItem(this.cacheKeys.oldTableState)) || null,
        };
    }

    componentDidMount() {
        const {filters, data} = this.props;

        if(data.length === 0){
            this.props.getData({
                ...this.state.defaultMeta,
                filters,
            });
        }
    }

    columns(){
        const {setFitter} = this.props;

        const columns = [];

        columns.push({
            dataField: 'fitter_status',
            text: __("status"),
            formatter: JobStateColumn,
            sort: true,
            style: {
                width: '10%',
                //display: 'block',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
            }
        });

        columns.push({
            dataField: 'nickname',
            text: __("nickname"),
            formatter: NicknameColumn,
            sort: false,
            style: {
                width: '30%',
            }
        });

        columns.push({
            dataField: 'company',
            text: __("company"),
            formatter: CompanyColumn,
            sort: false,
            style: {
                width: '30%',
            }
        });

        columns.push({
            dataField: 'locations',
            text: __("locations"),
            formatter: LocationsColumn,
            sort: false,
            style: {
                width: '30%',
            }
        });

        columns.push({
            dataField: 'id',
            text: '',
            formatter: ViewColumn,
            sort: false,
            formatExtraData: {
                setFitter,
            }
        });

        return columns;
    }

    reloadData = () => {
        this.props.getData();
    }

    onTableChange = (type, newState) => {
        const {oldTableState} = this.state;

        if (oldTableState !== null
            && newState.sortOrder === oldTableState.sortOrder
            && newState.sortField === oldTableState.sortField
            //&& newState.filters === oldTableState.filters
            && newState.page === oldTableState.page
            && newState.sizePerPage === oldTableState.sizePerPage) {

            //&& newState.searchText === this.oldTableState.searchText)
            return;
        }


        this.setState({
            oldTableState: newState
        },() => {
            localStorage.setItem(this.cacheKeys.oldTableState, JSON.stringify(newState))
        });
    }

    sort = () => {
        const {meta} = this.props;

        return {
            dataField: meta?.sort?.column ? meta.sort.column : '',
            order: meta?.sort?.order ? meta.sort.order.toLowerCase() : 'desc',
        };
    }

    paginationFactory = () => {
        return paginationFactory({
            ...BootstrapTablePaginationOptions,
            //page: meta.current_page,
            //sizePerPage: parseInt(meta.per_page),
            //totalSize: meta.total,
            showTotal: true,
        });
    }

    render() {
        const {loading, data} = this.props;
        const self = this;

        return (
            <ToolkitProvider
                keyField='id'
                data={ data }
                columns={ this.columns() }
            >
                {
                    props => (
                        <div className="">
                            <BootstrapTable
                                { ...props.baseProps }
                                { ...BootstrapTableOptions }
                                ref={ n => self.node = n }
                                loading={loading}
                                pagination={ self.paginationFactory() }
                                //sort={ self.sort() }
                                //onTableChange={self.onTableChange}
                                /**
                                remote={ {
                                    filter: false,
                                    pagination: false,
                                    sort: false,
                                    cellEdit: false
                                } }*/
                            />

                        </div>
                    )
                }
            </ToolkitProvider>
        );
    }
}

export default FittersTable;
