import axios from 'axios';
import {getApiAuthBackend} from "./Auth";

const apiAuthBackend    = getApiAuthBackend();

class ApiBackend {

    constructor(){
        axios.defaults.headers.common = {
            //Authorization: `Bearer ${apiAuthBackend.getAuthToken()}`,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        };

        this.api = axios.create({
            //baseURL: `${process.env.REACT_APP_API_URL}/api`,
            baseURL: `/backend/api`,
            params: process.env.NODE_ENV === 'development' ? { XDEBUG_SESSION_START: 'local' } : {},
        });

        this.api.interceptors.request.use (
            function (config) {
                const token = apiAuthBackend.getAuthToken();
                if (token) config.headers.Authorization = `Bearer ${token}`;
                return config;
            },
            function (error) {
                return Promise.reject (error);
            }
        );
    }

    post = (uri, data) => {
        return this.api.post(uri, data);
    };

    patch = (uri, data) => {
        return this.api.patch(uri, data);
    };

    get = (uri) => {
        return this.api.get(uri);
    };

    delete = (uri) => {
        return this.api.delete(uri);
    };

    fetch = (uri) => {

        return fetch('/backend/api'+ uri,
            {
                headers: new Headers({
                    'Authorization': 'Bearer '+ apiAuthBackend.getAuthToken(),
                    'Accept': 'application/json'
                }),
            });
    };
}

let _apiBackend = null;

/**
 * Initilize the backend
 * @param {*} config
 */
const initApiBackend = () => {
    if (!_apiBackend) {
        _apiBackend = new ApiBackend();
    }
    return _apiBackend;
};

/**
 * Initilize the backend
 * @param {*} config
 */
const reInitApiBackend = () => {
    _apiBackend = new ApiBackend();

    return _apiBackend;
};

/**
 * Returns the firebase backend
 */
const getApiBackend = () => {
    if(!_apiBackend)
        initApiBackend();
    return _apiBackend;
};

export { initApiBackend, reInitApiBackend, getApiBackend };
