import { call, takeEvery, put } from "redux-saga/effects";

import {
    getCancellationTemplateSuccess,
    getCancellationTemplatesSuccess
} from "./actions";

import {getApiBackend} from "../../api";

const api    = getApiBackend();

/**
 * Changes the layout type
 * @param {*} param0
 */
function* getCancellationTemplate({ payload: { id } }) {
    try {
        const response = yield call(api.get, '/v2/jobs/cancellations/templates/'+ id)
        yield put(getCancellationTemplateSuccess(response));

    } catch (error) { }
}

/**
 * Changes the layout type
 * @param {*} param0
 */
function* getCancellationTemplates() {
    try {
        const response = yield call(api.get, '/v2/jobs/cancellations/templates?per_page=999')

        yield put(getCancellationTemplatesSuccess(response));

    } catch (error) { }
}

/**
 * Watchers
 */

function* CancellationTemplatesSaga() {
    yield takeEvery('GET_CANCELLATION_TEMPLATE', getCancellationTemplate);
    yield takeEvery('GET_CANCELLATION_TEMPLATES', getCancellationTemplates);


}

export default CancellationTemplatesSaga;
