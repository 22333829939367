// @flow
import {
    GET_JOB_TYPE,
    GET_JOB_TYPE_SUCCESS,
    GET_JOB_TYPES,
    GET_JOB_TYPES_SUCCESS,
} from "./actionTypes";

const INIT_STATE = {
    error: null,
    loading: false,
    jobTypesLoading: false,
    jobTypes:  [],
};

const JobTypes = (state = INIT_STATE, action) => {

    let job;

    switch (action.type) {

        case GET_JOB_TYPE:
            job = state.jobs.find((element) => {
                return element.id === action.payload.id;
            });

            if(job === undefined)
                job = {};

            return {
                ...state,
                loading: true,
                job: job,
                //tasks: []
            };
        case GET_JOB_TYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                //task: state.tasks[0]
                //task: task,
                //tasks: {},
                jobs: state.jobs.map(function(item){
                    if(item.id !== action.payload.id){
                        return item;
                    }

                    return action.payload;
                })
            };

        case GET_JOB_TYPES:
            return {
                ...state,
                jobTypesLoading: true,
            }
        case GET_JOB_TYPES_SUCCESS:
            return {
                ...state,
                jobTypesLoading: false,
                jobTypes: action.payload,
            }
        default:
            return state;
    }
};


export default JobTypes;
