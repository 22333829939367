import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import { withRouter } from "react-router-dom";
import {connect} from "react-redux";
import {getJobMonitor, getJobTypes, getFitters, setJob, setPageTitle} from "../../../store/actions";


// Pages Components
import UnconfirmedJobs from "./UnconfirmedJobs";
import ConfirmedJobs from "./ConfirmedJobs";
import AppointmentJobs from "./AppointmentJobs";
import FilterContainer from "./FilterContainer";
import WaitInfoJobs from "./WaitInfoJobs";
import CancellationJobs from "./CancellationJobs";
import LockedScreen from "../../../components/Layout/LockedScreen";
import {__} from "../../../utils/helpers";

class JobMonitor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            countries: [
                {
                    'code': 'de',
                    'name': 'Deutschland',
                    'icon': 'flag-icon flag-icon-de',
                },
                {
                    'code': 'at',
                    'name': 'Österreich',
                    'icon': 'flag-icon flag-icon-at',
                },
                {
                    'code': 'ch',
                    'name': 'Schweiz',
                    'icon': 'flag-icon flag-icon-ch',
                },
                {
                    'code': 'nl',
                    'name': 'Niederlande',
                    'icon': 'flag-icon flag-icon-nl',
                }
            ],
            filters: {
                jobTypes: [],
                countries: [],
                textSearch: "",
                fitters: []
            }
        };

        this.togglemodal.bind(this);
    }

    componentDidMount() {
        this.unconfirmedJobSound = new Audio('/sounds/notification-1.wav');
        this.unconfirmedJobSound.load();

        this.confirmedJobSound = new Audio('/sounds/notification-2.wav');
        this.confirmedJobSound.load();

        this.interval = setInterval(function(self){
            self.setState({ rand: Math.random() });
        }, 60000, this);

        this.props.getJobMonitor();
        this.props.getJobTypes();
        this.props.getFitters();
        document.title = __("jobMonitor");
        this.props.setPageTitle(__("jobMonitor"));
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if(prevProps.jobMonitorLoading === false && this.props.jobMonitorLoading === false && prevProps.jobMonitorJobs !== this.props.jobMonitorJobs){
            console.log("componentDidUpdate", prevProps);
            //this.playUnconfirmedJobSound();


            const confirmedJobs_before = this.getJobs('confirmed', prevProps.jobMonitorJobs, false);
            const confirmedJobs_after = this.getJobs('confirmed', this.props.jobMonitorJobs, false);

            if(confirmedJobs_before > confirmedJobs_after){
                this.playConfirmedJobSound();
            }

            const unconfirmedJobs_before = this.getJobs('unconfirmed', prevProps.jobMonitorJobs, false);
            const unconfirmedJobs_after = this.getJobs('unconfirmed', this.props.jobMonitorJobs, false);

            if(unconfirmedJobs_before > unconfirmedJobs_after){
                this.playUnconfirmedJobSound();
            }
        }
    }

    playUnconfirmedJobSound () {
        const audioPromise = this.unconfirmedJobSound.play()
        if (audioPromise !== undefined) {
            audioPromise
                .then(_ => {
                    // autoplay started
                })
                .catch(err => {
                    // catch dom exception
                    console.info(err)
                })
        }
    }

    playConfirmedJobSound () {
        const audioPromise = this.confirmedJobSound.play()
        if (audioPromise !== undefined) {
            audioPromise
                .then(_ => {
                    // autoplay started
                })
                .catch(err => {
                    // catch dom exception
                    console.info(err)
                })
        }
    }

    togglemodal = () => {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    onClearCountries = (e) => {
        this.setState({
            filters: {
                ...this.state.filters,
                countries: [],
            }
        });
    }

    onCountryClick = (e) => {
        const value = e.target.dataset.value;
        const {countries} = this.state.filters;
        const index = countries.findIndex((element) => element === value);

        if(index === -1){
            countries.push(value);
        } else {
            countries.splice(index, 1);
        }

        this.setState({
            filters: {
                ...this.state.filters,
                countries: countries,
            }
        });

        //this.props.onFiltersUpdated(this.state.filters);
    }

    onClearJobTypes = (e) => {
        this.setState({
            filters: {
                ...this.state.filters,
                jobTypes: [],
            }
        });
    }

    onJobTypeClick = (e) => {
        const value = e.target.dataset.value;
        const {jobTypes} = this.state.filters;
        const index = jobTypes.findIndex((element) => element === value);

        if(index === -1){
            jobTypes.push(value);
        } else {
            jobTypes.splice(index, 1);
        }

        this.setState({
            filters: {
                ...this.state.filters,
                jobTypes: jobTypes,
            }
        });

        //this.props.onFiltersUpdated(this.state.filters);
    }

    onClearTextSearch = (e) => {
        this.setState({
            filters: {
                ...this.state.filters,
                textSearch: '',
            }
        });
    }

    onSelectFitters = (values) => {
        const data = values === null ? [] : values.map(function(value){
            return value.value;
        });

        this.setState({
            filters: {
                ...this.state.filters,
                fitters: data,
            }
        });
    }

    onTextChange = (e) => {
        this.setState({
            filters: {
                ...this.state.filters,
                textSearch: e.target.value,
            }
        });
    }

    onFiltersUpdated = (filters) => {
        this.setState({
            filters
        })
    };

    getJobs(type, jobMonitorJobs, applyFilters) {
        if(typeof(jobMonitorJobs) === "undefined")
            jobMonitorJobs = this.props.jobMonitorJobs;

        if(typeof(applyFilters) === "undefined")
            applyFilters = true;

        const {filters} = this.state;

        let data = jobMonitorJobs.filter(job => job.job_monitor.column === type);

        if(applyFilters === true){
            if(filters.jobTypes.length > 0){
                data = data.filter(function(job) {
                    return filters.jobTypes.includes(job.type.id.toString());
                });
            }

            if(filters.countries.length > 0){
                data = data.filter(function(job) {
                    return filters.countries.includes(job.address.country);
                });
            }

            if(filters.fitters.length > 0){
                data = data.filter(function(job) {
                    return filters.fitters.includes(job.fitter.id);
                });
            }

            const lowercasedValue = filters.textSearch.toLowerCase().trim();

            data = data.filter(job => {
                const content = job.id +' '+ job.code +' '
                    + job.address.zipcode +' '+ job.address.city +' ' + job.address.street +' '+ job.address.house +' '
                    + job.contact.name +' '+ job.contact.phone +' '
                    + job.fitter.display_name;
                return content.toLowerCase().trim().includes(lowercasedValue);
            });

        }

        return data;
    }

    render() {
        const {jobTypesLoading, jobTypes, fitters, user} = this.props;
        const {countries, filters} = this.state;

        if(!user)
            return (
                <LockedScreen user={user} />
            );

        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                        {/* Render Breadcrumb
                        <Breadcrumbs title="Skote" breadcrumbItem="Dashboard" />
*/}
                        <Row>
                            <Col xl="12">
                                <FilterContainer
                                    loading={jobTypesLoading}
                                    jobTypes={jobTypes}
                                    countries={countries}
                                    fitters={fitters.filter(fitter => fitter.status.status === 'active')}
                                    filters={filters}
                                    onFiltersUpdated={this.onFiltersUpdated}
                                    onClearCountries={this.onClearCountries}
                                    onCountryClick={this.onCountryClick}
                                    onClearJobTypes={this.onClearJobTypes}
                                    onJobTypeClick={this.onJobTypeClick}
                                    onClearTextSearch={this.onClearTextSearch}
                                    onTextChange={this.onTextChange}
                                    onSelectFitters={this.onSelectFitters}
                                />
                            </Col>
                            <Col xl="4">
                                <UnconfirmedJobs loading={this.props.jobMonitorLoading} jobs={this.getJobs('unconfirmed')} setJob={this.props.setJob} />
                            </Col>
                            <Col xl="4">
                                <ConfirmedJobs loading={this.props.jobMonitorLoading} jobs={this.getJobs('confirmed')} setJob={this.props.setJob} />
                            </Col>
                            <Col xl="4">
                                <AppointmentJobs loading={this.props.jobMonitorLoading} jobs={this.getJobs('appointment')} setJob={this.props.setJob} />
                            </Col>
                            <Col xl="4">
                                <WaitInfoJobs loading={this.props.jobMonitorLoading} jobs={this.getJobs('wait-info')} setJob={this.props.setJob} />
                            </Col>
                            <Col xl="4">
                                <CancellationJobs loading={this.props.jobMonitorLoading} jobs={this.getJobs('cancellation')} setJob={this.props.setJob} />
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { user } = state.Auth;
    const { jobMonitorJobs, jobMonitorLoading } = state.Jobs;
    const { jobTypes, jobTypesLoading } = state.JobTypes;
    const { fitters, fittersLoading } = state.Fitters;

    return {
        user,
        jobMonitorJobs,
        jobMonitorLoading,
        jobTypes,
        jobTypesLoading,
        fitters,
        fittersLoading,
    };
};

export default withRouter(
    connect(mapStatetoProps, { getJobMonitor, getJobTypes, getFitters, setJob, setPageTitle })(JobMonitor)
);
