import React, { Component } from "react";
import {Link} from "react-router-dom";
import PerfectScrollbar from 'react-perfect-scrollbar'
import User from "../../models/User";
import {__} from "../../utils/helpers";

class LeftSidebar extends Component {
    render() {
        const {settings, compliances, user} = this.props;

        const userObject = new User(user);

        return (
            <aside className="left-sidebar" data-sidebarbg={settings.SidebarColor}>
                <PerfectScrollbar className="scroll-sidebar">
                    <nav className="sidebar-nav">
                        <ul id="sidebarnav">
                            <li className="sidebar-item user-profile">
                                <Link className="sidebar-link bg-success" to="/jobs/new">
                                    <i className="fa fa-plus-square"/>
                                    <span className="hide-menu">{__("createJob")}</span>
                                </Link>
                            </li>
                            <li className="sidebar-item">
                                <Link className="sidebar-link waves-effect waves-dark sidebar-link" to="/dashboard" aria-expanded="false">
                                    <i data-feather="home" className="feather-icon"/>
                                    <span className="hide-menu">
                                        Home
                                    </span>
                                </Link>
                            </li>

                            <li className="sidebar-item">
                                <Link className="sidebar-link waves-effect waves-dark sidebar-link" to="/jobs" aria-expanded="false">
                                    <i className="mdi mdi-inbox-arrow-down"/>
                                    <span className="hide-menu">
                                        {__("jobs")}
                                    </span>
                                </Link>
                            </li>

                            { userObject.hasPermission('view fitter') && (
                                <li className="sidebar-item">
                                    <Link className="sidebar-link waves-effect waves-dark sidebar-link" to="/fitters" aria-expanded="false">
                                        <i className="mdi mdi-worker"/>
                                        <span className="hide-menu">
                                        {__("partners")}
                                    </span>
                                    </Link>
                                </li>
                            )}

                            {/*
                            <li className="sidebar-item">
                                <Link className="sidebar-link waves-effect waves-dark sidebar-link" to="/fitters/locations" aria-expanded="false">
                                    <i className="mdi mdi-map-marker-distance"/>
                                    <span className="hide-menu">Standorte</span>
                                </Link>
                            </li>
                            {/*
                            <li className="sidebar-item">
                                <Link className="sidebar-link waves-effect waves-dark sidebar-link" to="/employees" aria-expanded="false">
                                    <i className="mdi mdi-account-multiple"/>
                                    <span className="hide-menu">
                                        Mitarbeiter
                                        <span className="side-badge badge bg-info">+</span>
                                    </span>
                                </Link>
                            </li>
                            */}
                            { userObject.hasPermission(['view statistic fitter', 'view statistic location', 'view statistic jobMap']) && (
                                <li className="sidebar-item">
                                    <Link className="sidebar-link waves-effect waves-dark sidebar-link" to="/statistics" aria-expanded="false">
                                        <i className="mdi mdi-chart-bar"/>
                                        <span className="hide-menu">
                                            {__("stats")}
                                        </span>
                                    </Link>
                                </li>
                            )}

                            { userObject.hasPermission('view compliance') && user?.type === 'fitter_system' && compliances.length > 0 && (
                                <>
                                    <li className="nav-small-cap">
                                        <i className="mdi mdi-hospital-building" />
                                        <span className="hide-menu">{__("compliance")}</span>
                                    </li>
                                    { compliances.map((compliance) => {
                                        return (
                                            <li className="sidebar-item">
                                                <Link className="sidebar-link waves-effect waves-dark sidebar-link"
                                                      to={'/compliances/'+ compliance.id}
                                                      aria-expanded="false"
                                                >
                                                    <i data-feather="book" className="feather-icon"/>
                                                    <span className="hide-menu">Standard</span>
                                                </Link>
                                            </li>
                                        );
                                    }) }
                                </>
                            )}



                            {/*
                            <li className="nav-small-cap">
                                <i className="mdi mdi-dots-horizontal" />
                                <span className="hide-menu">Abrechnung</span>
                            </li>
                            <li className="sidebar-item">
                                <Link className="sidebar-link waves-effect waves-dark sidebar-link" to="/billing" aria-expanded="false">
                                    <i data-feather="calendar" className="feather-icon"/>
                                    <span className="hide-menu">Übersicht</span>
                                </Link>
                            </li>
                            <li className="sidebar-item">
                                <Link className="sidebar-link waves-effect waves-dark sidebar-link" to="/jobs/sales" aria-expanded="false">
                                    <i data-feather="message-circle" className="feather-icon"/>
                                    <span className="hide-menu">Umsätze</span>
                                </Link>
                            </li>
                            <li className="sidebar-item">
                                <Link className="sidebar-link waves-effect waves-dark sidebar-link" to="/billing/archive" aria-expanded="false">
                                    <i data-feather="book" className="feather-icon"/>
                                    <span className="hide-menu">Archiv</span>
                                </Link>
                            </li>


                            <li className="nav-small-cap">
                                <i className="mdi mdi-dots-horizontal" />
                                <span className="hide-menu">Telefonie</span>
                            </li>
                            <li className="sidebar-item">
                                <Link className="sidebar-link waves-effect waves-dark sidebar-link" to="/pbx/calls" aria-expanded="false">
                                    <i data-feather="phone" className="feather-icon"/>
                                    <span className="hide-menu">Anrufe</span>
                                </Link>
                            </li>*/ }
                            {/*
                            <li className="sidebar-item">
                                <Link className="sidebar-link waves-effect waves-dark sidebar-link" to="/pbx/blacklist" aria-expanded="false">
                                    <i data-feather="book" className="feather-icon"/>
                                    <span className="hide-menu">Blacklist</span>
                                </Link>
                            </li>*/}
                        </ul>
                    </nav>
                </PerfectScrollbar>
            </aside>
        );
    }
}

export default LeftSidebar;