import PropTypes from "prop-types";

function Telegram(props) {
    let user = props.user;


    const showUser = props.showUser === undefined || props.showUser === true;

    return (
        <a className={"btn btn-"+ props.size +" btn-dark"} href={'https://telegram.me/'+ user} target="_blank" style={props.style} rel="noreferrer">
            <i className={'fa fa-telegram '+ (showUser ? 'm-r-5' : '')} />
            {showUser && (
                <>@{user}</>
            )}
        </a>
    );
}

export default Telegram;

Telegram.propTypes = {
    size: PropTypes.string.isRequired,
    user: PropTypes.string.isRequired,
    showUser: PropTypes.bool,
    style: PropTypes.object,
};
