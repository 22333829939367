import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import App from './App';
import reportWebVitals from './reportWebVitals';
import {LoadScript} from "@react-google-maps/api";

Sentry.init({
    dsn: "https://6804278619ce4d5cb8f14679f2f5f09d@o97209.ingest.sentry.io/6275107",
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
});

ReactDOM.render(
  <React.Fragment>
      <LoadScript
          libraries={['places']}
          googleMapsApiKey="AIzaSyD2NyzvdbyO7eXMIc3lFnlhGt69oNszzD8"
      >
          <App />
      </LoadScript>
  </React.Fragment>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
