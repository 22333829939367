import Moment from "moment";
import React  from 'react';
import {stateToBadge} from "../../../utils/helpers";

export const DisplayDateTimeColumn = (created) => {
    return (
        <>
            <span style={{display:'block'}}>
                {Moment(created).format('DD.MM.YY')}
            </span>
            <small>
                {Moment(created).format('HH:mm')} Uhr
            </small>
        </>
    );
}

export const DisplayJobTypeColumn = (jobType) => {

    if(jobType) {
        return (
            <span>
                <i className={jobType.icon} title={jobType.name} />
            </span>
        );
    }

    return '';
};

export const DisplayJobCodeColumn = (jobCode) => {
    return (
        <span>{jobCode}</span>
    );
}

export const DisplayStateColumn = (state, props) => {
    return stateToBadge(state, props);
};

export const DisplayCountryColumn = (country) => {
    return (
        <span>
            <i className={'flag-icon flag-icon-'+ country} />
        </span>
    );
}
