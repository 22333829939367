import React, {Component} from 'react';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter, ButtonGroup} from 'reactstrap';
import PropTypes from "prop-types";

import Label from "../Form/Label";
import {stateTranslationsMap, __} from "../../utils/helpers";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {addJobStatus} from "../../store/actions";


class AddStatusModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            input: {
                status: 'new',
                comment: '',
            },
        };
    }

    submit = () => {
        const {job, type} = this.props;
        const {input} = this.state;

        this.props.addJobStatus(job.id, {
            ...input,
            type,
        });
        this.toggle();
    }

    toggle = () => {
        const {modal} = this.state;
        this.setState({
            modal: !modal,
        })
    };

    renderStatusDropdown = () => {
        const states = [
            'new', 'in-progress', 'offer', 'closed', 'canceled',
        ];

        return states.map((state) => {
            const label = stateTranslationsMap.de[state];
            return (
                <option key={'state-'+ state} value={state}>{label}</option>
            );
        });
    }

    render() {
        const {
            color,
            size,
            className,
            modalClassName,
            type,
            children,
        } = this.props;

        const { modal, input } = this.state;

        let title = __("addStatus");

        if(type !== undefined && type === "comment"){
            title = __("addComment");
        }

        return (
            <>
                <Button color={color} size={size} className={className} onClick={this.toggle}>{children}</Button>
                <Modal isOpen={modal} toggle={this.toggle} className={modalClassName}>
                    <ModalHeader toggle={this.toggle}>{title}</ModalHeader>
                    <ModalBody>

                        {!type || type === "status" ? (
                            <div className="form-group m-b-15">
                                <Label>{__("status")}</Label>
                                <select className="form-control"
                                        value={input.status}
                                        onChange={(e) => {
                                            this.setState({
                                                input: {
                                                    ...input,
                                                    status: e.target.value
                                                }
                                            })
                                        }}>
                                    {this.renderStatusDropdown()}
                                </select>
                            </div>
                        ) : ''}

                        <div className="form-group">
                            <Label>{__("comment")}</Label>
                            <textarea className="form-control"
                                      value={input.comment}
                                      onChange={(e) => {
                                          this.setState({
                                              input: {
                                                  ...input,
                                                  comment: e.target.value
                                              }
                                          })
                                      }}
                            />
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <ButtonGroup>
                            <Button color="secondary" onClick={this.toggle}><i className="fa fa-times-circle" /> {__("close")}</Button>
                            <Button color="inverse" onClick={this.submit}><i className="fa fa-save" /> {__("save")}</Button>
                        </ButtonGroup>
                    </ModalFooter>
                </Modal>
            </>
        );
    }
}

const mapStatetoProps = (state) => {
    return {
    };
};

export default withRouter(connect(mapStatetoProps, { addJobStatus })(AddStatusModal));


AddStatusModal.propTypes = {
    job: PropTypes.object.isRequired,
    type: PropTypes.string,
    color: PropTypes.string,
    size: PropTypes.string,
    className: PropTypes.string,
    modalClassName: PropTypes.string,
};
