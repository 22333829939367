import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import { withRouter } from "react-router-dom";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

import {
    setPageTitle,
    setBreadCrumbs,
    getJobs,
    getJobTypes,
    getJobSources,
    setJob
} from "../../store/actions";
import JobsTable from "../../components/Tables/JobsTable";
import FilterContainer from "../../components/Filter";
import {__} from "../../utils/helpers";

class Jobs extends Component {
    constructor(props) {
        super(props);
        const addJobData = {
            code: "",
        }

        this.cacheKeys = {
            filters: 'jobs.filter',
        }

        this.ref = {
            JobsTable: null,
        }

        const filters = JSON.parse(localStorage.getItem(this.cacheKeys.filters)) || {
            jobTypes: [],
            jobSources: [],
            jobStates: [],
            countries: [],
            textSearch: "",
            fitters: [],
            claimPriorities: [],
            startDate: null,
            endDate: null,
            calls: [],
        };

        if(typeof(filters.claimPriorities) === "undefined"){
            filters.claimPriorities = [];
        }

        this.state = {
            countries: [
                {
                    'code': 'de',
                    'name': 'Deutschland',
                    'icon': 'flag-icon flag-icon-de',
                },
                {
                    'code': 'at',
                    'name': 'Österreich',
                    'icon': 'flag-icon flag-icon-at',
                },
                {
                    'code': 'ch',
                    'name': 'Schweiz',
                    'icon': 'flag-icon flag-icon-ch',
                },
                {
                    'code': 'nl',
                    'name': 'Niederlande',
                    'icon': 'flag-icon flag-icon-nl',
                }
            ],
            filters: filters,
            addJobData,
        };

        this.togglemodal.bind(this);
    }

    componentDidMount() {
        document.title = __("jobs");

        //console.log("componentDidMount", this.props.jobTypes.length, this.props.jobTypesLoading);
        if(this.props.jobTypes.length === 0 && this.props.jobTypesLoading === false)
            this.props.getJobTypes();

        if(this.props.jobSources.length === 0 && this.props.jobSourcesLoading === false)
            this.props.getJobSources();
    }

    togglemodal = () => {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    onClearCountries = (e) => {
        const filters = {
            ...this.state.filters,
            countries: [],
        };
        this.setState({
            filters
        }, () => {
            localStorage.setItem(this.cacheKeys.filters, JSON.stringify(filters))
        });
    }

    onCountryClick = (e) => {
        const value = e.target.dataset.value;
        const {countries} = this.state.filters;
        const index = countries.findIndex((element) => element === value);

        if(index === -1){
            countries.push(value);
        } else {
            countries.splice(index, 1);
        }

        const filters = {
            ...this.state.filters,
            countries: countries,
        };

        this.setState({
            filters,
        }, () => {
            localStorage.setItem(this.cacheKeys.filters, JSON.stringify(filters))
        });
    }

    onClearJobStates = (e) => {
        const filters = {
            ...this.state.filters,
            jobStates: [],
        };
        this.setState({
            filters
        }, () => {
            localStorage.setItem(this.cacheKeys.filters, JSON.stringify(filters))
        });
    }

    onJobStateClick = (e) => {
        const value = e.target.dataset.value;
        let {jobStates} = this.state.filters;

        if(jobStates === undefined)
            jobStates = [];

        const index = jobStates.findIndex((element) => element === value);

        if(index === -1){
            jobStates.push(value);
        } else {
            jobStates.splice(index, 1);
        }

        const filters = {
            ...this.state.filters,
            jobStates: jobStates,
        };

        this.setState({
            filters,
        }, () => {
            localStorage.setItem(this.cacheKeys.filters, JSON.stringify(filters))
        });
    }

    onClearJobTypes = (e) => {
        const filters = {
            ...this.state.filters,
            jobTypes: [],
        };

        this.setState({
            filters
        }, () => {
            localStorage.setItem(this.cacheKeys.filters, JSON.stringify(filters))
        });
    }

    onJobTypeClick = (e) => {
        const value = e.target.dataset.value;
        const {jobTypes} = this.state.filters;
        const index = jobTypes.findIndex((element) => element === value);

        if(index === -1){
            jobTypes.push(value);
        } else {
            jobTypes.splice(index, 1);
        }

        const filters = {
            ...this.state.filters,
            jobTypes: jobTypes,
        };

        this.setState({
            filters
        }, () => {
            localStorage.setItem(this.cacheKeys.filters, JSON.stringify(filters))
        });
    }

    onJobSourceClick = (e) => {
        const value = e.target.dataset.value;

        e.target.blur();

        let {jobSources} = this.state.filters;

        if(typeof(jobSources) === "undefined"){
            jobSources  = [];
        }

        const index = jobSources.findIndex((element) => element === value);

        if(index === -1){
            jobSources.push(value);
        } else {
            jobSources.splice(index, 1);
        }

        const filters = {
            ...this.state.filters,
            jobSources: jobSources,
        };

        this.setState({
            filters
        }, () => {
            localStorage.setItem(this.cacheKeys.filters, JSON.stringify(filters))
        });
    }

    onClearTextSearch = (e) => {
        const filters = {
            ...this.state.filters,
            textSearch: '',
        };

        this.setState({
            filters: filters
        }, () => {
            localStorage.setItem(this.cacheKeys.filters, JSON.stringify(filters))
        });
    }

    onSelectFitters = (values) => {
        const data = values === null ? [] : values.map(function(value){
            return value.value;
        });

        const filters = {
            ...this.state.filters,
            fitters: data,
        };

        this.setState({
            filters
        }, () => {
            localStorage.setItem(this.cacheKeys.filters, JSON.stringify(filters))
        });
    }

    onTextChange = (e) => {
        const filters = {
            ...this.state.filters,
            textSearch: e.target.value,
        };
        this.setState({
            filters
        }, () => {
            localStorage.setItem(this.cacheKeys.filters, JSON.stringify(filters))
        });
    };

    onClearDateRange = (e) => {
        this.onDateRangeChange(null, null);
    }

    onDateRangeChange = (start, end) => {
        const filters = {
            ...this.state.filters,
            startDate: start !== null ? start.format('DD.MM.YYYY') : null,
            endDate: end !== null ? end.format('DD.MM.YYYY') : null
        };
        this.setState({
            filters
        }, () => {
            localStorage.setItem(this.cacheKeys.filters, JSON.stringify(filters))
        });
    }

    onClearAppointmentDateRange = (e) => {
        this.onAppointmentDateRangeChange(null, null);
    }

    onAppointmentDateRangeChange = (start, end) => {
        const filters = {
            ...this.state.filters,
            appointmentStartDate: start !== null ? start.format('DD.MM.YYYY') : null,
            appointmentEndDate: end !== null ? end.format('DD.MM.YYYY') : null
        };
        this.setState({
            filters
        }, () => {
            localStorage.setItem(this.cacheKeys.filters, JSON.stringify(filters))
        });
    }

    onFiltersUpdated = (filters) => {
        this.setState({
            filters
        }, () => {
            localStorage.setItem(this.cacheKeys.filters, JSON.stringify(filters))
        })
    };

    getJobs = (meta) => {
        this.props.getJobs(
            meta
        );
    }

    setBreadCrumbs(){
        this.props.setBreadCrumbs([
            {
                name: 'Home',
                href: '/dashboard'
            },
            {
                name: __("jobs"),
                href: '#'
            },
        ]);
    }

    setPageTitle(){
        this.props.setPageTitle(__("jobs"));
    }

    reloadData(){
        if(this.ref.JobsTable !== null){
            this.ref.JobsTable.reloadData();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        //this.setPageTitle();
        //this.setBreadCrumbs();
    }

    render() {
        const {jobTypesLoading, jobTypes, jobSources, jobSourcesLoading, claimPriorities, fitters, jobs, jobsLoading, setJob} = this.props;
        const {countries, filters} = this.state;

        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Row>
                            <Col xl="9">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title m-b-0">
                                            {__("jobs")}
                                        </h4>
                                    </div>
                                    <div className="">
                                        <div>
                                            <JobsTable
                                                ref={ n => this.ref['JobsTable'] = n }
                                                loading={jobsLoading}
                                                getData={this.getJobs}
                                                setJob={setJob}
                                                filters={filters}
                                                meta={jobs.meta}
                                                data={jobs.data}
                                            />
                                        </div>
                                    </div>
                                </div>

                            </Col>
                            <Col xl="3">
                                <FilterContainer
                                    loading={jobTypesLoading}
                                    jobTypes={jobTypes}
                                    jobSources={jobSources}
                                    jobSourcesLoading={jobSourcesLoading}
                                    claimPriorities={claimPriorities}
                                    countries={countries}

                                    fitters={fitters.filter(fitter => fitter.status.status === 'active')}

                                    filters={filters}

                                    display={{
                                        jobSourcesTagCloud: true,
                                        jobTypesSelect: true,
                                        countriesSelect: true,
                                        fittersSelect: true,
                                        textSearchInput: true,
                                        dateRangePicker: true,
                                        appointmentDateRangePicker: true,
                                        callsSelect: false,
                                        jobStatesSelect: true
                                    }}

                                    onFiltersUpdated={this.onFiltersUpdated}
                                    onJobStateClick={this.onJobStateClick}
                                    onClearJobStates={this.onClearJobStates}
                                    onClearCountries={this.onClearCountries}
                                    onCountryClick={this.onCountryClick}
                                    onClearJobTypes={this.onClearJobTypes}
                                    onJobTypeClick={this.onJobTypeClick}
                                    onJobSourceClick={this.onJobSourceClick}
                                    onClearTextSearch={this.onClearTextSearch}
                                    onTextChange={this.onTextChange}
                                    onDateRangeChange={this.onDateRangeChange}
                                    onClearDateRange={this.onClearDateRange}
                                    onAppointmentDateRangeChange={this.onAppointmentDateRangeChange}
                                    onClearAppointmentDateRange={this.onClearAppointmentDateRange}
                                    onSelectFitters={this.onSelectFitters}
                                />
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = (state) => {
    const { calls, callsLoading } = state.Pbx;
    const { jobs, jobsLoading } = state.Jobs;
    const { jobTypes, jobTypesLoading } = state.JobTypes;
    const { jobSources, jobSourcesLoading } = state.JobSources;

    return {
        calls,
        callsLoading,
        jobs,
        jobsLoading,
        jobTypes,
        jobTypesLoading,
        fitters: [],
        jobSources,
        jobSourcesLoading,
    };
};

function mapDispatchToProps(dispatch) {
    let actions = bindActionCreators({ setPageTitle, setBreadCrumbs, getJobs, setJob, getJobTypes, getJobSources }, dispatch);
    return { ...actions, dispatch };
}


export default withRouter(
    connect(mapStatetoProps, mapDispatchToProps)(Jobs)
);
