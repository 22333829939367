// @flow
import {
    GET_PBX_CALL,
    GET_PBX_CALL_SUCCESS,
    GET_PBX_CALL_REASONS,
    GET_PBX_CALL_REASONS_SUCCESS,
    GET_PBX_CALLS,
    GET_PBX_CALLS_SUCCESS,
} from "./actionTypes";

const INIT_STATE = {
    error: null,
    loading: false,
    callsLoading: false,
    calls: {
        data: [],
        meta: {
            //sort_column: 'call_created',
            //sort_order: 'desc',

            sort: {
                column: 'call_created',
                order: 'desc',
            },
            current_page: 1,
            from: 0,
            last_page: 0,
            per_page: 10,
            to: 0,
            total: 0,
            path: '',
        }
    },
    callReasonsLoading: false,
    callReasons: []
};

const Pbx = (state = INIT_STATE, action) => {

    let job;

    switch (action.type) {

        case GET_PBX_CALL_REASONS:
            return {
                ...state,
                callReasonsLoading: true,
            }

        case GET_PBX_CALL_REASONS_SUCCESS:
            return {
                ...state,
                callReasonsLoading: false,
                callReasons: action.payload,
            }

        case GET_PBX_CALL:
            job = state.jobs.find((element) => {
                return element.id === action.payload.id;
            });

            if(job === undefined)
                job = {};

            return {
                ...state,
                loading: true,
                job: job,
                //tasks: []
            };
        case GET_PBX_CALL_SUCCESS:
            return {
                ...state,
                loading: false,
                jobs: state.jobs.map(function(item){
                    if(item.id !== action.payload.id){
                        return item;
                    }

                    return action.payload;
                })
            };

        case GET_PBX_CALLS:
            return {
                ...state,
                callsLoading: true,
            }
        case GET_PBX_CALLS_SUCCESS:
            return {
                ...state,
                callsLoading: false,
                calls: action.payload,
            }
        default:
            return state;
    }
};


export default Pbx;
