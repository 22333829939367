import { call, takeEvery, put } from "redux-saga/effects";

import {
    getJobTypeSuccess,
    getJobTypesSuccess
} from "./actions";

import {getApiBackend} from "../../api";

const api    = getApiBackend();

/**
 * Changes the layout type
 * @param {*} param0
 */
function* getJobType({ payload: { id } }) {
    try {
        const response = yield call(api.get, '/v2/jobs/types/'+ id)
        yield put(getJobTypeSuccess(response));

    } catch (error) { }
}

/**
 *
 * @returns {Generator<SimpleEffect<"PUT", PutEffectDescriptor<{payload: *, type: string}>>|SimpleEffect<"CALL", CallEffectDescriptor<RT | RT | RT>>, void, *>}
 */
function* getJobTypes() {
    try {
        const response = yield call(api.get, '/v2/jobs/types')

        yield put(getJobTypesSuccess(response));

    } catch (error) { }
}

/**
 * Watchers
 */

function* JobTypesSaga() {
    yield takeEvery('GET_JOB_TYPE', getJobType);
    yield takeEvery('GET_JOB_TYPES', getJobTypes);


}

export default JobTypesSaga;
