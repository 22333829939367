import React, { Component } from "react";
import Moment from 'moment';
import 'moment/locale/de';
import {Link} from "react-router-dom";
import ExternalLink from "../../../../components/Buttons/ExternalLink";

class JobTableRow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            toggleOpen: false,
        };
    }

    toggleOpen = () => {
        const {toggleOpen} = this.state;
        this.setState({
            toggleOpen: !toggleOpen
        })
    }

    renderDateColumn() {
        const {job, type} = this.props;
        const {toggleOpen} = this.state;

        switch(type){
            default:
            case undefined:
                return (
                    <span>
                        {Moment(job.created).fromNow()}
                    </span>
                );
            case 'appointment':
                return (
                    <span>
                        {Moment(job.handle.datetime.date).fromNow()}
                        { toggleOpen === true ? (
                            <span className={'d-block'}>{job.handle.date} {job.handle.time}</span>
                        ) : ''}
                    </span>
                );
            case 'wait-info':
            case 'cancellation':
                return (
                    <span>
                        {Moment(job.status.created).fromNow()}
                        { toggleOpen === true ? (
                            <span className={'d-block'}>{Moment(job.created).format('DD.MM HH:mm')}</span>
                        ) : ''}
                    </span>
                );
        }
    }

    render() {
        const {job} = this.props;
        const {toggleOpen} = this.state;

        let rowClass = '';
        let cancellationTime = 0;

        if(toggleOpen){
            rowClass = 'table-active';
        }

        if(job.status.status === 'request-cancellation'){
            cancellationTime = Moment(job.status.created).unix() - Moment(job.created).unix();
            console.log(cancellationTime);
            if(cancellationTime > (20 * 60)){
                rowClass = rowClass +' table-warning';
            }
        }

        return (
            <tr onDoubleClick={this.toggleOpen} className={rowClass}>
                <td style={{paddingRight: '.25rem', width: '20px'}}><i className={job.type.icon} title={job.type.name} /></td>
                <td style={{paddingLeft: '.25rem', width: '20px'}}><i className={'flag-icon flag-icon-'+ job.address.country} /></td>
                <td style={{width: '120px', paddingLeft: '.25rem', paddingRight: '.25rem'}}>
                    <small>
                        {this.renderDateColumn()}
                        { toggleOpen === true ? (
                            <span className={'d-block'}>Code: {job.code}</span>
                        ) : ''}

                    </small>
                </td>
                <td>
                    <span className={'d-block'}>
                        <Link to={'/fitters/'+ job.fitter.id}>
                            <small>
                                {job.fitter.display_name}
                            </small>
                        </Link>
                    </span>
                    <small>
                        {toggleOpen === true ? (
                            <span>
                                kd: {job.contact.name}
                                <br />{job.address.street} {job.address.house}
                                <br />{job.address.zipcode} {job.address.city}
                                { job.contact.phone && (
                                    <><br />tel: <a href={'tel:'+ job.contact.phoneLink}>{job.contact.phone}</a></>
                                )}
                                { job.contact.email && (
                                    <><br />email: <a href={'mailto:'+ job.contact.email}>{job.contact.email}</a></>
                                )}
                                { job.info.meeting_point && (
                                    <><br />treff: {job.info.meeting_point}</>
                                )}
                                { job.info.remark && (
                                    <><br />bem: {job.info.remark}</>
                                )}
                                <br />id: {job.code}
                            </span>
                        ) : (
                            <span>{job.address.zipcode} {job.address.city}</span>
                        )}
                    </small>
                </td>
                <td style={{paddingRight: '.25rem', width: '20px'}}>
                    <a href={'tel:+'+ job.fitter.contact.phone} className={'btn btn-xs btn-secondary'}>
                        <i className={'fa fa-phone'} />
                    </a>
                </td>
                <td style={{paddingLeft: '.25rem', width: '20px'}}>
                    <ExternalLink href={'/jobs/'+ job.id} className={'btn btn-xs btn-primary'}>
                        <i className={'fa fa-eye'} />
                    </ExternalLink>
                </td>
            </tr>
        );
    }
}

export default JobTableRow;
