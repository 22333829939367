import React, { Component } from "react";
import { Code } from 'react-content-loader';
import {connect} from "react-redux";
import {
    getComplianceRandomJob,
    getCompliances,
} from "../../../store/actions";
import {__} from "../../../utils/helpers";
import {withRouter, Redirect} from "react-router-dom";
import {bindActionCreators} from "redux";

const MyCodeLoader = () => <Code />;


class RandomJob extends Component {
    constructor(props) {
        super(props);
        const compliance_id = parseInt(this.props.match.params.id);
        this.state = {
            compliance_id,
        };

        if(this.props.compliances.length === 0)
            this.props.getCompliances();

        this.props.getComplianceRandomJob(this.props.match.params.id);
    }

    componentDidMount() {
        //alert("RandomJob");
    }

    setComplianceJob = (job) => {
        const {compliance_id} = this.state;
        this.props.dispatch({ type: 'GET_COMPLIANCE_JOB_SUCCESS', compliance_id, payload: job });
    }

    render() {
        const { compliance_id } = this.state;
        const {randomJobLoading, randomJob, compliances} = this.props;

        const boxStyle = {
            height: '300px',
            overflow: 'auto'
        };

        return (
            <div className="card">
                <div className="card-body">
                    <h4 className="card-title">
                        {__("jobWillLoaded")}

                        { randomJobLoading === false && compliances.length > 0 ? (
                            <span className="label label-danger float-right">{compliances.length}</span>
                        ) : '' }
                    </h4>

                </div>

                <div className="comment-widgets m-b-20" style={boxStyle}>
                    { randomJobLoading === true ? (
                        <div className={'m-3'}>
                            <MyCodeLoader />
                        </div>
                    ) : (
                        <div>
                            { randomJob !== null ? (
                                <Redirect to={"/compliances/"+ compliance_id +"/jobs/"+ randomJob.id }  />
                            ) : (
                                <div className={'text-center'} style={{marginTop: '80px'}}>
                                    <i className={'fa fa-check-circle text-danger'} style={{fontSize: '100px'}} />
                                </div>
                            )
                            }
                        </div>
                    )
                    }
                </div>
            </div>
        );
    }
}


function mapDispatchToProps(dispatch) {
    let actions = bindActionCreators({ getComplianceRandomJob, getCompliances }, dispatch);
    return { ...actions, dispatch };
}

const mapStatetoProps = state => {
    const { compliances, complianceJobs, randomJob, randomJobLoading } = state.Compliances;

    return {
        compliances,
        complianceJobs,
        randomJob,
        randomJobLoading,
    };
};

export default withRouter(
    connect(mapStatetoProps, mapDispatchToProps)(RandomJob)
);

