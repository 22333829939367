export const SET_FITTER = "SET_FITTER";

export const GET_FITTER = "GET_FITTER";
export const GET_FITTER_SUCCESS = "GET_FITTER_SUCCESS";

export const ADD_FITTER_STATUS = "ADD_FITTER_STATUS";
export const ADD_FITTER_STATUS_SUCCESS = "ADD_FITTER_STATUS_SUCCESS";

export const GET_FITTERS = "GET_FITTERS";
export const GET_FITTERS_SUCCESS = "GET_FITTERS_SUCCESS";

export const GET_FITTERS_BY_DISTANCE = "GET_FITTERS_BY_DISTANCE";
export const GET_FITTERS_BY_DISTANCE_SUCCESS = "GET_FITTERS_BY_DISTANCE_SUCCESS";