import {
    GET_CANCELLATION_TEMPLATE,
    GET_CANCELLATION_TEMPLATE_SUCCESS,
    GET_CANCELLATION_TEMPLATES,
    GET_CANCELLATION_TEMPLATES_SUCCESS,
} from "./actionTypes";

export const getCancellationTemplate = () => ({
    type: GET_CANCELLATION_TEMPLATE,
    payload: {}
});

export const getCancellationTemplateSuccess = response => ({
    type: GET_CANCELLATION_TEMPLATE_SUCCESS,
    payload: response.data.data
});

export const getCancellationTemplates = () => ({
    type: GET_CANCELLATION_TEMPLATES,
    payload: {}
});

export const getCancellationTemplatesSuccess = response => ({
    type: GET_CANCELLATION_TEMPLATES_SUCCESS,
    payload: response.data.data
});

