import React, { Component } from "react";
import {stateColor, stateText} from "../../utils/helpers";

class StatusBadge extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        let { state, className, prepend, append, border } = this.props;

        if(typeof prepend === 'undefined')
            prepend = '';

        if(typeof append === 'undefined')
            append = '';

        if(className){
            return (
                <span className={'label '+ className}> '+ prepend +' '+ state +' '+ append +'</span>
            );
        }

        const color = stateColor(state);

        className = "bg-light-"+ color;

        if(border !== undefined && border !== false)
            className = "border border-"+ color;

        return (
            <span className={"badge "+ className  +" text-"+ color}> {prepend} {stateText(state)} {append}</span>
        );
    }
}

export default StatusBadge;
