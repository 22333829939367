import React, { Component } from "react";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {getFitter, addJobStatus, addJobReset, patchJob, deleteJobPartialPayment} from "../../../store/actions";
import { Code } from 'react-content-loader';
import OverviewCard from "../../../components/Fitters/OverviewCard";
import StatusHistory from "../../../components/Fitters/StatusHistory";
import Locations from "../../../components/Fitters/Locations";
import {__} from "../../../utils/helpers";

const MyCodeLoader = () => <Code />;

class Fitter extends Component {
    constructor(props) {
        super(props);

        this.toasts = {
            created: null,
        }

        const fitter_id = parseInt(props.match.params.id);

        this.state = {
            fitter_id,
        };
    }

    componentDidMount() {
        const {fitter_id} = this.state;
        const {getFitter} = this.props;

        getFitter(fitter_id);

        document.title = __('partners');
    }

    getFitter(){
        return this.props.fitter;
    }

    hasOpenClaim(){
        const job = this.getJob();
        return job && job.claims && job.claims.findIndex((item) => item.state === 'open' ) !== -1;
    }

    requestWaitInfo = () => {
        const {job} = this.props;
        this.props.addJobStatus(job.id, {
            type: 'status',
            status: 'request-wait-info',
        });
    }

    requestCancellation = () => {
        const {job} = this.props;
        this.props.addJobStatus(job.id, {
            type: 'status',
            status: 'request-cancellation',
        });
    }

    render() {
        const {fitter, fitterHistoryLoading} = this.props;

        if(!fitter)
            return "";

        const tableStyle = {
            maxHeight: '300px',
            overflowY: 'auto',
        };

        document.title = __('partner') +" "+ fitter.code;

        return (
            <>
                <div className={"row"}>
                    <div className={'col-md-4'}>
                        <OverviewCard fitter={fitter} />
                    </div>
                    <div className={'col-md-8'}>
                        <div className={'card'}>
                            <div className="card-body d-flex justify-content-between align-items-center">
                                <h4 className="card-title m-b-0">{__("statusHistory")}</h4>
                            </div>

                            {fitterHistoryLoading ? (
                                <div className="card-body">
                                    <MyCodeLoader />
                                </div>
                            ) : (
                                <StatusHistory
                                    style={tableStyle}
                                    fitter={fitter}
                                />
                            )}

                        </div>

                        <div className={'card'}>
                            <div className="card-body d-flex justify-content-between align-items-center">
                                <h4 className="card-title m-b-0">{__("locations")}</h4>
                            </div>

                            {fitterHistoryLoading ? (
                                <div className="card-body">
                                    <MyCodeLoader />
                                </div>
                            ) : (
                                <Locations
                                    style={tableStyle}
                                    fitter={fitter}
                                />
                            )}

                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapStatetoProps = state => {
    const { fitter, fitterLoading, fitterHistoryLoading  } = state.Fitters;
    return { fitter, fitterLoading, fitterHistoryLoading };
}

export default withRouter(connect(mapStatetoProps, { getFitter, addJobStatus, addJobReset, patchJob, deleteJobPartialPayment })(Fitter));
