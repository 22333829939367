import React, { Component } from "react";
import Select from 'react-select'
import PropTypes from "prop-types";
import {__} from "../../utils/helpers";


class FittersSelect extends Component {
    constructor (props) {
        super(props)

        this.state = {
            selected: [],
        }

        this.fitters = React.createRef()
    }

    onDelete (i) {
        const tags = this.state.tags.slice(0)
        tags.splice(i, 1)
        this.setState({ tags })
    }

    onAddition (tag) {
        const tags = [].concat(this.state.tags, tag)
        this.setState({ tags })
    }

    options() {
        const {data} = this.props;

        if(data === undefined)
            return [];

        return data.map(function(fitter, key){
            return {
                value: fitter.id,
                label: fitter.display_name,
            };
        });

    }

    onSelectRef = ref => {
        this.selectRef = ref;
    };

    render () {
        return (
            <Select options={this.options()}
                    isMulti
                    placeholder={__("partnerSelection")}
                    name="filter-fitters"
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={this.props.onSelectFitters}
                    ref={ref => {
                        this.selectRef = ref;
                    }}
            />
        )
    }
}

export default FittersSelect;

FittersSelect.propTypes = {
    onSelectFitters: PropTypes.func.isRequired,
    data: PropTypes.array.isRequired,
    filters: PropTypes.object.isRequired,
};
