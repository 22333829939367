import React, { Component } from "react";
import moment from "moment";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import PropTypes from "prop-types";
import 'bootstrap-daterangepicker/daterangepicker.css';

class AppointmentDateRangeSelect extends Component {

    render() {
        const {filters, onDateRangeChange, onClearDateRange} = this.props;
        let {appointmentStartDate, appointmentEndDate} = filters;

        if(typeof(appointmentStartDate) === "undefined"){
            appointmentStartDate = null;
        }
        if(typeof(appointmentEndDate) === "undefined"){
            appointmentEndDate = null;
        }

        const handleCallback = (start, end, picker) => {
            onDateRangeChange(start, end);
        }

        const handleApply = (event, picker) => {
            picker.element.val(
                picker.startDate.format('DD.MM.YYYY') +
                ' - ' +
                picker.endDate.format('DD.MM.YYYY')
            );
        };

        const handleCancel = (event, picker) => {
            picker.element.val('');
            onDateRangeChange(null, null);
        };

        const initialSettings = {
            autoUpdateInput: false,
            showDropdowns: true,
            alwaysShowCalendars: true,
            showWeekNumbers: true,
            locale: {
                cancelLabel: 'clear',
                format: 'DD.MM.YYYY'
            },
            ranges: {
                Today: [moment().toDate(), moment().toDate()],
                Yesterday: [
                    moment().subtract(1, 'days').toDate(),
                    moment().subtract(1, 'days').toDate(),
                ],
                'Last 7 Days': [
                    moment().subtract(6, 'days').toDate(),
                    moment().toDate(),
                ],
                'Last 30 Days': [
                    moment().subtract(29, 'days').toDate(),
                    moment().toDate(),
                ],
                'This Month': [
                    moment().startOf('month').toDate(),
                    moment().endOf('month').toDate(),
                ],
                'Last Month': [
                    moment().subtract(1, 'month').startOf('month').toDate(),
                    moment().subtract(1, 'month').endOf('month').toDate(),
                ],
            }
        };

        if(appointmentStartDate !== undefined && appointmentStartDate !== null){
            initialSettings.appointmentStartDate = moment(appointmentStartDate, 'DD.MM.YYYY').toDate();
        }

        if(appointmentEndDate !== undefined && appointmentEndDate !== null){
            initialSettings.appointmentEndDate = moment(appointmentEndDate, 'DD.MM.YYYY').toDate();
        }

        const dateRangeString = appointmentStartDate !== null && appointmentEndDate !== null ? appointmentStartDate +' - '+ appointmentEndDate : '';

        return (
            <div className={'form-group d-inline-block w-100 m-r-15'}>
                <div className={"input-group"}>
                    <DateRangePicker
                        initialSettings={initialSettings}
                        onApply={handleApply}
                        onCancel={handleCancel}
                        onCallback={handleCallback}
                    >
                        <input className={'form-control'} readOnly={true} style={{height: '40px'}} placeholder="Termin Zeitraum"  value={dateRangeString} />
                    </DateRangePicker>
                    <div className="input-group-append">
                        <button onClick={onClearDateRange} className={'btn btn-'+ (filters.appointmentStartDate === null ? 'secondary' : 'dark') +''} type="button" disabled={filters.appointmentStartDate === null}>
                            <i className={'fa fa-times'} />
                        </button>
                    </div>
                </div>
            </div>
        )
    }

}

export default AppointmentDateRangeSelect;

AppointmentDateRangeSelect.propTypes = {
    onDateRangeChange: PropTypes.func.isRequired,
    onClearDateRange: PropTypes.func.isRequired,
    filters: PropTypes.object.isRequired,
};