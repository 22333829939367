import React, { Component } from "react";

class Plain extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    };

    componentDidMount() {

    }

    render() {
        const {children} = this.props;

        return (
            <React.Fragment>
                {children}
            </React.Fragment>
        );
    }
}
export default Plain;
