import {call, put, takeLatest} from "redux-saga/effects";

import {
    getCommissionsOverviewSuccess,
    getCommissionsSummarySuccess
} from "./actions";

import {getApiBackend} from "../../api";

const api    = getApiBackend();



/**
 * Changes the layout type
 */
function* getCommissionsSummary() {
    try {
        const response = yield call(api.get, '/v2/user/commissions/summary')

        yield put(getCommissionsSummarySuccess(response));

    } catch (error) { }
}

/**
 * Changes the layout type
 */
function* getCommissionsOverview() {
    try {
        const response = yield call(api.get, '/v2/user/commissions/overview')

        yield put(getCommissionsOverviewSuccess(response));

    } catch (error) { }
}



/**
 * Watchers
 */

function* CommissionsSaga() {
    yield takeLatest('GET_COMMISSIONS_SUMMARY', getCommissionsSummary);
    yield takeLatest('GET_COMMISSIONS_OVERVIEW', getCommissionsOverview);
}

export default CommissionsSaga;
